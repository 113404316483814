import React, { Component}  from 'react';
import axios from "../../axios/index";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../layout/Navbar";
import PhotoId from "./PhotoId";
import ShieldIcon from "../../images/shield-icon.png";
import MobileVerification from "./MobileVerification";
import UtilityBill from "./UtilityBill";
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; 
import { toast } from 'react-toastify';
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'

class SkillCertifications extends Component {

	constructor(props){
		super(props);
		this.state= {
			certificationName:"",
			certificationUrl:"",
			certificationIssuedDate:"",
			certificationExpiryDate:"",
			certificationDetails:"",
			certificationsCount:0,
			certificationArray:[],
			isCertificationEmpty:false,
			isCertificationExists:false
		}

	}

componentDidMount(){
	var newObj = {"email":this.props.auth.user.email}
      axios.post("/api/certifications/certificationlist", newObj)
  .then(res => {
  	console.log("res.data", res.data);
   this.setState({certificationArray:res.data.certifications});
   this.setState({certificationsCount:res.data.certificationsCount});


  })
  .catch(err =>
    console.log(err)
  );
}

  notifySuccess = () => toast.success('Certification added successfully', {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

onChange = (e) => {
	if(e.target.id === "certificationName") {
		this.setState({certificationName : e.target.value})
	}
	else if (e.target.id === "certificationUrl") {
		this.setState({certificationUrl: e.target.value})
	}
	else {
		this.setState({certificationDetails : e.target.value})

	}
}

delRow=(event,idx)=>{  
	event.preventDefault();

	confirmAlert({
      title: 'Confirm',
      message: 'Are you sure, you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var array = [...this.state.certificationArray];
			var index = idx;
			array.splice(index, 1);
			this.setState({certificationArray: array});

			const newCertificate = {"email":this.props.auth.user.email, "certificationArray":array, "certificationsCount":this.state.certificationArray.length };

			      axios.post("/api/certifications/addcertification", newCertificate)
      .then(res => {
         
          axios.post("/api/certifications/certificationlist", newCertificate)
          .then(res => {
          	console.log("res.data", res.data);
            this.setState({certificationArray:res.data.certifications});
            this.setState({certificationsCount:res.data.certificationsCount});

          })
          .catch(err =>
            console.log(err)
          );
      })
      .catch(err =>
        console.log(err)
      );

          }
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });

	
}

addAnotherCertificate = (e) => {
    e.preventDefault();
  	const CertificationName = this.state.certificationName;
  	const CertificationUrl = this.state.certificationUrl;
  	const CertificationIssuedDate = this.state.certificationIssuedDate;
  	const CertificationExpiryDate = this.state.certificationExpiryDate;
  	const CertificationDetails = this.state.certificationDetails;
  	const CertificationsCount = this.state.certificationsCount;


    if(CertificationName=== "" || CertificationUrl==="" || CertificationIssuedDate==="" || CertificationDetails ==="") {
    	this.setState({isCertificationEmpty:true});
    }
    else {

  	        this.setState({certificationName:""});
            this.setState({certificationUrl:""});
            this.setState({certificationDetails:""});
            this.setState({certificationIssuedDate:new Date()});
            this.setState({certificationExpiryDate:new Date()});
            document.getElementById("certificationName").value="";
            document.getElementById("certificationUrl").value="";
            document.getElementById("certificationDetails").value="";
            document.getElementById("certificationIssuedDate").value="";
            document.getElementById("certificationExpiryDate").value="";

      this.setState({isCertificationEmpty:false});
      this.setState({certificationsCount:this.state.certificationArray.length})
      var newObj = {"email":this.props.auth.user.email,"certificationName":CertificationName, "certificationUrl":CertificationUrl,"certificationIssuedDate":CertificationIssuedDate,"certificationExpiryDate":CertificationExpiryDate, "setCertificationDetails":CertificationDetails, "isApproved":false};
      var list = this.state.certificationArray;
      var newlist = JSON.stringify(list);
      var currObjCertificationName = JSON.stringify(newObj.certificationName.trim());
        if (newlist.indexOf(currObjCertificationName.trim()) !== -1) {
          this.setState({isCertificationExists:true});
        }else {
          this.setState({isCertificationExists:false});
          this.setState({certificationArray:[...this.state.certificationArray,{"certificationName":CertificationName, "certificationUrl":CertificationUrl,"certificationIssuedDate":CertificationIssuedDate,"certificationExpiryDate":CertificationExpiryDate, "certificationDetails":CertificationDetails, "isApproved":false}]}, () => {
      const newCertificate = {"email":this.props.auth.user.email, "certificationArray":this.state.certificationArray,"certificationsCount":this.state.certificationArray.length };
      axios.post("/api/certifications/addcertification", newCertificate)
      .then(res => {
          this.notifySuccess();
          axios.post("/api/certifications/certificationlist", newCertificate)
          .then(res => {
          	console.log("res.data", res.data);
            this.setState({certificationArray:res.data.certifications});
            this.setState({certificationsCount:res.data.certificationsCount});
          })
          .catch(err =>
            console.log(err)
          );
      })
      .catch(err =>
        console.log(err)
      );
    });

        }
    }
}

render() {

  return (
    <React.Fragment>
    <ToastContainer />
      <div className="row" >
    <div className="col s12 module">
    <h4>Add Skill Certification
    	<button className="btn right black" onClick={this.addAnotherCertificate}>Save / Add New</button>
    </h4>
    {this.state.isCertificationEmpty && <p className="red-text">Please enter all the details before you save</p>}
    {this.state.isCertificationExists && <p className="red-text">A certification with the same name already exists</p>}
      <div className="row">
        <div className="input-field col s12">
          <input id="certificationName" type="text" className="validate"  autoComplete="off"  onChange={this.onChange} />
          <label htmlFor="certificationName" className="active">Enter Your Skill Certification Name</label>
        </div>

        <div className="input-field col s12">
          <input id="certificationUrl" type="text" className="validate"  autoComplete="off"  onChange={this.onChange}/>
          <label htmlFor="certificationUrl" className="active">Skill Certification Url</label>
        </div>

        <div className="input-field col s6 full-width-input">
          <DatePicker id="certificationIssuedDate" selected={this.state.certificationIssuedDate} autoComplete="off"  className="datepicker" 
          onChange={date => this.setState({certificationIssuedDate:date})} maxDate={addDays(new Date(), 0)} 
          peekNextMonth showMonthDropdown showYearDropdown dropdownMode= "scroll" />
          <label htmlFor="certificationIssuedDate" className="active">Issued Date</label>
        </div>
        <div className="input-field col s6 full-width-input">
          <DatePicker id="certificationExpiryDate" selected={this.state.certificationExpiryDate} autoComplete="off" className="datepicker" 
          onChange={date => this.setState({certificationExpiryDate:date})} minDate={subDays(new Date(), 0)}
          showMonthDropdown showYearDropdown dropdownMode= "scroll" />
          <label htmlFor="certificationExpiryDate" className="active">Expiry Date</label>
        </div>

        <div className="input-field col s12">
        <div className="profile-summary-textarea">
          <textarea id="certificationDetails" className="materialize-textarea" onChange={this.onChange}></textarea>
        </div>
          <label htmlFor="certificationDetails" className="active">Description</label>
        </div>
      </div>

      	{this.state.certificationArray.map(function(d, idx){
	        return (<>
	        		<div style={{borderTop: "1px solid #e4e4e4", paddingBottom:"20px"}}>
	        			<div><button style={{position:"relative", top:"25px"}} className="del-row right" onClick={(event) => this.delRow(event, idx)}>Delete</button></div>
		        		<div><h4>{d.certificationApproved && <img src={ShieldIcon} alt="Approved" /> } {d.certificationName}</h4></div>
                		<div style={{paddingBottom:"10px", paddingTop:"10px"}}>Issued Date : <b>{new Intl.DateTimeFormat('en-US').format( new Date(d.certificationIssuedDate))}</b>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Expiry Date : <b>{new Intl.DateTimeFormat('en-US').format( new Date(d.certificationExpiryDate))}</b></div>		        		
		        		<div>{d.certificationDetails}</div>
		        		<div style={{paddingBottom:"10px", paddingTop:"10px"}}><a target="_blank" href={d.certificationUrl}>View Certificate</a></div>
	        		</div></>)
	      }.bind(this))}

      </div>
      </div>
    </React.Fragment>
  )
}
};

SkillCertifications.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {   }
)(SkillCertifications);