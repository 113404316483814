import axios from "../axios/index";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => 
          history.push("/registration-success"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Confirm Email
export const confirmEmail = (userData) => dispatch => {
  axios
    .post("/api/users/confirmemail", userData)
    .then(res => 
          {
            console.log(res);
            const { email } = res.data;
            console.log(email);
            localStorage.setItem("validation_email", email);
          }
          )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Register User
export const verifyEmail = (userData, history) => dispatch => {
  axios
    .post("/api/users/verifyemail", userData)
    .then(res => 
          history.push("/verification-email-sent"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Create User
export const createAdminUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => 
          history.push("/admin-users"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      console.log("login decoded",decoded);
      // Set current user
      dispatch(setCurrentUser(decoded));

    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set screenname
export const setScreenName = (userData, history) => dispatch => {
  axios
    .post("/api/users/screenname", userData)
    .then(res => history.push("/edit-profile"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// remove profile pic
export const removeProfilePicture = (userData, history) => dispatch => {
  axios
    .post("/api/users/removepic", userData)
    .then(res => {
      console.log('remove profile picture response',res);
      history.push("/edit-profile")
  })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Forgot Password
export const forgotPassword = (userData, history) => dispatch => {
  axios
    .post("/api/users/forgotpassword", userData)
    .then(res =>{
      console.log('forgot password response',res);

    } )

    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Forgot Password
export const checkEmail = (userData) => dispatch => {
  axios
    .post("/api/users/checkemail", userData)
    .then(res =>{
      console.log('forgot password response',res);

    } )

    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Reset Password
export const resetPassword = (userData, history) => dispatch => {

  axios
    .post("/api/users/resetpassword", userData)
    .then(res =>{
      history.push("/reset-password-success");
    } )

    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// update user

export const updateUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/updateProfile", userData)
    .then(res => {
      history.push("/id-verification");
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
