import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser, createAdminUser } from "../../actions/authActions";
import classnames from "classnames";
import logo from "../layout/logo.png";
import Navbar from "../layout/Navbar";

class CreateUser extends Component {
  constructor() {
    super();
    this.state = {
      screenname: "",
      email: "",
      userRole: "",
      passwordValidate: false,
      password2Validate: false,
      emailValidate: false,
      password: "",
      password2: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/dashboard");
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onPasswordChange = (e) => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;
    if (e.target.value !== "") {
      if (e.target.value.match(passw)) {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ passwordValidate: false });
        this.setState({ errors: {} });
        return true;
      } else {
        this.setState({ passwordValidate: true });
        this.setState({ errors: {} });
        e.target.focus();
        return false;
      }
    } else {
      this.setState({ passwordValidate: false });
      this.setState({ errors: {} });
    }
  };

  onPassword2Change = (e) => {
    if (e.target.value !== "") {
      if (e.target.value === this.state.password) {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errors: {} });
        this.setState({ password2Validate: false });
        return true;
      } else {
        this.setState({ password2Validate: true });
        this.setState({ errors: {} });
        e.target.focus();
        return false;
      }
    } else {
      this.setState({ password2Validate: false });
      this.setState({ errors: {} });
    }
  };

  onEmailChange = (e) => {
    if (e.target.value !== "") {
      var pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!pattern.test(e.target.value)) {
        this.setState({ emailValidate: true });
        this.setState({ errors: {} });
        e.target.focus();
        return false;
      } else {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errors: {} });
        this.setState({ emailValidate: false });
        return true;
      }
    } else {
      this.setState({ emailValidate: false });
      this.setState({ errors: {} });
    }
  };

  onUserRole = (e) => {
    if (e.target.value !== "" || e.target.value !== -1) {
      this.setState({ [e.target.id]: e.target.value });
      this.setState({ errors: {} });

      return true;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ emailValidate: false });
    this.setState({ passwordValidate: false });
    this.setState({ password2Validate: false });
    const newUser = {
      screenname: this.state.screenname,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      userRole: this.state.userRole,
      isActive: true,
    };

    this.props.createAdminUser(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;
    const categoryList = this.state.categorylist;
    const self = this;
    const showRow = this.state.showTr;
    const HideSkills = this.state.HideSkills;
    const err = this.state.errors;
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />

        <div
          className="container auth"
          style={{ marginTop: "50px", borderRadius: "20px" }}
        >
          <div className="row">
            <div className="col s8 offset-s2">
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>Create User</h4>
              </div>

              <form noValidate onSubmit={this.onSubmit}>
                <div
                  className="input-field col s12"
                  style={{ display: "none" }}
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.screenname}
                    error={errors.screenname}
                    id="screenname"
                    type="text"
                    className={classnames("", {
                      invalid: errors.screenname,
                    })}
                  />
                  <label htmlFor="name">Screen Name</label>
                  <span className="red-text">{errors.screenname}</span>
                </div>
                <div className="input-field col s12">
                  <input
                    onChange={this.onChange}
                    onBlur={this.onEmailChange}
                    value={this.state.email}
                    error={errors.email}
                    id="email"
                    type="email"
                    className={classnames("", {
                      invalid: errors.email,
                    })}
                  />
                  <label htmlFor="email">Email</label>
                  <span className="red-text">{errors.email}</span>
                  {this.state.emailValidate && (
                    <span className="red-text">A valid email is required</span>
                  )}
                </div>
                <div className="input-field col s12">
                  <input
                    onChange={this.onChange}
                    onBlur={this.onPasswordChange}
                    value={this.state.password}
                    error={errors.password}
                    id="password"
                    type="password"
                    className={classnames("", {
                      invalid: errors.password,
                    })}
                  />
                  <label htmlFor="password">Password</label>
                  <span className="red-text">{errors.password}</span>
                  {this.state.passwordValidate && (
                    <span className="red-text">
                      A Password must contain between 6 to 30 characters, at
                      least 1number, 1 uppercase and 1 lowercase letter
                    </span>
                  )}
                </div>
                <div className="input-field col s12">
                  <input
                    onChange={this.onChange}
                    onBlur={this.onPassword2Change}
                    value={this.state.password2}
                    error={errors.password2}
                    id="password2"
                    type="password"
                    className={classnames("", {
                      invalid: errors.password2,
                    })}
                  />
                  <label htmlFor="password2">Confirm Password</label>
                  <span className="red-text">{errors.password2}</span>
                  {this.state.password2Validate && (
                    <span className="red-text"> Passwords must match</span>
                  )}
                </div>
                <div className="input-field col s12">
                  <select
                    onChange={this.onUserRole}
                    value={this.state.userRole}
                    error={errors.userRole}
                    id="userRole"
                    className={classnames("browser-default", {
                      invalid: errors.userRole,
                    })}
                  >
                    <option value="-1">Please select</option>
                    <option value="1">Admin User</option>
                    <option value="4">Operational Manager</option>
                    <option value="5">Resourcer</option>
                    <option value="3">Service Manager</option>
                  </select>
                  <label htmlFor="User Role">Select User Role</label>
                  {this.state.userRoleValidate && (
                    <span className="red-text">
                      Please select the user role
                    </span>
                  )}
                </div>
                <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                  <button
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                    }}
                    type="submit"
                    className="btn btn-large waves-effect waves-light hoverable black accent-3"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CreateUser.propTypes = {
  registerUser: PropTypes.func.isRequired,
  createAdminUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser, createAdminUser })(
  CreateUser
);
