import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import "../dashboard/dashboard.css";
import SocialIcon from "../../images/team.png";
import CallIcon from "../../images/call-icon.png";
import EmailIcon from "../../images/email-icon.png";
import ShieldIcon from "../../images/shield-icon.png";
import OrangeShieldIcon from "../../images/orange-shield-icon.png";
import axios from "../../axios/index";
import { HashLink as Link } from "react-router-hash-link";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      profileImage: "",
      userdetails: {},
      email: "",
      contactdetails: {},
      skillMatrix: [],
      socialProfiles: [],
      extensionError: false,
      fileDimensionError: false,
      fileSizeError: false,
      certificationArray: [],
      customerRatings: [],
      completedJobs: 0,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  async componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    await axios
      .post("/api/users/userdetails", { _id: urlParams.get("id") })
      .then((res) => {
        this.setState({
          userdetails: res.data,
          email: res.data.email,
          contactdetails: res.data.contactdetails,
          skillMatrix: res.data.skillMatrix,
          socialProfiles: res.data.socialProfiles,
          profileImage: res.data.profileImage,
        });
      })
      .catch((err) => console.log(err));

    await axios
      .get(
        `/api/resource/freelancercustomerratings?freelancerEmail=${this.state.email}`
      )
      .then((res) => {
        this.setState({
          customerRatings: res.data,
        });
      })
      .catch((err) => console.log(err));

    await axios
      .get(
        `/api/resource/freelancerProjectCount?freelancerEmail=${this.state.email}`
      )
      .then((res) => {
        this.setState({
          completedJobs: res.data,
        });
      })
      .catch((err) => console.log(err));

    var newObj = { email: this.props.auth.user.email };
    axios
      .post("/api/certifications/certificationlist", newObj)
      .then((res) => {
        console.log("res.data", res.data);
        this.setState({ certificationArray: res.data.certifications });
      })
      .catch((err) => console.log(err));
  }

  onChangeHandler = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
        loaded: 0,
      },
      () => {
        var ext = this.state.selectedFile.name.split(".").pop();

        var fileSize = this.state.selectedFile.size;

        if (ext !== "png" && ext !== "jpg" && ext !== "jpeg" && ext !== "gif") {
          this.setState({ extensionError: true });
          this.setState({ fileSizeError: false });
          this.setState({ fileDimensionError: false });
        } else {
          this.setState({ extensionError: false });
          this.setState({ fileSizeError: false });
          this.setState({ fileDimensionError: false });

          var file = this.state.selectedFile;
          if (file) {
            var img = new Image();
            img.src = window.URL.createObjectURL(file);

            img.onload = () => {
              var width = img.naturalWidth,
                height = img.naturalHeight;

              window.URL.revokeObjectURL(img.src);

              if (width > 2000 || height > 2000) {
                this.setState({ fileDimensionError: true });
              } else {
                this.setState({ fileDimensionError: false });

                if (fileSize > 2000000) {
                  this.setState({ fileSizeError: true });
                } else {
                  this.setState({ fileSizeError: false });

                  const data = new FormData();
                  data.append("email", this.state.email);
                  data.append("id", this.state.userdetails._id);
                  data.append("file", this.state.selectedFile);
                  axios.post("/api/users/upload", data, {}).then((res) => {
                    this.setState({ profileImage: res.data });
                  });
                }
              }
            };
          }

          this.setState({ extensionError: false });
        }
      }
    );
  };

  render() {
    const { user } = this.props.auth;

    const userDetails = this.state.userdetails;
    const contactdetails = this.state.contactdetails;
    const skillMatrix = this.state.skillMatrix;
    const socialProfiles = this.state.socialProfiles;
    const { customerRatings } = this.state;
    var profilePic = this.state.profileImage;
    if (profilePic !== undefined) {
      if (profilePic.length > 0) {
        profilePic = this.state.profileImage.replace("public", "");
      }
    }
    const customersWhoRated = customerRatings.filter((e) => e.rating);

    const totalRating = `${customersWhoRated.reduce(
      (x, y, i, arr) => x + y.rating / arr.length,
      0
    )}`;
    const starArray = [...Array(5).keys()];

    return (
      <>
        <Navbar
          current={this.props.history.location.pathname}
          viewProfileOnly={true}
        />
        {(userDetails && Object.keys(userDetails).length && (
          <>
            <div style={{ height: "75vh" }} className="container">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div
                  className="container grid-container"
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className="col s12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ marginTop: "10px" }}>
                      {userDetails.screenname}'s Profile
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "120px" }}>
                <div className="col s12 module">
                  <div className="row profile-container">
                    <div className="input-field col s12 profile-pic-container">
                      {this.state.profileImage ? (
                        <img
                          src={`https://devfreelancersapi.supportpod.com/${profilePic}`}
                          id="profile-pic"
                          alt="Profile image"
                        />
                      ) : null}
                      {/* <input
                        type="file"
                        title="Update profile picture"
                        name="file"
                        // onChange={this.onChangeHandler}
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          zIndex: 101,
                        }}
                      /> */}
                    </div>
                    {this.state.extensionError && (
                      <p className="extension-error red-text">
                        Please upload a valid image
                        <br />
                        (png, jpg, jpeg, gif)
                      </p>
                    )}
                    {this.state.fileDimensionError && (
                      <p className="extension-error red-text">
                        Please select a image below 2000 px
                      </p>
                    )}
                    {this.state.fileSizeError && (
                      <p className="extension-error red-text">
                        Maximum file size allowed is 2MB
                      </p>
                    )}

                    <div className="input-field col s12 profile-pic-screenname">
                      <h5 style={{ textTransform: "uppercase" }}>
                        {userDetails.screenname}
                      </h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col s12">
                      {/* <button className="right edit-btn">
                        <Link to="/edit-profile"> Edit</Link>
                      </button> */}
                      <div
                        className="left col s6"
                        style={{ marginTop: "-20px" }}
                      >
                        <h5>
                          {userDetails.firstname} {userDetails.lastname}
                        </h5>
                        <h6>{userDetails.profiletitle} </h6>
                        <p>
                          {contactdetails.region}, {contactdetails.country}
                        </p>
                      </div>
                      {/* <div className="left col s4 offset-s2">
                        <p>
                          <img
                            src={CallIcon}
                            alt="Call Icon"
                            style={{ width: "18px" }}
                          />
                          &nbsp; {contactdetails.phone}
                        </p>
                        <p>
                          <img
                            src={EmailIcon}
                            alt="Email Icon"
                            style={{ width: "18px" }}
                          />
                          &nbsp; {userDetails.email}
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col s12">
                    <p>{userDetails.profilesummary}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 module ratingblock">
                  <h6>{`Number of jobs completed: ${
                    this.state.completedJobs ? this.state.completedJobs : "None"
                  }`}</h6>
                </div>
              </div>
              {customersWhoRated.length && (
                <div className="row">
                  <div className="col s12 module ratingblock">
                    <h6 className="ratingHead">Customer Ratings</h6>
                    {starArray.map((e) => (
                      <span className="starIcon">
                        <span
                          style={{
                            width:
                              (+totalRating.slice(0, 1) === e &&
                                totalRating % 1 !== 0 &&
                                `${(totalRating % 1) * 100}%`) ||
                              "100%",
                          }}
                          className={`star ${(e < +totalRating &&
                            "activeStar") ||
                            ""}`}
                        >
                          &#9733;
                        </span>
                        &#9733;
                      </span>
                    ))}
                    <h6>{`Average Rating : ${Number(totalRating).toFixed(
                      1
                    )} out of 5 from ${
                      customersWhoRated.length
                    } review(s).`}</h6>
                    <hr
                      style={{ border: "3px solid #f0f0f0", marginTop: "10px" }}
                    />
                    <div className="customerRatings">
                      {customersWhoRated.map((review) => (
                        <>
                          <span className="ratingCustName">
                            {review.customerName.replace("-", "")}
                          </span>
                          {starArray.map((e) => (
                            <span className="starIcon">
                              <span
                                style={{
                                  width:
                                    (+review.rating.slice(0, 1) === e &&
                                      review.rating % 1 !== 0 &&
                                      `${(review.rating % 1) * 100}%`) ||
                                    "100%",
                                }}
                                className={`star ${(e < +review.rating &&
                                  "activeStar") ||
                                  ""}`}
                              >
                                &#9733;
                              </span>
                              &#9733;
                            </span>
                          ))}
                          <p>{review.remarks}</p>

                          <hr style={{ border: "1px solid #f1f1f1" }} />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col s12 module">
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Categories</th>
                        <th>Skills</th>
                        <th className="txt_align_c">Ranking</th>
                        <th className="txt_align_c">Years of Exp</th>
                        <th className="txt_align_c">SupportPod Rating</th>
                        <th style={{ position: "relative" }}>
                          {/* <button className="right add-btn small-font">
                            <Link to="/edit-profile#skills-table">+ Add</Link>
                          </button> */}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {skillMatrix.map(function(d, idx) {
                        return (
                          <tr key={idx}>
                            <td>{d.selectedCategory}</td>
                            <td>{d.selectedSkills}</td>
                            <td className="txt_align_c">{d.selectedRanking}</td>
                            <td className="txt_align_c">
                              {d.selectedYearsOfExp}
                            </td>
                            <td className="txt_align_c">
                              {d.supportpodRating}
                              {d.supportpodRating >= 7 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    marginLeft: "10px",
                                    width: "16px",
                                  }}
                                  src={ShieldIcon}
                                  alt="Rating"
                                />
                              ) : (
                                d.supportpodRating >= 1 && (
                                  <img
                                    style={{
                                      position: "absolute",
                                      marginLeft: "10px",
                                      width: "16px",
                                    }}
                                    src={OrangeShieldIcon}
                                    alt="Rating"
                                  />
                                )
                              )}
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {this.state.certificationArray.length > 0 && (
                <div className="row">
                  <div className="col s12 module">
                    <h6>Skills & Certification</h6>
                    {/* <button className="right edit-btn">
                      <Link to="/edit-profile#certifications"> Edit</Link>
                    </button> */}
                    {this.state.certificationArray.map(
                      function(d, idx) {
                        return (
                          <div
                            style={{
                              borderTop: "1px solid #e4e4e4",
                              paddingBottom: "20px",
                            }}
                          >
                            <div>
                              <h4>{d.certificationName}</h4>
                            </div>
                            <div>
                              Issued Date :
                              <b>
                                {new Intl.DateTimeFormat("en-US").format(
                                  new Date(d.certificationIssuedDate)
                                )}
                              </b>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Expiry Date :
                              <b>
                                {new Intl.DateTimeFormat("en-US").format(
                                  new Date(d.certificationExpiryDate)
                                )}
                              </b>
                            </div>
                            <div>{d.certificationDetails}</div>
                            <div>
                              <a href={d.certificationUrl} target="_blank">
                                View Certificate
                              </a>
                            </div>
                          </div>
                        );
                      }.bind(this)
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )) || (
          <div className="loader">
            <h2>No Freelancer Found </h2>
          </div>
        )}
      </>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Profile);
