import { GET_CUSTOMER_DETAILS } from "../actions/customer";


const initialState = {
  email: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        email: action.payload
      };
    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
