import React, { useState, useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from "../../axios/index";
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Navbar from "../layout/Navbar";
import Menu from "../dashboard/Menu";
import Overlay from "../layout/Overlay";
import AddIcon from "../../images/add-icon.png";
import UploadIcon from "../../images/upload-icon.png";
import ZoomIcon from "../../images/zoom-in.png";
import SuccessIcon from "../../images/success.png";
import 'react-toastify/dist/ReactToastify.css';
import './profile.css';
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { API_URL } from '../utils/constants';

  const IdVerification = (props) => {
  const [file, setFile] = useState(null); // state for storing actual image
  const [idPRoof, setIdProof] = useState(null);
  const [idPRoofPrev, setIdProofPrev] = useState(null);
  const [idproofPrevBtn, setIdProofPrevBtn] = useState(null);
  const [showIdProof, setShowIdProof] = useState(false);
  const [showIdSuccess, setShowIdSuccess] = useState(false);
  const [userId, setUserId] = useState('');
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage
  const [email, setEmail] = useState(localStorage.getItem('emailid'));
  const [errorMsg, setErrorMsg] = useState('');
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
  const [showIdUploader, setShowIdUploader] = useState(false);
  const [isOVerlay, setIsOVerlay] = useState(false);
  const [viewLargePhotoId, setViewLargePhotoId] = useState(false);
  const [isPhotoIdVerified, setIsPhotoIdVerified] = useState(false);

  const dropRef = useRef();
  const handleInputChange = (event) => {
    
  };

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);
    setIdProof(uploadedFile);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };

    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
    dropRef.current.style.border = '2px dashed #e9ebeb';
  };

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData()
       data.append('email',email)
       data.append('id',userId)
       data.append('file', idPRoof)
       axios.post("/api/users/uploadid", data, { 
      }).then(res => {
       setIdProof(res.data)
       setShowIdUploader(false)
       setIsOVerlay(false)
       notifySuccess()
       setTimeout(() => {
          setShowIdSuccess(false)
       }, 5000);
     })
  };

  const notifySuccess = () => toast.success('Photo id has been uploaded successfully', {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const showIdFunc = (event) => {
    event.preventDefault();
    setShowIdUploader(true);
    setIsOVerlay(true);
  }

  const hideIdFunc = (event) => {
    event.preventDefault();
    setShowIdUploader(false);
    setIsOVerlay(false);
    setViewLargePhotoId(false);
  }

  const viewLargeImage = (event) => {
    setViewLargePhotoId(true);
    setIsOVerlay(true);
  }

  useEffect(() => {
    
  setEmail(props.auth.user.email);
  setUserId(props.auth.user.id);

  const userData = {email:props.auth.user.email}

    axios
    .post("/api/users/userdetails", userData)
    .then(res => {
        // console.log(res.data.idProof)
        if(res.data.isPhotoIdVerified) {

          setIsPhotoIdVerified(true);
        }
        else {
          setIsPhotoIdVerified(false);
        }
        if(res.data.idProof.match(/\.(jpeg|jpg|png)$/)) {
            setIdProofPrevBtn("");
            setIdProofPrev(res.data.idProof);
          }
        else {
          setIdProofPrev("");
          setIdProofPrevBtn(res.data.idProof);
        }          
       
    })
    .catch(err =>
      console.log(err)
    );
  });

  var previewAdd ="";
  var idproof = idPRoofPrev;
    if(idproof !== null){

      if (idproof.length > 0) {
         idproof = idproof.replace('public','');
         previewAdd = <img src={`https://devfreelancersapi.supportpod.com${idproof}`} alt="ID Proof" />;
          
      }
    }
    var idproofBtn = idproofPrevBtn;
    if(idproofBtn !== null){

      if (idproofBtn.length > 0) {
         idproofBtn = idproofBtn.replace('public','');
         previewAdd = <div >Preview not available <br/> <a className="btn black view-btn" target="_blank" href={`https://devfreelancersapi.supportpod.com${idproofBtn}`}  >View File</a></div>;
      }
    }

  return (
    <React.Fragment>
      <div className="idProof-block uploader-display-section">
          <h4>Id Card</h4>
          <div className="preview-section" >
              {previewAdd ? (<div>{previewAdd}</div>) : (
              <div><span><img src={AddIcon} alt="Add Icon" /></span>
              <p>Add Id Card</p>
              </div>
              )}
               {isPhotoIdVerified ? <></> : 
              <button onClick={showIdFunc} className="upload"></button>}
              {idproof ? (<button className="view-large" onClick={viewLargeImage}><img src={ZoomIcon} width="18"/></button> ) :(null)}
          </div>
      </div>
      {isPhotoIdVerified && <><img src={SuccessIcon} style={{position: "relative", top : "10px", width:"32px"}} /> Photo Id Verified </>}
      {showIdUploader ?
      (<div className="row">
        <Form className="search-form" onSubmit={handleOnSubmit}>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
       
        <div className="upload-section">
          <h6>ID Card</h6>
          <p>Upload Photo ID</p>
          <Dropzone multiple={false} onDrop={onDrop} onDragEnter={() => updateBorder('over')} onDragLeave={() => updateBorder('leave')}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
                <input {...getInputProps()} />
                <p style={{ margin : "0 auto", lineHeight : "14px", borderRadius : "50%", marginTop : "120px", cursor:"pointer"}}><img src={UploadIcon} alt="Upload Icon" style={{width:"42px"}} /></p>
                <p>Upload</p>
                {file && (
                  <div>
                   
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          <button type="button" className="close-btn" onClick={hideIdFunc}>+</button>
          <button
                  style={{
                    width: "180px",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    position: "absolute",
                    top : "440px",
                    left : "50%",
                    marginLeft : "-90px"

                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3 "
                >
                  Submit
                </button>
          {previewSrc ? (
            isPreviewAvailable ? (
              <div className="image-preview">
                <img className="preview-image" src={previewSrc} alt="Preview" style={{maxWidth : "100%"}}/>
              </div>
            ) : (
              <div className="preview-message">
                <p>No preview available for this file</p>
              </div>
            )
          ) : (
            <div className="preview-message">
              
            </div>
          )}
        </div>
      </Form>
     
      </div> ) : null
    }
     
      {viewLargePhotoId &&
        <div className="popup" >
            <button type="button" className="close-btn" onClick={hideIdFunc}>+</button>
            {idproof ? (<img src={`https://devfreelancersapi.supportpod.com${idproof}`} alt="id proof" style={{width:"100%"}} />) : (null) }
        </div>
      }
      {isOVerlay ? 
        (<Overlay />) :  (null)
      }

    </React.Fragment>
  );
};

IdVerification.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(IdVerification);