import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeProfilePicture } from "../../actions/authActions";

// import { updateUser, removeProfilePicture } from "../../actions/authActions";
// import TransparentOverlay from "../layout/TransparentOverlay";
// import Menu from "../dashboard/Menu";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import SocialIcon from "../../images/team.png";
// import CallIcon from "../../images/call-icon.png";
// import EmailIcon from "../../images/email-icon.png";
// import ImageUploader from "react-images-upload";
// import { confirmAlert } from "react-confirm-alert";
// import ReactTooltip from "react-tooltip";

import { createBid } from "../../actions/bidActions";
import Navbar from "../layout/Navbar";
import "../dashboard/dashboard.css";
import LeftArrow from "../../images/left-arrow.png";
import PaperClip from "../../images/paperclip-icon.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../axios/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import AddSkillPopup from "../profile/AddSkillPopup";
import NoData from "./NoData";
import { CUSTOMER_URL, URL_PARAM } from "../../utils/Config";
import ClearIcon from "../../images/clear-icon.png";

const NoDataConst = (props) => (
  <NoData message="No Skills Selected" {...props} />
);

class AddBids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sameObject: false,
      screenname: "",
      first_name: "",
      last_name: "",
      profile_summary: "",
      email: "",
      profile_title: "",
      category: "",
      customer: "",
      customerEmail: "",
      region: "",
      phone: "",
      zip_code: "",
      social_links: [],
      temptask: "",
      categories: [],
      skills: [],
      categorySkills: [],
      ranking: [],
      yearsOfExp: [],
      errors: {
        title: "",
        category: "",
        skills: "",
        customer: "",
      },
      selectedSkillMatrix: [],
      skillMatrix: [],
      selectedCategory: "",
      selectedSkills: "",
      selectedRanking: "",
      selectedYearsOfExp: "",
      categoryList: [],
      tempRow: {
        selectedCategory: "",
        selectedSkills: "",
        selectedRanking: "",
        selectedYearsOfExp: "",
      },
      userdetails: {},
      contactdetails: {},
      isSocialEmpty: false,
      isSkillEmpty: false,
      pictures: [],
      profileImage: "",
      extensionError: false,
      fileDimensionError: false,
      fileSizeError: false,
      showCategoryList: false,
      showCustomerList: false,
      showServiceRequestIdList: false,
      options: [],
      startDate: "",
      endDate: "",
      endDateValidate: false,
      minBudget: "",
      maxBudget: "",
      titleError: false,
      descriptionError: false,
      selectedSkillMatrixError: false,
      categoryError: false,
      startDateError: false,
      endDateError: false,
      title: "",
      description: "",
      serviceRequestId: "",
      attachments: [],
      selectedItems: [],
      customerList: [],
      serviceRequestIdList: [],
      openAddSkillPopup: false,
      selectedFile: [],
      uploadFilesMaping: [],
    };

    this.onDrop = this.onDrop.bind(this);
  }

  async componentDidMount() {
    console.log(this.props.auth);
    // const userData = { email: this.props.auth.user.email };

    // Service Request categories

    await fetch(
      CUSTOMER_URL +
        "/TicketProblem/GetAllTicketProblemTypes?isactive=1&URL=" +
        URL_PARAM
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ categoryList: data }, () => {
          console.log(this.state.categoryList);
        });
      });

    await fetch(CUSTOMER_URL + "/Customer/GetAllCustomersFL?URL=" + URL_PARAM)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ customerList: data }, () => {
          console.log(this.state.customerList);
        });
      });

    await this.fetchCategories();
  }

  async fetchCategories() {
    return axios
      .get("/api/categories/categorylist")
      .then((res) => {
        const modifiedCategories =
          res.data &&
          res.data.length &&
          res.data
            .filter((e) => e.isActive)
            .map((item) =>
              item.skills.map((skill) => ({
                group: item.categoryname,
                label: skill,
                id: skill,
              }))
            )
            .flat();
        this.setState({ categories: modifiedCategories });
      })
      .catch((err) => console.log(err));
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    //this.setState({ [e.target.id]: e.target.value.split(' ').join('')});

    const { errors } = this.state;
    if (e.target.id === "minBudget" || e.target.id === "maxBudget") {
      delete errors[e.target.id];
      if (e.target.value < 0) {
        errors[e.target.id] = "No negative values allowed";
      }
    }
    this.setState({ [e.target.id]: e.target.value, errors });
  };

  handleKeyUp = (e) => {
    console.log(e.keyCode);
    if (e.keyCode === 32) {
      this.setState({ [e.target.id]: e.target.value.trim() });
    } else {
      this.setState({ [e.target.id]: "" });
    }
  };

  delRow = (event, idx) => {
    event.preventDefault();
    var array = [...this.state.selectedSkillMatrix];
    var index = idx;
    array.splice(index, 1);
    this.setState({ selectedSkillMatrix: array });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const attachments = this.state.attachments.filter(
      (file) => !file.startsWith("blob")
    );

    if (this.state.title === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          title: "Title is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.description === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          description: "Description is required field",
        }),
      });

      this.setState(state);
    }
    if (this.state.selectedSkillMatrix.length === 0) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          skills: "Skills are required",
        }),
      });

      this.setState(state);
    }
    if (this.state.category === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          category: "Category is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.startDate === "" || this.state.startDate === null) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          startDate: "Start date is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.endDate === "" || this.state.endDate === null) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          endDate: "End date is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.serviceRequestId === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          serviceRequestId: "Service request id is required",
        }),
      });

      this.setState(state);
    }
    if (
      this.state.errors["minBudget"] ||
      this.state.errors["maxBudget"] ||
      this.state.errors["startDate"] ||
      this.state.errors["endDate"]
    ) {
      return;
    }

    if (
      this.state.title !== "" &&
      this.state.description !== "" &&
      this.state.selectedSkillMatrix.length !== 0 &&
      this.state.category !== "" &&
      this.state.startDate !== "" &&
      this.state.endDate !== ""
    ) {
      const newBid = {
        title: this.state.title,
        description: this.state.description,
        category: this.state.category,
        serviceRequestId: JSON.stringify(this.state.serviceRequestId),
        skills: this.state.selectedSkillMatrix,
        minBudget: this.state.minBudget,
        maxBudget: this.state.maxBudget,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        attachments: attachments,
        createdBy: this.props.auth.user.email,
        assignedTo: this.props.auth.user.email,
        customerName: this.state.customer,
        customerEmail: this.state.customerEmail,
      };

      if (this.state.selectedFile.length > 0) {
        this.uploadAttachments();
      }
      this.props.createBid(newBid, this.props.history);
    }
  };

  handleCategoryDropdown = (event, idx) => {
    event.preventDefault();
    this.setState({ category: idx });
    this.setState({ showCategoryList: false });
  };

  changeShowCategoryList = () => {
    this.setState({ showCategoryList: true });
  };

  hideShowCategoryList = () => {
    this.setState({ showCategoryList: false });
  };

  handleCustomerDropdown = (event, name, email) => {
    event.preventDefault();
    this.setState({ customer: name });
    this.setState({ customerEmail: email });
    this.setState({ showCustomerList: false });
    document.getElementById("serviceRequestId").value = "";
    this.setState({ serviceRequestId: "" });
    fetch(
      CUSTOMER_URL +
        "/Customer/GetAllSRSByCustomerEmail?email=" +
        email +
        "&url=" +
        URL_PARAM
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ serviceRequestIdList: data }, () => {
          console.log(this.state.serviceRequestIdList);
        });
      });
  };

  changeShowCustomerList = () => {
    this.setState({ showCustomerList: true });
  };

  hideShowCustomerList = () => {
    this.setState({ showCustomerList: false });
  };

  handleServiceRequestIdDropdown = (event, idx) => {
    event.preventDefault();
    this.setState({ serviceRequestId: idx });
    this.setState({ showServiceRequestIdList: false });
  };

  changeShowServiceRequestIdList = () => {
    this.setState({ showServiceRequestIdList: true });
  };

  hideShowServiceRequestIdList = () => {
    this.setState({ showServiceRequestIdList: false });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13 || code === 44 || code === 9) {
      event.preventDefault();
      var str = event.target.value;
      if (str !== "") {
        str = str.replace(/,\s*$/, "");
        this.setState(
          { selectedSkillMatrix: [...this.state.selectedSkillMatrix, str] },
          () => {}
        );
        event.target.value = "";
      }
    }
  }

  blurPressed(event) {
    event.preventDefault();
    var str = event.target.value;
    if (str !== "") {
      str = str.replace(/,\s*$/, "");
      this.setState(
        { selectedSkillMatrix: [...this.state.selectedSkillMatrix, str] },
        () => {}
      );
      event.target.value = "";
    }
  }

  textChange = () => {};

  endAfterStart = (date) => {
    const { errors } = this.state;
    delete errors["endDate"];
    this.setState({ endDate: date, errors });
    var start = document.getElementById("startDate").value;
    var startDate = new Date(start);
    var endDate = new Date(date);

    const diff = Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / 86400000
    );

    if (diff === 0) {
      errors["endDate"] = "Minimum Duration of 1 Day is required";
      this.setState({ errors });
    }

    if (endDate.getTime() < startDate.getTime()) {
      this.setState({ endDateValidate: true });
    } else {
      this.setState({ endDateValidate: false });
    }
  };

  // ADD ATTACHMENTS
  onChangeHandler = (event) => {
    if (document.getElementsByClassName("uploaded_attachments").length) {
      var attaches = document.querySelectorAll(".uploaded_attachments");
      for (var i = 0; i < attaches.length; i++) {
        attaches[i].style.display = "none";
      }
    }
    document.getElementById("attachments_root").style.display = "block";

    const attachmentUrl = URL.createObjectURL(event.target.files[0]);

    this.setState({
      selectedFile: [...this.state.selectedFile, event.target.files[0]],
      uploadFilesMaping: [
        ...this.state.uploadFilesMaping,
        [event.target.files[0].name, attachmentUrl],
      ],
      attachments: [...this.state.attachments, attachmentUrl],
    });
  };

  // REMOVE ATTACHMENTS
  removeAttachments = (attachmentId, fileUrl) => {
    const updatedUploades = [];
    const removeUploadMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] === fileUrl
    );

    const newMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] !== fileUrl
    );

    this.state.selectedFile.forEach((el) => {
      if (el.name === removeUploadMaping[0][0]) {
        window.URL.revokeObjectURL(fileUrl);
      } else {
        updatedUploades.push(el);
      }
    });

    const updatedAttachments = this.state.attachments.filter(
      (currentValue, index) => attachmentId * 1 !== index
    );

    this.setState({
      attachments: updatedAttachments,
      selectedFile: updatedUploades,
      uploadFilesMaping: newMaping,
    });
  };

  // UPLOAD ATTACHMENTS
  uploadAttachments = () => {
    if (
      this.state.selectedFile !== null &&
      this.state.selectedFile !== undefined &&
      this.state.selectedFile !== "undefined"
    ) {
      this.state.uploadFilesMaping.forEach((el) => {
        let img = new Image();
        img.src = el[1];

        img.onload = () => {
          var width = img.naturalWidth;
          var height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);

          if (width > 2000 || height > 2000) {
            this.setState({ fileDimensionError: true });
          } else {
            this.setState({ fileDimensionError: false });
          }
        };
      });

      const data = new FormData();
      data.append("serviceRequestId", this.state.serviceRequestId);

      this.state.selectedFile.forEach((el, index) => {
        var ext = el.name.split(".").pop();
        var fileSize = el.size;

        if (fileSize > 50000000) {
          this.setState({ fileSizeError: true });
        } else {
          this.setState({ fileSizeError: false });
        }

        if (
          ext !== "png" &&
          ext !== "jpg" &&
          ext !== "jpeg" &&
          ext !== "gif" &&
          ext !== "PNG" &&
          ext !== "JPG" &&
          ext !== "JPEG" &&
          ext !== "GIF"
        ) {
          this.setState({
            extensionError: true,
            fileSizeError: false,
            fileDimensionError: false,
          });

          if (
            ext === "pdf" ||
            ext === "PDF" ||
            ext === "doc" ||
            ext === "docx" ||
            ext === "DOCX" ||
            ext === "xlsx" ||
            ext === "XLSX" ||
            ext === "PPTX" ||
            ext === "pptx"
          ) {
            this.setState({
              extensionError: false,
              fileSizeError: false,
              fileDimensionError: false,
            });
          }

          data.append("file", el);
        } else {
          this.setState({
            extensionError: false,
            fileSizeError: false,
            fileDimensionError: false,
          });
          this.setState({ extensionError: false });
          data.append("file", el);
        }
      });

      axios
        .post("/api/bids/upload", data)
        .then((res) => {
          console.log(res.data);

          var file_paths = this.state.attachments.filter(
            (file) => !file.startsWith("blob")
          );

          res.data.forEach((file_path) => {
            if (file_path !== undefined) {
              if (file_path.length > 0) {
                file_path = file_path.replace("public", "");
              }
            }
            file_paths.push(file_path);
          });
          this.setState({
            attachments: file_paths,
            selectedFile: [],
            uploadFilesMaping: [],
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleBack() {
    window.history.back();
  }

  handleChange = (selectedItems) => {
    this.setState({
      selectedItems,
      selectedSkillMatrix: selectedItems.map((e) => e.id),
    });
  };

  addSkill = (e) => {
    e.preventDefault();
    this.setState({ openAddSkillPopup: true });
  };

  closePopup = async () => {
    await this.fetchCategories();
    this.setState({ openAddSkillPopup: false });
  };

  render() {
    const { auth: { user: { email, userRole } = {} } = {} } = this.props;
    const isFreelancer = !!(userRole === 2);
    const { errors, categories, selectedItems, openAddSkillPopup } = this.state;

    var profilePic = this.state.profileImage;
    if (profilePic !== undefined) {
      if (profilePic.length > 0) {
        profilePic = this.state.profileImage.replace("public", "");
      }
    }
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        <div className="container" style={{ marginTop: "60px" }}>
          <div className="row">
            <h4>
              <span onClick={this.handleBack}>
                <img src={LeftArrow} alt="back" style={{ width: "16px" }} />
              </span>
              Add Bid Request
            </h4>
            <form
              className="col s12"
              style={{ marginTop: "10px" }}
              noValidate
              onSubmit={this.onSubmit}
            >
              <div className="row">
                <div className="col s12 module">
                  <div className="row">
                    <div
                      className="input-field col s12"
                      style={{ marginTop: "40px" }}
                    >
                      <input
                        id="title"
                        type="text"
                        className="validate"
                        onChange={this.onChange}
                        value={this.state.title}
                      />

                      <label htmlFor="title" className="active">
                        Title<span className="red-text">*</span>
                      </label>
                      <span className="red-text">{errors.title}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <div className="profile-summary-textarea">
                        <textarea
                          id="description"
                          className="materialize-textarea"
                          onChange={this.onChange}
                          value={this.state.description}
                        ></textarea>
                      </div>
                      <label htmlFor="description" className="active">
                        Description<span className="red-text">*</span>
                      </label>
                      <span className="red-text">{errors.description}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s4">
                      <input
                        id="category"
                        type="text"
                        className="validate"
                        onFocus={this.changeShowCategoryList}
                        onClick={this.changeShowCategoryList}
                        onChange={this.onChange}
                        autoComplete="off"
                        value={this.state.category}
                        onKeyUp={this.handleKeyUp}
                      />
                      {this.state.showCategoryList && (
                        <div className="category-list">
                          <ul>
                            {this.state.categoryList.map(
                              function(d, idx) {
                                return (
                                  <li
                                    value={d.TicketProblemTypeName}
                                    onClick={(event) =>
                                      this.handleCategoryDropdown(
                                        event,
                                        d.TicketProblemTypeName
                                      )
                                    }
                                  >
                                    {d.TicketProblemTypeName}
                                  </li>
                                );
                              }.bind(this)
                            )}
                          </ul>
                        </div>
                      )}
                      <label htmlFor="category" className="active">
                        Category<span className="red-text">*</span>
                      </label>
                      <span className="red-text">{errors.category}</span>
                    </div>
                    <div className="input-field col s4">
                      <input
                        id="customer"
                        type="text"
                        className="validate"
                        onFocus={this.changeShowCustomerList}
                        onClick={this.changeShowCustomerList}
                        onChange={this.onChange}
                        autoComplete="off"
                        value={this.state.customer}
                        onKeyUp={this.handleKeyUp}
                      />
                      {this.state.showCustomerList && (
                        <div className="category-list">
                          <ul>
                            {this.state.customerList.map(
                              function(d, idx) {
                                return (
                                  <li
                                    value={d.EmailAddress}
                                    onClick={(event) =>
                                      this.handleCustomerDropdown(
                                        event,
                                        d.CustomerName,
                                        d.EmailAddress
                                      )
                                    }
                                  >
                                    {d.CustomerName
                                      ? d.CustomerName
                                      : d.CompanyName
                                      ? d.CompanyName
                                      : d.EmailAddress}
                                  </li>
                                );
                              }.bind(this)
                            )}
                          </ul>
                        </div>
                      )}
                      <label htmlFor="category" className="active">
                        Customer<span className="red-text">*</span>
                      </label>
                      <span className="red-text">{errors.customer}</span>
                    </div>
                    <div className="input-field col s4">
                      <input
                        id="serviceRequestId"
                        type="text"
                        onFocus={this.changeShowServiceRequestIdList}
                        onClick={this.changeShowServiceRequestIdList}
                        onChange={this.onChange}
                        value={this.state.serviceRequestId}
                        error={errors.serviceRequestId}
                        className="validate"
                        onKeyUp={this.handleKeyUp}
                      />
                      {this.state.showServiceRequestIdList && (
                        <div className="category-list">
                          <ul>
                            {this.state.serviceRequestIdList.map(
                              function(d, idx) {
                                return (
                                  <li
                                    value={d.PKTicketId}
                                    onClick={(event) =>
                                      this.handleServiceRequestIdDropdown(
                                        event,
                                        d.PKTicketId
                                      )
                                    }
                                  >
                                    {d.PKTicketId}
                                  </li>
                                );
                              }.bind(this)
                            )}
                          </ul>
                        </div>
                      )}
                      <label htmlFor="serviceRequestId" className="active">
                        Service Request Id<span className="red-text">*</span>
                      </label>
                      <div className="red-text">{errors.serviceRequestId}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="container">
                      <div className="input-field col s12">
                        <label
                          htmlFor="skills"
                          className="active"
                          // style={{ left: "-5px" }}
                        >
                          Skills<span className="red-text">*</span>
                        </label>
                        <div className="add_skills">
                          <button
                            className="custombtn"
                            style={{ width: "20%" }}
                            onClick={(e) => this.addSkill(e)}
                          >
                            + Add New Skills
                          </button>
                        </div>
                        <div className="multiselect_skills">
                          <MultiSelect
                            showSelectAll={false}
                            items={categories}
                            withGrouping
                            selectedItems={selectedItems}
                            onChange={this.handleChange}
                            noItemsRenderer={NoDataConst}
                          />
                        </div>
                        <span className="red-text">{errors.skills}</span>
                      </div>

                      <div className="input-field col s3">
                        <input
                          type="number"
                          id="minBudget"
                          onChange={this.onChange}
                          value={this.state.minBudget}
                          min="0"
                        />
                        <label htmlFor="minBudget" className="active">
                          Budget
                        </label>
                        <span className="red-text">{errors.minBudget}</span>
                      </div>
                      <div className="input-field col s3">
                        <input
                          type="number"
                          id="maxBudget"
                          onChange={this.onChange}
                          value={this.state.maxBudget}
                          min="0"
                        />
                        <span className="red-text">{errors.maxBudget}</span>
                      </div>
                      <div className="input-field col s3">
                        <DatePicker
                          id="startDate"
                          selected={this.state.startDate}
                          onChange={(date) => {
                            const { errors } = this.state;
                            delete errors.endDate;
                            delete errors.startDate;
                            let start = new Date(date);
                            let end = new Date(this.state.endDate);
                            const diff = Math.floor(
                              (Date.parse(end) - Date.parse(start)) / 86400000
                            );
                            if (diff === 0) {
                              errors["startDate"] =
                                "Minimum Duration of 1 Day is required";
                            }
                            if (diff < 0) {
                              errors["startDate"] =
                                "Start Date should be lesser than End Date";
                            }

                            this.setState({ startDate: date, errors });
                          }}
                          className="datepicker"
                          autoComplete="off"
                        />
                        <p
                          className="red-text m-0"
                          style={{ paddingLeft: "20px" }}
                        >
                          {errors.startDate}
                        </p>
                        <label htmlFor="Duration" className="active">
                          Duration<span className="red-text">*</span>
                        </label>
                      </div>
                      <div className="input-field col s3">
                        <DatePicker
                          id="endDate"
                          selected={this.state.endDate}
                          onChange={this.endAfterStart}
                          className="datepicker"
                          autoComplete="off"
                        />
                        <p
                          className="red-text m-0"
                          style={{ paddingLeft: "20px" }}
                        >
                          {errors.endDate}
                        </p>
                        {this.state.endDateValidate && (
                          <p className="red-text">
                            End date should be greater than start date
                          </p>
                        )}
                      </div>

                      <div className="col s12">
                        <p>Add Attachments</p>
                        <div
                          id="attachments_root"
                          className="uploaded_attachments"
                          style={{ display: "none" }}
                        >
                          {this.state.attachments.map(
                            function(item, idx) {
                              if (item.startsWith("blob")) {
                                return (
                                  <span
                                    className="file-field input-field "
                                    style={{
                                      float: "left",
                                      position: "relative",
                                    }}
                                    key={idx}
                                  >
                                    <a href={item} target="_blank">
                                      <img
                                        src={item}
                                        alt="Attachment"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </a>
                                    <img
                                      src={ClearIcon}
                                      alt="attachments"
                                      style={{
                                        display: "block",
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        zIndex: 1,
                                        width: "20%",
                                      }}
                                      onClick={() =>
                                        this.removeAttachments(idx, item)
                                      }
                                    />
                                  </span>
                                );
                              } else {
                                var extension = item.split(".").pop();
                                if (
                                  extension === "jpg" ||
                                  extension === "png" ||
                                  extension === "jpeg" ||
                                  extension === "PNG" ||
                                  extension === "JPG" ||
                                  extension === "gif"
                                ) {
                                  return (
                                    <span
                                      className="file-field input-field "
                                      style={{ float: "left" }}
                                      key={idx}
                                    >
                                      <a
                                        target="_blank"
                                        href={`https://devfreelancersapi.supportpod.com${item}`}
                                      >
                                        <img
                                          src={`https://devfreelancersapi.supportpod.com${item}`}
                                          alt="item"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      </a>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <>
                                      <span
                                        className="file-field input-field attachment"
                                        style={{ float: "left" }}
                                        key={idx}
                                      >
                                        <a
                                          target="_blank"
                                          href={`https://devfreelancersapi.supportpod.com${item}`}
                                        >
                                          <img
                                            src={PaperClip}
                                            alt="attachments"
                                          />
                                          <img src={item} alt="item" />
                                        </a>
                                      </span>
                                    </>
                                  );
                                }
                              }
                            }.bind(this)
                          )}
                        </div>
                        <div
                          className="file-field input-field"
                          style={{
                            width: "42px",
                            height: "42px",
                            float: "left",
                          }}
                        >
                          <div
                            className="btn"
                            style={{ width: "42px", height: "42px" }}
                          >
                            <span
                              style={{
                                marginTop: "4px",
                                marginLeft: "-13px",
                                backgroundSize: "24px 24px",
                              }}
                            >
                              File
                            </span>
                            <input
                              type="file"
                              title="Add Attachment"
                              name="file"
                              onChange={(event) =>
                                this.onChangeHandler(event, "root")
                              }
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                zIndex: 101,
                              }}
                            />
                          </div>
                          <div
                            className="file-path-wrapper"
                            style={{
                              visibility: "hidden",
                              height: "1px",
                              width: "1px",
                            }}
                          >
                            <input
                              className="file-path validate"
                              type="text"
                              placeholder="Upload one or more files"
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        {this.state.extensionError && (
                          <span className="red-text">
                            Please upload a valid image
                            <br />
                            (png, jpg, jpeg, gif, doc, xl, pdf, docx)
                          </span>
                        )}
                        {this.state.fileDimensionError && (
                          <span className="red-text">
                            Please select a image below 2000 px
                          </span>
                        )}
                        {this.state.fileSizeError && (
                          <span className="red-text">
                            Maximum file size allowed is 2MB
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col s12 center"
                style={{ paddingLeft: "11.250px" }}
              >
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    maxWidth: "240px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {(this.state.showCategoryList ||
          this.state.showCustomerList ||
          this.state.showServiceRequestIdList) && (
          <div
            onClick={() => {
              this.setState({ showCategoryList: false });
              this.setState({ showCustomerList: false });
              this.setState({ showServiceRequestIdList: false });
            }}
            className="overlay"
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.01)",
              zIndex: "97",
            }}
          ></div>
        )}
        {openAddSkillPopup && (
          <AddSkillPopup
            show={openAddSkillPopup}
            handleClose={this.closePopup}
            updatedBy={email}
            isFreelancer={isFreelancer}
          />
        )}
      </>
    );
  }
}

AddBids.propTypes = {
  createBid: PropTypes.func.isRequired,
  removeProfilePicture: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { createBid, removeProfilePicture })(
  AddBids
);
