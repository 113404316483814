import React, { Component } from "react";
import axios from "../../axios/index";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Navbar from "../layout/Navbar";

// import { Link } from "react-router-dom";
// import MoreIcon from "../../images/more-icon.png";
// import BidIcon from "../../images/bid-icon.png";
// import AddIconWhite from "../../images/add-icon-white.png";

import FilterIcon from "../../images/filter-icon.png";
import AscIcon from "../../images/asc-icon.png";
import DscIcon from "../../images/dsc-icon.png";
import SearchIcon from "../../images/search-icon.png";
import TimeIcon from "../../images/time-icon.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import BidProposal from "./BidProposal";
import LoadingSpinner from "../Common/LoadingSpinner";

// import ReactTimeAgo from 'react-time-ago';

class AllBids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialBids: [],
      bidslist: {},
      showFilters: false,
      categoryList: [],
      startDate: "",
      endDate: "",
      endDateValidate: false,
      startDateNull: false,
      endDateNull: false,
      ascSorting: true,
      pageNumbers: [],
      skillMatrix: [],
      currentPage: 1,
      lastPage: 0,
      openBidModal: false,
      modalData: {},
      attachments: [],
      errors: {},
      loading: false,
      bidProposalData: [],
    };
  }

  async componentDidMount() {
    await this.fetchBids();

    this.setState({ loading: true });
    // Service Request categories
    await fetch(
      "https://supportpodapi.apoyar.eu/api/TicketProblem/GetAllTicketProblemTypes?isactive=1&URL=support.apoyar.eu"
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ categoryList: data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    this.setState({ loading: false });
  }

  fetchBids = async () => {
    const { auth: { user: { email, skillMatrix } = {} } = {} } = this.props;
    const { currentPage } = this.state;
    this.setState({ loading: true });
    const { data } = await axios.post("/api/bids/freelancerbids", {
      email: email,
    });
    // const bidsLength = res.data.length;
    // const filteredOpenBids = res.data;
    const skillsInBids = [
      ...new Set(data.reduce((acc, { skills }) => [...acc, ...skills], [])),
    ];
    const userSkillsDisplayed = skillMatrix.filter(({ selectedSkills }) =>
      skillsInBids
        .map((e) => e.toLowerCase())
        .includes(selectedSkills.toLowerCase())
    );

    const userSkillToDisplay = userSkillsDisplayed.filter(function(e) {
      return e.supportpodRating >= 6;
    });
    const filteredOpenBids = this.filtered(data, userSkillToDisplay);
    const { totalPages, pageNumbers } = this.generatePaginationParams(
      filteredOpenBids
    );
    const temp = this.paginate(filteredOpenBids, 10, currentPage);

    this.setState(
      {
        bidslist: temp,
        initialBids: filteredOpenBids,
        lastPage: totalPages,
        pageNumbers,
        skillMatrix: userSkillsDisplayed,
        currentPage,
        openBidModal: false,
        modalData: {},
        loading: false,
      },
      () => {
        this.state.bidslist.forEach((item) => {
          const bidProposalId = `${item.serviceRequestId}_${this.props.auth.user.email}`;
          axios
            .post("/api/bidproposals/bidproposaldetails", { bidProposalId })
            .then((res) => {
              this.setState({
                bidProposalData: [...this.state.bidProposalData, res.data],
              });
            })
            .catch((err) => console.log(err));
        });
      }
    );
  };

  // Utils
  generatePaginationParams = (records) => {
    const totalPages = Math.ceil(records.length / 10);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    return { totalPages, pageNumbers };
  };

  //Filter bids based on user default skillset (the logic should be optimized)
  filtered = (bids, skills) =>
    (bids.length &&
      bids.filter((item) =>
        item.skills.some((skill) =>
          skills
            .map((ele) => ele.selectedSkills.toLowerCase())
            .includes(skill.toLowerCase())
        )
      )) ||
    [];

  paginate = (array, pageSize, pageNumber) =>
    array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  // Utils Ends

  viewBidDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push("/bid-request?bidrequestid=" + id);
  };

  showFiltersFunc = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  endAfterStart = (date) => {
    this.setState({ endDate: date });
    var start = document.getElementById("startDate").value;
    var startDate = new Date(start);
    var endDate = new Date(date);

    if (endDate.getTime() < startDate.getTime()) {
      this.setState({ endDateValidate: true });
    } else {
      this.setState({ endDateValidate: false });
    }
  };

  resetFilterData = () => {
    const { skillMatrix } = this.state;
    document.getElementById("searchCategory").value = "";
    // document.getElementById('status').value = "all";
    document.getElementById("startDate").value = "";
    document.getElementById("endDate").value = "";
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });

    const filterData = {
      category: "",
      status: "open",
      startDate: "",
      endDate: "",
    };

    axios
      .post("/api/bids/statusfilterbids", filterData)
      .then((res) => {
        const filteredOpenBids = this.filtered(res.data, skillMatrix);
        const { totalPages, pageNumbers } = this.generatePaginationParams(
          filteredOpenBids
        );
        const temp = this.paginate(filteredOpenBids, 10, 1);
        this.setState({
          bidslist: temp,
          initialBids: filteredOpenBids,
          lastPage: totalPages,
          pageNumbers,
          skillMatrix,
        });
      })
      .catch((err) => console.log(err));
  };

  filterData = (event) => {
    const { skillMatrix } = this.state;
    // var status = document.getElementById('status').value;
    var category = document.getElementById("searchCategory").value;
    if (document.getElementById("endDate") !== null) {
      var startDate = document.getElementById("startDate").value;
      var endDate = document.getElementById("endDate").value;
    } else {
      // eslint-disable-next-line no-redeclare
      var startDate = "";
      // eslint-disable-next-line no-redeclare
      var endDate = "";
    }
    const filterData = {
      status: "open",
      category: category,
      startDate: startDate,
      endDate: endDate,
    };

    axios
      .post("/api/bids/statusfilterbids", filterData)
      .then((res) => {
        const filteredOpenBids = this.filtered(res.data, skillMatrix);
        const { totalPages, pageNumbers } = this.generatePaginationParams(
          filteredOpenBids
        );
        // const temp = this.paginate(filteredOpenBids, 10, 1);
        this.setState({
          bidslist: filteredOpenBids,
          initialBids: filteredOpenBids,
          lastPage: totalPages,
          pageNumbers,
          skillMatrix,
        });
      })
      .catch((err) => console.log(err));
  };

  enterPressed = (event) => {
    // const { skillMatrix, bidslist } = this.state;
    const { skillMatrix } = this.state;
    var code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      event.preventDefault();
      var str = event.target.value;
      const filterData = {
        serviceRequestId: str,
      };
      if (str !== "") {
        axios
          .post("/api/bids/searchbids", filterData)
          .then((res) => {
            const filteredOpenBids = this.filtered(res.data, skillMatrix);
            const { totalPages, pageNumbers } = this.generatePaginationParams(
              filteredOpenBids
            );
            const temp = this.paginate(filteredOpenBids, 10, 1);
            this.setState({
              bidslist: temp,
              initialBids: filteredOpenBids,
              lastPage: totalPages,
              pageNumbers,
              skillMatrix,
            });
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .get("/api/bids/bids")
          .then((res) => {
            const filteredOpenBids = this.filtered(res.data, skillMatrix);
            const { totalPages, pageNumbers } = this.generatePaginationParams(
              filteredOpenBids
            );
            const temp = this.paginate(filteredOpenBids, 10, 1);
            this.setState({
              bidslist: temp,
              initialBids: filteredOpenBids,
              lastPage: totalPages,
              pageNumbers,
              skillMatrix,
            });
          })
          .catch((err) => console.log(err));
      }
    }
  };

  onAscSort(event, sortKey) {
    const bidslist = this.state.bidslist;
    bidslist.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    this.setState({ bidslist });
    this.setState({ ascSorting: false });
  }
  onDscSort(event, sortKey) {
    const bidslist = this.state.bidslist;
    bidslist.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    this.setState({ bidslist });
    this.setState({ ascSorting: true });
  }

  calDaysLeft = (endDate) => {
    var dateFuture = new Date(endDate);
    var dateNow = new Date();

    var seconds = Math.floor((dateFuture - dateNow) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return `${days} days ${hours} hours`;
  };

  removeSkill = (skill) => (event) => {
    event.preventDefault();
    const { skillMatrix, bidslist } = this.state;
    const newSkills = skillMatrix.filter((e) => e.selectedSkills !== skill);
    const filteredOpenBids = this.filtered(bidslist, newSkills);
    const { totalPages, pageNumbers } = this.generatePaginationParams(
      filteredOpenBids
    );
    const temp = this.paginate(filteredOpenBids, 10, 1);
    this.setState({
      bidslist: temp,
      initialBids: filteredOpenBids,
      lastPage: totalPages,
      pageNumbers,
      skillMatrix: newSkills,
    });
  };

  togglePage = {
    next: (event) => {
      event.preventDefault();
      const { initialBids, currentPage } = this.state;
      const temp = this.paginate(initialBids, 10, currentPage + 1);
      this.setState({ bidslist: temp, currentPage: currentPage + 1 });
    },
    prev: (event) => {
      event.preventDefault();
      const { initialBids, currentPage } = this.state;
      const temp = this.paginate(initialBids, 10, currentPage - 1);
      this.setState({ bidslist: temp, currentPage: currentPage - 1 });
    },
    numbered: (currentPageNumber) => (event) => {
      event.preventDefault();
      const { initialBids } = this.state;
      const temp = this.paginate(initialBids, 10, currentPageNumber);
      this.setState({ bidslist: temp, currentPage: currentPageNumber });
    },
  };

  toggleBidProposal = (bool, data) => (event) => {
    if (bool) {
      this.setState({ openBidModal: true, modalData: data, errors: {} });
    } else {
      this.setState({ openBidModal: false, modalData: {}, errors: {} });
    }
  };

  lastBidTypeByUser = (email, participants) => {
    const userParticipation = participants.filter((e) => e.email === email);
    let draftBool = true;
    if (userParticipation.length > 1) {
      draftBool = userParticipation[userParticipation.length - 1].isDraft;
    } else if (userParticipation.length === 1) {
      draftBool = userParticipation[0].isDraft;
    }
    return draftBool;
  };

  renderDetails = (id) => {
    // const email = this.props.auth.user.email;
    const bidProposalId = `${id}_${this.props.auth.user.email}`;
    const element = { bidProposalId: bidProposalId };
    var filtered = this.state.bidProposalData.filter(function(el) {
      return el != null;
    });
    var item = filtered.find(
      (item) => item.bidProposalId === element.bidProposalId
    );

    if (item !== undefined) {
      return item.status;
    }
  };

  render() {
    const { auth: { user: { email } = {} } = {} } = this.props;
    const {
      bidslist,
      skillMatrix,
      pageNumbers,
      currentPage,
      lastPage,
      openBidModal,
      modalData,
      loading,
    } = this.state;

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />

        <br></br>
        {(loading && <LoadingSpinner />) || (
          <div className="container grid-container">
            <div className="row nav-widgets" style={{ marginBottom: "0px" }}>
              <div className="col s12">
                <h4 style={{ marginTop: "10px" }}>Bid Requests</h4>
              </div>
            </div>
            <div className="nav-widgets">
              <div className="col s2">
                <select
                  className="browser-default dropdown-sel"
                  id="searchCategory"
                  style={{
                    border: "1px solid black",
                    height: "100%",
                    borderRadius: "15px",
                  }}
                  onChange={this.filterData}
                >
                  <option value="">Select a Category</option>
                  <option value="">All</option>
                  {this.state.categoryList.map(function(d, idx) {
                    return (
                      <option value={d.TicketProblemTypeName}>
                        {d.TicketProblemTypeName}
                      </option>
                    );
                  })}
                </select>
              </div>
              &emsp;
              <button
                className="btn-transparent"
                onClick={this.showFiltersFunc}
              >
                <img
                  src={FilterIcon}
                  alt="Filter"
                  style={{ width: "10px", marginRight: "5px" }}
                />
                Filter
              </button>
              <div className="searchbox">
                <img
                  src={SearchIcon}
                  alt="Filter"
                  style={{
                    width: "16px",
                    marginRight: "5px",
                    marginLeft: "10px",
                    lineHeight: "32px",
                  }}
                />

                <input
                  autocomplete="off"
                  onKeyUp={this.enterPressed}
                  placeholder="Search by Service Request Id"
                ></input>

                <label>{bidslist.length} Results</label>
              </div>
              {this.state.ascSorting ? (
                <button
                  className="btn-transparent"
                  onClick={(e) => this.onAscSort(e, "serviceRequestId")}
                >
                  <img
                    src={AscIcon}
                    alt="Ascending"
                    style={{ width: "12px", marginRight: "5px" }}
                  />
                  Sort
                </button>
              ) : (
                <button
                  className="btn-transparent"
                  onClick={(e) => this.onDscSort(e, "serviceRequestId")}
                >
                  <img
                    src={DscIcon}
                    alt="Descending"
                    style={{ width: "12px", marginRight: "5px" }}
                  />
                  Sort
                </button>
              )}
            </div>
            {this.state.showFilters && (
              <div className="row" style={{ marginBottom: "0px" }}>
                <div
                  className="col s12"
                  style={{
                    backgroundColor: "#FAFAFA",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    borderTop: "1px solid #e4e4e4",
                  }}
                >
                  <div className="col s4">
                    Bid End date between&nbsp;&nbsp;
                    <DatePicker
                      id="startDate"
                      selected={this.state.startDate}
                      onChange={(date) => this.setState({ startDate: date })}
                      className="datepicker start-date"
                    />
                    {this.state.startDateNull && (
                      <p className="red-text" style={{ marginLeft: "120px" }}>
                        Please enter start date
                      </p>
                    )}
                  </div>
                  <div className="col s1">
                    <span style={{ paddingTop: "6px", display: "block" }}>
                      {" "}
                      and{" "}
                    </span>
                  </div>
                  <div className="col s3">
                    <DatePicker
                      id="endDate"
                      selected={this.state.endDate}
                      onChange={this.endAfterStart}
                      className="datepicker end-date"
                    />
                    {this.state.endDateValidate && (
                      <p className="red-text">
                        End date should be greater than start date
                      </p>
                    )}
                    {this.state.endDateNull && (
                      <p className="red-text" style={{ marginLeft: "50px" }}>
                        Please enter end date
                      </p>
                    )}
                  </div>
                  <div className="col s3">
                    <button
                      className="btn black"
                      onClick={this.filterData}
                      style={{ height: "32px", lineHeight: "32px" }}
                    >
                      Filter
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn red"
                      onClick={this.resetFilterData}
                      style={{ height: "32px", lineHeight: "32px" }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="toolbar">
              <ul>
                {(skillMatrix.length &&
                  skillMatrix.map((e) => (
                    <li>
                      {e.selectedSkills}&nbsp;
                      <span onClick={this.removeSkill(e.selectedSkills)}>
                        <FontAwesomeIcon icon={faWindowClose} />
                      </span>
                    </li>
                  ))) ||
                  ""}
              </ul>
            </div>
            {(bidslist.length &&
              bidslist.map((item) => (
                <div className="cards" style={{ marginTop: "16px" }}>
                  <div
                    className="leftBlock"
                    onClick={(event) =>
                      this.viewBidDetails(event, item.serviceRequestId)
                    }
                  >
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>

                    {item.skills && item.skills.length && (
                      <ul>
                        {item.skills.map((skill) => (
                          <li>{skill}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="rightblock">
                    <ul>
                      <li>
                        <span>
                          <b>
                            {(item.minBudget && `${item.minBudget}€`) || "-"}-
                            {(item.maxBudget && `${item.maxBudget}€`) || "-"}
                          </b>
                        </span>
                      </li>
                      {item.status === "open" && (
                        <li>
                          <img
                            src={TimeIcon}
                            alt="Filter"
                            style={{
                              width: "16px",
                              marginRight: "5px",
                              marginLeft: "10px",
                              lineHeight: "32px",
                            }}
                          />
                        </li>
                      )}
                      <li>
                        <span>
                          {(item.status === "open" && `Bidding ends in`) ||
                            "Bidding has ended"}
                          <br />
                          <b>
                            {(item.status === "open" &&
                              this.calDaysLeft(item.endDate)) ||
                              ""}
                          </b>
                        </span>
                      </li>

                      {this.renderDetails(item.serviceRequestId) ===
                        "accepted" && (
                        <span
                          className="green-text center"
                          style={{
                            display: "block",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Your proposal has been accepted
                        </span>
                      )}

                      {(!this.lastBidTypeByUser(email, item.participants) && (
                        <li>
                          <button className="custombtn">
                            <HashLink
                              to={`/bid-request?bidrequestid=${item.serviceRequestId}#my-bid-proposal`}
                            >
                              View Bid
                            </HashLink>
                          </button>
                        </li>
                      )) || (
                        <li>
                          <button
                            className="custombtn"
                            onClick={this.toggleBidProposal(true, item)}
                          >
                            Bid now
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              ))) || (
              <div style={{ textAlign: "center" }}>
                <h3> No Bids Available </h3>
              </div>
            )}
            {openBidModal && (
              <BidProposal
                data={modalData}
                openBidModal={openBidModal}
                toggleBidProposal={this.toggleBidProposal}
                refreshBids={this.fetchBids}
              />
            )}
            <div class="pagination">
              <button
                className={(currentPage === 1 && "disabled") || ""}
                disabled={currentPage === 1}
                onClick={this.togglePage["prev"]}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>
              {pageNumbers.map((e) => (
                <button
                  onClick={this.togglePage["numbered"](e)}
                  className={(currentPage === e && "activepage") || ""}
                >
                  {e}
                </button>
              ))}
              <button
                className={(currentPage === lastPage && "disabled") || ""}
                disabled={currentPage === lastPage}
                onClick={this.togglePage["next"]}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

AllBids.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(AllBids);
