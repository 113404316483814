import React, { Component } from "react";

class Overlay extends Component {
  render() {
    return (
      <div className="overlay" style={{position:"fixed", top:"0px", right:"0px", bottom:"0px", left:"0px", height:"100%",width:"100%", backgroundColor: "rgba(0,0,0,0.9)", zIndex : "97"}}> </div>
    );
  }
}

export default Overlay;
