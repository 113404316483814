import React, { Component } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";

import { loginUser } from "../../../actions/authActions";
import { connect } from "react-redux";
import Navbar from "../../layout/Navbar";
import { createCustomer } from "../Services";
import { quoteValidator as validator } from "../Validators";
import { CO_NAME, F_NAME, L_NAME, EMAIL_ADD, REQ_FIELD } from "../Constants";
import LoadingSpinner from "../../Common/LoadingSpinner";

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      customerData: {
        [CO_NAME]: "",
        [F_NAME]: "",
        [L_NAME]: "",
        [EMAIL_ADD]: "",
      },
      itemCodes: [],
      serviceRequests: [],
      bids: [],
      customers: [],
      loading: false,
      showBidTooltip: false,
    };
  }

  componentDidMount = () => {};

  notify = (type, message) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleChange = (event) => {
    const { customerData, errors } = this.state;
    const { target: { value, id: name } = {} } = event;
    delete errors[name];

    if (name === EMAIL_ADD) {
      const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      const isValid = value.match(regex);
      if (isValid) {
        delete errors[name];
      } else {
        errors[name] = "Please enter valid Email";
      }
    }
    customerData[name] = value;

    this.setState({ customerData });
  };

  submitCustomer = async () => {
    const { auth: { user: { email } = {} } = {} } = this.props;
    this.setState({ loading: true });
    const { customerData } = this.state;
    const errors = validator(customerData);
    console.log({ errors });
    this.setState({ errors });

    if (Object.values(errors).includes(REQ_FIELD)) {
      this.setState({ loading: false });
      return;
    }

    this.setState({ loading: true });
    const response = await createCustomer(customerData);
    if (response && response.data && response.status === 200) {
      this.notify("success", "Quote was successfully created");
      this.props.history.push("/quote-details");
    } else {
      this.notify("error", "failed to create Customer");
    }

    this.setState({ loading: false });
  };

  close = () => {
    this.props.history.push("/quote-details");
  };

  render() {
    const { customerData, errors, loading } = this.state;

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        {(loading && <LoadingSpinner />) || (
          <div className="container ">
            <div style={{ paddingTop: "40px" }}>
              <span>
                <h4>Add Customer</h4>
              </span>
            </div>
            <div className="quoteform">
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id={F_NAME}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={customerData[F_NAME]}
                  />
                  <label className="active">
                    First Name<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[F_NAME]}</div>
                </div>

                <div className="input-field col s6">
                  <input
                    id={L_NAME}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={customerData[L_NAME]}
                  />
                  <label className=" active">
                    Last Name<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[L_NAME]}</div>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    id={EMAIL_ADD}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={customerData[EMAIL_ADD]}
                  />
                  <label className="active">
                    Email Address<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[EMAIL_ADD]}</div>
                </div>

                <div className="input-field col s6">
                  <input
                    id={CO_NAME}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={customerData[CO_NAME]}
                  />
                  <label className=" active">
                    Company Name<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[CO_NAME]}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col s12 center"
                style={{ paddingTop: "20px", paddingLeft: "11.250px" }}
              >
                <button
                  className=" btn black"
                  onClick={() => this.submitCustomer()}
                >
                  Submit
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn red"
                  onClick={() => this.close()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
  }
}

AddCustomer.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(AddCustomer);
