import { REQ_FIELD } from "../Constants";

export const quoteValidator = (data = {}, ignoredKeys = []) =>
  Object.keys(data).reduce(
    (errors, item) => ({
      ...errors,
      [item]: (!ignoredKeys.includes(item) && typeof data[item] === 'string' && !data[item].trim() && "Required Field" && REQ_FIELD) || "",
    }),
    {}
  );

export const lineItemsValidator = (data = {}, ignoredKeys = []) =>
  data.map((lineItem) =>
    Object.keys(lineItem).reduce(
      (errors, item) => ({
        ...errors,
        [item]:
          (!ignoredKeys.includes(item) && typeof data[item] === 'string' && !lineItem[item].trim() && "Required Field" && REQ_FIELD) || "",
      }),
      {}
    )
  );
