import React, { Component } from "react";
import { loginUser } from "../../actions/authActions";
import { logoutUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import AdminNav from "../layout/AdminNav";
import axios from "../../axios/index";
import { HashLink as Link } from "react-router-hash-link";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      userid: "",
      userRole: "",
      userdetails: {},
      mandatoryCount: 0,
      mandatorycategoryCount: 0,
    };
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  gotoDocuments = (e) => {
    e.preventDefault();
    this.props.history.push("/id-verification");
  };
  gotoProfile = (e) => {
    e.preventDefault();
    this.props.history.push("/edit-profile");
  };

  componentDidMount() {
    const userData = { email: this.props.auth.user.email };
    console.log(userData);

    axios
      .post("/api/users/userdetails", userData)
      .then((res) => {
        console.log(res);

        this.setState({ userdetails: res.data });
        this.setState({ email: res.data.email });
        this.setState({ contactdetails: res.data.contactdetails });
        this.setState({ skillMatrix: res.data.skillMatrix }, () => {
          var count = 0;
          var self = this;
          this.state.skillMatrix.forEach(function(item, index) {
            if (item.isMandatoryCategory === true) {
              count = count + 1;
            }
            self.setState({ mandatoryCount: count });
          });
        });
        this.setState({ socialProfiles: res.data.socialProfiles });
        this.setState({ profileImage: res.data.profileImage });
      })
      .catch((err) => {
        console.log(err);

        this.props.logoutUser();
      });

    axios
      .get("/api/categories/mandatorycategorylist")
      .then((res) => {
        this.setState({ mandatorycategoryCount: res.data });
      })
      .catch((err) => console.log(err));

    localStorage.setItem("emailid", this.props.auth.user.email);
    localStorage.setItem("userid", this.props.auth.user.id);
  }

  render() {
    const { user } = this.props.auth;
    const UserDetails = this.state.userdetails;
    var mandateFilled = false;
    if (
      parseInt(this.state.mandatoryCount) >=
      parseInt(this.state.mandatorycategoryCount)
    ) {
      mandateFilled = true;
    }

    var sname = "";
    if (UserDetails === null) {
    } else {
      if (
        UserDetails.screenname !== undefined &&
        UserDetails.screenname !== ""
      ) {
        sname = UserDetails.screenname;
      } else {
        if (localStorage.getItem("ScreenName")) {
          sname = localStorage.getItem("ScreenName");
        } else {
          sname = user.email;
        }
      }
    }

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="container">
            <div className="s12 col">
              <h4>
                <b>Hello</b> {sname}
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "10px",
            border: "1px solid rgba(112,112,112,0.27)",
            marginBottom: "30px",
            marginTop: "20px",
          }}
          className="container valign-wrapper"
        >
          <div className="row">
            {UserDetails.isActive ? (
              <div className="landing-copy col s12 center-align">
                <div className="row">
                  <h6 className="left">
                    <b>Your Recent Activities</b>
                  </h6>
                </div>
                <div className="row">
                  <p>Data not available</p>
                </div>
              </div>
            ) : (
              <>
                {UserDetails.userRole === 2 ? (
                  <div className="landing-copy col s12 center-align">
                    <h6>Your account is under verification process</h6>
                    <br />

                    <div className="col s12">
                      <div
                        className="col s4 bordered"
                        onClick={this.gotoProfile}
                      >
                        Profile :
                        {UserDetails.isProfilePicVerified &&
                        UserDetails.isBioVerified ? (
                          <p className="green-text">Verified</p>
                        ) : (
                          <p className="red-text"> Not Verified</p>
                        )}
                      </div>

                      <div
                        className="col s4 bordered"
                        onClick={this.gotoDocuments}
                      >
                        ID Check :
                        {UserDetails.isPhoneVerified &&
                        UserDetails.isPhotoIdVerified &&
                        UserDetails.isAddressProofVerified ? (
                          <p className="green-text">Verified</p>
                        ) : (
                          <p className="red-text"> Not Verified</p>
                        )}
                      </div>
                      <Link
                        to={{ pathname: "/edit-profile", hash: "skills-table" }}
                      >
                        <div className="col s4 bordered">
                          <span style={{ color: "#282828" }}>Skills :</span>
                          {UserDetails.isCertificationVerified &&
                          UserDetails.isSkillsVerified ? (
                            <p className="green-text">Verified</p>
                          ) : (
                            <p className="red-text"> Not Verified</p>
                          )}
                        </div>
                      </Link>
                      {!mandateFilled && (
                        <p className="red-text">
                          Note: Please add at least one skill for all the
                          mandatory categories in your profile skills section to
                          get approved by the Admin
                        </p>
                      )}
                    </div>

                    <button className="btn black" onClick={this.gotoDocuments}>
                      Check your documents
                    </button>
                  </div>
                ) : (
                  <div className="landing-copy col s12 center-align">
                    <h6>Your account has been deactivated by admin</h6>
                    <br />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="row">
            <div className="landing-copy col s12 center-align"></div>
          </div>
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, logoutUser })(Dashboard);
