import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeProfilePicture } from "../../actions/authActions";
import { updateBid } from "../../actions/bidActions";
import Navbar from "../layout/Navbar";

// import { updateUser, removeProfilePicture } from "../../actions/authActions";
// import Menu from "../dashboard/Menu";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import SocialIcon from "../../images/team.png";
// import CallIcon from "../../images/call-icon.png";
// import EmailIcon from "../../images/email-icon.png";
// import ImageUploader from "react-images-upload";
// import { confirmAlert } from "react-confirm-alert";
// import ReactTooltip from "react-tooltip";
// import { Multiselect } from "multiselect-react-dropdown";
// import classnames from "classnames";

import "../dashboard/dashboard.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../axios/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LeftArrow from "../../images/left-arrow.png";
import PaperClip from "../../images/paperclip-icon.png";

import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import NoData from "./NoData";
import LoadingSpinner from "../Common/LoadingSpinner";
import { CUSTOMER_URL, URL_PARAM } from "../../utils/Config";
import ClearIcon from "../../images/clear-icon.png";

const NoDataConst = (props) => (
  <NoData message="No Skills Selected" {...props} />
);

class EditBid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sameObject: false,
      screenname: "",
      first_name: "",
      last_name: "",
      profile_summary: "",
      email: "",
      profile_title: "",
      category: "",
      custome: "",
      customerEmail: "",
      region: "",
      phone: "",
      zip_code: "",
      social_links: [],
      temptask: "",
      categories: [],
      skills: [],
      categorySkills: [],
      ranking: [],
      yearsOfExp: [],
      errors: {
        title: "",
        category: "",
        skills: "",
      },
      selectedSkillMatrix: [],
      skillMatrix: [],
      selectedCategory: "",
      selectedSkills: "",
      selectedRanking: "",
      selectedYearsOfExp: "",
      categoryList: [],
      tempRow: {
        selectedCategory: "",
        selectedSkills: "",
        selectedRanking: "",
        selectedYearsOfExp: "",
      },
      userdetails: {},
      contactdetails: {},
      isSocialEmpty: false,
      isSkillEmpty: false,
      pictures: [],
      profileImage: "",
      extensionError: false,
      fileDimensionError: false,
      fileSizeError: false,
      showCategoryList: false,
      options: [],
      startDate: "",
      endDate: "",
      endDateValidate: false,
      minBudget: "",
      maxBudget: "",
      titleError: false,
      descriptionError: false,
      selectedSkillMatrixError: false,
      categoryError: false,
      startDateError: false,
      endDateError: false,
      title: "",
      description: "",
      serviceRequestId: "",
      biddetails: {},
      selectedItems: [],
      attachments: [],
      status: "",
      assignedTo: "",
      freelancersList: [],
      userRole: "",
      loading: false,
      selectedFile: [],
      uploadFilesMaping: [],
    };

    this.onDrop = this.onDrop.bind(this);
  }

  async componentDidMount() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get("bidrequestid");
    const userRole = this.props.auth.user.userRole;
    this.setState({ userRole: this.props.auth.user.userRole });
    const isActiveUser = this.props.auth.user.isActive;

    if (
      (isActiveUser && userRole === 1) ||
      (isActiveUser && userRole === 3) ||
      (isActiveUser && userRole === 4)
    ) {
      const bidId = { bidrequestid: id.trim() };
      this.setState({ loading: true });
      await axios
        .get("/api/categories/categorylist")
        .then((res) => {
          const modifiedCategories =
            res.data &&
            res.data.length &&
            res.data
              .filter((e) => e.isActive)
              .map((item) =>
                item.skills.map((skill) => ({
                  group: item.categoryname,
                  label: skill,
                  id: skill,
                }))
              )
              .flat();
          this.setState({ categories: modifiedCategories });
        })
        .catch((err) => console.log(err));

      await axios
        .post("/api/bids/biddetails", bidId)
        .then((res) => {
          const { categories } = this.state;
          const {
            data: {
              biddetails,
              skills = [],
              title,
              description,
              category,
              serviceRequestId,
              minBudget,
              maxBudget,
              attachments,
              startDate,
              endDate,
              status,
              assignedTo,
              customerName,
              customerEmail,
            } = {},
          } = res;
          console.log(attachments);
          this.setState({
            biddetails,
            skills,
            selectedSkillMatrix: skills,
            title,
            description,
            category,
            serviceRequestId,
            minBudget,
            maxBudget,
            attachments,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status,
            assignedTo,
            customerName,
            customerEmail,
            selectedItems: categories.filter((e) => skills.includes(e.label)),
          });

          console.log(res);
        })
        .catch((err) => console.log(err));

      // Service Request categories

      await fetch(
        CUSTOMER_URL +
          "/TicketProblem/GetAllTicketProblemTypes?isactive=1&URL=" +
          URL_PARAM
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ categoryList: data }, () => {
            console.log(this.state.categoryList);
          });
        });

      await fetch(CUSTOMER_URL + "/Customer/GetAllCustomersFL?URL=" + URL_PARAM)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ customerList: data }, () => {
            console.log(this.state.customerList);
          });
        });

      await axios
        .get("/api/users/adminusers")
        .then((res) => {
          this.setState({ freelancersList: res.data }, () => {
            console.log(this.state.freelancersList);
          });
        })
        .catch((err) => console.log(err));
      this.setState({ loading: false });
    } else {
      this.props.history.push("/dashboard");
    }
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: pictureFiles,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    const { errors } = this.state;
    if (e.target.id === "minBudget" || e.target.id === "maxBudget") {
      delete errors[e.target.id];
      if (e.target.value < 0) {
        errors[e.target.id] = "No negative values allowed";
      }
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  delRow = (event, idx) => {
    event.preventDefault();
    var array = [...this.state.selectedSkillMatrix];
    var index = idx;
    array.splice(index, 1);
    this.setState({ selectedSkillMatrix: array });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const attachments = this.state.attachments.filter(
      (file) => !file.startsWith("blob")
    );

    if (this.state.title === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          title: "Title is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.description === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          description: "Description is required field",
        }),
      });

      this.setState(state);
    }
    if (this.state.selectedSkillMatrix.length === 0) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          skills: "Skills are required",
        }),
      });

      this.setState(state);
    }
    if (this.state.category === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          category: "Category is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.startDate === "" || !this.state.startDate) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          startDate: "Start date is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.endDate === "" || !this.state.endDate) {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          endDate: "End date is required",
        }),
      });

      this.setState(state);
    }
    if (this.state.serviceRequestId === "") {
      const state = Object.assign(this.state, {
        errors: Object.assign(this.state.errors, {
          serviceRequestId: "Service request id is required",
        }),
      });

      this.setState(state);
    }
    if (
      this.state.errors["minBudget"] ||
      this.state.errors["maxBudget"] ||
      this.state.errors["startDate"] ||
      this.state.errors["endDate"]
    ) {
      return;
    }
    if (
      this.state.title !== "" &&
      this.state.description !== "" &&
      this.state.selectedSkillMatrix.length !== 0 &&
      this.state.category !== "" &&
      this.state.startDate !== "" &&
      this.state.startDate &&
      this.state.endDate !== "" &&
      this.state.endDate
    ) {
      const newBid = {
        title: this.state.title,
        description: this.state.description,
        category: this.state.category,
        serviceRequestId: this.state.serviceRequestId,
        skills: this.state.selectedSkillMatrix,
        minBudget: this.state.minBudget,
        maxBudget: this.state.maxBudget,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        attachments: attachments,
        status: this.state.status,
        assignedTo: this.state.assignedTo,
        customerName: this.state.customer,
        customerEmail: this.state.customerEmail,
      };

      if (this.state.selectedFile.length > 0) {
        this.uploadAttachments();
      }
      this.props.updateBid(newBid, this.props.history);
    }
  };

  // ADD ATTACHMENTS
  onChangeHandler = (event) => {
    if (document.getElementsByClassName("uploaded_attachments").length) {
      var attaches = document.querySelectorAll(".uploaded_attachments");
      for (var i = 0; i < attaches.length; i++) {
        attaches[i].style.display = "none";
      }
    }
    document.getElementById("attachments_root").style.display = "block";

    const attachmentUrl = URL.createObjectURL(event.target.files[0]);

    this.setState({
      selectedFile: [...this.state.selectedFile, event.target.files[0]],
      uploadFilesMaping: [
        ...this.state.uploadFilesMaping,
        [event.target.files[0].name, attachmentUrl],
      ],
      attachments: [...this.state.attachments, attachmentUrl],
    });
  };

  // REMOVE ATTACHMENTS
  removeAttachments = (attachmentId, fileUrl) => {
    const updatedUploades = [];
    const removeUploadMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] === fileUrl
    );

    const newMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] !== fileUrl
    );

    this.state.selectedFile.forEach((el) => {
      if (el.name === removeUploadMaping[0][0]) {
        window.URL.revokeObjectURL(fileUrl);
      } else {
        updatedUploades.push(el);
      }
    });

    const updatedAttachments = this.state.attachments.filter(
      (currentValue, index) => attachmentId * 1 !== index
    );

    this.setState({
      attachments: updatedAttachments,
      selectedFile: updatedUploades,
      uploadFilesMaping: newMaping,
    });
  };

  // UPLOAD ATTACHMENTS
  uploadAttachments = () => {
    if (
      this.state.selectedFile !== null &&
      this.state.selectedFile !== undefined &&
      this.state.selectedFile !== "undefined"
    ) {
      this.state.uploadFilesMaping.forEach((el) => {
        let img = new Image();
        img.src = el[1];

        img.onload = () => {
          var width = img.naturalWidth;
          var height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);

          if (width > 2000 || height > 2000) {
            this.setState({ fileDimensionError: true });
          } else {
            this.setState({ fileDimensionError: false });
          }
        };
      });

      const data = new FormData();
      data.append("serviceRequestId", this.state.serviceRequestId);

      this.state.selectedFile.forEach((el, index) => {
        var ext = el.name.split(".").pop();
        var fileSize = el.size;

        if (fileSize > 50000000) {
          this.setState({ fileSizeError: true });
        } else {
          this.setState({ fileSizeError: false });
        }

        if (
          ext !== "png" &&
          ext !== "jpg" &&
          ext !== "jpeg" &&
          ext !== "gif" &&
          ext !== "PNG" &&
          ext !== "JPG" &&
          ext !== "JPEG" &&
          ext !== "GIF"
        ) {
          this.setState({
            extensionError: true,
            fileSizeError: false,
            fileDimensionError: false,
          });

          if (
            ext === "pdf" ||
            ext === "PDF" ||
            ext === "doc" ||
            ext === "docx" ||
            ext === "DOCX" ||
            ext === "xlsx" ||
            ext === "XLSX" ||
            ext === "PPTX" ||
            ext === "pptx"
          ) {
            this.setState({
              extensionError: false,
              fileSizeError: false,
              fileDimensionError: false,
            });
          }

          data.append("file", el);
        } else {
          this.setState({
            extensionError: false,
            fileSizeError: false,
            fileDimensionError: false,
          });
          this.setState({ extensionError: false });
          data.append("file", el);
        }
      });

      axios
        .post("/api/bids/upload", data)
        .then((res) => {
          console.log(res.data);

          var file_paths = this.state.attachments.filter(
            (file) => !file.startsWith("blob")
          );

          res.data.forEach((file_path) => {
            if (file_path !== undefined) {
              if (file_path.length > 0) {
                file_path = file_path.replace("public", "");
              }
            }
            file_paths.push(file_path);
          });
          this.setState({
            attachments: file_paths,
            selectedFile: [],
            uploadFilesMaping: [],
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleCategoryDropdown = (event, idx) => {
    event.preventDefault();
    this.setState({ category: idx });
    this.setState({ showCategoryList: false });
  };

  changeShowCategoryList = () => {
    this.setState({ showCategoryList: true });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13 || code === 44) {
      event.preventDefault();
      var str = event.target.value;
      str = str.replace(/,\s*$/, "");
      this.setState(
        { selectedSkillMatrix: [...this.state.selectedSkillMatrix, str] },
        () => {}
      );
      event.target.value = "";
    }
  }
  blurPressed(event) {
    event.preventDefault();
    var str = event.target.value;
    if (str !== "") {
      str = str.replace(/,\s*$/, "");
      this.setState(
        { selectedSkillMatrix: [...this.state.selectedSkillMatrix, str] },
        () => {}
      );
      event.target.value = "";
    }
  }

  textChange = () => {};

  endAfterStart = (date) => {
    const { errors } = this.state;
    delete errors["endDate"];
    this.setState({ endDate: date, errors });
    var start = document.getElementById("startDate").value;
    var startDate = new Date(start);
    var endDate = new Date(date);

    const diff = Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / 86400000
    );

    if (diff < 1) {
      errors["endDate"] = "Minimum Duration of 1 Day is required";
      this.setState({ errors });
    }

    if (endDate.getTime() < startDate.getTime()) {
      this.setState({ endDateValidate: true });
    } else {
      this.setState({ endDateValidate: false });
    }
  };

  handleBack() {
    window.history.back();
  }

  handleChange = (selectedItems) => {
    this.setState({
      selectedItems,
      selectedSkillMatrix: selectedItems.map((e) => e.id),
    });
  };

  render() {
    const { errors, categories, selectedItems, loading } = this.state;
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        {(loading && <LoadingSpinner />) || (
          <>
            <div className="container" style={{ marginTop: "60px" }}>
              <div className="row">
                <h4>
                  <span onClick={this.handleBack}>
                    <img
                      src={LeftArrow}
                      alt="back"
                      style={{ width: "16px", marginRight: "10px" }}
                    />
                  </span>
                  Edit Bid Request
                </h4>
                <form
                  className="col s12"
                  style={{ marginTop: "10px" }}
                  noValidate
                  onSubmit={this.onSubmit}
                >
                  <div className="row">
                    <div className="col s12 module">
                      <div className="row">
                        <div
                          className="input-field col s12"
                          style={{ marginTop: "40px" }}
                        >
                          <input
                            id="title"
                            type="text"
                            className="validate"
                            onChange={this.onChange}
                            value={this.state.title}
                          />

                          <label htmlFor="title" className="active">
                            Title<span className="red-text">*</span>
                          </label>
                          <span className="red-text">{errors.title}</span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="input-field col s12">
                          <div className="profile-summary-textarea">
                            <textarea
                              id="description"
                              className="materialize-textarea"
                              onChange={this.onChange}
                              value={this.state.description}
                            ></textarea>
                          </div>
                          <label htmlFor="description" className="active">
                            Description<span className="red-text">*</span>
                          </label>
                          <span className="red-text">{errors.description}</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="input-field col s4">
                          <input
                            id="category"
                            type="text"
                            className="validate"
                            onClick={this.changeShowCategoryList}
                            onChange={this.onChange}
                            autoComplete="off"
                            value={this.state.category}
                          />
                          {this.state.showCategoryList && (
                            <div className="category-list">
                              <ul>
                                {this.state.categoryList.map(
                                  function(d, idx) {
                                    return (
                                      <li
                                        value={d.TicketProblemTypeName}
                                        onClick={(event) =>
                                          this.handleCategoryDropdown(
                                            event,
                                            d.TicketProblemTypeName
                                          )
                                        }
                                      >
                                        {d.TicketProblemTypeName}
                                      </li>
                                    );
                                  }.bind(this)
                                )}
                              </ul>
                            </div>
                          )}
                          <label htmlFor="category" className="active">
                            Category<span className="red-text">*</span>
                          </label>
                          <span className="red-text">{errors.category}</span>
                        </div>
                        <div className="input-field col s4">
                          <input
                            id="customer"
                            disabled="true"
                            type="text"
                            className="validate"
                            onFocus={this.changeShowCustomerList}
                            onClick={this.changeShowCustomerList}
                            onChange={this.onChange}
                            autoComplete="off"
                            value={this.state.customerName}
                          />
                          {this.state.showCustomerList && (
                            <div className="category-list">
                              <ul>
                                {this.state.customerList.map(
                                  function(d, idx) {
                                    return (
                                      <li
                                        value={d.EmailAddress}
                                        onClick={(event) =>
                                          this.handleCustomerDropdown(
                                            event,
                                            d.CustomerName,
                                            d.EmailAddress
                                          )
                                        }
                                      >
                                        {d.CustomerName}
                                      </li>
                                    );
                                  }.bind(this)
                                )}
                              </ul>
                            </div>
                          )}
                          <label htmlFor="category" className="active">
                            Customer<span className="red-text">*</span>
                          </label>
                          <span className="red-text">{errors.customer}</span>
                        </div>
                        <div className="input-field col s4">
                          <input
                            id="serviceRequestId"
                            disabled="true"
                            type="text"
                            onChange={this.onChange}
                            value={this.state.serviceRequestId}
                            error={errors.serviceRequestId}
                            className="validate"
                          />
                          <label htmlFor="serviceRequestId" className="active">
                            Service Request Id
                            <span className="red-text">*</span>
                          </label>
                          <div className="red-text">
                            {errors.serviceRequestId}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="container">
                          <div className="input-field col s12 selected-categories-container">
                            {/* <ul className="selected-categories">
            {this.state.selectedSkillMatrix.map(function(d, idx){
                      return (<li>{d} <button className="del-row" onClick={(event) => this.delRow(event, idx)}>Delete</button></li>)
                  }.bind(this))}
          </ul>
          <input style={{bordercolor:"transparent"}}
          placeholder='New Skill...'
          onChange={this.textChange.bind(this)}
          value={this.state.name} 
          onKeyPress={this.enterPressed.bind(this)}
          onBlur={this.blurPressed.bind(this)}
      /> */}
                            <label
                              htmlFor="skills"
                              className="active"
                              // style={{ left: "-5px" }}
                            >
                              Skills<span className="red-text">*</span>
                            </label>
                            <MultiSelect
                              showSelectAll={false}
                              items={categories}
                              withGrouping
                              selectedItems={selectedItems}
                              onChange={this.handleChange}
                              noItemsRenderer={NoDataConst}
                            />
                            <span className="red-text">{errors.skills}</span>
                          </div>

                          <div className="input-field col s3">
                            <input
                              type="number"
                              id="minBudget"
                              onChange={this.onChange}
                              value={this.state.minBudget}
                            />
                            <label htmlFor="minBudget" className="active">
                              Budget
                            </label>
                            <span className="red-text">{errors.minBudget}</span>
                          </div>
                          <div className="input-field col s3">
                            <input
                              type="number"
                              id="maxBudget"
                              onChange={this.onChange}
                              value={this.state.maxBudget}
                            />
                            <span className="red-text">{errors.maxBudget}</span>
                          </div>
                          <div className="input-field col s3">
                            <DatePicker
                              id="startDate"
                              selected={this.state.startDate}
                              onChangeRaw={(e) => {
                                e.preventDefault();
                              }}
                              onChange={(date) => {
                                const { errors } = this.state;
                                delete errors.endDate;
                                delete errors.startDate;
                                let start = new Date(date);
                                let end = new Date(this.state.endDate);
                                const diff = Math.floor(
                                  (Date.parse(end) - Date.parse(start)) /
                                    86400000
                                );
                                if (diff === 0) {
                                  errors["startDate"] =
                                    "Minimum Duration of 1 Day is required";
                                }
                                if (diff < 0) {
                                  errors["startDate"] =
                                    "Start Date should be lesser than End Date";
                                }

                                this.setState({ startDate: date, errors });
                              }}
                              className="datepicker"
                              autoComplete="off"
                            />
                            <p
                              className="red-text m-0"
                              style={{ paddingLeft: "20px" }}
                            >
                              {errors.startDate}
                            </p>
                            <label htmlFor="Duration" className="active">
                              Duration<span className="red-text">*</span>
                            </label>
                          </div>
                          <div className="input-field col s3">
                            <DatePicker
                              id="endDate"
                              selected={this.state.endDate}
                              onChange={this.endAfterStart}
                              onChangeRaw={(e) => {
                                e.preventDefault();
                              }}
                              className="datepicker"
                              autoComplete="off"
                            />
                            <p
                              className="red-text m-0"
                              style={{ paddingLeft: "20px" }}
                            >
                              {errors.endDate}
                            </p>
                            {this.state.endDateValidate && (
                              <p className="red-text">
                                End date should be greater than start date
                              </p>
                            )}
                          </div>

                          <div className="col s12">
                            <div className="input-field col s3">
                              <select
                                id="status"
                                onChange={this.onChange}
                                value={this.state.status}
                                className="browser-default"
                                style={{
                                  marginLeft: "-15px",
                                  borderRadius: "20px",
                                  border: "1px solid rgba(0,0,0,0.44)",
                                  height: "54px",
                                }}
                              >
                                <option value="open">Open</option>
                                <option value="closed">Closed</option>
                                <option value="open">Negotiation</option>
                              </select>
                              <label
                                htmlFor="status"
                                className="active"
                                style={{ marginLeft: "-15px" }}
                              >
                                Status<span className="red-text">*</span>
                              </label>
                              <span className="red-text">{errors.status}</span>
                            </div>
                            {(this.state.userRole === 1 ||
                              this.state.userRole === 4) && (
                              <div className="input-field col s3">
                                <select
                                  id="assignedTo"
                                  onChange={this.onChange}
                                  value={this.state.assignedTo}
                                  className="browser-default"
                                  style={{
                                    marginLeft: "-15px",
                                    borderRadius: "20px",
                                    border: "1px solid rgba(0,0,0,0.44)",
                                    height: "54px",
                                  }}
                                >
                                  {this.state.freelancersList.map(function(
                                    d,
                                    idx
                                  ) {
                                    return (
                                      d.userRole === 3 &&
                                      d.isActive && (
                                        <option key={idx} value={d.email}>
                                          {d.email}
                                        </option>
                                      )
                                    );
                                  })}
                                </select>
                                <label
                                  htmlFor="assignedTo"
                                  className="active"
                                  style={{ marginLeft: "-15px" }}
                                >
                                  Assigned To<span className="red-text">*</span>
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col s12">
                            <p>Add Attachments</p>
                            <div
                              id="attachments_root"
                              className="uploaded_attachments"
                            >
                              {this.state.attachments.map(
                                function(item, idx) {
                                  if (item.startsWith("blob")) {
                                    return (
                                      <span
                                        className="file-field input-field "
                                        style={{
                                          float: "left",
                                          position: "relative",
                                        }}
                                        key={idx}
                                      >
                                        <a target="_blank" href={item}>
                                          <img
                                            src={item}
                                            alt="Attachment"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              width: "100%",
                                              height: "100%",
                                            }}
                                          />
                                        </a>
                                        <img
                                          src={ClearIcon}
                                          alt="attachments"
                                          style={{
                                            display: "block",
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            zIndex: 1,
                                            width: "25%",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            opacity: "85%",
                                          }}
                                          onClick={() =>
                                            this.removeAttachments(idx, item)
                                          }
                                        />
                                      </span>
                                    );
                                  } else {
                                    item = item.replace("public", "");
                                    var extension = item.split(".").pop();
                                    if (
                                      extension === "jpg" ||
                                      extension === "png" ||
                                      extension === "jpeg" ||
                                      extension === "PNG" ||
                                      extension === "JPG" ||
                                      extension === "gif"
                                    ) {
                                      return (
                                        <span
                                          className="file-field input-field "
                                          style={{ float: "left" }}
                                          key={idx}
                                        >
                                          <a
                                            target="_blank"
                                            href={`https://devfreelancersapi.supportpod.com${item}`}
                                          >
                                            <img
                                              src={`https://devfreelancersapi.supportpod.com${item}`}
                                              alt="item"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          </a>
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <span
                                            className="file-field input-field attachment"
                                            style={{ float: "left" }}
                                            key={idx}
                                          >
                                            <a
                                              target="_blank"
                                              href={`https://devfreelancersapi.supportpod.com${item}`}
                                            >
                                              <img
                                                src={PaperClip}
                                                alt="attachments"
                                              />
                                              <img src={item} alt="item" />
                                            </a>
                                          </span>
                                        </>
                                      );
                                    }
                                  }
                                }.bind(this)
                              )}
                            </div>
                            <div
                              className="file-field input-field"
                              style={{
                                width: "42px",
                                height: "42px",
                                float: "left",
                              }}
                            >
                              <div
                                className="btn"
                                style={{ width: "42px", height: "42px" }}
                              >
                                <span
                                  style={{
                                    marginTop: "4px",
                                    marginLeft: "-13px",
                                    backgroundSize: "24px 24px",
                                  }}
                                >
                                  File
                                </span>
                                <input
                                  type="file"
                                  title="Add Attachment"
                                  name="file"
                                  onChange={(event) =>
                                    this.onChangeHandler(event, "root")
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    zIndex: 101,
                                  }}
                                />
                              </div>
                              <div
                                className="file-path-wrapper"
                                style={{
                                  visibility: "hidden",
                                  height: "1px",
                                  width: "1px",
                                }}
                              >
                                <input
                                  className="file-path validate"
                                  type="text"
                                  placeholder="Upload one or more files"
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            {this.state.extensionError && (
                              <span className="red-text">
                                Please upload a valid image
                                <br />
                                (png, jpg, jpeg, gif, doc, xl, pdf, docx, text)
                              </span>
                            )}
                            {this.state.fileDimensionError && (
                              <span className="red-text">
                                Please select a image below 2000 px
                              </span>
                            )}
                            {this.state.fileSizeError && (
                              <span className="red-text">
                                Maximum file size allowed is 2MB
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col s12 center"
                    style={{ paddingLeft: "11.250px" }}
                  >
                    <button
                      style={{
                        width: "100%",
                        borderRadius: "20px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        maxWidth: "240px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      type="submit"
                      className="btn btn-large waves-effect waves-light hoverable black accent-3"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {this.state.showCategoryList && (
              <div
                onClick={() => this.setState({ showCategoryList: false })}
                className="overlay"
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(0,0,0,0.01)",
                  zIndex: "97",
                }}
              ></div>
            )}
          </>
        )}
      </>
    );
  }
}

EditBid.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateBid, removeProfilePicture })(
  EditBid
);
