import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import "./auth.css";
import logo from "../layout/logo.png";
import ReCAPTCHA from "react-google-recaptcha";

class Register extends Component {
  constructor() {
    super();

    this.state = {
      screenname:"",
      email: "",
      passwordValidate:false,
      password2Validate:false,
      emailValidate:false,
      termsConditionsValidate:false,
      isChecked: true,
      password: "",
      password2: "",
      errors: {},
      grecaptcha:"",
      grecaptchaValid:true
    };
     
  }

  

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  toggleChange = () => {
    this.setState({isChecked: !this.state.isChecked}, ()=>{
      if(this.state.isChecked === true){
          this.setState({termsConditionsValidate:false})
      }
    });
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onPasswordChange = e => {

    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;

    if (e.target.value !=="") {
      if(e.target.value.match(passw)) 
        { 
        this.setState({ [e.target.id]: e.target.value });
         this.setState({passwordValidate:false})
         this.setState({errors:{}})
        return true;
        }
        else
        { 
        this.setState({passwordValidate:true})
        this.setState({errors:{}})
        e.target.focus();
        return false;
        }
      }
      else {

        this.setState({passwordValidate:false})
        this.setState({errors:{}})
      }
    }

  onPassword2Change = e => {

    if (e.target.value !=="") {
      if(e.target.value === this.state.password) 
        { 
        this.setState({ [e.target.id]: e.target.value });
        this.setState({errors:{}})
         this.setState({password2Validate:false})
        return true;
        }
        else
        { 
        this.setState({password2Validate:true})
        this.setState({errors:{}})
        e.target.focus();
        return false;
        }
      }
      else {

        this.setState({password2Validate:false})
        this.setState({errors:{}})
      }
    }

    onEmailChange = e => {
      if (e.target.value !=="") {
          var pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
          if (!pattern.test(e.target.value)) {
            this.setState({emailValidate:true})
            this.setState({errors:{}})
            e.target.focus();
            return false;
          }
          else {
            this.setState({ [e.target.id]: e.target.value });
            this.setState({errors:{}})
            this.setState({emailValidate:false});
            return true;
          }
      }else {
        this.setState({emailValidate:false})
        this.setState({errors:{}})
      }
    }



  onSubmit = e => {
    e.preventDefault();
    const recaptchaValue = this.state.grecaptcha;
    if(recaptchaValue ===""){
      this.setState({grecaptchaValid:false});
    }
    else{
      if(this.state.isChecked===true){
        
      this.setState({emailValidate:false});
      this.setState({passwordValidate:false});
      this.setState({password2Validate:false});

      const newUser = {
        screenname: this.state.screenname,
        email: this.state.email,
        password: this.state.password,
        password2: this.state.password2,
        userRole: 2,
        isActive:false
      };

      this.props.registerUser(newUser, this.props.history);
      }
      else {
        this.setState({termsConditionsValidate:true});
      }
    }
  };

  onChangeCaptcha = (value)=> {
    console.log("Captcha value:", value);
    this.setState({grecaptcha:value});
    this.setState({grecaptchaValid:true});
  }

  render() {
    const { errors } = this.state;

    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace"
              }}
              className="col s5 brand-logo center black-text"
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>
      </div>
      <div className="container auth">
        <div className="row">
          <div className="col s8 offset-s2">
            
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4 style={{marginTop:"0"}}>Freelancer Sign Up</h4>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12" style={{display:"none"}} >
                <input
                  onChange={this.onChange}
                  value={this.state.screenname}
                  error={errors.screenname}
                  id="screenname"
                  type="text"
                  className={classnames("", {
                    invalid: errors.screenname
                  })}
                />
                <label htmlFor="name">Screen Name</label>
                <span className="red-text">{errors.screenname}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onEmailChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email
                  })}
                />
                <label htmlFor="email">Email</label>
                <span className="red-text">{errors.email}</span>
                {this.state.emailValidate && <span className="red-text">A valid email is required</span> }
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onPasswordChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password
                  })}
                />
                <label htmlFor="password">Password</label>
                <span className="red-text">{errors.password}</span>
                {this.state.passwordValidate && <span className="red-text"> A Password must contain between 6 to 30 characters, at least 1number, 1 uppercase and 1 lowercase letter</span> }
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onPassword2Change}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password2
                  })}
                />
                <label htmlFor="password2">Confirm Password</label>
                <span className="red-text">{errors.password2}</span>
                {this.state.password2Validate && <span className="red-text"> Passwords must match</span> }
              </div>
              <p className="left" style={{marginTop:"0",marginBottom:"0"}}><label>
                <input type="checkbox" className="filled-in" id="termsConditions" defaultChecked={this.state.isChecked}
          onChange={this.toggleChange}/>
                <span style={{fontSize:"13px"}}>Accept <a href="#">Terms and Conditions</a></span>
              </label> </p>
              {this.state.termsConditionsValidate && 
                <div className="col"><span className="red-text" style={{marginLeft:"10px"}}>Please accept terms and conditions</span></div>
              }
              <div className="col s12" style={{marginTop:"10px", marginLeft:"-11px" }}>
                    <ReCAPTCHA style={{width:"240px"}}
                    ref={this.recaptchaRef}
                    sitekey="6LdO19YZAAAAAMt8VUerVkouNZ6oHVokl2_LRW-E"
                    onChange={this.onChangeCaptcha}
                  />
                  {!this.state.grecaptchaValid && 
                    <span className="red-text">Please verify the reCAPTCHA</span>
                  }
              </div>

              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >
                  Sign up
                </button>
              </div>
              
            </form>
          </div>
        </div>
      </div>
      <p className="grey-text text-darken-1" style={{textAlign:"center"}}>
      Already have an account? <Link to="/login">Log in</Link>
    </p>
    </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
