import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../layout/Navbar";
import "../dashboard/dashboard.css";
import ShieldIcon from "../../images/shield-icon.png";
import OrangeShieldIcon from "../../images/orange-shield-icon.png";
import axios from "../../axios/index";

import { HashLink as Link } from "react-router-hash-link";
import LoadingSpinner from "../Common/LoadingSpinner";

class Skills extends Component {
  constructor() {
    super();
    this.state = {
      userdetails: {},
      contactdetails: {},
      skillMatrix: [],
      socialProfiles: [],
      certifications: [],
      loading: false,
    };
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  async componentDidMount() {
    const userData = { email: this.props.auth.user.email };
    this.setState({ loading: true });
    await axios
      .post("/api/users/userdetails", userData)
      .then((res) => {
        this.setState({ userdetails: res.data });
        this.setState({ contactdetails: res.data.contactdetails });
        this.setState({ skillMatrix: res.data.skillMatrix });
        this.setState({ socialProfiles: res.data.socialProfiles });
        console.log(res.data.socialProfiles);
      })
      .catch((err) => console.log(err));

    await axios
      .post("/api/certifications/certificationlist", userData)
      .then((res) => {
        this.setState({ certifications: res.data.certifications });
      })
      .catch((err) => console.log(err));
    this.setState({ loading: false });
  }

  render() {
    const { certifications, skillMatrix, loading } = this.state;

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        {(loading && <LoadingSpinner />) || (
          <>
            <div
              style={{
                borderRadius: "20px",
                padding: "10px",
                marginBottom: "30px",
                marginTop: "60px",
              }}
              className="container"
            >
              <div className="row">
                <div className="txt_align_c">
                  <h5>Skills</h5>
                </div>
                <button
                  className="right add-btn small-font"
                  style={{ width: "120px" }}
                >
                  <Link
                    to={{ pathname: "/edit-profile", hash: "skills-table" }}
                  >
                    Edit Skills
                  </Link>
                </button>
                <div className="col s12 module">
                  <table className="striped">
                    <thead>
                      <tr>
                        <th>Categories</th>
                        <th>Skill</th>
                        <th className="txt_align_c">Ranking</th>
                        <th className="txt_align_c">Years of Exp</th>
                        <th className="txt_align_c">SupportPod Rating</th>
                      </tr>
                    </thead>

                    <tbody>
                      {skillMatrix.map(function(d, idx) {
                        return (
                          <tr key={idx}>
                            <td>{d.selectedCategory}</td>
                            <td>{d.selectedSkills}</td>
                            <td className="txt_align_c">{d.selectedRanking}</td>
                            <td className="txt_align_c">
                              {d.selectedYearsOfExp}
                            </td>
                            <td className="txt_align_c">
                              {d.supportpodRating}
                              {d.supportpodRating >= 7 ? (
                                <img
                                  style={{
                                    position: "absolute",
                                    marginLeft: "10px",
                                    width: "16px",
                                  }}
                                  src={ShieldIcon}
                                  alt="Rating"
                                />
                              ) : (
                                d.supportpodRating >= 1 && (
                                  <img
                                    style={{
                                      position: "absolute",
                                      marginLeft: "10px",
                                      width: "16px",
                                    }}
                                    src={OrangeShieldIcon}
                                    alt="Rating"
                                  />
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "20px",
                  padding: "10px",
                  marginBottom: "30px",
                  marginTop: "60px",
                }}
                className="container"
              >
                <div className="row">
                  <div className="txt_align_c">
                    <h5>Certifications</h5>
                  </div>
                  <button
                    className="right add-btn small-font"
                    style={{ width: "150px" }}
                  >
                    <Link
                      to={{ pathname: "/edit-profile", hash: "certifications" }}
                    >
                      Edit Certifications
                    </Link>
                  </button>
                  <div className="col s12 module">
                    {(certifications.length &&
                      certifications.map((d, idx) => {
                        return (
                          <>
                            <div
                              style={{
                                // borderTop: "1px solid #e4e4e4",
                                paddingBottom: "20px",
                              }}
                            >
                              <div>
                                <h4>
                                  {d.certificationApproved && (
                                    <img src={ShieldIcon} alt="Approved" />
                                  )}
                                  {d.certificationName}
                                </h4>
                              </div>
                              <div
                                style={{
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                Issued Date :
                                <b>
                                  {new Intl.DateTimeFormat("en-US").format(
                                    new Date(d.certificationIssuedDate)
                                  )}
                                </b>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Expiry Date :
                                <b>
                                  {new Intl.DateTimeFormat("en-US").format(
                                    new Date(d.certificationExpiryDate)
                                  )}
                                </b>
                              </div>
                              <div>{d.certificationDetails}</div>
                              <div
                                style={{
                                  paddingBottom: "10px",
                                  paddingTop: "10px",
                                }}
                              >
                                <a target="_blank" href={d.certificationUrl}>
                                  View Certificate
                                </a>
                              </div>
                            </div>
                          </>
                        );
                      })) || (
                      <div>
                        <h6 className="txt_align_c">
                          No certifications to be Displayed
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

Skills.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Skills);
