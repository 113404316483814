import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../layout/Navbar";
import PhotoId from "./PhotoId";
import MobileVerification from "./MobileVerification";
import UtilityBill from "./UtilityBill";
const IdVerification = (props) => {
  return (
    <React.Fragment>
      <Navbar current={props.history.location.pathname} />
      <div className="row" style={{ marginTop: "60px" }}>
        <div className="container">
          <ToastContainer />
          <div className="left col s4">
            <h4>Proof of Identity</h4>
            <PhotoId />
          </div>
          <div className="left col s4">
            <h4>Mobile Verification</h4>
            <MobileVerification />
          </div>

          <div className="left col s4">
            <h4>Proof of Address</h4>
            <UtilityBill />
          </div>
        </div>
      </div>

      <div className="col s12 center" style={{ paddingLeft: "11.250px" }}>
        <button
          style={{
            width: "100%",
            borderRadius: "20px",
            letterSpacing: "1.5px",
            marginTop: "1rem",
            maxWidth: "240px",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "1rem",
          }}
          type="submit"
          className="btn btn-large waves-effect waves-light hoverable black accent-3"
        >
          <Link
            to="/profile"
            style={{ color: "#ffffff", display: "inline-block", width: "100%" }}
          >
            Save
          </Link>
        </button>
      </div>
    </React.Fragment>
  );
};
export default IdVerification;
