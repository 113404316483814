import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/authActions";
import classnames from "classnames";
import "./auth.css";
import logo from "../layout/logo.png";
import axios from "axios";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2:"",
      email: "",
      errors: {},
      resetToken:"",
      password2Validate:false,
      passwordValidate:false
    };
  }

   componentDidMount() {
   	const queryString = window.location.search;
   	const urlParams = new URLSearchParams(queryString);

   	const resetToken = urlParams.get('resetToken')
	console.log(resetToken);
	this.setState({resetToken:resetToken})
  }

  componentWillReceiveProps(nextProps) {
    console.log('next props',nextProps)
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onPasswordChange = e => {

    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;

    if (e.target.value !=="") {
      if(e.target.value.match(passw)) 
        { 
        this.setState({ [e.target.id]: e.target.value });
         this.setState({passwordValidate:false})
         this.setState({errors:{}})
        return true;
        }
        else
        { 
        this.setState({passwordValidate:true})
        this.setState({errors:{}})
        e.target.focus();
        return false;
        }
      }
      else {

        this.setState({passwordValidate:false})
        this.setState({errors:{}})
      }
    }

  onPassword2Change = e => {

    if (e.target.value !=="") {
      if(e.target.value === this.state.password) 
        { 
        this.setState({ [e.target.id]: e.target.value });
        this.setState({errors:{}})
         this.setState({password2Validate:false})
        return true;
        }
        else
        { 
        this.setState({password2Validate:true})
        this.setState({errors:{}})
        e.target.focus();
        return false;
        }
      }
      else {

        this.setState({password2Validate:false})
        this.setState({errors:{}})
      }
    }

  onSubmit = e => {
    e.preventDefault();
    this.setState({passwordValidate:false});
    this.setState({password2Validate:false});
    const newUser = {
      password: this.state.password,
      password2: this.state.password2,
      id: this.state.resetToken
    };

   this.props.resetPassword(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace"
              }}
              className="col s5 brand-logo center black-text">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>
      </div>
      <div className="container auth">
        <div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4 style={{ fontSize: "28px" }}>Reset Password</h4>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onPasswordChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password
                  })}
                />
                <label htmlFor="password">Password</label>
                <span className="red-text">{errors.password}</span>
                {this.state.passwordValidate && <span className="red-text"> A Password must contain between 6 to 30 characters, at least 1number, 1 uppercase and 1 lowercase letter</span> }
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onPassword2Change}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password2
                  })}
                />
                <label htmlFor="password2">Confirm Password</label>
                <span className="red-text">{errors.password2}</span>
                {this.state.password2Validate && <span className="red-text"> Passwords must match</span> }
              </div>


              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { resetPassword }
)(withRouter(ResetPassword));
