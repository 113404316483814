import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import Register from "./components/auth/Register";
import VerifyEmail from "./components/auth/VerifyEmail";
import EmailVefifiationSuccess from "./components/auth/EmailVefifiationSuccess";
import Login from "./components/auth/Login";
import WebflowLogin from "./components/auth/WebflowLogin";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import ScreenName from "./components/auth/ScreenName";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ResetPasswordSuccess from "./components/auth/ResetPasswordSuccess";
import Profile from "./components/profile/Profile";
import FreelancerProfile from "./components/profile/FreelancerProfile";
import EditProfile from "./components/profile/EditProfile";
import ServiceManager from "./components/dashboard/ServiceManager";
import Messages from "./components/dashboard/Messages";
import Skills from "./components/dashboard/Skills";
import IdVerification from "./components/profile/IdVerification";
import RegistrationSuccess from "./components/auth/RegistrationSuccess";
import Admin from "./components/layout/Admin";
import AdminUsers from "./components/admin/AdminUsers";
import CreateUser from "./components/admin/CreateUser";
import Freelancers from "./components/admin/Freelancers";
import Categories from "./components/admin/Categories";
import Bids from "./components/dashboard/Bids";
import AddBids from "./components/serviceManager/AddBids";
import AllBids from "./components/serviceManager/AllBids";
import BidRequest from "./components/serviceManager/BidRequest";
import EditBid from "./components/serviceManager/EditBid";
import QuotesList from "./components/Finances/Components/QuotesList";
import QuoteDetails from "./components/Finances/Components/QuoteDetails";
import AddCustomer from "./components/Finances/Components/AddCustomer";

import "./App.css";
import Customer from "./components/customers/Customer";
import AddNewCustomer from "./components/customers/AddNewCustomer";
import EditCustomer from "./components/customers/EditCustomer";
import ServiceRequests from "./components/customers/ServiceRequests";
import CreateRequest from "./components/customers/CreateRequest";
import CustomerCategory from "./components/customers/CustomerCategory";
import CustomerStatus from "./components/customers/CustomerStatus";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  console.log(decoded);

  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "https://supportpod.webflow.io/freelancers";
  }
}

class App extends Component {
  componentDidMount() {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5fce23ad920fc91564ce391f/default";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route exact path="/" component={Login} />
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route
              exact
              path="/email-verification-success"
              component={EmailVefifiationSuccess}
            />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
             <Route exact path="/webflow-login" component={WebflowLogin} />
            <Route
              exact
              path="/registration-success"
              component={RegistrationSuccess}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/reset-password-success"
              component={ResetPasswordSuccess}
            />
            <Route
              exact
              path="/freelancer-profile"
              component={FreelancerProfile}
            />
            <Switch>
              <PrivateRoute exact path="/admin" component={Admin} />
              <PrivateRoute exact path="/admin-users" component={AdminUsers} />
              <PrivateRoute exact path="/create-user" component={CreateUser} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/service-manager"
                component={ServiceManager}
              />
              <PrivateRoute exact path="/bids" component={Bids} />
              <PrivateRoute exact path="/messages" component={Messages} />
              <PrivateRoute exact path="/skills" component={Skills} />
              <PrivateRoute exact path="/screenname" component={ScreenName} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute
                exact
                path="/edit-profile"
                component={EditProfile}
              />
              <PrivateRoute
                exact
                path="/id-verification"
                component={IdVerification}
              />
              <PrivateRoute exact path="/freelancers" component={Freelancers} />
              <PrivateRoute exact path="/categories" component={Categories} />
              <PrivateRoute exact path="/add-bids" component={AddBids} />
              <PrivateRoute exact path="/all-bids" component={AllBids} />
              <PrivateRoute exact path="/bid-request" component={BidRequest} />
              <PrivateRoute exact path="/edit-bid" component={EditBid} />
              <PrivateRoute exact path="/quotes" component={QuotesList} />
              <PrivateRoute
                exact
                path="/quote-details"
                component={QuoteDetails}
              />
              <PrivateRoute
                exact
                path="/add-customers"
                component={AddCustomer}
              />
              <PrivateRoute exact path="/customers" component={Customer} />
              <PrivateRoute exact path="/add-new-customer" component={AddNewCustomer} />
              <PrivateRoute exact path="/edit-customer/:email" component={EditCustomer} />
              <PrivateRoute exact path="/customer-status" component={CustomerStatus} />
              <PrivateRoute exact path="/customer-category" component={CustomerCategory} />
              <PrivateRoute exact path="/service-requests" component={ServiceRequests} />
              <PrivateRoute exact path="/createRequest" component={CreateRequest} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
