export const QUOTE_ID = "QuoteID";
export const QUOTE_NUM = "QuoteNumber";
export const SERV_REQ_ID = "serviceRequestId";
export const BID_ID = "bidId";
export const REF = "Reference";
export const T_AND_C = "Terms";
export const DATE = "Date";
export const EXPIRY_DATE = "ExpiryDate";
export const SUB_TOTAL = "SubTotal";
export const TAX = "TotalTax";
export const TOTAL = "Total";
export const TITLE = "Title";
export const SUMMARY = "Summary";
export const CUST_NAME = "customername";
export const CUST_EMAIL = "customeremail";
export const IS_CUST_EXIST = "isexistingcustomer";

export const LINE_ITEMS = "SendQuoteLineitems";

export const DESC = "Description";
export const ITEM_CODE = "ItemCode";
export const UNIT_AMT = "UnitAmount";
export const QUANTITY = "Quantity";
export const LINE_AMT = "LineAmount";

export const REQ_FIELD = "Required Field";

export const DEFAULT_EMAIL = "saiabbena@gmail.com";

export const CO_NAME = "CompanyName";
export const F_NAME = "FirstName";
export const L_NAME = "LastName";
export const EMAIL_ADD = "EmailAddress";
