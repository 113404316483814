import React, { Component } from "react";
import PropTypes from "prop-types";

import Menu from "../dashboard/Menu";
class Navbar extends Component {
  
  componentDidMount () {
    window.scrollTo(0, 0);

  }
  
  render() {
    return (
      <div className="navbar-fixed">
        <nav className="z-depth-0">
          <div className="nav-wrapper white"></div>
        </nav>
        <Menu {...this.props} />
      </div>
    );
  }
}

Navbar.propTypes = {
  current: PropTypes.string.isRequired,
};

export default Navbar;
