import React, { Component } from 'react'
import Navbar from '../layout/Navbar'

class CreateRequest extends Component {
    render() {
        return (
            <>
             <Navbar current={this.props.history.location.pathname} /> 
             <div style={{ marginTop: '50px' }}>

             new request  
             </div>
            </>
        )
    }
}

export default CreateRequest
