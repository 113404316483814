import React, { Component } from "react";
import Navbar from "../layout/Navbar";
class Admin extends Component {
  render() {
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
      </>
    );
  }
}

export default Admin;
