import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../../actions/authActions";
import classnames from "classnames";
import './auth.css';
import logo from "../layout/logo.png";
import Navbar from "../layout/Navbar";
import LoadingSpinner from "../Common/LoadingSpinner";
// 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

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      emailValidation: true
    };
  }

  componentDidMount() {
    console.log('props---',this.props.auth.user);
    // If logged in and user navigates to Login page, should redirect them to dashboard
    localStorage.removeItem('screenName');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const username = urlParams.get('username');
    const password = urlParams.get('password');
    const decodedpassword = atob(password);

    this.setState({ email: username });
    this.setState({ password: decodedpassword });
    setTimeout(()=>{
      const userData = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.loginUser(userData);

      setTimeout(()=>{
          if (!this.state.emailValidation) {
            alert('Emial id not verified in freelancer system');
             this.props.logoutUser();
          }
      },1000)
    },1000)


    if (this.props.auth.isAuthenticated) {

       if(this.props.auth.user.isEmailVerified) {
        
          if (this.props.auth.user.screenname === undefined || this.props.auth.user.screenname === ""){

                this.props.history.push("/screenname");
          
          } else {
            console.log(this.props.auth.user.userRole);

            if(this.props.auth.user.userRole === 1 || this.props.auth.user.userRole === 5) {

              this.props.history.push("/freelancers");

            }
            else if(this.props.auth.user.userRole === 3 || this.props.auth.user.userRole === 4){
              this.props.history.push("/all-bids");
            }
            else if (this.props.auth.user.userRole === 2 && this.props.auth.user.isActive === true) {

              this.props.history.push("/bids");
            }
            else {
              alert(this.props.auth.user.userRole);
              this.props.history.push("/dashboard");
            }
          }
    }else {
      this.setState({emailValidation: false});
    }

    }
  }

  componentWillReceiveProps(nextProps) {
    
   
    if (nextProps.auth.isAuthenticated) {

      if(nextProps.auth.user.isEmailVerified) {


                if (nextProps.auth.user.screenname ===undefined || nextProps.auth.user.screenname ==="" ){
                      
                      this.props.history.push("/screenname");
                }
                else {
                  if(nextProps.auth.user.userRole == 1  || nextProps.auth.user.userRole === 5)  {

                    this.props.history.push("/freelancers");
                  }
                else
                  if( nextProps.auth.user.userRole == 3 || nextProps.auth.user.userRole == 4){

                    this.props.history.push("/all-bids");
                  }
                  else if (nextProps.auth.user.userRole == 2 && nextProps.auth.user.isActive === true) {

                    this.props.history.push("/bids");
                  }
                  else {
                    this.props.history.push("/dashboard");
                  }

                }
      }
      else {
        this.setState({emailValidation: false});
      }
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }


  render() {
    const { errors } = this.state;

    return (
      <>
      
     <LoadingSpinner />

    </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, logoutUser }
)(Login);
