import React, { useState, useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from "../../axios/index";
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Overlay from "../layout/Overlay";
import AddIcon from "../../images/add-icon.png";
import UploadIcon from "../../images/upload-icon.png";
import ZoomIcon from "../../images/zoom-in.png";
import SuccessIcon from "../../images/success.png";
import 'react-toastify/dist/ReactToastify.css';
import './profile.css';
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { API_URL } from '../utils/constants';

  const UtilityBill = (props) => {
  const [file, setFile] = useState(null); // state for storing actual image
  const [addressProof, setAddressProof] = useState(null);
  const [addressProofPrev, setAddressProofPrev] = useState(null);
  const [addressProofPrevBtn, setAddressProofPrevBtn] = useState(null);
  const [showAddressProof, setShowAddressProof] = useState(false);
  const [showIdSuccess, setShowIdSuccess] = useState(false);
  const [userId, setUserId] = useState('');
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage
  const [email, setEmail] = useState(localStorage.getItem('emailid'));
  const [errorMsg, setErrorMsg] = useState('');
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
  const [showAddressUploader, setShowAddressUploader] = useState(false);
  const [isOVerlay, setIsOVerlay] = useState(false);
  const [viewLargeAddressProof, setViewLargeAddressProof] = useState(false);
  const [extensionError, setExtensionError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [addressProofVerified, setAddressProofVerified] = useState(false);
  
  const dropRef = useRef(); // React ref for managing the hover state of droppable area

  const handleInputChange = (event) => {
    
  };

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);
    setAddressProof(uploadedFile);
    console.log(uploadedFile);

    if (uploadedFile.name.match(/\.(jpeg|jpg|png|pdf)$/)) {
      setExtensionError(false);
      if (uploadedFile.size > 2000000) {
        setFileSizeError(true);
      }else {
        setFileSizeError(false);

            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewSrc(fileReader.result);
            };

            fileReader.readAsDataURL(uploadedFile);
            setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
            dropRef.current.style.border = '2px dashed #e9ebeb';
      }
    }else{
      setExtensionError(true);
      setFileSizeError(false);
    }

    
  };


  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData()
       data.append('email',email)
       data.append('id',userId)
       data.append('file', addressProof)
       axios.post("/api/users/uploadaddress", data, { 
      }).then(res => {
       setAddressProof(res.data)
       setShowAddressUploader(false)
       setIsOVerlay(false)
       setExtensionError(false);
       notifySuccess()
       setTimeout(() => {
          setShowIdSuccess(false)
       }, 5000);
     }

     ).catch((err) =>{
        console.log(err);
        setExtensionError(true);
    })


  };

  const notifySuccess = () => toast.success('Utility Bill has been uploaded successfully', {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const showAddressFunc = (event) => {
    event.preventDefault();
    setShowAddressUploader(true);
    setIsOVerlay(true);
  }

  const hideAddressFunc = (event) => {
    event.preventDefault();
    setShowAddressUploader(false);
    setIsOVerlay(false);
    setViewLargeAddressProof(false);
  }

  const viewLargeAddressId = (event) => {
    setViewLargeAddressProof(true);
    setIsOVerlay(true);
  }

  useEffect(() => {
    
    setEmail(props.auth.user.email);
    setUserId(props.auth.user.id);

    const userData = {email:props.auth.user.email}

    axios
    .post("/api/users/userdetails", userData)
    .then(res => {
      if(res.data.isAddressProofVerified) {

          setAddressProofVerified(true);
        }
        else {
          setAddressProofVerified(false);
        }

        if (res.data.addressProof)
            if(res.data.addressProof.match(/\.(jpeg|jpg|png)$/)) {
              setAddressProofPrevBtn("");
              setAddressProofPrev(res.data.addressProof);
            }
            else {
              setAddressProofPrev("");
              setAddressProofPrevBtn(res.data.addressProof);
            }        
            
    })
    .catch(err =>{
        console.log(err);
    }
    );
  });
  var previewAdd ="";
  var addressproof = addressProofPrev;
    if(addressproof !== null){

      if (addressproof.length > 0) {
         addressproof = addressproof.replace('public','');
         previewAdd = <img src={`https://devfreelancersapi.supportpod.com${addressproof}`} alt="address proof" />;
          
      }
    }
    var addressproofBtn = addressProofPrevBtn;
    if(addressproofBtn !== null){

      if (addressproofBtn.length > 0) {
         addressproofBtn = addressproofBtn.replace('public','');
         previewAdd = <div >Preview not available <br/> <a className="btn black view-btn" target="_blank" rel="noopener noreferrer" href={`https://devfreelancersapi.supportpod.com${addressproofBtn}`}  >View File</a></div>;
      }
    }



  return (
    <React.Fragment>

      <div className="idProof-block uploader-display-section" style={{float: "left"}}>
          <h4>Utility Bill</h4>
          <div className="preview-section" >
             {previewAdd ? (<div>{previewAdd}</div>)
              : (
                <div>
                  <span><img src={AddIcon} alt="Add Icon" /></span>
                  <p>Add Utility Bill</p>
                </div>
                )}
              
              {addressProofVerified ? <></> : <button onClick={showAddressFunc} className="upload"></button> }
              {addressproof ? (<button className="view-large" onClick={viewLargeAddressId}><img src={ZoomIcon} width="18"/></button> ) : (null)}
          </div>
      </div>
      {addressProofVerified && <div style={{marginLeft:"20px"}}><img src={SuccessIcon} style={{position: "relative", top : "10px", width:"32px"}} /> Address Proof Verified </div>}
      {showAddressUploader ?
      (<div className="row">
       
        <Form className="search-form" onSubmit={handleOnSubmit}>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
       
        <div className="upload-section">
          <h6>Address Proof</h6>

          <p>Upload Utility Bill</p>

          <Dropzone multiple={false} onDrop={onDrop} onDragEnter={() => updateBorder('over')} onDragLeave={() => updateBorder('leave')}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
                <input {...getInputProps()} />
                <p style={{ margin : "0 auto", lineHeight : "14px", borderRadius : "50%", marginTop : "120px", cursor:"pointer"}}><img src={UploadIcon} alt="Upload Icon" style={{width:"42px"}} /></p>
                <p>Upload</p>
                {file && (
                  <div>
                   
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {extensionError && <p className="red-text">Only .jpg, .jpeg, .gif, .png and .pdf are allowed</p>}
          {fileSizeError && <p className="red-text">File too large, please upload a file below 2MB</p>}
          <button type="button" className="close-btn" onClick={hideAddressFunc}>+</button>
          <button
                  style={{
                    width: "180px",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    position: "absolute",
                    top : "440px",
                    left : "50%",
                    marginLeft : "-90px"

                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3 "
                >
                  Submit
                </button>
          {previewSrc ? (
            isPreviewAvailable ? (
              <div className="image-preview">
                <img className="preview-image" src={previewSrc} alt="Preview" style={{maxWidth : "100%"}}/>
              </div>
            ) : (
              <div className="preview-message">
                <p>No preview available for this file</p>
              </div>
            )
          ) : (
            <div className="preview-message">
              
            </div>
          )}
        </div>
      </Form>

     
      </div> ) : null
    }

      {viewLargeAddressProof &&
        <div className="popup" >
            <button type="button" className="close-btn" onClick={hideAddressFunc}>+</button>
            {addressproof ? (<img src={`https://devfreelancersapi.supportpod.com${addressproof}`} alt="Address Proof" style={{width:"100%"}} />) : (null) }
            
        </div>

      }
    

      {isOVerlay ? 
        (<Overlay />) :  (null)
      }
    </React.Fragment>
  );
};


UtilityBill.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(UtilityBill);