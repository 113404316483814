import axios from "../axios/index";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// create Bid

export const createBid = (bidData, history) => dispatch => {
  axios
    .post("/api/bids/createbid", bidData)
    .then(res => {
      history.push("/all-bids");
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const updateBid = (bidData, history) => dispatch => {
  axios
    .post("/api/bids/updatebid", bidData)
    .then(res => {
      history.push("/all-bids");
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Create Comment

export const createComment = (commentData) => dispatch => {
    axios
    .post("/api/comments/addComment", commentData)
    .then(res => {
        // console.log(res);
        
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.res.data
      })
    );
}