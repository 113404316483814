import React, { Component } from "react";

export default class LoadingSpinner extends Component {
  render() {
    const { smaller } = this.props;
    return (
      <div className={(smaller && 'loader_small') || 'loader' }>
        <div className="loading_spinner" />
      </div>
    );
  }
}
