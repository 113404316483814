import React, { Component } from "react";
import axios from "../../axios/index";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Navbar from "../layout/Navbar";
import MoreIcon from "../../images/more-icon.png";
import BidIcon from "../../images/bid-icon.png";
import AddIconWhite from "../../images/add-icon-white.png";
import FilterIcon from "../../images/filter-icon.png";
import AscIcon from "../../images/asc-icon.png";
import DscIcon from "../../images/dsc-icon.png";
import SearchIcon from "../../images/search-icon.png";

import ReactTimeAgo from "react-time-ago";
import LoadingSpinner from "../Common/LoadingSpinner";
import {CUSTOMER_URL, URL_PARAM} from "../../utils/Config";

class AllBids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail : "",
      bidslist: {},
      showFilters: false,
      categoryList: [],
      customerList:[],
      startDate: "",
      endDate: "",
      endDateValidate: false,
      startDateNull: false,
      endDateNull: false,
      ascSorting: true,
      totalpages: 0,
	  loading: false,
    mybids:true,
    allbids:false
    };
  }
  async componentDidMount() {
    const userRole = this.props.auth.user.userRole;
    const isActiveUser = this.props.auth.user.isActive;

    this.setState({userEmail:this.props.auth.user.email});

    console.log(this.props.auth.user.email);
	  this.setState({ loading:true });
    if ((isActiveUser && userRole === 1) || (isActiveUser && userRole === 4 || isActiveUser && userRole === 3)) {
      await axios
        .get("/api/bids/bids")
        .then((res) => {
          this.setState({ bidslist: res.data }, () => {
            console.log(this.state.bidslist);
          });
          this.setState({ totalpages: res.data.length });
        })
        .catch((err) => console.log(err));
    } 
    
    // else if (isActiveUser && userRole === 3) {
    //   const filterData = {
    //     email: this.props.auth.user.email,
    //   };

    //   await axios
    //     .post("/api/bids/smbids", filterData)
    //     .then((res) => {
    //       this.setState({ bidslist: res.data }, () => {
    //         console.log(this.state.bidslist);
    //       });
    //       this.setState({ totalpages: res.data.length });
    //     })
    //     .catch((err) => console.log(err));
    // } 

    else {
      this.props.history.push("/dashboard");
    }
    // Service Request categories
    await fetch(
       CUSTOMER_URL+"/TicketProblem/GetAllTicketProblemTypes?isactive=1&URL="+URL_PARAM
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ categoryList: data }, () => {});
      });

          await fetch(
      CUSTOMER_URL+"/Customer/GetAllCustomersFL?URL="+URL_PARAM
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ customerList: data }, () => {
          console.log(this.state.customerList);
        });
      });

	  this.setState({ loading:false });


}

  handleAddBid = (e) => {
    e.preventDefault();
    console.log(this.props);
    this.props.history.push("/add-bids");
  };

  handleEditBid = (event, id) => {
    event.preventDefault();
    this.props.history.push("/edit-bid?bidrequestid=" + id);
  };

  viewBidDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push("/bid-request?bidrequestid=" + id);
  };

  showFiltersFunc = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  endAfterStart = (date) => {
  	this.setState({ endDateNull: false });
    this.setState({ endDate: date });
    var start = document.getElementById("startDate").value;
    var startDate = new Date(start);
    var endDate = new Date(date);

    if (endDate.getTime() < startDate.getTime()) {
      this.setState({ endDateValidate: true });
    } else {
      this.setState({ endDateValidate: false });
    }
  };

  resetFilterData = () => {
    document.getElementById("searchCategory").value = "";
    document.getElementById("status").value = "all";
    document.getElementById("startDate").value = "";
    document.getElementById("endDate").value = "";
    this.setState({ startDate: "",startDateNull:"", endDateNull:"", endDate: "", endDateValidate:"" });

    const filterData = {
      category: "",
      status: "all",
      startDate: "",
      endDate: "",
    };

    axios
      .post("/api/bids/statusfilterbids", filterData)
      .then((res) => {
        this.setState({ bidslist: res.data }, () => {
          console.log(this.state.bidslist);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  };

  filterData = (event) => {
    var status = document.getElementById("status").value;
    var category = document.getElementById("searchCategory").value;
    var customerEmail = document.getElementById("customerEmail").value;
    if (document.getElementById("endDate") !== null) {
      var startDate = document.getElementById("startDate").value;
      var endDate = document.getElementById("endDate").value;
    } else {
      var startDate = "";
      var endDate = "";
    }

    const filterData = {
      status: status,
      category: category,
      startDate: startDate,
      endDate: endDate,
      customerEmail:customerEmail
    };

    axios
      .post("/api/bids/statusfilterbids", filterData)
      .then((res) => {
        this.setState({ bidslist: res.data }, () => {
          console.log(this.state.bidslist);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  };

  filterCustomerData = (event) => {
    var customerEmail = document.getElementById("customerEmail").value;
    document.getElementById("status").value="all";
    document.getElementById("searchCategory").value="";
    const filterData = {
      customerEmail:customerEmail
    };
    axios
      .post("/api/bids/customerfilterbids", filterData)
      .then((res) => {
        this.setState({ bidslist: res.data }, () => {
          console.log(this.state.bidslist);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  };

  dateFilterData = (event) => {
  	if(document.getElementById("startDate").value ==="" || document.getElementById("endDate").value ==="" ) {
  		if (document.getElementById("startDate").value ===""){
  			this.setState({startDateNull:true});
  		}
  		if (document.getElementById("endDate").value ===""){
  			this.setState({endDateNull:true});
  		}
  	}
  	else{
  		var status = document.getElementById("status").value;
    var category = document.getElementById("searchCategory").value;
    if (document.getElementById("endDate").value !== null) {
      var startDate = document.getElementById("startDate").value;
      var endDate = document.getElementById("endDate").value;
    } else {
      var startDate = "";
      var endDate = "";
    }

    const filterData = {
      status: status,
      category: category,
      startDate: startDate,
      endDate: endDate,
    };

    axios
      .post("/api/bids/statusfilterbids", filterData)
      .then((res) => {
        this.setState({ bidslist: res.data }, () => {
          console.log(this.state.bidslist);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  	}
    
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      event.preventDefault();
      var str = event.target.value;
      const filterData = {
        serviceRequestId: str,
      };
      if (str !== "") {
        axios
          .post("/api/bids/searchbids", filterData)
          .then((res) => {
            this.setState({ bidslist: res.data }, () => {
              console.log(this.state.bidslist);
            });
            this.setState({ totalpages: res.data.length });
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .get("/api/bids/bids")
          .then((res) => {
            this.setState({ bidslist: res.data }, () => {
              console.log(this.state.bidslist);
            });
            this.setState({ totalpages: res.data.length });
          })
          .catch((err) => console.log(err));
      }
    }
  };

  onAscSort(event, sortKey) {
    const bidslist = this.state.bidslist;
    var bids = bidslist.sort( function ( a, b ) { return a.serviceRequestId - b.serviceRequestId; } );
    this.setState({ bids });
    this.setState({ ascSorting: false });
  }
  onDscSort(event, sortKey) {
    const bidslist = this.state.bidslist;
    var bids = bidslist.sort( function ( a, b ) { return b.serviceRequestId - a.serviceRequestId; } );
    this.setState({ bids });
    this.setState({ ascSorting: true });
  }

  getSmBids = (event) => {
    event.preventDefault();
      const email = this.state.userEmail;

      const filterData = {
        email: email,
      };

      axios
        .post("/api/bids/smbids", filterData)
        .then((res) => {
          this.setState({ bidslist: res.data }, () => {
            console.log(this.state.bidslist);
            this.setState({mybids:false});
            this.setState({allbids:true});
          });
          this.setState({ totalpages: res.data.length });
        })
        .catch((err) => console.log(err));
  }

    getAllBids = (event) => {
    axios
        .get("/api/bids/bids")
        .then((res) => {
          this.setState({ bidslist: res.data }, () => {
            console.log(this.state.bidslist);
            this.setState({mybids:true});
            this.setState({allbids:false});
          });
          this.setState({ totalpages: res.data.length });
        })
        .catch((err) => console.log(err));
  }

  render() {
	const {loading, totalpages, bidslist} = this.state;
  const self = this;
    var pagetotal = 0;
    if (totalpages < 25) {
      pagetotal = this.state.totalpages;
    }else {
      pagetotal = this.state.totalpages;
    }
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
		{(loading && (<LoadingSpinner />))||(<>
		
        <div className="row" style={{ marginBottom: "0px" }}>
          <div
            className="container grid-container"
            style={{ marginTop: "60px" }}
          >
            <div className="col s12">
              <button
                className="btn right black font-normal"
                onClick={this.handleAddBid}
              >
                <img src={AddIconWhite} width="12" /> Add Bid Request
              </button>
              <h4 style={{ marginTop: "10px" }}>Bid Requests</h4>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: "0px" }}>
          <div className="container grid-container">
                 <div className="col s12">
                       <div className="col s2 right" style={{ textAlign: "right" }}>
                {totalpages > 0 && (
                  <>
                    <span
                      style={{
                        marginRight: "5px",
                        marginLeft: "10px",
                        lineHeight: "32px",
                      }}
                    >
                      1 - {pagetotal} of {totalpages}
                    </span>
                   
                  </>
                )}
              </div>
            </div>
            <div
              className="col s12"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                background: "#fafafa",
              }}
            >
              <div className="col s2 right-border">
                <select
                  className="browser-default"
                  id="searchCategory"
                  onChange={this.filterData}
                >
                  <option value="">Select a Category</option>
                  <option value="">All</option>
                  {this.state.categoryList.map(function(d, idx) {
                    return (
                      <option value={d.TicketProblemTypeName} key={idx}>
                        {d.TicketProblemTypeName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="col s1 right-border"
                style={{ paddingTop: "5px" }}
              >
                <button
                  className="btn-transparent"
                  onClick={this.showFiltersFunc}
                >
                  <img
                    src={FilterIcon}
                    alt="Filter"
                    style={{ width: "10px", marginRight: "5px" }}
                  />
                  Filter
                </button>
              </div>
              <div
                className="col s1 right-border"
                style={{ paddingTop: "5px" }}
              >
                {this.state.ascSorting ? (
                  <button
                    className="btn-transparent"
                    onClick={(e) => this.onAscSort(e, "serviceRequestId")}
                  >
                    <img
                      src={AscIcon}
                      alt="Ascending"
                      style={{ width: "12px", marginRight: "5px" }}
                    />
                    Sort
                  </button>
                ) : (
                  <button
                    className="btn-transparent"
                    onClick={(e) => this.onDscSort(e, "serviceRequestId")}
                  >
                    <img
                      src={DscIcon}
                      alt="Descending"
                      style={{ width: "12px", marginRight: "5px" }}
                    />
                    Sort
                  </button>
                )}
              </div>

              <div
                className="col s3 right-border"
                style={{ paddingTop: "5px" }}
              >
                <img
                  src={SearchIcon}
                  alt="Filter"
                  style={{
                    width: "12px",
                    marginRight: "5px",
                    marginLeft: "10px",
                    lineHeight: "32px",
                  }}
                />
                <input
                  onKeyUp={this.enterPressed}
                  type="text"
                  placeholder="Search by Service Request Id"
                  style={{
                    width: "220px",
                    borderRadius: "0px",
                    height: "24px",
                    margin: "0",
                    border: "0 none",
                  }}
                />
              </div>
              <div className="col s2 right-border">
                <select
                  id="status"
                  className="browser-default"
                  style={{ width: "120px", float: "left", height: "32px" }}
                  onChange={this.filterData}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="all">All</option>
                  <option value="open">Open</option>
                  <option value="closed">Closed</option>
                </select>
              </div>

              <div className="col s2 " style={{ textAlign: "left" }}>
               <select
                  id="customerEmail"
                  className="browser-default"
                  style={{ width: "120px", float: "left", height: "32px" }}
                  onChange={this.filterCustomerData}
                >
                  <option value="" disabled>
                    Select Customer
                  </option>
                  <option value="all">All Customers</option>
                  {this.state.customerList.map(function(d, idx) {
                    return (
                      <option value={d.EmailAddress} key={idx}>
                        {d.CustomerName} - {d.EmailAddress}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col s1 " style={{ textAlign: "left" }}>
              {this.props.auth.user.userRole === 3 && (<> 
                  {this.state.mybids && <button style={{ width: "80px", float: "left", height: "32px", cursor:"pointer" }} onClick={this.getSmBids}>My Bids</button> }
                  {this.state.allbids && <button style={{ width: "80px", float: "left", height: "32px", cursor:"pointer" }} onClick={this.getAllBids}>All Bids</button> }
              </>)}
              </div>
            </div>
          </div>
        </div>
        {this.state.showFilters && (
          <div className="row" style={{ marginBottom: "0px" }}>
            <div className="container grid-container">
              <div
                className="col s12"
                style={{
                  backgroundColor: "#FAFAFA",
                  paddingTop: "10px",
                  borderTop: "1px solid #e4e4e4",
                }}
              >
                <div className="col xl4  bid_filter">
                  Bid End date between &emsp;
                  <DatePicker
                    id="startDate"
                    selected={this.state.startDate}
                    onChange={(date) => {this.setState({ startDate: date }); this.setState({startDateNull:false})}}
                    className="datepicker start-date"
                  />
                  {this.state.startDateNull && (
                    <p className="red-text" style={{ marginLeft: "150px" }}>
                      Please enter start date
                    </p>
                  )}
                </div>
                <div className="col s1" style={{textAlign:"center",marginTop: "7px"}}>
                  and 
                </div>
                <div className="col s3"  style={{marginLeft:"18px"}}>
                  <DatePicker
                    id="endDate"
                    selected={this.state.endDate}
                    onChange={this.endAfterStart}
                    className="datepicker end-date"
                  />
                  {this.state.endDateValidate && (
                    <p className="red-text" style={{width:"300px"}}>
                      End date should be greater than start date
                    </p>
                  )}
                  {this.state.endDateNull && (
                    <p className="red-text" style={{ marginLeft: "50px" }}>
                      Please enter end date
                    </p>
                  )}
                </div>
                <div className="col s3">
                  <button
                    className="btn black"
                    onClick={this.dateFilterData}
                    style={{ height: "32px", lineHeight: "32px" }}
                  >
                    Filter
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn red"
                    onClick={this.resetFilterData}
                    style={{ height: "32px", lineHeight: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "10px",
            marginBottom: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className=" valign-wrapper container grid-container"
        >
          <div className="landing-copy col s12" style={{ width: "100%" }}>
            <table id="myTable">
              <thead>
                <tr>
                  <th>Service Request Id</th>
                  <th>Details</th>
                  <th>End Date</th>
                  <th>Bid Min-Max Price</th>
                  <th>Bid Proposals</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {bidslist.length > 0 ? (
                <tbody>
                  {bidslist.map(
                    function(d, idx) {
                      return (
                        <tr key={d.idx} className={d.status === "closed" && "closed"}>
                          <td
                            onClick={(event) =>
                              this.viewBidDetails(event, d.serviceRequestId)
                            }
                          >
                            <div className="">{d.serviceRequestId}</div>
                          </td>
                          <td
                            onClick={(event) =>
                              this.viewBidDetails(event, d.serviceRequestId)
                            }
                          >
                            <div className="nowraptr">
                              <div>{d.title}</div>
                              {/* <span>
                                <ReactTimeAgo date={d.date} locale="en-US" />
                              </span> */}
                              <br />
                              {d.description}
                            </div>
                          </td>
                          <td> <div style={{maxWidth:"100px"}} className="nowraptr" > <span> <ReactTimeAgo date={d.date} locale="en-US" /> </span> </div> </td>
                          <td>
                            {d.minBudget !== "" && d.maxBudget !== "" && (
                              <span>
                                € {d.minBudget} - {d.maxBudget}
                              </span>
                            )}
                            {d.minBudget === "" && d.maxBudget !== "" && (
                              <span> € {d.maxBudget}</span>
                            )}
                            {d.minBudget === "" && d.maxBudget === "" && (
                              <span> ---</span>
                            )}
                          </td>
                          <td>
                            <img
                              src={BidIcon}
                              alt="Bid Icon"
                              style={{ width: "12px", marginRight:"5px" , position:"relative", top:"1px" }}
                            />
                            {d.participants.length}
                          </td>
                          <td>
                            {d.status === "open" ? (
                              <span
                                className="new badge green"
                                data-badge-caption="Open"
                              ></span>
                            ) : (
                              <span
                                class="new badge red"
                                data-badge-caption="Closed"
                              ></span>
                            )}
                          </td>
                          <td>
                            <div className="view-btn btn-flat">
                              <img
                                src={MoreIcon}
                                alt="More Actions"
                                style={{ height: "24px" }}
                              />
                              <div className="context-menu">
                                <ul>
                                  <li
                                    onClick={(event) =>
                                      this.viewBidDetails(
                                        event,
                                        d.serviceRequestId
                                      )
                                    }
                                  >
                                    View
                                  </li>
                                  <li
                                    onClick={(event) =>
                                      this.handleEditBid(
                                        event,
                                        d.serviceRequestId
                                      )
                                    }
                                  >
                                    Edit
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }.bind(this)
                  )}
                </tbody>
              ) : (
                <>
                  <tbody>
                    <tr>
                      <td>No Data available</td>
                    </tr>
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
		</>)}
      </>
    );
  }
}

AllBids.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(AllBids);
