import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "../../axios/index";
import { ToastContainer, toast } from "react-toastify";
import { loginUser } from "../../actions/authActions";
import Navbar from "../layout/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import deleteIcon from "../../images/trash-red.png";
import deletIconWhite from "../../images/trash-white.png";
import ShieldIcon from "../../images/shield-icon.png";
import LoadingSpinner from "../Common/LoadingSpinner";

class Categories extends Component {
  constructor() {
    super();
    this.state = {
      categorylist: [],
      showForm: false,
      newCategory: "",
      newSkill: "",
      newSkills: [],
      showTr: "",
      HideSkills: false,
      errors: {},
      categoryNameValidate: false,
      skillNameValidate: false,
      skillNameExistsValidate: false,
      checked: false,
      openAddRequests: false,
      skillsToApprv: [],
      loading: false
    };
    
    this.wrapperRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    const userRole = this.props.auth.user.userRole;
    const isActiveUser = this.props.auth.user.isActive;
    this.setState({ isActiveAdmin: this.props.auth.user.isActive });
    if (
      (isActiveUser && userRole === 1) ||
      (isActiveUser && userRole === 3) ||
      (isActiveUser && userRole === 4)
    ) {
      await axios
        .get("/api/categories/categorylist")
        .then((res) => {
          this.setState({ categorylist: res.data });
        })
        .catch((err) => console.log(err));
      await this.fetchSkillsForAppvoal();
    } else {
      this.props.history.push("/dashboard");
    }
  
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async fetchCategories(){
    return axios
        .get("/api/categories/categorylist")
        .then((res) => {
          this.setState({ categorylist: res.data });
        })
        .catch((err) => console.log(err));
  }

  handleClickOutside(event) {
    const { openAddRequests } = this.state;
    if (openAddRequests && this.wrapperRef &&  (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) ) {
      this.setState({openAddRequests: false});
    }
  }

  fetchSkillsForAppvoal = async () => {
    return axios
      .get("/api/skillstoapprove/skillstoapprovelist")
      .then((res) => {
        this.setState({ skillsToApprv: res.data });
      })
      .catch((err) => console.log(err));
  };

  handleShowForm = (event) => {
    event.preventDefault();
    this.setState({ showForm: true });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ newCategory: e.target.value });
  };

  addCategory = (event) => {
    event.preventDefault();
    if (this.state.newCategory !== "") {
      document.getElementById("addCategory").value = "";

      const newCategoryList = { categoryname: this.state.newCategory.trim() };
      axios
        .post("/api/categories/categories", newCategoryList)
        .then((res) => {
          this.notifySuccess();
          axios
            .get("/api/categories/categorylist")
            .then((res) => {
              this.setState({ categorylist: res.data });
              this.setState({ errors: { category: " " } });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          this.setState({ errors: { category: "Category already exists" } });
        });
      this.setState({ categoryNameValidate: false });
    } else {
      this.setState({ categoryNameValidate: true });
    }
  };

  notifySuccess = () =>
    toast.success("Category added successfully", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifySkillSuccess = () =>
    toast.success("Skill added successfully", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyApprove = () =>
    toast.info(
      "Add Skill request approved and freelancer will be notified shortly",
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  notifyDeny = () =>
    toast.info(
      "Add Skill request was denied and freelancer will be notified shortly",
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

  notifySkillDeleted = () =>
    toast.info("You have deleted a skill", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  notifyInfoSuccess = () =>
    toast.info("You have deactivate a category", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  deactivate = (categoryname) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to deactivate this category.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const newCategoryList = { categoryname: categoryname.trim() };
            axios
              .post("/api/categories/deactivatecategory", newCategoryList)
              .then((res) => {
                this.notifyInfoSuccess();
                axios
                  .get("/api/categories/categorylist")
                  .then((res) => {
                    this.setState({ categorylist: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  addNewSkill = (event, name) => {
    event.preventDefault();
    if (this.state.newSkill !== "") {
      var arr = this.state.newSkills.map((v) => v.toLowerCase());
      if (arr.indexOf(this.state.newSkill.trim().toLowerCase()) === -1) {
        this.setState(
          { newSkills: [...this.state.newSkills, this.state.newSkill.trim()] },
          () => {
            document.getElementById(this.state.newSkillId).value = "";
            const newSkills = {
              categoryname: name,
              skills: this.state.newSkills,
            };
            axios
              .post("/api/categories/addskills", newSkills)
              .then((res) => {
                this.notifySkillSuccess();
                axios
                  .get("/api/categories/categorylist")
                  .then((res) => {
                    this.setState({ categorylist: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          }
        );
        this.setState({ skillNameValidate: false });
        this.setState({ skillNameExistsValidate: false });
      } else {
        this.setState({ skillNameValidate: false });
        this.setState({ skillNameExistsValidate: true });
      }
    } else {
      this.setState({ skillNameExistsValidate: false });
      this.setState({ skillNameValidate: true });
    }
  };

  handleSkillChange = (e) => {
    this.setState({ newSkill: e.target.value });
    this.setState({ newSkillId: e.target.id });
  };

  showSkills = (id, skills) => {
    document.getElementById(id).style.display = "table-row";

    this.setState({ newSkills: skills });

    document.getElementById(id + "-hide").style.display = "inline-block";

    document.getElementById(id + "-show").style.display = "none";
  };

  addSkills = (id, skills) => {
    document.getElementById(id).style.display = "table-row";

    this.setState({ newSkills: skills });
  };

  hideSkills = (id) => {
    document.getElementById(id).style.display = "none";

    document.getElementById(id + "-hide").style.display = "none";
    document.getElementById(id + "-show").style.display = "inline-block";

    this.setState({ newSkills: [] });

    this.setState({ HideSkills: false });
  };

  delSkills = (categoryname, ind) => {
    this.state.newSkills.splice(ind, 1);

    const newSkills = {
      categoryname: categoryname,
      skills: this.state.newSkills,
    };
    axios
      .post("/api/categories/addskills", newSkills)
      .then((res) => {
        this.notifySkillDeleted();
        axios
          .get("/api/categories/categorylist")
          .then((res) => {
            this.setState({ categorylist: res.data });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  onChangeMandatory(e, name) {
    e.preventDefault();
    const newCategory = { categoryname: name, isMandatory: true };
    axios
      .post("/api/categories/mandatorycategory", newCategory)
      .then((res) => {
        axios
          .get("/api/categories/categorylist")
          .then((res) => {
            this.setState({ categorylist: res.data });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  onUndoMandatory(e, name) {
    e.preventDefault();
    const newCategory = { categoryname: name, isMandatory: false };
    axios
      .post("/api/categories/mandatorycategory", newCategory)
      .then((res) => {
        axios
          .get("/api/categories/categorylist")
          .then((res) => {
            this.setState({ categorylist: res.data });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  openAddReqList = (e) => {
    const { openAddRequests } = this.state;
    this.setState({ openAddRequests: !openAddRequests });
  };

  toggleSkillStatus = async (data, isApproved) => {
    const { categorylist } = this.state;
    const reqBody = {
      ...data,
      isApproved,
      isActive: (isApproved && true) || false,
    };
    
    if (isApproved) {
      this.notifyApprove();
      this.setState({ loading: true });
      const { skills: previousSkills } = categorylist.find(
      (e) => !!e.isActive && e.categoryname === reqBody.category);
      const newSkills = {
        categoryname: reqBody.category,
        skills: [
          ...previousSkills,
          reqBody.skill,
        ],
      };
      await axios.post("/api/categories/addskills", newSkills);
      await this.fetchCategories();
      this.setState({ loading: false });
    } else if (!isApproved) {
      this.notifyDeny();
    }
    this.setState({ loading: true });
    await axios
      .put("/api/skillstoapprove/toggleskill", reqBody)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
    await this.fetchSkillsForAppvoal();
    this.setState({ loading: false});
  };

  render() {
    const { errors, openAddRequests, skillsToApprv, loading } = this.state;
    const categoryList = this.state.categorylist;
    const self = this;
    const showRow = this.state.showTr;
    const HideSkills = this.state.HideSkills;
    const err = this.state.errors;
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        <div className="row">
          <div className="container" style={{ marginTop: "40px" }}>
            <div className="col s8">
              <h4>Categories</h4>
            </div>
            <div className="col s2 ">
              <div className="listDopdown">
                <button
                  className={`btn right black drop-head ${(openAddRequests &&
                    "backBg") ||
                    ""}`}
                    disabled={!skillsToApprv.length}
                  onClick={this.openAddReqList}
                >
                  <span>{skillsToApprv.length}</span> Add Request(s)
                </button>
                {openAddRequests && !!skillsToApprv.length && (
                  <>
                    <div className="drop-body" ref={this.wrapperRef}>
                      {/* <label>Coding</label> */}
                      <ul className="drop-list">
                        {(loading && <LoadingSpinner smaller={true}/>) ||
                          skillsToApprv.map((item) => (
                            <>
                              <li>
                                <span>
                                  <b>{item.updatedBy}</b>&nbsp; requested &nbsp;
                                  <b>{item.skill}</b>&nbsp;skill in&nbsp; 
                                  <b>{item.category}</b>&nbsp;category 
                                </span>
                                <button
                                  className="custombtn"
                                  style={{margin:"2px"}}
                                  onClick={() =>
                                    this.toggleSkillStatus(item, false)
                                  }
                                >
                                  Deny
                                </button>
                                <button
                                  className="custombtn"
                                  style={{margin:"2px"}}
                                  onClick={() =>
                                    this.toggleSkillStatus(item, true)
                                  }
                                >
                                  Approve
                                </button>
                              </li>
                            </>
                          ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <h4 className="col s2">
              <button className="btn right black" onClick={this.handleShowForm}>
                Add Category
              </button>
            </h4>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "10px",
            border: "1px solid rgba(112,112,112,0.27)",
            marginBottom: "30px",
            marginTop: "20px",
          }}
          className="container valign-wrapper"
        >
          <div className="row">
            <div className="landing-copy col s12">
              {this.state.showForm && (
                <div className="add-form s12" style={{ marginTop: "20px" }}>
                  <div className="col s6">
                    <input
                      type="text"
                      placeholder="Enter New Category"
                      onChange={this.onChange}
                      id="addCategory"
                    />
                    <p className="red-text">{err.category}</p>
                    {this.state.categoryNameValidate && (
                      <span className="red-text">
                        Category name is required
                      </span>
                    )}
                  </div>
                  <div className="col s3">
                    <button
                      onClick={this.addCategory}
                      className="btn btn-large waves-effect waves-light hoverable black accent-3"
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
              {categoryList.length > 0 ? (
                <div className="categorylist">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">Category Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList.map(function(d, idx) {
                        return (
                          <>
                            {d.isActive ? (
                              <tr key={idx}>
                                <td>
                                  {d.categoryname}
                                  {d.isMandatory && (
                                    <img
                                      src={ShieldIcon}
                                      alt="Mandatory"
                                      style={{
                                        position: "relative",
                                        top: "3px",
                                        width: "12px",
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {d.skills.length > 0 ? (
                                    <>
                                      <button
                                        style={{ display: "none" }}
                                        id={`${d._id}-hide`}
                                        className="btn"
                                        onClick={() => self.hideSkills(d._id)}
                                      >
                                        Hide Skills
                                      </button>
                                      <button
                                        id={`${d._id}-show`}
                                        className="btn"
                                        onClick={() =>
                                          self.showSkills(d._id, d.skills)
                                        }
                                      >
                                        View Skills
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        self.addSkills(d._id, d.skills)
                                      }
                                    >
                                      Add Skills
                                    </button>
                                  )}
                                </td>
                                <td>
                                  <button
                                    title="Deactivate"
                                    className="btn red"
                                    onClick={() =>
                                      self.deactivate(d.categoryname)
                                    }
                                  >
                                    <img
                                      src={deletIconWhite}
                                      alt="Delete"
                                      style={{
                                        width: "16px",
                                        marginTop: "8px",
                                      }}
                                    />
                                  </button>
                                </td>
                                <td>
                                  {!d.isMandatory && d.skills.length > 0 && (
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        self.onChangeMandatory(
                                          e,
                                          d.categoryname
                                        )
                                      }
                                      className="btn black"
                                    >
                                      Mark it as Mandatory
                                    </button>
                                  )}
                                  {d.isMandatory && d.skills.length > 0 && (
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        self.onUndoMandatory(e, d.categoryname)
                                      }
                                      className="btn red"
                                    >
                                      Undo Mandatory
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ) : null}
                            <tr
                              id={d._id}
                              style={{ display: "none" }}
                              colSpan="3"
                            >
                              <td colSpan="3">
                                <ul>
                                  <li style={{ position: "relative" }}>
                                    <h6>Skills</h6>
                                  </li>
                                  {d.skills.map(function(item, index) {
                                    return (
                                      <li
                                        className="col s12"
                                        style={{
                                          padding: "10px",
                                          display: "inline-block",
                                        }}
                                      >
                                        <div className="col s4">{item} </div>
                                        <div className="col s2">
                                          <button
                                            className="btn white"
                                            onClick={() =>
                                              self.delSkills(
                                                d.categoryname,
                                                index
                                              )
                                            }
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="Delete"
                                              style={{ width: "16px" }}
                                            />
                                          </button>
                                        </div>
                                      </li>
                                    );
                                  })}
                                  <li>
                                    <br />
                                    <input
                                      type="text"
                                      style={{
                                        width: "300px",
                                        height: "32px",
                                        borderRadius: "5px",
                                      }}
                                      onChange={self.handleSkillChange}
                                      id={`addNewSkill_${d._id}`}
                                    />
                                    <button
                                      style={{ marginLeft: "10px" }}
                                      className="btn black"
                                      onClick={(event) =>
                                        self.addNewSkill(event, d.categoryname)
                                      }
                                    >
                                      Add New
                                    </button>
                                    <br />
                                    {self.state.skillNameValidate && (
                                      <span className="red-text">
                                        Skill name is required
                                      </span>
                                    )}
                                    {self.state.skillNameExistsValidate && (
                                      <span className="red-text">
                                        Skill name already exists
                                      </span>
                                    )}
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                "Categories not available"
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

Categories.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Categories);
