import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// import ManagerIcon from "../../images/service-manager.png";
// import ProfileIcon from "../../images/user.png";
// import MessagesIcon from "../../images/messages.png";
// import SkillsIcon from "../../images/skills.png";
// import LogoutIcon from "../../images/logout.png";
// import DashboardIcon from "../../images/dashboard-icon.png";

import logo from "../layout/logo.png";
import FreelancersIcon from "../../images/freelancers-icon.png";
import DocumentIcon from "../../images/document.png";
import BidsIcon from "../../images/bid-icon.png";
import ListIcon from "../../images/list-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faInfoCircle, faListAlt, faUserFriends } from "@fortawesome/free-solid-svg-icons";

import axios from "../../axios/index";

const profileRoutes = {
  "/edit-profile": "active_link",
  "/profile": "active_link",
  "/id-verification": "active_link",
};
const dashboardRoutes = {
  "/dashboard": "active_link",
};
// const messageRoutes = { "/messages": "active_link" };
const freelancerRoutes = { "/freelancers": "active_link_two" };
const bidsRoutes = {
  "/bids": "active_link_two",
  "/all-bids": "active_link_two",
  "/add-bids": "active_link_two",
  "/bid-request": "active_link_two",
  "/edit-bid": "active_link_two",
};
const financeRoutes = {
  "/quotes": "active_link_two",
  "/quote-details": "active_link_two",
};
const categoriesRoutes = {
  "/categories": "active_link_two",
};
const skillRoutes = {
  "/skills": "active_link_two",
};
const adminUserRoutes = {
  "/admin-users": "active_link_two",
  "/create-user": "active_link_two",
};
const customerRoutes = {
  "/customers": "active_link_two",
  "/add-new-customer": "active_link_two",
  "/edit-customer": "active_link_two",
};
const customerStatusRoutes = {
  "/customer-status": "active_link_two",
};
const customerCategoryRoutes = {
  "/customer-category": "active_link_two",
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      showNotifications: false,
      unreadCount: 0,
      isOpen: {},
    };
  }

  componentDidMount() {
    axios
      .get("/api/notifications/notifications", {
        params: {
          email: this.props.auth.user.email,
        },
      })
      .then((res) => {
        let unreadCount = res.data.reduce((acc, cur) => {
          return !cur.isRead ? acc + 1 : acc;
        }, 0);
        this.setState({ notifications: res.data, unreadCount });
      });
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  // OPEN CLOSE NOTIFICATION PREVIEW
  notificationToggle = (e) => {
    e && e.preventDefault();
    if (this.state.notifications.length > 0) {
      this.setState((currentState) => ({
        showNotifications: !currentState.showNotifications,
      }));
    }
  };

  // MARK NOTIFICATION AS READ AND REDIRECT
  readAndRedirect = (el, idx) => {
    if (!el.isRead) {
      axios
        .post("/api/notifications/notification/isRead", {
          id: el._id,
        })
        .then((res) => {
          const UpdatedNotifications = [...this.state.notifications];
          UpdatedNotifications[idx] = res.data;
          this.setState((currentState) => ({
            notifications: UpdatedNotifications,
            unreadCount: currentState.unreadCount - 1,
          }));
          this.props.history.push({
            pathname: "/bid-request",
            search: `bidrequestid=${el.serviceRequestId}`,
            state: {
              rootCommentId: el.rootCommentId ? el.rootCommentId : null,
              commentId: el.commentId ? el.commentId : null,
              bidProposalId: el.bidProposalId ? el.bidProposalId : null,
            },
          });
          // (only query parameters will change if url is same) important to reload !!
          window.location.reload();
        });
    } else {
      this.props.history.push({
        pathname: "/bid-request",
        search: `bidrequestid=${el.serviceRequestId}`,
        state: {
          rootCommentId: el.rootCommentId ? el.rootCommentId : null,
          commentId: el.commentId ? el.commentId : null,
          bidProposalId: el.bidProposalId ? el.bidProposalId : null,
        },
      });
      // (only query parameters will change if url is same) important to reload !!
      window.location.reload();
    }
  };

  render() {
    const { user } = this.props.auth;
    const { current, viewProfileOnly } = this.props;
    return (
      <>
        <div className="header-nav">
          {this.state.showNotifications && (
            <div
              className="notification"
              style={{
                position: "fixed",
                display: "block",
                right: "5px",
                marginTop: "65px",
                width: "auto",
                maxWidth: "600px",
                minWidth: "450px",
                height: "auto",
                // minHeight: "460px",
                maxHeight: "500px",
                zIndex: "100",
                backgroundColor: "rgba(200, 200, 200, 0.3)",
                borderRadius: "10px",
                padding: "5px",
                overflowY: "scroll",
              }}
            >
              {this.state.notifications.map((el, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "60px",
                    backgroundColor: `${
                      el.isRead
                        ? "rgba(220,220,220, 0.95)"
                        : "rgba(255,255,255, 0.95)"
                    }`,
                    borderRadius: "5px",
                    margin: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.readAndRedirect(el, idx);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "15%",
                      height: "100%",
                    }}
                  >
                    <img
                      alt="User"
                      src={`https://devfreelancersapi.supportpod.com${el.senderProfileImage}`}
                      style={{
                        width: "42px",
                        height: "42px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "85%",
                      height: "75%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "50%",
                        padding: "0 10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "75%",
                          height: "100%",
                        }}
                      >
                        <span
                          style={{
                            width: "60px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <b
                            style={{
                              width: "50px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {el.senderName}{" "}
                          </b>
                        </span>
                        <span
                          style={{
                            color: "grey",
                            fontSize: "12px",
                            padding: "0 5px",
                            width: "160px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el.from}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "25%",
                          height: "100%",
                        }}
                      >
                        <span
                          style={{
                            color: "grey",
                            fontSize: "11px",
                            padding: "0 5px",

                            float: "right",
                          }}
                        >
                          {new Date(el.createdDate).toLocaleTimeString()}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "50%",
                        padding: "0 10px ",
                      }}
                    >
                      <p
                        style={{
                          width: "240px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          margin: "0",
                          padding: "0",
                          fontSize: "14px",
                          color: "gray",
                        }}
                      >
                        <b style={{ fontWeight: "600" }}>{el.for}:</b>{" "}
                        {el.message}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="row">
            <div
              className="col s12"
              style={{ position: "fixed", zIndex: "99" }}
            >
              <div
                className={`menu-wrapper col s12  ${(viewProfileOnly &&
                  "view_profile_only") ||
                  ""}`}
              >
                <a
                  // href="https://2020.supportpod.com"
                  href="https://www.supportpod.com/freelancers"
                  style={{
                    fontFamily: "monospace",
                  }}
                  className="col brand-logo black-text"
                >
                  <img src={logo} alt="logo" />
                </a>
                {!viewProfileOnly && (
                  <div className="col s6 right " style={{ textAlign: "right" }}>
                    {this.props.auth.isAuthenticated &&
                    this.props.auth.user.isEmailVerified ? (
                      <>
                        {/* <Link to="/"> */}
                        <div className="dropdown">
                          <button
                            style={{ padding: "10px" }}
                            className="dropbtn"
                          >
                            Welcome {user.screenname}{" "}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                          <div className="dropdown-content">
                            <Link
                              to="/dashboard"
                              className={dashboardRoutes[current]}
                            >
                              Dashboard
                            </Link>
                            <Link
                              to="/edit-profile"
                              className={profileRoutes[current]}
                            >
                              Profile
                            </Link>
                            <button
                              onClick={this.onLogoutClick}
                              className=" "
                              style={{
                                border: "0 none",
                                background: "transparent",
                                cursor: "pointer",
                                display: "inline-block",
                                marginTop: "3px",
                              }}
                            >
                              Logout
                            </button>
                          </div>
                        </div>
                        {/* </Link> */}
                        {/* <Link
                          to="/dashboard"
                          className={dashboardRoutes[current]}
                        >
                          Dashboard
                        </Link> */}
                        {/* <Link to="/edit-profile" className={profileRoutes[current]}>
                          Profile
                        </Link> */}

                        <button
                          style={{
                            background: "none",
                            border: "none",
                            padding: "2px 0",
                            width: "120px",
                          }}
                          onClick={this.notificationToggle}
                        >
                          <span
                            style={{
                              width: "100%",
                            }}
                          >
                            Messages
                            <span
                              style={{
                                display: "inline-block",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: `${
                                  this.state.unreadCount ? "green" : "grey"
                                }`,
                                color: "white",
                                margin: "5px",
                              }}
                            >{`${this.state.unreadCount}`}</span>
                          </span>
                        </button>
                        {this.notifications}

                        {/* <button
                          onClick={this.onLogoutClick}
                          className=" "
                          style={{
                            border: "0 none",
                            background: "transparent",
                            cursor: "pointer",
                            display: "inline-block",
                            marginTop: "3px",
                          }}
                        >
                          Logout
                        </button> */}
                      </>
                    ) : (
                      <>
                        <a
                          href="https://devfreelancers.supportpod.com/"
                          className=""
                        >
                          Freelancers
                        </a>
                        <a href="https://partners.supportpod.com" className="">
                          Partners
                        </a>
                        <a
                          href="https://supportpod.webflow.io/login"
                          className=""
                        >
                          Customer Log In
                        </a>
                        <a
                          href="https://supportpod.webflow.io/sign-up"
                          className=""
                        >
                          Customer Sign Up
                        </a>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.props.auth.isAuthenticated &&
              this.props.auth.user.isEmailVerified &&
              !viewProfileOnly && (
                <div className="subnav">
                  <div className="row">
                    <div className="menu-wrapper left col s12">
                      {(this.props.auth.user.userRole === 1 ||
                        this.props.auth.user.userRole === 5) && (
                        <div style={{ marginTop: "5px" }}>
                          <Link
                            to="/freelancers"
                            className={`col ${freelancerRoutes[current]}`}
                          >
                            <img
                              src={FreelancersIcon}
                              alt="Freelancers"
                              style={{
                                width: "16px",
                              }}
                            />
                            Freelancers
                          </Link>
                          {this.props.auth.user.userRole !== 5 && (
                            <>
                              <Link
                                to="/all-bids"
                                className={`col ${bidsRoutes[current]}`}
                              >
                                <img
                                  src={BidsIcon}
                                  alt="Bids"
                                  style={{ width: "16px" }}
                                />
                                Bid Requests
                              </Link>

                              <Link
                                to="/categories"
                                className={`col ${categoriesRoutes[current]}`}
                              >
                                <img
                                  src={ListIcon}
                                  alt="Categories"
                                  style={{ width: "13px" }}
                                />
                                Categories
                              </Link>

                              <Link
                                to="/admin-users"
                                className={`col ${adminUserRoutes[current]}`}
                              >
                                <img
                                  src={FreelancersIcon}
                                  alt="Freelancers"
                                  style={{ width: "16px" }}
                                />
                                Admin Users
                              </Link>
                            </>
                          )}
                        </div>
                      )}
                      {this.props.auth.user.userRole !== 2 &&
                        this.props.auth.user.userRole !== 5 && (
                          <div style={{ marginTop: "5px" }}>
                            <Link
                              to="/quotes"
                              className={`col ${financeRoutes[current]}`}
                            >
                              <img
                                src={DocumentIcon}
                                alt="Quotes"
                                style={{ width: "16px" }}
                              />
                              Quotes
                            </Link>
                          </div>
                        )}
                      {this.props.auth.user.userRole !== 2 &&(
                        // this.props.auth.user.userRole !== 5 && (
                          <div style={{ marginTop: "5px" }}>
                            <Link
                              to="/customers"
                              className={`col ${customerRoutes[current]}`}
                            >
                              <FontAwesomeIcon icon={faUserFriends} />Customers
                            </Link>
                          </div>
                        )}
                      {this.props.auth.user.userRole !== 2 &&(
                        // this.props.auth.user.userRole !== 5 && (
                          <div style={{ marginTop: "5px" }}>
                            <Link
                              to="/customer-status"
                              className={`col ${customerStatusRoutes[current]}`}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} /> Status
                            </Link>
                          </div>
                        )}
                      {this.props.auth.user.userRole !== 2 &&(
                        // this.props.auth.user.userRole !== 5 && (
                          <div style={{ marginTop: "5px" }}>
                            <Link
                              to="/customer-category"
                              className={`col ${customerCategoryRoutes[current]}`}
                            >
                              <FontAwesomeIcon icon={faListAlt} /> Service Category
                            </Link>
                          </div>
                        )}
                      {(this.props.auth.user.userRole === 3 ||
                        this.props.auth.user.userRole === 4) && (
                        <>
                          <div style={{ marginTop: "5px" }}>
                            <Link
                              to="/all-bids"
                              className={`col ${bidsRoutes[current]}`}
                            >
                              <img src={BidsIcon} alt="Bids" />
                              Bid Requests
                            </Link>
                            <Link
                              to="/categories"
                              className={`col ${categoriesRoutes[current]}`}
                            >
                              <img
                                src={ListIcon}
                                alt="Categories"
                                style={{ width: "13px" }}
                              />
                              Categories
                            </Link>
                          </div>
                        </>
                      )}
                      {this.props.auth.user.userRole === 2 && (
                        <div style={{ marginTop: "5px" }}>
                          {this.props.auth.user.isActive === true && (
                            <Link
                              to="/bids"
                              className={`col ${bidsRoutes[current]}`}
                            >
                              <img src={BidsIcon} alt="Bids" />
                              Bid Requests
                            </Link>
                          )}
                          <Link
                            to="/skills"
                            className={`col ${skillRoutes[current]}`}
                          >
                            <img
                              src={ListIcon}
                              alt="Categories"
                              style={{ width: "13px" }}
                            />
                            Skills
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

Menu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Menu));