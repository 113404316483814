import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import './auth.css';
import logo from "../layout/logo.png";
import SuccessIcon from '../../images/success.png';

// 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

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  render() {
    const { errors } = this.state;
    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
              <Link
                to="/"
                style={{
                  fontFamily: "monospace"
                }}
                className="col s5 brand-logo center black-text">
                <img src={logo} alt="logo" />
              </Link>
          </div>
        </nav>
      </div>
      <div className="container auth" >
        <div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <h4>Email Verification</h4>
            </div>
            	<img src={SuccessIcon} alt="Success Icon" style={{margin:"0 auto", display:"inherit"}}/>
            	<p className="center" style={{fontSize : "16px"}}>We have sent you a verification email to your registered email id. Please follow the instructions to activate your account.</p>
          </div>
        </div>
      </div>
      
    </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
