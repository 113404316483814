import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { loginUser } from "../../../actions/authActions";
import Navbar from "../../layout/Navbar";

import AddIconWhite from "../../../images/add-icon-white.png";
import AscIcon from "../../../images/asc-icon.png";
import DscIcon from "../../../images/dsc-icon.png";
import SearchIcon from "../../../images/search-icon.png";

import { getQuotes, getQuoteURL } from "../Services";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import DataTable from 'react-data-table-component';

class QuotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0,
      currentPage: 0,
      lastPage: 0,
      pageNumbers: [],
      initialQuotes: [],
      quotesList: [],
      loading: false,
      perPage:10
    };
  }

  async componentDidMount() {
    await this.fetchQuotes();
    this.setState({ loading: false });
  }

  notify = (type, message) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  generatePaginationParams = (records) => {
    const totalPages = Math.ceil(records.length / 10);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    return { totalPages, pageNumbers };
  };

  paginate = (array, pageSize, pageNumber) =>
    array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  fetchQuotes = async (currentPage = 1) => {
    try {
      const { auth: { user: { email } = {} } = {} } = this.props;
      this.setState({ loading: true });
      const { data } = await getQuotes(email);
      const { totalPages, pageNumbers } = this.generatePaginationParams(data);
      const temp = this.paginate(data, 10, currentPage);
      console.log(data);
      this.setState({
        quotesList: temp,
        initialQuotes: data,
        lastPage: totalPages,
        pageNumbers,
        currentPage,
        loading: false,
      });


      // this.notify("success", "Quotes fetched Successfully");
    } catch (error) {
      console.log(error);
      this.notify("error", "Failed to Fetch Quotes");
      this.setState({ loading: false });
    }
  };

  handleAddQuote = (e) => {
    e.preventDefault();
    this.props.history.push("/quote-details");
  };

  viewQuote = async (e, data) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { data: url } = await getQuoteURL(data);
    window.open(url);
    this.setState({ loading: false });
  };

  onAscSort(event, sortKey) {
    event.preventDefault();
    const { quotesList } = this.state;
    quotesList.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    this.setState({ quotesList, ascSorting: false });
  }
  onDscSort(event, sortKey) {
    event.preventDefault();
    const { quotesList } = this.state;
    quotesList.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    this.setState({ quotesList, ascSorting: true });
  }

  filterData = (data, searchValue) =>
    data.filter(
      (item) =>
        `${item.FirstName} ${item.LastName}`
          .toLowerCase()
          .includes(searchValue) ||
        item.EmailAddress.toLowerCase().includes(searchValue)
    );

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13 || code === 9) {
      event.preventDefault();
      var str = event.target.value;
      const { initialQuotes } = this.state;
      if (str) {
        const filtered = this.filterData(
          initialQuotes,
          `${str}`.toLowerCase().trim()
        );

        const temp = this.paginate(filtered, 10, 1);

        this.setState({ quotesList: temp, currentPage: 1 });
      } else {
        const temp = this.paginate(initialQuotes, 10, 1);
        this.setState({ quotesList: temp, currentPage: 1 });
      }
    }
  };

  togglePage = {
    next: (event) => {
      event.preventDefault();
      const { initialQuotes, currentPage } = this.state;
      const temp = this.paginate(initialQuotes, 10, currentPage + 1);
      this.setState({ quotesList: temp, currentPage: currentPage + 1 });
    },
    prev: (event) => {
      event.preventDefault();
      const { initialQuotes, currentPage } = this.state;
      const temp = this.paginate(initialQuotes, 10, currentPage - 1);
      this.setState({ quotesList: temp, currentPage: currentPage - 1 });
    },
    numbered: (currentPageNumber) => (event) => {
      event.preventDefault();
      const { initialQuotes } = this.state;
      const temp = this.paginate(initialQuotes, 10, currentPageNumber);
      this.setState({ quotesList: temp, currentPage: currentPageNumber });
    },
  };

  render() {
    const {
      totalPages,
      quotesList,
      currentPage,
      loading,
      lastPage,
      pageNumbers,
    } = this.state;

    const data = this.state.initialQuotes;
   const columns = [
      {
        name: 'Quote ID ',
        selector: 'QuoteID',
        sortable: true,
         wrap:true
      },
      {
        name: 'Quote Number',
        selector: 'QuoteNumber',
        sortable: true,
        wrap:true
      },
      {
        name: 'Customer Email',
        selector: 'EmailAddress',
        sortable: true
      },
      {
        name: 'Date',
        selector: 'DateString',
        sortable: true
      },
      {
        name: 'Expiry Date',
        selector: 'ExpiryDateString',
        sortable: true
      },
      {
        name: 'Service Request ID',
        selector: 'Reference',
        sortable: true
      },
      {
        name: 'Status',
        selector: 'Status',
        sortable: true
      },
    ];

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        <div className="row" style={{ marginBottom: "0px" }}>
          <div
            className="container grid-container"
            style={{ marginTop: "60px" }}
          >
            <div className="col s12">
              <button
                className="btn right black font-normal"
                onClick={this.handleAddQuote}
              >
                <img src={AddIconWhite} width="12" alt="add" /> Create Quote
              </button>
              
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className=" valign-wrapper container grid-container"
        >
          {(loading && (
            <>
              <LoadingSpinner />
            </>
          )) || (
            <div className="landing-copy col s12" style={{ width: "100%" }}>
              
                <DataTable
                title="List of Quotes"
                  columns={columns}
                  data={data}
                   pagination
                />
            </div>
          )}
        </div>
        
        <ToastContainer />
      </>
    );
  }
}

QuotesList.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(QuotesList);
