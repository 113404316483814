import React, { useState, useEffect } from 'react';
import axios from "../../axios/index";
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './profile.css';
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { API_URL } from '../utils/constants';

  const MobileVerification = (props) => {
  const [email, setEmail] = useState(props.auth.user.email);
  const [mobile, setMobile] = useState('');
  const [CountryCode, setCountryCode] = useState('');
  const [verification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');
  const [mobileVerificationStatus, setMobileVerificationStatus] = useState('');
  

  const handleCountryCodeInputChange = (event) => {
  		setCountryCode(event.target.value )
  };

  const handleMobileNumberInputChange = (event) => {
    	setMobile(event.target.value )
  };

  const handleVerificationCodeInputChange = (event) => {
    	setVerificationCode(event.target.value )
  };

  

  const handleOnSubmit = async (event) => {

    event.preventDefault();

       axios.get("/api/users/mobilecode", 
       	{
		    params: {
		      phonenumber: CountryCode+mobile,
		      channel:"sms"
		    }
		}
       	, { 
      }).then(res => {
       console.log(res.data)
       setShowVerification(true)
       notifyInfo();
       
     })
  };

  const handleVerificationOnSubmit = async (event) => {
    event.preventDefault();
       axios.get("/api/users/mobileverify", 
       	{
		    params: {
			      phonenumber: CountryCode+mobile,
			      code: verificationCode
			  }
		}
       	, { 
      }).then(res => {
       console.log("Mobile verify",res)
       console.log(res.data.data.status)
       if (res.data.data.status === "approved"){
       	setMobileVerificationStatus(true);
       	const userData = {
  			isPhoneVerified: true,
	      	email:email,
	      	verifiedPhoneNumber:res.data.data.to
	    };
	    axios
	    .post("/api/users/mobilestatus", userData)
	    .then(res => {
	        console.log(res.data)
	    })
	    .catch(err =>
	      console.log(err)
	    );
       		
       }
       setShowVerification(true)
       notifySuccess();
     })
  };

  const notifyInfo = () => toast.info('Verification code sent to your mobile.', {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notifySuccess = () => toast.info('Mobile number has been verified successfully.', {
    position: "top-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
  	console.log(props.auth.user.email);
    setEmail(props.auth.user.email);
    const userData = {email:props.auth.user.email}
    axios
    .post("/api/users/userdetails", userData)
    .then(res => {
        console.log(res.data);
        setMobileVerificationStatus(res.data.isPhoneVerified)
        if(res.data.verifiedPhoneNumber) {
        	setVerifiedPhoneNumber(res.data.verifiedPhoneNumber);
        }
    })
    .catch(err =>
      console.log(err)
    );

  });

  return (
    <React.Fragment>
      <div className="mobile-verification-block uploader-display-section" style={{float: "left"}}>

      {mobileVerificationStatus ? 
      	(<div className="green-text" style={{marginTop:"100px"}}><b>{`Your mobile number ${verifiedPhoneNumber} has been verified successfully`}</b></div>)
       : 
       ( verification ? (<div>
          <h4>Verification Code</h4>
          <p>Enter OTP Sent +<span>{CountryCode}{mobile}</span></p>
          	<div className="row">
		        <Form className="search-form" onSubmit={handleVerificationOnSubmit}>
		        	<input type="text" placeholder="Verification code" onChange={handleVerificationCodeInputChange} value={verificationCode}/>
		        	<button className="btn btn-large waves-effect waves-light hoverable black accent-3" style={{width:"100%", borderRadius : "20px"}}>VERIFY</button>
		        </Form>
      		</div>
      		<div>Did't Receive The OTP? <button onClick={handleOnSubmit}>Resend Again</button></div>
      </div>):
       (<div>
          <h4>Mobile Verification<span className="red-text">*</span></h4>
          <p>Please enter your mobile number, we will send you a OTP on your mobile number to verify</p>
          	<div className="row">
		        <Form className="search-form" onSubmit={handleOnSubmit}>
		        	<input type="text" placeholder="Country Code" onChange={handleCountryCodeInputChange} />
		        	<input type="text" placeholder="Phone Number" onChange={handleMobileNumberInputChange} />
		        	<button className="btn btn-large waves-effect waves-light hoverable black accent-3" style={{width:"100%", borderRadius : "20px"}}>GET OTP</button>
		        </Form>
      		</div>
      </div>)
       )
   }
  
     </div>
    </React.Fragment>
  );
};


MobileVerification.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(MobileVerification);