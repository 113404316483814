import React, { Component } from "react";
import axios from "../../axios/index";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import Overlay from "../layout/Overlay";
import Photo from "../../images/file-upload-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faUserCheck,
  faUserTimes,
  faPortrait,
  faAddressBook,
  faStar,
  faAddressCard,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import SocialIcon from "../../images/team.png";
import CallIcon from "../../images/call-icon.png";
import EmailIcon from "../../images/email-icon.png";
import ProfileImg from "../../images/file-upload-avatar.png";
import SuccessIcon from "../../images/success.png";
import { confirmAlert } from "react-confirm-alert";
import MoreIcon from "../../images/more-icon.png";
import BidIcon from "../../images/bid-icon.png";
import ShieldIcon from "../../images/shield-icon.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import update from "immutability-helper";
import DatePicker from "react-datepicker";
import AddIconWhite from "../../images/add-icon-white.png";
import FilterIcon from "../../images/filter-icon.png";
import AscIcon from "../../images/asc-icon.png";
import DscIcon from "../../images/dsc-icon.png";
import SearchIcon from "../../images/search-icon.png";

class Freelancers extends Component {
  constructor() {
    super();
    this.state = {
      freelancersList: [],
      userdetails: {},
      profileImage: "",
      contactdetails: {},
      skillMatrix: [],
      socialProfiles: [],
      IDProof: "",
      idproofPrevBtn: "",
      AddressProofBtn: "",
      AddressProof: "",
      IDProofVerified: false,
      AddressProofVerified: false,
      idPopup: false,
      addressPopup: false,
      isActiveAdmin: true,
      editSkills: false,
      profilePicBtn: false,
      ProfilePicVerified: false,
      bioVerified: false,
      certifications: [],
      startDate: "",
      endDate: "",
      endDateValidate: false,
      startDateNull: false,
      endDateNull: false,
      ascSorting: true,
      totalpages: 0,
      mandatorycategoryCount: 0,
      mandatoryCount: 0,
      currentPage: 1,
      itemsPerPage: 10,
      allPages: 0,
      magentoAuthCode: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const userRole = this.props.auth.user.userRole;
    const isActiveUser = this.props.auth.user.isActive;
    this.setState({ isActiveAdmin: this.props.auth.user.isActive });
    if (
      (isActiveUser && userRole === 1) ||
      (isActiveUser && userRole === 3) ||
      (isActiveUser && userRole === 4) ||
      (isActiveUser && userRole === 5)
    ) {
      axios
        .get("/api/users/freelancers")
        .then((res) => {
          this.setState({ freelancersList: res.data });
          this.setState({ totalpages: res.data.length });
          this.setState({
            allPages: Math.ceil(
              this.state.freelancersList.length / this.state.itemsPerPage
            ),
          });
        })
        .catch((err) => console.log(err));
    } else {
      this.props.history.push("/dashboard");
    }

    axios
      .get("/api/categories/mandatorycategorylist")
      .then((res) => {
        this.setState({ mandatorycategoryCount: res.data });
      })
      .catch((err) => console.log(err));
  }

  showDetails = (email) => {
    const userData = { email: email };

    axios
      .post("/api/users/userdetails", userData)
      .then((res) => {
        this.setState({ userdetails: res.data });
        // this.setState({ email: res.data.email });
        this.setState({ contactdetails: res.data.contactdetails });
        // this.setState({ skillMatrix: res.data.skillMatrix });
        this.setState({ skillMatrix: res.data.skillMatrix }, () => {
          var count = 0;
          var self = this;
          this.state.skillMatrix.forEach(function(item, index) {
            if (item.isMandatoryCategory === true) {
              count = count + 1;
            }
            self.setState({ mandatoryCount: count });
          });
        });
        this.setState({ socialProfiles: res.data.socialProfiles });
        this.setState({ profileImage: res.data.profileImage });

        this.setState({ IDProofVerified: res.data.isPhotoIdVerified });
        this.setState({
          AddressProofVerified: res.data.isAddressProofVerified,
        });
        this.setState({ ProfilePicVerified: res.data.isProfilePicVerified });
        this.setState({ bioVerified: res.data.isBioPicVerified });

        if (res.data.idProof !== undefined) {
          if (res.data.idProof.match(/\.(jpeg|jpg|png)$/)) {
            this.setState({ idproofPrevBtn: "" });
            this.setState({ IDProof: res.data.idProof });
          } else {
            this.setState({ idproofPrevBtn: res.data.idProof });
            this.setState({ IDProof: "" });
          }
        } else {
          this.setState({ IDProof: "" });
          this.setState({ idproofPrevBtn: "" });
        }

        if (res.data.addressProof !== undefined) {
          if (res.data.addressProof.match(/\.(jpeg|jpg|png)$/)) {
            this.setState({ AddressProofBtn: "" });
            this.setState({ AddressProof: res.data.addressProof });
          } else {
            this.setState({ AddressProofBtn: res.data.addressProof });
            this.setState({ AddressProof: "" });
          }
        } else {
          this.setState({ AddressProofBtn: "" });
          this.setState({ AddressProof: "" });
        }
      })
      .catch((err) => console.log(err));

    axios
      .post("/api/certifications/certificationlist", userData)
      .then((res) => {
        if (res.data != null) {
          this.setState({ certifications: res.data.certifications });
          this.setState({ certificationsCount: res.data.certificationsCount });
          this.setState({
            approvedCertificationsCount: res.data.approvedCertificationsCount,
          });
        } else {
          this.setState({ certifications: [] });
          this.setState({ certificationsCount: 0 });
          this.setState({ approvedCertificationsCount: 0 });
        }
      })
      .catch((err) => console.log(err));
  };

  closePopup = () => {
    this.setState({ userdetails: {} });
  };

  closeIdPopup = () => {
    this.setState({ idPopup: false });
  };

  closeAddressPopup = () => {
    this.setState({ addressPopup: false });
  };

  approvePhotoId = (event) => {
    event.preventDefault();
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you have verified the Photo Id.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ IDProofVerified: true });
            const userData = { email: this.state.userdetails.email };

            axios
              .post("/api/users/photoidstatus", userData)
              .then((res) => {
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                    this.setState({ totalpages: res.data.length });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  approveProfilePic = (event) => {
    event.preventDefault();
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to approve the profile picture?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ profilePicVerified: true });

            const userData = { email: this.state.userdetails.email };

            axios
              .post("/api/users/profilepicstatus", userData)
              .then((res) => {
                this.showDetails(this.state.userdetails.email);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                    this.setState({ totalpages: res.data.length });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  approveBio = (event) => {
    event.preventDefault();
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to approve the bio",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ bioVerified: true });
            const userData = { email: this.state.userdetails.email };

            axios
              .post("/api/users/biostatus", userData)
              .then((res) => {
                this.showDetails(this.state.userdetails.email);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                    this.setState({ totalpages: res.data.length });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  approveCertification = (event, certificationName) => {
    event.preventDefault();
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to approve the certification?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const userData = {
              email: this.state.userdetails.email,
              certificationName: certificationName,
            };
            axios
              .post("/api/certifications/certificationStatus", userData)
              .then((res) => {
                this.showDetails(this.state.userdetails.email);

                axios
                  .post("/api/users/certificationstatus", userData)
                  .then((res) => {
                    axios
                      .get("/api/users/freelancers")
                      .then((res) => {
                        console.log(res.data);
                        this.setState({ freelancersList: res.data });
                        this.setState({ totalpages: res.data.length });
                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  approveAddressProof = (event) => {
    event.preventDefault();
    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you have verified the Address Proof.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ AddressProofVerified: true });
            const userData = { email: this.state.userdetails.email };
            axios
              .post("/api/users/addressproofstatus", userData)
              .then((res) => {
                console.log(res.data);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                    this.setState({ totalpages: res.data.length });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  activateAccount = (email) => {
    const userData = { email: email };

    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to activate this account.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ AddressProofVerified: true });

            axios
              .post("/api/users/activate", userData)
              .then((res) => {
                console.log("Activated user Data", res.data);
                const activatedUserData = res.data;
                const productData = {
                  product: {
                    sku: activatedUserData._id,
                    name: activatedUserData.screenname,
                    attribute_set_id: 4,
                    price: 1,
                    status: 1,
                    visibility: 4,
                    type_id: "simple",
                    weight: "0",
                    extension_attributes: {
                      category_links: [
                        {
                          position: 0,
                          category_id: "11",
                        },
                        {
                          position: 1,
                          category_id: "12",
                        },
                        {
                          position: 2,
                          category_id: "16",
                        },
                      ],
                      stock_item: {
                        qty: "10",
                        is_in_stock: true,
                      },
                    },
                    custom_attributes: [
                      {
                        attribute_code: "domain_experience",
                        value: "6",
                      },
                      {
                        attribute_code: "tax_class_id",
                        value: "2",
                      },
                      {
                        attribute_code: "tech_skill",
                        value: "12",
                      },
                      {
                        attribute_code: "country_of_manufacture",
                        value: "IN",
                      },
                    ],
                  },
                };
                axios
                  .post(
                    "https://freelancer.apoyar.eu/rest/all/V1/integration/admin/token",
                    { username: "devadmin", password: "admin@123" }
                  )
                  .then((response) => {
                    console.log(response.data);
                    this.setState({magentoAuthCode:response.data});
                    axios
                      .post(
                        "https://freelancer.apoyar.eu/rest/default/V1/products",
                        productData,
                        {
                          headers: {
                            Authorization: `Bearer ${response.data}`,
                          },
                        }
                      )
                      .then((response) => {
                        console.log("product created", response.data);
                        const userImagePath = {"profileImg" : "https://devfreelancersapi.supportpod.com/"+activatedUserData.profileImage.replace('public/',"")};
                        var ext = activatedUserData.profileImage.split(".");
                        ext = ext.pop();
						axios.post("/api/users/base64image", userImagePath)
						.then((res) => {
							var productImage ={};
							if (ext === "jpg" || ext === "jpeg") {
							 productImage= {
						    "entry": {
						        "media_type": "image",
						        "label": "Image",
						        "position": 1,
						        "disabled": false,
						        "types": [
						            "image",
						            "small_image",
						            "thumbnail"
						        ],
						        "content": {
						            "base64_encoded_data": res.data,
						            "type": "image/jpeg",
						            "name": activatedUserData._id+".jpg"
						        }
						    }
						}
					}
					else if(ext==="png" || ext==="PNG"){
						 productImage= {
						    "entry": {
						        "media_type": "image",
						        "label": "Image",
						        "position": 1,
						        "disabled": false,
						        "types": [
						            "image",
						            "small_image",
						            "thumbnail"
						        ],
						        "content": {
						            "base64_encoded_data": res.data,
						            "type": "image/png",
						            "name": activatedUserData._id+".png"
						        }
						    }
						}
					}
					else {
						 productImage= {
						    "entry": {
						        "media_type": "image",
						        "label": "Image",
						        "position": 1,
						        "disabled": false,
						        "types": [
						            "image",
						            "small_image",
						            "thumbnail"
						        ],
						        "content": {
						            "base64_encoded_data": res.data,
						            "type": "image/gif",
						            "name": activatedUserData._id+".gif"
						        }
						    }
						}
					}
                        axios
                      .post(
                        "https://freelancer.apoyar.eu/rest/default/V1/products/"+activatedUserData._id+"/media",
                        productImage,
                        {
                          headers: {
                            Authorization: `Bearer ${this.state.magentoAuthCode}`,
                          },
                        }
                      )
                      .then((response) => {
                        console.log("product created", response.data);
                        

                      })
                      .catch((err) => console.log(err));
						})
						.catch((err) => console.log(err));

                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  deactivateAccount = (email) => {
    const userData = { email: email };

    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to deactivate this account.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ AddressProofVerified: true });

            axios
              .post("/api/users/deactivate", userData)
              .then((res) => {
                console.log(res.data);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  viewLargeId = () => {
    this.setState({ idPopup: true });
  };

  viewLargeAddress = () => {
    this.setState({ addressPopup: true });
  };

  saveSkills = (event, email) => {
    event.preventDefault();

    const userData = {
      email: email,
      skillMatrix: this.state.skillMatrix,
      updatedBy: this.props.auth.user.email,
    };

    axios
      .post("/api/skills/updateskills", userData)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .post("/api/users/updateskills", userData)
      .then((res) => {
        console.log(res.data);
        axios
          .get("/api/users/freelancers")
          .then((res) => {
            this.setState({ freelancersList: res.data });
            this.setState({ totalpages: res.data.length });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    this.setState({ editSkills: false });
  };

  verifySkills = (event, email) => {
    const userData = {
      email: email,
      skillMatrix: this.state.skillMatrix,
      updatedBy: this.props.auth.user.email,
    };
    axios
      .post("/api/users/verifyskills", userData)
      .then((res) => {
        this.showDetails(this.state.userdetails.email);
      })
      .catch((err) => console.log(err));
  };

  editSkills = (event) => {
    event.preventDefault();
    this.setState({ editSkills: true });
  };

  handleSkillRating = (event, idx) => {
    event.preventDefault();
    var rating = event.target.value;
    var index = idx;
    let skillMatrix = [...this.state.skillMatrix];
    let skill = { ...skillMatrix[index] };
    skill.supportpodRating = rating;
    skillMatrix[index] = skill;
    this.setState({ skillMatrix });
  };

  filterData = (event) => {
    var status = document.getElementById("status").value;
    const filterData = {
      status: status,
    };

    axios
      .post("/api/users/statusfilterfreelancers", filterData)
      .then((res) => {
        this.setState({ freelancersList: res.data }, () => {
          console.log(this.state.freelancersList);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  };

  onAscSort(event, sortKey) {
    const freelancersList = this.state.freelancersList;
    freelancersList.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    this.setState({ freelancersList });
    this.setState({ ascSorting: false });
  }
  onDscSort(event, sortKey) {
    const freelancersList = this.state.freelancersList;
    freelancersList.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    this.setState({ freelancersList });
    this.setState({ ascSorting: true });
  }

  showFiltersFunc = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  filterChange = () => {
    var status = document.getElementById("filterChange").value;

    const filterData = {
      status: status,
    };

    axios
      .post("/api/users/filterfreelancers", filterData)
      .then((res) => {
        this.setState({ freelancersList: res.data }, () => {
          console.log(this.state.freelancersList);
        });
        this.setState({ totalpages: res.data.length });
      })
      .catch((err) => console.log(err));
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;

    event.preventDefault();
    var str = event.target.value;
    const filterData = {
      screenname: str,
    };
    if (str !== "") {
      axios
        .post("/api/users/searchfreelancer", filterData)
        .then((res) => {
          this.setState({ freelancersList: res.data }, () => {
            console.log(this.state.freelancersList);
          });
          this.setState({ totalpages: res.data.length });
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get("/api/users/freelancers")
        .then((res) => {
          this.setState({ freelancersList: res.data }, () => {
            console.log(this.state.freelancersList);
          });
          this.setState({ totalpages: res.data.length });
        })
        .catch((err) => console.log(err));
    }
  };

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }
  handlePrevClick(event) {
    if (Number(event.target.id) > 1) {
      this.setState({
        currentPage: Number(event.target.id) - 1,
      });
    }
  }
  handleNextClick(event) {
    if (Number(event.target.id) < Number(this.state.allPages)) {
      this.setState({ currentPage: Number(event.target.id) + 1 }, () => {
        console.log(this.state.currentPage);
      });
    }
  }

  render() {
    const freelancersList = this.state.freelancersList;
    const self = this;
    const userDetails = this.state.userdetails;
    const contactDetails = this.state.contactdetails;
    const socialProfiles = this.state.socialProfiles;
    const skillMatrix = this.state.skillMatrix;
    const certifications = this.state.certifications;
    const certificationsCount = this.state.certificationsCount;
    const approvedCertificationsCount = this.state.approvedCertificationsCount;
    const { currentPage, itemsPerPage } = this.state;

    var profilePic = this.state.profileImage;
    var IDProof = this.state.IDProof;
    var AddressProof = this.state.AddressProof;
    var previewId = "";
    var previewAddress = "";
    const isActiveAdminUser = this.state.isActiveAdmin;

    var isProfilePicVerified = this.state.ProfilePicVerified;
    var isBioVerified = this.state.bioVerified;

    const totalpages = this.state.totalpages;
    var pagetotal = 0;
    if (totalpages < 25) {
      pagetotal = totalpages;
    } else {
      pagetotal = 25;
    }

    var mandateFilled = false;
    if (
      parseInt(this.state.mandatoryCount) >=
      parseInt(this.state.mandatorycategoryCount)
    ) {
      mandateFilled = true;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = freelancersList.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    // displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(freelancersList.length / itemsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li key={number} id={number} onClick={this.handleClick}>
          {number}
        </li>
      );
    });

    if (profilePic !== undefined) {
      if (profilePic.length > 0) {
        profilePic = this.state.profileImage.replace("public", "");
      }
    }

    if (IDProof !== undefined) {
      if (IDProof.length > 0) {
        IDProof = this.state.IDProof.replace("public", "");
        previewId = (
          <>
            <img
              style={{ width: "220px" }}
              src={`https://devfreelancersapi.supportpod.com${IDProof}`}
              alt="ID Proof"
            />
            <br />
            <button
              style={{ marginBottom: "10px" }}
              className="btn black"
              onClick={this.viewLargeId}
            >
              View Large Image
            </button>
            {this.state.idPopup && (
              <div className="popup">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.closeIdPopup}
                >
                  +
                </button>
                <img
                  style={{ width: "100%" }}
                  src={`https://devfreelancersapi.supportpod.com${IDProof}`}
                  alt="ID Proof"
                />
              </div>
            )}
          </>
        );
      }
    }

    var idproofBtn = this.state.idproofPrevBtn;
    if (idproofBtn !== null) {
      if (idproofBtn.length > 0) {
        idproofBtn = idproofBtn.replace("public", "");
        previewId = (
          <div>
            Preview not available <br />
            <a
              className="btn black view-btn"
              target="_blank"
              href={`https://devfreelancersapi.supportpod.com${idproofBtn}`}
            >
              View File
            </a>
          </div>
        );
      }
    }

    if (AddressProof !== undefined) {
      if (AddressProof.length > 0) {
        AddressProof = this.state.AddressProof.replace("public", "");
        previewAddress = (
          <>
            <img
              style={{ width: "220px" }}
              src={`https://devfreelancersapi.supportpod.com${AddressProof}`}
              alt="Address Proof"
            />
            <br />
            <button
              style={{ marginBottom: "10px" }}
              className="btn black"
              onClick={this.viewLargeAddress}
            >
              View Large Image
            </button>
            {this.state.addressPopup && (
              <div className="popup">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.closeAddressPopup}
                >
                  +
                </button>
                <img
                  style={{ width: "100%" }}
                  src={`https://devfreelancersapi.supportpod.com${AddressProof}`}
                  alt="Address Proof"
                />
              </div>
            )}
          </>
        );
      }
    }

    var AddressProofBtn = this.state.AddressProofBtn;
    if (AddressProofBtn !== null) {
      if (AddressProofBtn.length > 0) {
        AddressProofBtn = AddressProofBtn.replace("public", "");
        previewAddress = (
          <div>
            Preview not available <br />
            <a
              className="btn black view-btn"
              target="_blank"
              href={`https://devfreelancersapi.supportpod.com${AddressProofBtn}`}
            >
              View File
            </a>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <Navbar current={this.props.history.location.pathname} />
        <div className="row" style={{ marginBottom: "0px" }}>
          <div
            className="container grid-container"
            style={{ marginTop: "60px" }}
          >
            <div className="col s12">
              <h4 style={{ marginTop: "10px" }}>Freelancers</h4>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "0px" }}>
          <div className="container grid-container">
            <div
              className="col s12"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                background: "#fafafa",
              }}
            >
              <div
                className="col s1 right-border"
                style={{ paddingTop: "5px" }}
              >
                <button
                  className="btn-transparent"
                  onClick={this.showFiltersFunc}
                >
                  <img
                    src={FilterIcon}
                    alt="Filter"
                    style={{ width: "10px", marginRight: "5px" }}
                  />
                  Filter
                </button>
              </div>
              <div
                className="col s1 right-border"
                style={{ paddingTop: "5px" }}
              >
                {this.state.ascSorting ? (
                  <button
                    className="btn-transparent"
                    onClick={(e) => this.onAscSort(e, "screenname")}
                  >
                    <img
                      src={AscIcon}
                      alt="Ascending"
                      style={{ width: "12px", marginRight: "5px" }}
                    />
                    Sort
                  </button>
                ) : (
                  <button
                    className="btn-transparent"
                    onClick={(e) => this.onDscSort(e, "screenname")}
                  >
                    <img
                      src={DscIcon}
                      alt="Descending"
                      style={{ width: "12px", marginRight: "5px" }}
                    />
                    Sort
                  </button>
                )}
              </div>

              <div
                className="col s3 right-border"
                style={{ paddingTop: "5px" }}
              >
                <img
                  src={SearchIcon}
                  alt="Filter"
                  style={{
                    width: "12px",
                    marginRight: "5px",
                    marginLeft: "10px",
                    lineHeight: "32px",
                  }}
                />
                <input
                  onKeyUp={this.enterPressed}
                  type="text"
                  placeholder="Search Freelancer"
                  style={{
                    width: "210px",
                    borderRadius: "0px",
                    height: "24px",
                    margin: "0",
                    border: "0 none",
                  }}
                />
              </div>
              <div className="col s2 right-border">
                <select
                  id="status"
                  className="browser-default"
                  style={{ width: "120px", float: "left", height: "32px" }}
                  onChange={this.filterData}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div className="col s3 right" style={{ textAlign: "right" }}>
                {totalpages > 0 && (
                  <>
                    <span
                      style={{
                        marginRight: "5px",
                        marginLeft: "10px",
                        lineHeight: "32px",
                      }}
                    >
                      {currentPage * itemsPerPage - (itemsPerPage - 1)} -{" "}
                      {currentItems.length + (currentPage - 1) * itemsPerPage}{" "}
                      of {totalpages}
                    </span>
                    <button
                      className="btn-transparent"
                      onClick={(event) => self.handlePrevClick(event)}
                      id={currentPage}
                      className={currentPage <= 1 ? "disabled" : ""}
                    >
                      {" "}
                      Prev{" "}
                    </button>
                    <button
                      className="btn-transparent"
                      onClick={(event, currentItemsLength) =>
                        self.handleNextClick(event, currentItemsLength)
                      }
                      id={currentPage}
                      className={
                        currentPage >= this.state.allPages ? "disabled" : ""
                      }
                    >
                      {" "}
                      Next{" "}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showFilters && (
          <div className="row" style={{ marginBottom: "0px" }}>
            <div className="container grid-container">
              <div
                className="col s12"
                style={{
                  backgroundColor: "#FAFAFA",
                  paddingTop: "10px",
                  borderTop: "1px solid #e4e4e4",
                }}
              >
                <div className="col s3">
                  <select
                    className="browser-default"
                    id="filterChange"
                    style={{ height: "32px", marginBottom: "10px" }}
                    onChange={self.filterChange}
                  >
                    <option value="all">All</option>
                    <option value="profile">
                      Pending Profile Verifications
                    </option>
                    <option value="photoid">
                      Pending Photo Id Verifications
                    </option>
                    <option value="skills">Pending Skills Verifications</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "10px",
            marginBottom: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="container valign-wrapper grid-container"
        >
          <div className="row">
            <div className="">
              <div className="s12 col">
                <div className="row">
                  {isActiveAdminUser ? (
                    <table style={{ margin: "10px", width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="50">Select</th>
                          <th width="80"></th>
                          <th width="350">Name & Role</th>
                          <th width="250">Email</th>
                          <th width="350">Profile Status</th>
                          <th width="100">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map(function(d, idx) {
                          return (
                            d.userRole === 2 && (
                              <tr key={idx}>
                                <td>
                                  <label>
                                    <input
                                      type="checkbox"
                                      className="filled-in"
                                    />
                                    <span></span>
                                  </label>
                                </td>
                                <td>
                                  <div
                                    className={
                                      "profilepic-wrapper " +
                                      (d.isActive
                                        ? "green-border"
                                        : d.isBioVerified &&
                                          d.isPhoneVerified &&
                                          d.isSkillsVerified &&
                                          d.isCertificationVerified &&
                                          d.isAddressProofVerified &&
                                          d.isPhotoIdVerified
                                        ? "orange-border"
                                        : "red-border")
                                    }
                                  >
                                    {d.profileImage ? (
                                      <img
                                        src={`https://devfreelancersapi.supportpod.com/${d.profileImage.replace(
                                          "public",
                                          ""
                                        )}`}
                                        alt={userDetails.screenname}
                                        width="100%"
                                      />
                                    ) : (
                                      <img
                                        src={`https://devfreelancers.supportpod.com/profileimg.png`}
                                        alt={d.screenname}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {d.screenname}
                                  <br />
                                  {d.profiletitle}
                                </td>
                                <td>{d.email}</td>
                                <td
                                  className="status-icons"
                                  style={{ width: "200px" }}
                                >
                                  {d.isProfilePicVerified && d.isBioVerified ? (
                                    <span
                                      className="active-user"
                                      title="Profile Picture, Bio"
                                    >
                                      <FontAwesomeIcon icon={faUserCheck} />
                                      <label>Profile</label>
                                    </span>
                                  ) : (
                                    <span
                                      className="inactive-user"
                                      title="Profile Picture, Bio"
                                    >
                                      <FontAwesomeIcon icon={faUserTimes} />
                                      <label>Profile</label>
                                    </span>
                                  )}
                                  &nbsp;&nbsp;&nbsp;
                                  {d.isPhotoIdVerified &&
                                  d.isAddressProofVerified &&
                                  d.isPhoneVerified ? (
                                    <span
                                      className="active-user"
                                      title="Photo ID, Address Proof, Phone Number"
                                    >
                                      <FontAwesomeIcon icon={faAddressCard} />
                                      <label>ID Check</label>
                                    </span>
                                  ) : (
                                    <span
                                      className="inactive-user"
                                      title="Photo ID, Address Proof, Phone Number"
                                    >
                                      <FontAwesomeIcon icon={faAddressCard} />
                                      <label>ID Check</label>
                                    </span>
                                  )}
                                  &nbsp;&nbsp;&nbsp;
                                  {d.isSkillsVerified ? (
                                    <>
                                      {d.skillMatrix.length > 0 && (
                                        <>
                                          {Number(
                                            d.skillMatrix.filter(
                                              (item) =>
                                                item.selectedRanking > "0"
                                            ).length
                                          ) >
                                          Number(
                                            d.skillMatrix.filter(
                                              (item) =>
                                                item.supportpodRating > "0"
                                            ).length
                                          ) ? (
                                            <span
                                              className="notify-user"
                                              title="New skills are added by the freelancer and pending verification"
                                            >
                                              <FontAwesomeIcon
                                                icon={faUserGraduate}
                                              />
                                              <label>Skills</label>
                                            </span>
                                          ) : (
                                            <span
                                              className="active-user"
                                              title="Skills"
                                            >
                                              <FontAwesomeIcon
                                                icon={faUserGraduate}
                                              />
                                              <label>Skills</label>
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <span
                                      className="inactive-user"
                                      title="Skills"
                                    >
                                      <FontAwesomeIcon icon={faUserGraduate} />
                                      <label>Skills</label>
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <div className="view-btn btn-flat">
                                    <img
                                      src={MoreIcon}
                                      alt="More Actions"
                                      style={{ height: "24px" }}
                                    />
                                    <div className="context-menu">
                                      <ul>
                                        <li
                                          onClick={() =>
                                            self.showDetails(d.email)
                                          }
                                        >
                                          View
                                        </li>
                                        {d.isPhotoIdVerified &&
                                          d.isAddressProofVerified &&
                                          d.isPhoneVerified &&
                                          d.isProfilePicVerified &&
                                          d.isBioVerified &&
                                          d.isSkillsVerified &&
                                          !d.isActive && (
                                            <li
                                              onClick={() =>
                                                self.activateAccount(d.email)
                                              }
                                            >
                                              Activate
                                            </li>
                                          )}
                                        {d.isPhotoIdVerified &&
                                          d.isAddressProofVerified &&
                                          d.isPhoneVerified &&
                                          d.isProfilePicVerified &&
                                          d.isBioVerified &&
                                          d.isSkillsVerified &&
                                          d.isActive && (
                                            <li
                                              onClick={() =>
                                                self.deactivateAccount(d.email)
                                              }
                                            >
                                              Deactivate
                                            </li>
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <p className="red-text">
                      You are not authorized to view this page
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {Object.keys(userDetails).length > 0 && (
            <div>
              <div className="popup">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.closePopup}
                >
                  +
                </button>
                <div className="row">
                  <div className="container">
                    <div className="col s12">
                      <div className="col s2">
                        <div
                          className="profile-container no-margin"
                          style={{ maxWidth: "120px" }}
                        >
                          {this.state.profileImage ? (
                            <img
                              src={`https://devfreelancersapi.supportpod.com${profilePic}`}
                              alt={userDetails.screenname}
                              width="100%"
                            />
                          ) : null}
                        </div>
                        {previewId && !isProfilePicVerified && (
                          <button
                            className="btn left"
                            onClick={this.approveProfilePic}
                          >
                            Approve Pic
                          </button>
                        )}
                        {previewId && isProfilePicVerified && (
                          <div>
                            <img
                              src={SuccessIcon}
                              style={{
                                width: "24px",
                                position: "relative",
                                top: "7px",
                              }}
                            />
                            <span>Verification completed</span>
                          </div>
                        )}
                      </div>
                      <div className="col s10">
                        <div className="col s4">
                          <h6>{userDetails.screenname}</h6>
                          <div className="">{userDetails.profiletitle}</div>
                        </div>
                        <div className="col s4">
                          <h6>
                            {userDetails.firstname} {userDetails.lastname}
                          </h6>
                          {Object.keys(contactDetails).length > 0 && (
                            <div>
                              {contactDetails.phone && (
                                <p>
                                  <img
                                    src={CallIcon}
                                    alt="Call Icon"
                                    style={{ width: "18px" }}
                                  />
                                  &nbsp; {contactDetails.phone}
                                </p>
                              )}
                            </div>
                          )}
                          {userDetails.email && (
                            <p>
                              <img
                                src={EmailIcon}
                                alt="Email Icon"
                                style={{ width: "18px" }}
                              />
                              &nbsp; {userDetails.email}
                            </p>
                          )}
                        </div>
                        <div className="col s4">
                          <p>
                            {contactDetails.region} {contactDetails.country}
                          </p>
                          <p>{contactDetails.zipcode} </p>
                        </div>
                      </div>
                      <div className="col s10">
                        <p style={{wordBreak:"break-all"}}>{userDetails.profilesummary}</p>
                        {userDetails.profilesummary &&
                          !userDetails.isBioVerified && (
                            <button
                              className="btn left"
                              onClick={this.approveBio}
                            >
                              Approve Bio
                            </button>
                          )}
                        {userDetails.profilesummary &&
                          userDetails.isBioVerified && (
                            <div>
                              <img
                                src={SuccessIcon}
                                style={{
                                  width: "24px",
                                  position: "relative",
                                  top: "7px",
                                }}
                              />
                              <span>Verification completed</span>
                            </div>
                          )}
                      </div>
                    </div>

                    {skillMatrix.length > 0 && (
                      <div className="col s12 module">
                        <div className="col s6">
                          <h6>Skills</h6>
                        </div>
                        <div className="col s6">
                          <div className="action-btn right">
                            {self.state.editSkills ? (
                              <button
                                className="btn black"
                                onClick={(event) =>
                                  this.saveSkills(event, userDetails.email)
                                }
                                style={{ marginTop: "-10px" }}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                className="right edit-btn"
                                onClick={(event) => this.editSkills(event)}
                                title="edit"
                                style={{ top: "10px" }}
                              >
                                <a href="javascript:void(0)">Edit</a>
                              </button>
                            )}
                          </div>
                        </div>
                        <table className="striped">
                          <thead>
                            <tr>
                              <th>Categories</th>
                              <th>Skill</th>
                              <th>Ranking</th>
                              <th>SupportPod Rating</th>
                              <th>Years of Exp</th>
                            </tr>
                          </thead>

                          <tbody>
                            {skillMatrix.map(function(d, idx) {
                              return (
                                <tr key={idx}>
                                  <td>{d.selectedCategory}</td>
                                  <td>{d.selectedSkills}</td>
                                  <td>{d.selectedRanking}</td>
                                  <td>
                                    {self.state.editSkills ? (
                                      <select
                                        className="browser-default small"
                                        value={d.supportpodRating}
                                        onChange={(event) =>
                                          self.handleSkillRating(event, idx)
                                        }
                                      >
                                        <option value="">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    ) : (
                                      <span>{d.supportpodRating}</span>
                                    )}
                                  </td>
                                  <td>{d.selectedYearsOfExp}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {!userDetails.isSkillsVerified ? (
                          <>
                            {!mandateFilled ? (
                              <p className="red-text">
                                Note: All the mandatory categories are not added
                                by the freelancer.
                              </p>
                            ) : (
                              <button
                                className="btn btn-black right"
                                onClick={(event) =>
                                  this.verifySkills(event, userDetails.email)
                                }
                              >
                                Approve Skills
                              </button>
                            )}
                          </>
                        ) : (
                          <div>
                            <img
                              src={SuccessIcon}
                              style={{
                                width: "24px",
                                position: "relative",
                                top: "7px",
                              }}
                            />
                            <span>Verification completed</span>
                          </div>
                        )}
                      </div>
                    )}
                    {certifications.length > 0 && (
                      <div className="col s12 module">
                        <div className="col s6">
                          <h6>Certifications</h6>
                        </div>
                        <div className="col s12">
                          {certifications.map(function(d, idx) {
                            return (
                              <>
                                <div
                                  style={{
                                    borderBottom: "1px solid #e4e4e4",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <div className="right">
                                    {!d.isApproved ? (
                                      <button
                                        style={{
                                          position: "relative",
                                          top: "25px",
                                        }}
                                        className="btn right"
                                        onClick={(event) =>
                                          self.approveCertification(
                                            event,
                                            d.certificationName
                                          )
                                        }
                                      >
                                        Mark it as verified
                                      </button>
                                    ) : (
                                      <div>
                                        <img
                                          src={SuccessIcon}
                                          style={{
                                            width: "24px",
                                            position: "relative",
                                            top: "7px",
                                          }}
                                        />
                                        <span>Verification completed</span>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <h4>
                                      {d.certificationApproved && (
                                        <img src={ShieldIcon} alt="Approved" />
                                      )}
                                      {d.certificationName}
                                    </h4>
                                  </div>
                                  <div
                                    style={{
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    Issued Date :
                                    <b>{d.certificationIssuedDate}</b>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Expiry Date :
                                    <b>{d.certificationExpiryDate}</b>
                                  </div>
                                  <div>{d.certificationDetails}</div>
                                  <div
                                    style={{
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <a
                                      target="_blank"
                                      href={d.certificationUrl}
                                    >
                                      View Certificate
                                    </a>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {socialProfiles.length > 0 && (
                      <div className="col s12 module">
                        <h6>Social Links</h6>
                        {socialProfiles.map(function(d, idx) {
                          return (
                            <p key={idx}>
                              <img
                                src={SocialIcon}
                                alt="Social Icon"
                                style={{ width: "18px" }}
                              />
                              &nbsp;
                              <a href={d} target="_blank">
                                {d}
                              </a>
                            </p>
                          );
                        })}
                      </div>
                    )}
                    <div className="col s12 module">
                      <h6>Photo Id Verification Status</h6>
                      <div className="col s4">
                        <p>
                          {userDetails.isPhoneVerified ? (
                            <div>
                              <img
                                src={SuccessIcon}
                                style={{
                                  width: "24px",
                                  position: "relative",
                                  top: "7px",
                                }}
                              />
                              <span>
                                Phone Number Verified - Verified Number :
                                {userDetails.verifiedPhoneNumber}
                              </span>
                            </div>
                          ) : (
                            " Phone Number Not Verified"
                          )}
                        </p>
                      </div>
                      <div className="col s4">
                        <div className="row">
                          <div>
                            <h6>
                              {this.state.IDProofVerified ? (
                                <span>
                                  <img
                                    src={SuccessIcon}
                                    style={{
                                      width: "24px",
                                      position: "relative",
                                      top: "7px",
                                    }}
                                  />
                                </span>
                              ) : null}
                              Photo ID
                            </h6>
                            {previewId ? <>{previewId}</> : null}

                            {this.state.IDProofVerified ? (
                              <div>
                                <img
                                  src={SuccessIcon}
                                  style={{
                                    width: "24px",
                                    position: "relative",
                                    top: "7px",
                                  }}
                                />
                                <span>Verification completed</span>
                              </div>
                            ) : (
                              <>
                                {previewId && (
                                  <button
                                    className="btn left"
                                    onClick={this.approvePhotoId}
                                  >
                                    Mark it as Verified
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col s4">
                        <div className="row">
                          <div>
                            <h6>
                              {this.state.AddressProofVerified ? (
                                <span>
                                  <img
                                    src={SuccessIcon}
                                    style={{
                                      width: "24px",
                                      position: "relative",
                                      top: "7px",
                                    }}
                                  />
                                </span>
                              ) : null}
                              Address Proof / Utility Bill
                            </h6>
                            {previewAddress ? <>{previewAddress}</> : null}

                            {this.state.AddressProofVerified ? (
                              <div>
                                <img
                                  src={SuccessIcon}
                                  style={{
                                    width: "24px",
                                    position: "relative",
                                    top: "7px",
                                  }}
                                />
                                <span>Verification completed</span>
                              </div>
                            ) : (
                              <>
                                {previewAddress && (
                                  <button
                                    className="btn left"
                                    onClick={this.approveAddressProof}
                                  >
                                    Mark it as Verified
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn black right"
                  onClick={this.closePopup}
                >
                  Close
                </button>
              </div>

              <Overlay />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Freelancers.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Freelancers);
