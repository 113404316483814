import axios from "axios";
import supportProd from "../../../axios/index";

import { CUSTOMER_URL, URL_PARAM } from "../../../utils/Config";

// const { REACT_APP_SUPPORT_PROD_API, REACT_APP_TEST_ENV_VAR} = process.env;

// console.log('env var',REACT_APP_SUPPORT_PROD_API, REACT_APP_TEST_ENV_VAR);

const SUPPORT_APYR_API = `${CUSTOMER_URL}/A1Xero`;

// const SUPPORT_APYR_API = "http://ws-supportapi.apoyar.net/api/A1Xero";

export const getItemCodes = async () =>
  axios.get(`${SUPPORT_APYR_API}/GetItemCodes`);

// export const getCustomerContacts = async () =>
//   axios.get(`${SUPPORT_APYR_API}/GetAllContacts`);

// export const getCustomerContacts = async () =>
//   axios.get(`${CUSTOMER_URL}/Customer/GetAllCustomersFL?URL=${URL_PARAM}`);

export const getQuoteURL = async ({ QuoteID = "", QuoteNumber = "" } = {}) =>
  axios.get(
    `${SUPPORT_APYR_API}/GetQuoteDetails?quoteid=${QuoteID} &quotenumber=${QuoteNumber}`
  );

export const createQuote = async (reqBody) =>
  axios.post(`${SUPPORT_APYR_API}/PostQuote`, reqBody);

export const createCustomer = async (reqBody) =>
  axios.post(`${SUPPORT_APYR_API}/PostCustomerInformation`, reqBody);

export const createQuoteInSupportProd = async (reqBody) =>
  supportProd.post("/api/finances/createquote", reqBody);

export const getQuotes = async () =>
  supportProd.get(`${SUPPORT_APYR_API}/GetAllQuotes`);

export const getServiceRequests = async (email) =>
  axios.get(
    `${CUSTOMER_URL}/Customer/GetAllSRSByCustomerEmail?email=${email}&url=${URL_PARAM}`
  );

// export const getQuotes = async (email) =>
//   supportProd.get(
//     `/api/finances/getquotes${(email && `?serviceManagerEmailId=${email}`) ||
//       ""}`
//   );

// export const getServiceRequests = async (email) =>
//   supportProd.post("/api/bids/smbids", { email });

export const getBids = async (serviceRequestId) =>
  supportProd.post("/api/bidproposals/bidproposals", { serviceRequestId });
