import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../layout/Navbar";
import Menu from "../dashboard/Menu";
import "./dashboard.css";

class Messages extends Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;

    var sname = "";

    if (user.screenname !== undefined && user.screenname !== "") {
      sname = user.screenname;
    } else {
      if (localStorage.getItem("ScreenName")) {
        sname = localStorage.getItem("ScreenName");
      } else {
        sname = user.email;
      }
    }

    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "10px",
            border: "1px solid rgba(112,112,112,0.27)",
            marginBottom: "30px",
            marginTop: "100px",
          }}
          className="container valign-wrapper"
        >
          <div className="row">
            <div className="landing-copy col s12 center-align">
              <h6 style={{ marginTop: "40px" }}>
                <b>Hello</b> {sname}
              </h6>
              <h6>Messages page is under construction</h6>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Messages.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Messages);
