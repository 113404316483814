import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, confirmEmail } from "../../actions/authActions";
import classnames from "classnames";
import './auth.css';
import logo from "../layout/logo.png";
import SuccessIcon from '../../images/success.png';
import FailureIcon from '../../images/failure.png';


class EmailVefifiationSuccess extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
       emailValidate:false,
       confirmationCode:""
    };
  }
componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmationCode = urlParams.get('confirmationCode')

    this.setState({confirmationCode:confirmationCode},()=>{
      console.log('Confirmation Code is',this.state.confirmationCode);
    const newUser = {
      id: this.state.confirmationCode
    };

      this.props.confirmEmail(newUser);

      console.log('props',this.props);

      this.setState({email : localStorage.getItem('validation_email')});

    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
              <Link
                to="/"
                style={{
                  fontFamily: "monospace"
                }}
                className="col s5 brand-logo center black-text">
                <img src={logo} alt="logo" />
              </Link>
          </div>
        </nav>
      </div>

      <div className="container auth" >
        <div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            {errors.email ?
              <h4 className="red-text">Email verification failed</h4>
              :
              <h4>Email verification</h4>
            }
            
            </div>
             {errors.email ?
            	 <><img src={FailureIcon} alt="Success Icon" style={{margin:"0 auto", display:"inherit"}}/>
                <p className="center" style={{fontSize : "16px"}}>Please check your email account</p>
               </>
              : <><img src={SuccessIcon} alt="Success Icon" style={{margin:"0 auto", display:"inherit"}}/>
                <p className="center" style={{fontSize : "16px"}}>Congratulations, Your email id <b>{this.state.email} </b> has been verified successfully.</p><p className="center" style={{fontSize : "16px"}}> Please <Link to="https://www.supportpod.com/freelancers">Log in</Link> to continue</p></>
              }
            	
          </div>
        </div>
      </div>
    </>
    );
  }
}

EmailVefifiationSuccess.propTypes = {
  loginUser: PropTypes.func.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, confirmEmail }
)(EmailVefifiationSuccess);
