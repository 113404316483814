import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../../actions/authActions";
import classnames from "classnames";
import './auth.css';
import logo from "../layout/logo.png";
import Navbar from "../layout/Navbar";
// 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

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      emailValidation: true
    };
  }

  componentDidMount() {
    console.log('props---',this.props.auth.user);
    // If logged in and user navigates to Login page, should redirect them to dashboard
     localStorage.removeItem('screenName');

    if (this.props.auth.isAuthenticated) {

       if(this.props.auth.user.isEmailVerified) {
        
          if (this.props.auth.user.screenname === undefined || this.props.auth.user.screenname === ""){

                this.props.history.push("/screenname");
          
          } else {
            console.log(this.props.auth.user.userRole);

            if(this.props.auth.user.userRole === 1 || this.props.auth.user.userRole === 5) {

              this.props.history.push("/freelancers");

            }
            else if(this.props.auth.user.userRole === 3 || this.props.auth.user.userRole === 4){
              this.props.history.push("/all-bids");
            }
            else if (this.props.auth.user.userRole === 2 && this.props.auth.user.isActive === true) {

              this.props.history.push("/bids");
            }
            else {
              alert(this.props.auth.user.userRole);
              this.props.history.push("/dashboard");
            }
          }
    }else {
      this.setState({emailValidation: false});
    }

    }
  }

  componentWillReceiveProps(nextProps) {
    
   
    if (nextProps.auth.isAuthenticated) {

      if(nextProps.auth.user.isEmailVerified) {


                if (nextProps.auth.user.screenname ===undefined || nextProps.auth.user.screenname ==="" ){
                      
                      this.props.history.push("/screenname");
                }
                else {
                  if(nextProps.auth.user.userRole == 1  || nextProps.auth.user.userRole === 5)  {

                    this.props.history.push("/freelancers");
                  }
                else
                  if( nextProps.auth.user.userRole == 3 || nextProps.auth.user.userRole == 4){

                    this.props.history.push("/all-bids");
                  }
                  else if (nextProps.auth.user.userRole == 2 && nextProps.auth.user.isActive === true) {

                    this.props.history.push("/bids");
                  }
                  else {
                    this.props.history.push("/dashboard");
                  }

                }
      }
      else {
        this.setState({emailValidation: false});
      }
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }
  onChange = e => {
    const { errors } = this.state;
    const name = e.target.id;
    delete errors[name];
    this.setState({ [name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);

    setTimeout(()=>{
        if (!this.state.emailValidation) {
           this.props.logoutUser();
        }
    },1000)
  };

  render() {
    const { errors } = this.state;

    return (
      <>
      <Navbar />
      <div className="row">
          <div className="container">
              <div className="col s12">
                <h1 className="header-text">Join our network of talented certified experts</h1>

                  <div className="col s12 l6">
                  <ul className="highlights">
                      <li>Performance Dashboard</li>
                      <li>Qualified and Certified Work</li>
                      <li>Increase your success rate</li>
                      <li>Work for Service Delivery Managers</li>
                      <li>Simplify communications</li>
                      <li>Earn by delivering additional services to your clients</li>
                      <li>Referral Program extra commission</li>
                      <li>SupportPod Portal & Chat</li>
                      <li>Tech Assistance in Bid Process</li>
                  </ul>
                  </div>
                  <div className="col s12 l6">
                    
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
              <Link
                to="/"
                style={{
                  fontFamily: "monospace"
                }}
                className="col brand-logo center black-text"
              >
                <img src={logo} alt="logo" style={{width:"180px", maxWidth:"180px"}}/>
              </Link>
          </div>
        </nav>
      </div>
      <div className="container auth" >
        <div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <h4 style={{marginTop : "0"}}>Freelancer Log In</h4>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email 
                  })}
                />
                <label htmlFor="email">Email</label>
                <span className="red-text">
                  {errors.email}
                </span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("", {
                    invalid: errors.password
                  })}
                />
                <label htmlFor="password">Password</label>
                <span className="red-text">
                  {errors.password}
                </span>
                {(errors.emailnotfound || errors.passwordincorrect) && (<span className="red-text" style={{textAlign:'center', marginLeft:'25px'}}>Email Password combination Incorrect</span>) }
              </div>
                <p className="left" style={{marginTop:"0",marginBottom:"0"}}><label>
                <input type="checkbox" className="filled-in" />
                <span style={{fontSize:"13px"}}>Remember me</span>
              </label> </p>
              <p className="right" style={{marginTop:"0",marginBottom:"0",marginRight:"15px", fontSize:"13px", position:"relative",top:"2px"}}><Link to="/forgot-password">
              Forgot Password</Link></p>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    height:"42px",
                    lineHeight:"32px"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3 "
                >
                  Log in
                </button>
              </div>

            </form>

          </div>
          {!this.state.emailValidation &&
                <span className="red-text" style={{textAlign:"center", display:"inline-block", width:"100%", marginTop:"10px"}}>Your email id has not verified, please check your email account and verify your email.</span>
              }
        </div>
      </div>
      <p className="grey-text text-darken-1" style={{textAlign:"center"}}>
      Don't have an account? <Link to="/register">Get Started</Link>
    </p>

                  </div>
              </div>
          </div>

      </div>

    </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, logoutUser }
)(Login);
