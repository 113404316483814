import React, { Component } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios/index";

import Modal from "react-bootstrap/Modal";

class AddSkillPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skillData: { selectedCategory: "", skill: "" },
      errors: {},
      categories: [],
    };
  }

  componentDidMount = async () => {
    console.log(this.props);
    await axios
      .get("/api/categories/categorylist")
      .then((res) => {
        this.setState({
          categories:
            (res.data &&
              res.data.length &&
              res.data.filter(({ isActive }) => !!isActive)) ||
            [],
        });
      })
      .catch((err) => console.log(err));
  };

  notify = (type, message) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  findSKill = (data, category, skill) =>
    data.find(
      (e) =>
        e.categoryname === category &&
        e.skills
          .map((ele) => ele.toLowerCase())
          .includes(skill.toLowerCase().trim())
    );

  handleChange = (event) => {
    const { skillData, errors, categories } = this.state;
    const {
      target: { id, value },
    } = event;

    delete errors[id];
    if (id === "skill" && value.trim().length) {
      const exists = this.findSKill(
        categories,
        skillData.selectedCategory,
        value
      );
      if (exists) {
        errors.skill = "Skill already exists in selected Category";
      } else {
        delete errors.skill;
      }
    }

    skillData[id] = value;
    this.setState({ skillData });
  };

  validator = (data) =>
    Object.keys(data).reduce(
      (errors, item) => ({
        ...errors,
        [item]:
          (item === "skillMatrix" &&
            !data[item].length &&
            "Please select atleast one Skill") ||
          (typeof data[item] === 'string' && !data[item].trim() && "Required Field"),
      }),
      {}
    );

  submit = async () => {
    const { updatedBy, handleClose, isFreelancer } = this.props;
    const { skillData, categories } = this.state;

    const errors = this.validator(skillData);

    if (Object.values(errors).includes("Required Field")) {
      this.setState({ errors });
      return;
    }
    let response = {};
    try {
      if (isFreelancer) {
        response = await axios.post("/api/skillstoapprove/createskill", {
          updatedBy,
          category: skillData.selectedCategory,
          skill: skillData.skill.trim(),
          isApproved: false,
        });
      } else {
        const { skills: previousSkills } = categories.find(
          (e) => !!e.isActive && e.categoryname === skillData.selectedCategory
        );

        const newSkills = {
          categoryname: skillData.selectedCategory,
          skills: [
            ...previousSkills,
            skillData.skill && skillData.skill.trim(),
          ],
        };
        response = await axios.post("/api/categories/addskills", newSkills);
      }
      if (response && response.status === 200) {
        this.notify(
          "success",
          `${(isFreelancer && "Skill sent to Approval") || "Skill Added"}`
        );
      }
      handleClose();
    } catch (err) {
      this.notify(
        "error",
        "Same Skill already sent for Approval under Selected Category"
      );
    }
  };

  render() {
    const { show, handleClose } = this.props;
    const { skillData, errors, categories } = this.state;
    return (
      <>
        <Modal className="bid_proposal addSkillsMdl" show={show} onHide={handleClose}  aria-labelledby="contained-modal-title-vcenter" size="lg" centered={true}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add New Skill</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "10px" }}>
            <div className="container">
              <div className="row">
                <div
                  className="input-field col s6"
                  style={{ marginLeft: "100px" }}
                >
                  <label>Choose a Category</label>
                  <select
                    id="selectedCategory"
                    required
                    className="browser-default dropdown-sel"
                    style={{ borderRadius: "10px", height: "40px" }}
                    onChange={this.handleChange}
                    value={skillData.selectedCategory}
                  >
                    <option value="">--select--</option>
                    {categories.map((e) => (
                      <option value={e.categoryname}>{e.categoryname}</option>
                    ))}
                  </select>
                  <div className="red-text">{errors.selectedCategory}</div>
                </div>
                <div
                  className="input-field col s6"
                  style={{ marginLeft: "100px" }}
                >
                  <label>Add new Skill</label>
                  <input
                    id="skill"
                    required
                    disabled={!skillData.selectedCategory}
                    type="text"
                    onChange={this.handleChange}
                    value={skillData.skill}
                    autoComplete="off"
                  />
                  <div className="red-text">{errors.skill}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="custombtn"
              disabled={!!Object.values(errors).filter(Boolean).length}
              onClick={this.submit}
            >
              Save
            </button>
            <button className="custombtn" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
          <ToastContainer />
        </Modal>
      </>
    );
  }
}

AddSkillPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

export default AddSkillPopup;
