import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser,removeProfilePicture } from "../../actions/authActions";
import Navbar from "../layout/Navbar";
import Menu from "../dashboard/Menu";
import '../dashboard/dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SocialIcon from "../../images/team.png";
import CallIcon from "../../images/call-icon.png";
import EmailIcon from "../../images/email-icon.png";
import ImageUploader from 'react-images-upload';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import ReactTooltip from 'react-tooltip';
import axios from "../../axios/index";

import SkillCertifications from "../profile/SkillCertifications";

import AddSkillPopup from "../profile/AddSkillPopup";


class EditProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
      sameObject:false,
      selectedFile: null,
		  screenname: "",
		  first_name:"",
		  last_name:"",
		  profile_summary:"",
		  email:"",
		  profile_title:"",
	  	country:"",
	  	region:"",
	  	phone:"",
	  	zip_code:"",
		  social_links:[],
      temptask:"",
      categories: [],
      skills:[],
      categorySkills:[],
      ranking:["1","2","3","4","5","6","7","8","9","10"],
          yearsOfExp:["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"],
		  errors: {},
      skillMatrix:[],
      selectedCategory:"",
      selectedSkills:"",
      selectedRanking:"",
      selectedYearsOfExp:"",
      countriesList:[],
      filteredCountriesList:[],
      tempRow:{
        selectedCategory:"",
        selectedSkills:"",
        selectedRanking:"",
        selectedYearsOfExp:"",
      },
      userdetails:{},
      contactdetails:{},
      isSocialEmpty:false,
      isSkillEmpty:false,
      pictures: [],
      profileImage:"",
      extensionError:false,
      fileDimensionError:false,
      fileSizeError:false,
      showCountriesList:false,
      mandatoryCategory:false,
      openAddSkillPopup:false,
      customerRatings:[]

		};

     this.onDrop = this.onDrop.bind(this);
	}

  async componentDidMount(){
    console.log(this.props.auth);
        //countries list from API

   await fetch('https://restcountries.eu/rest/v2/all')
  .then(response => response.json())
  .then(data => 
      {

        this.setState({countriesList:data}, ()=> {});
        this.setState({filteredcountriesList:data}, ()=> {});

      }
    );
    const userData = {email:this.props.auth.user.email}
    await axios
    .post("/api/users/userdetails", userData)
    .then(res => {
        this.setState({ userdetails: res.data });
        this.setState({contactdetails:res.data.contactdetails});
        this.setState({ skillMatrix: res.data.skillMatrix });
        this.setState({socialProfiles:res.data.socialProfiles});

        this.setState({ 
         
          first_name: res.data.firstname,  
          last_name: res.data.lastname,
          profile_summary:res.data.profilesummary,
          profile_title:res.data.profiletitle,
          email : res.data.email,
          country:res.data.contactdetails.country,
          region:res.data.contactdetails.region,
          phone:res.data.contactdetails.phone,
          zip_code:res.data.contactdetails.zipcode,
          social_links: res.data.socialProfiles,
          skillMatrix: res.data.skillMatrix,
          profileImage:res.data.profileImage,
          categories: [],
          skills:[],
          ranking:["1","2","3","4","5","6","7","8","9","10"],
          yearsOfExp:["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"],
       }); 
    
    })
    .catch(err =>
      console.log(err)
    );

    if (this.props.auth.user.userRole === 2) {
      await axios
        .get(
          `/api/resource/freelancercustomerratings?freelancerEmail=${this.props.auth.user.email}`
        )
        .then((res) => {
          this.setState({
            customerRatings: res.data,
          });
        })
        .catch((err) => console.log(err));
    }

    // Categories

    await this.fetchCategories();




  }

   onDrop(pictureFiles, pictureDataURLs) {
        this.setState({
            pictures: pictureFiles
        });
    }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });

    }

  } 

fetchCategories = async () => {
 return axios.get("/api/categories/categorylist")
  .then(res => {
    this.setState({categories:res.data});
  })
  .catch(err =>
    console.log(err)
  );
}

getStringLength = (inputString="")=>{
  // use the \s quantifier to remove all white space
  let remText = inputString.replace(/\s/g, "");
 return  remText.length; 
}
  onChange = e => {
    const { errors }=this.state;
    const name = e.target.id;
    const value = e.target.value;
    delete errors[name];
    if(name==='profile_title'){
      if(this.getStringLength(value) > 100 || this.getStringLength(value) < 10){
        errors[name] = "Please provide Title with Minimum of 10 and Maximum of 100 characters"
      }
      else {
        delete errors[name];
      }
    }
    if(name==='profile_summary'){

      if(this.getStringLength(value) > 5000 || this.getStringLength(value) < 100){
        errors[name] = "Please provide Summary with Minimum of 100 and Maximum of 5000 characters"
      }
      else {
        delete errors[name];
      }
    }
    this.setState({ [name]: value, errors  });
  };

  categorySelectChange = e => {
    e.preventDefault();
    console.log(e.target.value);
    this.setState({selectedSkills:""})
    const targetId = e.target.id;
    this.setState({ [targetId]: e.target.value }, () => {
      const newData = {categoryname:this.state[targetId]}
      axios.post("/api/categories/getskills", newData)
      .then(res => {
          this.setState({mandatoryCategory:res.data.isMandatory});
          this.setState({categorySkills:res.data.skills}, () => {console.log(targetId, this.state.categorySkills)})
          document.getElementById("selectedSkills").value="";
      })
      .catch(err =>
        console.log(err)
      );

    });
  };

  skillSelectChange = e => {
    e.preventDefault();
    const targetId = e.target.id;
    this.setState({ [targetId]: e.target.value }, () => {console.log(targetId, this.state[targetId])});
  };
  selectChange = e => {
    e.preventDefault();
    const targetId = e.target.id;
    this.setState({ [targetId]: e.target.value }, () => {console.log(targetId, this.state[targetId])});
  };

  updateTask = (e) => {
    e.persist();
    this.setState({ temptask: e.target.value } );
  }

  addTask = e => {
    e.preventDefault();
    if (this.state.temptask === "") {
      this.setState({isSocialEmpty:true});
    }
    else{
      this.setState({isSocialEmpty:false});
      this.setState({ social_links: [...this.state.social_links, this.state.temptask]}, () => {document.getElementById('social_links').value=""; console.log(this.state.social_links);});      
      this.setState({temptask:""});
    }

  }

  addRow = e => {
    
    e.preventDefault();

    if(this.state.selectedCategory=== "" || this.state.selectedSkills==="" || this.state.selectedRanking==="" || this.state.selectedYearsOfExp==="") {
        this.setState({isSkillEmpty:true});
    }
    else {
      this.setState({isSkillEmpty:false});
      var newObj = {"selectedCategory":this.state.selectedCategory, "selectedSkills":this.state.selectedSkills,"selectedRanking":this.state.selectedRanking,"selectedYearsOfExp":this.state.selectedYearsOfExp, "isMandatoryCategory":this.state.mandatoryCategory};
      var list = this.state.skillMatrix;
      var newlist = JSON.stringify(list);
      var currObjCategory = JSON.stringify(newObj.selectedCategory);
      var currObjSkill = JSON.stringify(newObj.selectedSkills);

      console.log(newlist.indexOf(currObjCategory, currObjSkill));
       if (newlist.indexOf(currObjCategory) !== -1 && newlist.indexOf(currObjSkill) !== -1) {
            this.setState({sameObject:true});
        }else {
          this.setState({sameObject:false});
          this.setState({skillMatrix:[...this.state.skillMatrix,{"selectedCategory":this.state.selectedCategory, "selectedSkills":this.state.selectedSkills,"selectedRanking":this.state.selectedRanking,"selectedYearsOfExp":this.state.selectedYearsOfExp, "isMandatoryCategory":this.state.mandatoryCategory }]}, () => {console.log(this.state.skillMatrix)});
        }
    }
  }

  delRow=(event,idx)=>{  
    event.preventDefault();
    var array = [...this.state.skillMatrix];
    var index = idx;
    array.splice(index, 1);
    this.setState({skillMatrix: array});
  }

  validator = (data, prevErrors) =>
  Object.keys(data).reduce(
    (errors, item) => ({
      ...errors,
      [item]: (!data[item] && "Required Field") || prevErrors[item],
    }),
    {}
  );

  onSubmit = e => {
    const { errors }= this.state;
    e.preventDefault();
    const objToValidate = {
      first_name:this.state.first_name,
		  last_name:this.state.last_name,
		  profile_summary:this.state.profile_summary,
		  profile_title:this.state.profile_title,
		  skillMatrix:this.state.skillMatrix,
      country:this.state.country,
      region:this.state.region,
      phone:this.state.phone,
      zip_code:this.state.zip_code
    }
    const validationErrors = this.validator(objToValidate, errors);

    if(!this.state.skillMatrix.length){
      errors['skillMatrix'] = 'Please select atleast one skill'
      this.setState({errors});
    }

    if (Object.values(validationErrors).filter(Boolean).length) {
      this.setState({ errors: validationErrors });
      return;
    }

    const newUser = {
		  firstname:this.state.first_name,
		  lastname:this.state.last_name,
		  profilesummary:this.state.profile_summary,
		  email:this.props.auth.user.email,
		  profiletitle:this.state.profile_title,
      socialProfiles: this.state.social_links,
      skillMatrix: this.state.skillMatrix,
		  contactdetails: {
		  	country:this.state.country,
		  	region:this.state.region,
		  	phone:this.state.phone,
		  	zipcode:this.state.zip_code
		  }
    };

    console.log(newUser);
    this.props.updateUser(newUser, this.props.history);
  };

  onChangeHandler=event=>{
      this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    }, () => {

    var ext =  this.state.selectedFile.name.split('.').pop();

    var fileSize =  this.state.selectedFile.size;

    if(ext !== "png" && ext !== "jpg" && ext !== "jpeg" && ext !== "gif") {

      this.setState({extensionError:true})
      this.setState({fileSizeError:false})
      this.setState({fileDimensionError:false})
    }else{
      this.setState({extensionError:false})
      this.setState({fileSizeError:false})

      var file = this.state.selectedFile;
        if( file ) {
            var img = new Image();
            img.src = window.URL.createObjectURL( file );

            img.onload = () =>  {
                var width = img.naturalWidth,
                    height = img.naturalHeight;

                window.URL.revokeObjectURL( img.src );

                if (width > 2000 || height > 2000) {

                  this.setState({fileDimensionError:true})
                }
                else {
                  
                  this.setState({fileDimensionError:false});

                  if (fileSize > 2000000) {
                    this.setState({fileSizeError:true})
                  }
                  else {
                  this.setState({fileSizeError:false})
                  const data = new FormData()
                     data.append('email',this.state.email)
                     data.append('file', this.state.selectedFile)
                     axios.post("/api/users/upload", data, { 
                    }).then(res => {
                     this.setState({profileImage:res.data});
                     
                   })

                  }
                }
                
            };
        }

      this.setState({extensionError:false})
      
    }

    })
  }

  removeProfilePic = event => {

    event.preventDefault();
    // this.setState({profileImage:""})
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure to remove profile picture.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

            const newUser = {email:this.props.auth.user.email};

            this.props.removeProfilePicture(newUser, this.props.history);

            this.setState({profileImage:""})

          }
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });

  }

  handleClickDel=(event, idx)=>{
    event.preventDefault();
    var array = [...this.state.social_links];
    var index = idx;
    array.splice(index, 1);
    this.setState({social_links: array});

  }

  handleCountryKeyUp =(event) => {
    event.preventDefault();
    const str = event.target.value;
    var filtered = this.state.countriesList.filter(item => item.name.toLowerCase().includes(str.toLowerCase()));
    this.setState({filteredcountriesList:filtered});
  }

  handleCountryDropdown=(event,idx) => {
    event.preventDefault();
    this.setState({country: idx});
    this.setState({showCountriesList: false});
  }

  changeShowCountryList = () =>{

    this.setState({showCountriesList: true});
  }

  addSkill = (e) => {
    e.preventDefault();
    this.setState({ openAddSkillPopup: true });
  };

  closePopup = async () => {
    await this.fetchCategories();
    this.setState({ openAddSkillPopup: false });
  };

  render() {
    const { user } = this.props.auth;
    const userDetails = this.state.userdetails;
    const contactdetails = this.state.contactdetails;
    const skillMatrix = this.state.skillMatrix;
    const CategoriesLi = this.state.categories;
    const CategoriesList = CategoriesLi.filter(category => category.isActive === true);
    const CategorySkills = this.state.categorySkills;
    const socialProfiles = this.state.socialProfiles;
    const isSocialEmpty = this.state.isSocialEmpty;
    const isSkillEmpty = this.state.isSkillEmpty;
    var profilePic = this.state.profileImage;
    if(profilePic !== undefined){
      if (profilePic.length > 0) {
         profilePic = this.state.profileImage.replace('public','');
      }
    }
    const { openAddSkillPopup, errors , customerRatings} = this.state;

    
    const customersWhoRated = customerRatings.filter((e) => e.rating);

    const totalRating = `${customersWhoRated.reduce(
      (x, y, i, arr) => x + y.rating / arr.length,
      0
    )}`;
    const starArray = [...Array(5).keys()];
    
    const { email, userRole } = user;
    const isFreelancer = !!(userRole === 2);
    console.log(errors)
    return (
      <>
      <Navbar />
      <div className="container">
              <div className="row" style={{marginBottom:"0px"}}>
              <div className="container grid-container" style={{marginTop:"60px"}}>
                <div className="col s12">
                  <h4 style={{marginTop:"10px"}}>Edit Profile</h4>
                </div>
              </div>
          </div>
      <div className="row">
    <form className="col s12"  style={{marginTop:"120px"}} noValidate onSubmit={this.onSubmit}>
     <div className="row">
    <div className="col s12 module">
      <div className="row profile-container">

        <div className="input-field col s12 profile-pic-container">
        {this.state.profileImage ? 
            <img src={`https://devfreelancersapi.supportpod.com/${profilePic}`} id="profile-pic" />
            :null
          }
        <input type="file" name="file" title="Update profile picture" onChange={this.onChangeHandler } style={{width : "100%", height : "100%", position:"absolute", zIndex:101}} />
        
        <button className="right edit-btn" title="Update profile picture"></button>
        {this.state.profileImage ? <button className="right del-btn" onClick={this.removeProfilePic}  title="Remove profile picture"></button> :null}
        </div>
        <div className="profile-intro-text">These details will be provide to our Service Managers and Potential Clients you need to fully complete your profile so we can allocate leads for you and/or your company to place bids.</div>
        {this.state.extensionError &&

          <p className="extension-error red-text">Please upload a valid image<br/>(png, jpg, jpeg, gif)</p>
        } 
        {this.state.fileDimensionError &&

          <p className="extension-error red-text">Please select a image below 2000 px</p>
        } 
        {this.state.fileSizeError &&

          <p className="extension-error red-text">Maximum file size allowed is 2MB</p>
        } 

        <div className="input-field col s12 profile-pic-screenname">
			 <h5 style={{textTransform:"uppercase"}}>{userDetails.screenname}</h5>
        </div>
      </div>

      <div className="row">
        <div className="input-field col s6">
          <input id="first_name" type="text" className="validate" onChange={this.onChange}
                  value={this.state.first_name} />
                 
          <label htmlFor="first_name" className="active">First Name <span className="red-text">*</span></label>
          <span className="red-text">{errors.first_name}</span>

        </div>

        <div className="input-field col s6">
          <input id="last_name" type="text" className="validate" onChange={this.onChange}
                  value={this.state.last_name}  />
                 
          <label htmlFor="last_name" className="active">Last Name <span className="red-text">*</span></label>
          <span className="red-text">{errors.last_name}</span>

        </div>
      </div>

      <div className="row">
        <div className="input-field col s12">
        <div className="profile-summary-textarea">
          <textarea id="profile_summary" className="materialize-textarea" onChange={this.onChange}
                  value={this.state.profile_summary}
                  ></textarea>
        </div>
          <label htmlFor="profile_summary" className="active">Profile Summary <span className="red-text">*</span></label>
          <span className="red-text">{errors.profile_summary}</span>
        </div>
      </div>
          <div className="row">
        <div className="input-field col s6">
          <input id="email" type="email" className="validate" value={this.props.auth.user.email} readOnly/>
          <label htmlFor="email" className="active">Email <span className="red-text">*</span></label>
        </div>
         <div  id="skills-table"></div>
        <div className="input-field col s6">
          <input id="profile_title" type="text" className="validate" onChange={this.onChange}
                  value={this.state.profile_title} />
          <label htmlFor="profile_title" className="active">Profile Title <span className="red-text">*</span> <span data-tip="Ex : UI Developer / Full Stack Developer / Solution Architect "><FontAwesomeIcon icon={faInfoCircle} /></span> </label>
          <span className="red-text">{errors.profile_title}</span>

        </div>
      </div>
    </div>
      </div>
      {this.props.auth.user.userRole === 2 || 1  && customersWhoRated.length === 0 ? null :  customersWhoRated.length && (
            <div className="row">
              <div className="col s12 module ratingblock">
                <h6 className="ratingHead">Customer Ratings</h6>
                {starArray.map((e) => (
                  <span className="starIcon">
                    <span
                      style={{
                        width:
                          (+totalRating.slice(0, 1) === e &&
                            totalRating % 1 !== 0 &&
                            `${(totalRating % 1) * 100}%`) ||
                          "100%",
                      }}
                      className={`star ${(e < +totalRating && "activeStar") ||
                        ""}`}
                    >
                      &#9733;
                    </span>
                    &#9733;
                  </span>
                ))}
                <h6>{`Average Rating : ${Number(totalRating).toFixed(
                  1
                )} out of 5 from ${customersWhoRated.length} review(s).`}</h6>
                <hr style={{ border: "3px solid #f1f1f1" }} />
                <div className="customerRatings">
                  {customersWhoRated.map((review) => (
                    <>
                      <span className="ratingCustName">
                        {review.customerName}
                      </span>
                      {starArray.map((e) => (
                        <span className="starIcon">
                          <span
                            style={{
                              width:
                                (+review.rating.slice(0, 1) === e &&
                                  review.rating % 1 !== 0 &&
                                  `${(review.rating % 1) * 100}%`) ||
                                "100%",
                            }}
                            className={`star ${(e < +review.rating &&
                              "activeStar") ||
                              ""}`}
                          >
                            &#9733;
                          </span>
                          &#9733;
                        </span>
                      ))}
                      <p>{review.remarks}</p>

                      <hr style={{ border: "1px solid #f1f1f1" }} />
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}


    <div className="row">
      <div className="col s12 module">
      <div className="add_skills">
                          <button
                            className="custombtn"
                            style={{ width: "20%" }}
                            onClick={(e) => this.addSkill(e)}
                          >
                            + Add New Skills
                          </button>
                        </div>
      {isSkillEmpty ?
      <div className="red-text">Please select all the options</div>
      :null}
      {this.state.sameObject && <div className="red-text">Skill set already added</div>}
      <div className="skills-table">
       <table className="striped">
        <thead>
          <tr>
              <th>
              <div className="input-field">
				      <select  id="selectedCategory" className="browser-default" onChange={this.categorySelectChange}>
				      <option value="" disabled selected>Category</option>
				        {CategoriesList.map(function(d, idx){
                  return (<option key={idx} value={d.categoryname}>{d.categoryname}{d.isMandatory && '*'}</option>)
                })}
				      </select>
				      </div>
              </th>
              <th>
              <div className="input-field">
             
			    <select  id="selectedSkills" className="browser-default" onChange={this.skillSelectChange}>
			      <option value="" disabled selected>Skills</option>
            {CategorySkills.map(function(d, idx){
                  return (<option key={idx}>{d}</option>)
                })}
			    </select>
			  </div>
              </th>
              <th>
              <div className="input-field">
			    <select  id="selectedRanking" className="browser-default" onChange={this.selectChange}>
			      <option value="" disabled selected>Ranking</option>
			      {this.state.ranking.map(function(d, idx){
                  return (<option key={idx}>{d}</option>)
                })}
			    </select>
			  </div>
              </th>
              <th>
          <div className="input-field">
				    <select  id="selectedYearsOfExp" className="browser-default" onChange={this.selectChange}>
				      <option value="" disabled selected>Years of Exp</option>
				      {this.state.yearsOfExp.map(function(d, idx){
                  return (<option key={idx}>{d}</option>)
                })}
				    </select>
				  </div>
              </th>
              <th><button onClick={this.addRow} className="add-btn"> + </button> </th>
          </tr>
        </thead>
        <tbody>
          {(this.state.skillMatrix.length > 0) &&
            <tr>
              <th>Categories</th>
              <th>Skills</th>
              <th>Ranking</th>
              <th>Years of Exp</th>
              <th></th>
            </tr>
          }
          {this.state.skillMatrix.map(function(d, idx){
            return (<tr key={idx}><td>{d.selectedCategory}</td><td>{d.selectedSkills}</td><td>{d.selectedRanking}</td><td>{d.selectedYearsOfExp}</td><td>{(d.supportpodRating < 1 || d.supportpodRating === undefined) && <button className="del-row" onClick={(event) => this.delRow(event, idx)}>Delete</button> }</td></tr>)
          }.bind(this))}
        </tbody>
      </table>
      </div>
      <div className="blue-text" style={{marginTop:"10px"}}>* Marked are the mandatory categories, please add at least one skill from all the mandatory categories</div>
       <div id="certifications"></div>       
       <span className="red-text">{errors.skillMatrix}</span>
          </div>
        </div>

        <SkillCertifications />
        <div id="contactDetails"></div>
       <div className="row" >
    <div className="col s12 module">
    <h4>Contact Details</h4>
      <div className="row">
        <div className="input-field col s6">
          <input id="country" type="text" className="validate" onKeyUp={(event) => this.handleCountryKeyUp(event)}  onClick={this.changeShowCountryList} onChange={this.onChange} autoComplete="off"
                  value={this.state.country} />
           {this.state.showCountriesList && 
            <div className="countries-list">
              <ul>
                  {this.state.filteredcountriesList.map(function(d, idx){
                      return (<li value={d.name} onClick={(event) => this.handleCountryDropdown(event, d.name)}>{d.name}</li>)
                  }.bind(this))}

              </ul>
            </div>
          }

          <label htmlFor="country" className="active">Country <span className="red-text">*</span></label>
          <span className="red-text">{errors.country}</span>

        </div>

        <div className="input-field col s6">
          <input id="region" type="text" className="validate" onChange={this.onChange} autoComplete="off"
                  value={this.state.region}
                  />
          <label htmlFor="region" className="active">City <span className="red-text">*</span></label>
          <span className="red-text">{errors.region}</span>

        </div>
      </div>



	  <div className="row">
        <div className="input-field col s6">
          <input id="phone" type="text" className="validate" onChange={this.onChange}
                  value={this.state.phone} autoComplete="off"
                  />
          <label htmlFor="phone" className="active">Phone <span className="red-text">*</span></label>
          <span className="red-text">{errors.phone}</span>

        </div>

        <div className="input-field col s6">
          <input id="zip_code" type="text" className="validate" onChange={this.onChange}
                  value={this.state.zip_code} autoComplete="off"
                  />
          <label htmlFor="zip_code" className="active">Zip Code <span className="red-text">*</span></label>
          <span className="red-text">{errors.zip_code}</span>

        </div>
      </div>
    </div>
    </div>

 <div className="row">
    <div className="col s12 module">
    	<h4 className="left">Social Media Links </h4> <span style={{position : "relative", top : "25px", left:"10px"}} data-tip="Ex: https://www.linkedin.com/profilename"><FontAwesomeIcon icon={faInfoCircle} /></span>
	  <div className="row" style={{marginBottom:"0px"}}>
        <div className="input-field col s10">
          <input type="text" onChange={this.updateTask} id="social_links"/>
        </div>
        <div className="input-field col s2 center">
        	<button onClick={this.addTask} className="add-btn" style={{marginTop:"7px"}} >+</button>
        </div>
      </div>
      {isSocialEmpty ?
      <div className="red-text">Please enter the details</div>
      :null}
    <div className="row">
        <div className="input-field col s12" style={{marginTop:"0px"}}>
          <ul style={{marginTop:"0px"}}>
            {this.state.social_links.map(function(d, idx){
               return (<li key={idx}><img src={SocialIcon} alt="Social Icon" style={{width:"18px", position:"relative", top: "3px"}} /> &nbsp; {d} <button className="del-row" onClick={(event)=>this.handleClickDel(event,idx)} style={{position:"relative",top:"-3px",left:"5px"}}>Delete</button></li>)
             }.bind(this))}
          </ul>
        </div>
      </div>
    </div>
</div>
    <div className="col s12 center" style={{ paddingLeft: "11.250px" }}>
      <button
        style={{
          width: "100%",
          borderRadius: "20px",
          letterSpacing: "1.5px",
          marginTop: "1rem",
          maxWidth : "240px",
          marginLeft :"auto",
          marginRight :"auto"
        }}
        type="submit"
        className="btn btn-large waves-effect waves-light hoverable black accent-3"
      >
        Save & Next
      </button>
    </div>
    </form>
    </div>
  </div>
  <ReactTooltip />
  {this.state.showCountriesList &&
    <div onClick={() => this.setState({showCountriesList:false})} className="overlay" style={{position:"fixed", top:"0px", right:"0px", bottom:"0px", left:"0px", height:"100%",width:"100%", backgroundColor: "rgba(0,0,0,0.01)", zIndex : "97"}}> </div>
  }
  {openAddSkillPopup && (
          <AddSkillPopup
            show={openAddSkillPopup}
            handleClose={this.closePopup}
            updatedBy={email}
            isFreelancer={isFreelancer}
          />
        )}


      </>
    );
  }
}

EditProfile.propTypes = {
  updateUser: PropTypes.func.isRequired,
  removeProfilePicture: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateUser, removeProfilePicture  }
)(EditProfile);