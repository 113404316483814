import React from 'react';
import './customers.css'

const Modal = props => {
    return (
        <div
            className="Modal"
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? 1 : 0
            }}
        >
            {props.children}
            <button className="btn black right" onClick={props.hideModal}>Close</button>
        </div>
    );
};


export default Modal;