import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setScreenName } from "../../actions/authActions";
import classnames from "classnames";
import "./auth.css";
import logo from "../layout/logo.png";

class ScreenName extends Component {
  constructor() {
    super();
    this.state = {
      screenname: "",
      email: "",
      errors: {}
    };
  }

   componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      console.log(this.props.auth.user.email);
      this.setState({ email: this.props.auth.user.email});
    }
     
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newUser = {
      screenname: this.state.screenname,
      email: this.state.email
    };

    localStorage.setItem('screenName', newUser.screenname);

   this.props.setScreenName(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace"
              }}
              className="col s5 brand-logo center black-text"
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>
      </div>
      <div className="container auth">
        <div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4 style={{ fontSize: "28px" }}>Choose Screen Name</h4>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.screenname}
                  error={errors.screenname}
                  id="screenname"
                  type="text"
                  className={classnames("", {
                    invalid: errors.screenname
                  })}
                />
                <label htmlFor="name">Screen Name</label>
                <span className="red-text">{errors.screenname}</span>
              </div>


              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </>
    );
  }
}

ScreenName.propTypes = {
  setScreenName: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { setScreenName }
)(withRouter(ScreenName));
