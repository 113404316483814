import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";

import { loginUser } from "../../../actions/authActions";
import { connect } from "react-redux";
import Navbar from "../../layout/Navbar";
import {
  getItemCodes,
  createQuote,
  createQuoteInSupportProd,
  getServiceRequests,
  getBids,
  // getCustomerContacts,
} from "../Services";
import { quoteValidator, lineItemsValidator } from "../Validators";
import {
  QUOTE_ID,
  QUOTE_NUM,
  SERV_REQ_ID,
  BID_ID,
  REF,
  T_AND_C,
  DATE,
  EXPIRY_DATE,
  SUB_TOTAL,
  TAX,
  TOTAL,
  TITLE,
  SUMMARY,
  CUST_NAME,
  CUST_EMAIL,
  IS_CUST_EXIST,
  DESC,
  ITEM_CODE,
  UNIT_AMT,
  QUANTITY,
  LINE_AMT,
  LINE_ITEMS,
  REQ_FIELD,
  EMAIL_ADD,
} from "../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../Common/LoadingSpinner";

class QuoteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        [LINE_ITEMS]: [],
      },
      quoteData: {
        [QUOTE_NUM]: "",
        [SERV_REQ_ID]: "",
        [BID_ID]: "",
        [REF]: "",
        [T_AND_C]: "",
        [DATE]: "",
        [EXPIRY_DATE]: "",
        [SUB_TOTAL]: 0.0,
        [TAX]: "",
        [TOTAL]: 0.0,
        [TITLE]: "",
        [SUMMARY]: "",
        [CUST_NAME]: "",
        [CUST_EMAIL]: "",
        [IS_CUST_EXIST]: false,
        [LINE_ITEMS]: [],
      },
      itemCodes: [],
      serviceRequests: [],
      bids: [],
      customers: [],
      loading: false,
      showBidTooltip: false,
    };
  }

  componentDidMount = async () => {
   
    const obj = this.props.location.state;

    if (obj) {
      const { quoteData } = this.state;
      this.setState(
        {
          quoteData: {
            ...quoteData,
            ...obj,
            customername: obj.customerName,
            customeremail: obj.customerEmail,
            Reference: obj.serviceRequestId,
            bidId: obj._id,
          },
        },
        () => {
          console.log("quote data here", this.state.quoteData);
          console.log(this.state.quoteData.bidId);
        }
      );
    }
  };

  notify = (type, message) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  fetchItemCodes = async () => {
    this.setState({ loading: true });
    const response = await getItemCodes();
    if (response && response.data && response.status === 200) {
      this.setState({ itemCodes: response.data });
    } else {
      this.notify("error", "failed to fetch quotes");
    }
    this.setState({ loading: false });
  };

  // fetchCustomerContacts = async () => {
  //   this.setState({ loading: true });
  //   // const response = await getCustomerContacts();
  //   // if (response && response.data && response.status === 200) {
  //   //   this.setState({ customers: response.data });
  //   // } else {
  //   //   this.notify("error", "failed to fetch customers");
  //   // }
  //   // this.setState({ loading: false });
  // };

  fetchServiceRequests = async (email) => {
    // const { auth: { user: { email } = {} } = {} } = this.props;
    this.setState({ loading: true });
    const response = await getServiceRequests(email);
    if (response && response.data && response.status === 200) {
      console.log(response.data);
      this.setState({ serviceRequests: response.data });
    } else {
      this.notify("error", "Failed to fetch Service Requests");
    }
    this.setState({ loading: false });
  };

  fetchBids = async (serviceRequestId) => {
    this.setState({ loading: true });
    const response = await getBids(serviceRequestId);
    if (response && response.data && response.status === 200) {
      console.log("bidproposals", response.data);
      this.setState({ bids: response.data });
    } else {
      this.notify("error", "Failed to fetch Bids ");
    }
    this.setState({ loading: false });
  };

  addLineItem = () => {
    this.fetchItemCodes();
    const { quoteData, errors } = this.state;
    quoteData[LINE_ITEMS].push({
      [ITEM_CODE]: "",
      [DESC]: "",
      [UNIT_AMT]: 0.0,
      [QUANTITY]: "",
      [LINE_AMT]: 0.0,
    });
    errors[LINE_ITEMS].push({
      [ITEM_CODE]: "",
      [DESC]: "",
      [UNIT_AMT]: "",
      [QUANTITY]: "",
      [LINE_AMT]: "",
    });
    this.setState({ quoteData, errors });
  };

  removeLineItem = (idx) => {
    const { quoteData, errors } = this.state;
    quoteData[LINE_ITEMS].splice(idx, 1);
    errors[LINE_ITEMS].splice(idx, 1);

    quoteData[SUB_TOTAL] = quoteData[LINE_ITEMS].reduce(
      (x, y) => x + +y[LINE_AMT],
      0
    ).toFixed(2);
    quoteData[TOTAL] = (+quoteData[TAX] + +quoteData[SUB_TOTAL]).toFixed(2);

    this.setState({ quoteData, errors });
  };

  handleChange = (event) => {
    const { quoteData, errors, customers } = this.state;
    const { target: { value, id: name } = {} } = event;
    delete errors[name];
    if (name === TAX) {
      const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
      if (!regex.test(value)) {
        errors[name] = "Please enter a valid Positive Number";
      }
      quoteData[TOTAL] = (+quoteData[SUB_TOTAL] + +value).toFixed(2);
      quoteData[name] = +value;
    }
    if (name === CUST_NAME) {
      delete errors[EMAIL_ADD];
      quoteData[name] = value;
      if (value) {
        quoteData[CUST_EMAIL] = customers.find(
          (e) => e.CustomerName === value
        ).EmailAddress;
        this.fetchServiceRequests(quoteData[CUST_EMAIL]);
      }
    }

    if (name === SERV_REQ_ID) {
      quoteData[name] = value;
      quoteData[REF] = value;
      // this.fetchBids(value);
    } else {
      quoteData[name] = value;
    }

    this.setState({ quoteData });
  };

  handleDateChange = (name) => (value) => {
    const { quoteData, errors } = this.state;
    delete errors[name];
    quoteData[name] = value;
    if (name === DATE) {
      if (new Date(value) > new Date(quoteData[EXPIRY_DATE])) {
        errors[name] = "Created date is greater than Expiry date";
      } else {
        delete errors[EXPIRY_DATE];
        quoteData[name] = value;
      }
    }
    if (name === EXPIRY_DATE) {
      if (new Date(value) < new Date(quoteData[DATE])) {
        errors[name] = "Expiry date is lesser than Created date";
      } else {
        delete errors[DATE];
        quoteData[name] = value;
      }
    }
    this.setState({ quoteData });
  };

  handleLineItemChange = (idx) => (event) => {
    const { quoteData, errors } = this.state;
    const {
      target: { value, id: name },
    } = event;
    delete errors[LINE_ITEMS][idx][name];

    if (name === ITEM_CODE) {
      const { itemCodes } = this.state;
      const { UnitPrice } = itemCodes.find((e) => e.Code === value);
      quoteData[LINE_ITEMS][idx][UNIT_AMT] = (
        Math.round(UnitPrice * 100) / 100
      ).toFixed(2);
      quoteData[LINE_ITEMS][idx][LINE_AMT] =
        +UnitPrice * quoteData[LINE_ITEMS][idx][QUANTITY];
    }
    if (name === QUANTITY) {
      const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
      if (!regex.test(value)) {
        errors[name] = "Please enter a valid Positive Number";
      }
      quoteData[LINE_ITEMS][idx][QUANTITY] = +value;
      quoteData[LINE_ITEMS][idx][LINE_AMT] = (
        quoteData[LINE_ITEMS][idx][UNIT_AMT] *
        quoteData[LINE_ITEMS][idx][QUANTITY]
      ).toFixed(2);
    }

    if (name === QUANTITY || name === TOTAL) {
      delete errors[LINE_ITEMS][idx][name];
      if (+value < 0) {
        errors[LINE_ITEMS][idx][name] = "No negative values allowed";
      }
    }

    quoteData[LINE_ITEMS][idx][name] =
      (typeof +value === "number" && +value) || value;
    quoteData[SUB_TOTAL] = quoteData[LINE_ITEMS].reduce(
      (x, y) => x + +y[LINE_AMT],
      0
    ).toFixed(2);
    quoteData[TOTAL] = (+quoteData[TAX] + +quoteData[SUB_TOTAL]).toFixed(2);
    this.setState({ quoteData });
  };

  submitQuote = async () => {
    const { auth: { user: { email } = {} } = {} } = this.props;
    this.setState({ loading: true });
    const { quoteData } = this.state;
    const errors = quoteValidator(quoteData, [
      LINE_ITEMS,
      TAX,
      SUB_TOTAL,
      TOTAL,
      IS_CUST_EXIST,
    ]);
    errors[LINE_ITEMS] = lineItemsValidator(quoteData[LINE_ITEMS], [
      UNIT_AMT,
      LINE_AMT,
      BID_ID,
    ]);
    this.setState({ errors });
    if (!quoteData[LINE_ITEMS].length) {
      this.notify("warning", "Please Select atleast one line item");
      this.setState({ loading: false });
      return;
    }
    const errorInLineItems = errors[LINE_ITEMS].map(
      (lineItem) => Object.values(lineItem).filter(Boolean).length
    ).filter(Boolean).length;

    const { SendQuoteLineitems, ...errorsToBeChecked } = errors;
    if (
      Object.values(errorsToBeChecked).filter(Boolean).length ||
      errorInLineItems
    ) {
      this.setState({ loading: false });
      return;
    }

    const { serviceRequestId, bidId, ...reqBodyForPostQuote } = quoteData;

    const [formattedDate] = new Date(quoteData[DATE]).toJSON().split("T");
    const [formattedExpDate] = new Date(quoteData[EXPIRY_DATE])
      .toJSON()
      .split("T");

    this.setState({ loading: true });
    const response = await createQuote({
      objXeroQuote: {
        ...reqBodyForPostQuote,
        [QUOTE_NUM]: `${quoteData[QUOTE_NUM]}_${quoteData[BID_ID]}`.trim(),
        [TITLE]: quoteData[TITLE].trim(),
        [SUMMARY]: quoteData[SUMMARY].trim(),
        [T_AND_C]: quoteData[T_AND_C].trim(),
        [DATE]: formattedDate,
        [EXPIRY_DATE]: formattedExpDate,
        [SUB_TOTAL]: (Math.round(+quoteData[SUB_TOTAL] * 100) / 100).toFixed(2),
        [TAX]: (Math.round(+quoteData[TAX] * 100) / 100).toFixed(2),
        [TOTAL]: (Math.round(+quoteData[TOTAL] * 100) / 100).toFixed(2),
        [IS_CUST_EXIST]: true,
      },
    }).catch((err) => {
      this.notify("error", "failed to create quote");
    });

    if (response && response.data && response.status === 200) {
      this.setState({ itemCodes: response.data });
      const [reqBodyForSuppProd] = response.data;

      const supportProdResponse = await createQuoteInSupportProd({
        quoteId: reqBodyForSuppProd[QUOTE_ID],
        quoteNumber: reqBodyForSuppProd[QUOTE_NUM],
        serviceManagerEmailId: email,
        serviceRequestId: quoteData[SERV_REQ_ID],
        bidId: quoteData[BID_ID] || quoteData[REF],
        customerEmailId: quoteData[CUST_EMAIL],
      });
      if (
        supportProdResponse &&
        supportProdResponse.data &&
        supportProdResponse.status === 200
      ) {
        this.notify("success", "Quote was successfully created");
        this.props.history.push("/quotes");
      } else {
        this.notify("error", "failed to create quote in Support Prod");
      }
    }
    this.setState({ loading: false });
  };

  closeQuote = () => {
    this.props.history.push("/quotes");
  };

  addCustomer = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push("/add-customers");
  };

  render() {
    const {
      quoteData,
      errors,
      itemCodes,
      loading,
      serviceRequests,
      bids,
      customers,
    } = this.state;
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        {(loading && <LoadingSpinner />) || (
          <div className="container ">
            <div style={{ paddingTop: "40px" }}>
              <span>
                <h4>Quote Details</h4>
              </span>
            </div>
            <div className="quoteform">
              <div className="row">
                {/* <div className="add_customer">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.addCustomer(e)}
                    href
                  >
                    + Add New Customer
                  </a>
                </div> */}
                <div className="input-field col s6">
                  <input
                    id={CUST_NAME}
                    required
                    disabled
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[CUST_NAME]}
                  />
                  <label className="active">
                    Customer Name<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[CUST_NAME]}</div>
                </div>

                <div className="input-field col s6">
                  <input
                    id={CUST_EMAIL}
                    required
                    disabled
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[CUST_EMAIL]}
                  />
                  <label className="active">Customer Email</label>
                  <div className="red-text">{errors[CUST_EMAIL]}</div>
                </div>
              </div>
              <div className="row">
                <div
                  className="input-field col s3"
                  style={{ marginTop: "20px" }}
                >
                  <input
                    id={QUOTE_NUM}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[QUOTE_NUM]}
                  />
                  <label className="active">
                    Quote Number<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[QUOTE_NUM]}</div>
                </div>
                <div
                  className="input-field col s3"
                  style={{ marginTop: "20px" }}
                >
                  <input
                    id={BID_ID}
                    required
                    disabled
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[BID_ID]}
                  />
                  <label className="active">
                    Bid Proposal ID<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[BID_ID]}</div>
                </div>
                <div className="input-field col s6">
                  <input
                    id={SERV_REQ_ID}
                    required
                    disabled
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[SERV_REQ_ID]}
                    style={{ background: "none" }}
                  />
                  <label className="active">
                    Service Req ID<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[SERV_REQ_ID]}</div>
                </div>
              </div>
              {/* <div className="row">
                <div className="input-field col s6 ">
                  <select
                    id={BID_ID}
                    required
                    disabled={!quoteData[SERV_REQ_ID]}
                    className="browser-default dropdown-sel"
                    style={{ borderRadius: "20px", height: "55px" }}
                    onChange={this.handleChange}
                    value={quoteData[BID_ID]}
                  >
                    <option value="">-- select --</option>
                    {bids.map((e) => (
                      <option value={e.bidProposalId}>{e.bidProposalId}</option>
                    ))}
                  </select>
                  <label className="active">
                    Bid ID<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[BID_ID]}</div>
                </div>

                <div className="input-field col s6">
                  <input
                    id={REF}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[REF]}
                  />

                  <label className="active">
                    Ref<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[REF]}</div>
                </div>
              </div>
               */}
              <div className="row">
                <div className="input-field col s12">
                  <input
                    id={TITLE}
                    required
                    type="text"
                    onChange={this.handleChange}
                    value={quoteData[TITLE]}
                  />
                  <label className="active">
                    Title<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[TITLE]}</div>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <DatePicker
                    calendarClassName="input-field"
                    id={DATE}
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    selected={quoteData[DATE]}
                    onChange={this.handleDateChange(DATE)}
                    className="datepicker"
                    autoComplete="off"
                  />
                  <label className="active">
                    Created Date<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[DATE]}</div>
                </div>
                <div className="input-field col s6">
                  <DatePicker
                    calendarClassName="input-field"
                    id={EXPIRY_DATE}
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    selected={quoteData[EXPIRY_DATE]}
                    onChange={this.handleDateChange(EXPIRY_DATE)}
                    className="datepicker"
                    autoComplete="off"
                  />
                  <label className="active">
                    Expiry Date<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[EXPIRY_DATE]}</div>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <textarea
                    id={SUMMARY}
                    required
                    placeholder="Write a Summary..."
                    className=" materialize-textarea m-0 "
                    onChange={this.handleChange}
                    value={quoteData[SUMMARY]}
                  ></textarea>
                  <label className="active">
                    Summary<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[SUMMARY]}</div>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <textarea
                    id={T_AND_C}
                    required
                    placeholder="Describe Terms & Conditions..."
                    className=" materialize-textarea m-0 "
                    onChange={this.handleChange}
                    value={quoteData[T_AND_C]}
                  ></textarea>
                  <label className="active">
                    Terms & Conditions<span className="red-text">*</span>
                  </label>
                  <div className="red-text">{errors[T_AND_C]}</div>
                </div>
              </div>
              <div className="row">
                <button
                  className="custombtn"
                  style={{ width: "120px" }}
                  onClick={this.addLineItem}
                >
                  + Add Line Item
                </button>
              </div>
              {(quoteData.SendQuoteLineitems.length && (
                <div
                  className="row line-items"
                  style={{ border: "1px solid #acacac", borderRadius: "15px" }}
                >
                  <h6 style={{ marginBottom: "30px", marginLeft: "10px" }}>
                    Line Items
                  </h6>
                  {quoteData.SendQuoteLineitems.map((e, idx) => (
                    <div className="row">
                      <div className="input-field col m-0 s2 ">
                        <label className="t-0 active">
                          Item Code<span className="red-text">*</span>
                        </label>
                        <select
                          id={ITEM_CODE}
                          required
                          className="browser-default dropdown-sel"
                          style={{ borderRadius: "20px", height: "35px" }}
                          onChange={this.handleLineItemChange(idx)}
                          value={e[ITEM_CODE]}
                        >
                          <option value="">Select</option>
                          {itemCodes.map((e) => (
                            <option value={e.Code}>{e.Code}</option>
                          ))}
                        </select>
                        <div className="red-text">
                          {errors[LINE_ITEMS][idx][ITEM_CODE]}
                        </div>
                      </div>
                      <div className="input-field col m-0 s4">
                        <label className="t-0">
                          Description<span className="red-text">*</span>
                        </label>
                        <input
                          type="text"
                          id={DESC}
                          required
                          onChange={this.handleLineItemChange(idx)}
                          value={e[DESC]}
                          style={{ height: "35px" }}
                        />
                        <div className="red-text">
                          {errors[LINE_ITEMS][idx][DESC]}
                        </div>
                      </div>

                      <div className="input-field col m-0 s1">
                        <label className="t-0">
                          Quantity<span className="red-text">*</span>
                        </label>
                        <input
                          id={QUANTITY}
                          required
                          onChange={this.handleLineItemChange(idx)}
                          value={e[QUANTITY]}
                          style={{ height: "35px" }}
                        />
                        <div className="red-text">
                          {errors[LINE_ITEMS][idx][QUANTITY]}
                        </div>
                      </div>
                      <div className="input-field col m-0 s2">
                        <label className="t-0">Unit Amount</label>
                        <input
                          id={UNIT_AMT}
                          type="number"
                          onChange={this.handleLineItemChange(idx)}
                          value={e[UNIT_AMT]}
                          min={0}
                          disabled
                          style={{ height: "35px" }}
                        />
                      </div>
                      <div className="input-field col m-0 s2">
                        <label className="t-0">Line Amount</label>
                        <input
                          type="number"
                          onChange={this.handleLineItemChange(idx)}
                          value={e[LINE_AMT]}
                          min={0}
                          disabled
                          style={{ height: "35px" }}
                        />
                      </div>
                      <div className="col s1">
                        <button
                          className="close_icon"
                          onClick={() => this.removeLineItem(idx)}
                        >
                          <FontAwesomeIcon icon={faWindowClose} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )) ||
                ""}
              <div className="row">
                <div className="input-field col s4">
                  <label className="active">
                    Sub Total<span className="red-text"></span>
                  </label>
                  <input
                    id={SUB_TOTAL}
                    type="number"
                    onChange={this.handleChange}
                    value={quoteData[SUB_TOTAL]}
                    disabled
                  />
                </div>

                <div className="input-field  col s4">
                  <label className="active">Tax</label>

                  <input
                    id={TAX}
                    onChange={this.handleChange}
                    value={quoteData[TAX]}
                  />
                  <span className="red-text">{errors[TAX]}</span>
                </div>

                <div className="input-field  col s4">
                  <label className="active">
                    Total<span className="red-text"></span>
                  </label>

                  <input
                    id={TOTAL}
                    type="number"
                    onChange={this.handleChange}
                    value={quoteData[TOTAL]}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col s12 center"
                style={{ paddingTop: "20px", paddingLeft: "11.250px" }}
              >
                <button
                  className=" btn black"
                  onClick={() => this.submitQuote()}
                >
                  Submit
                </button>
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn red"
                  onClick={() => this.closeQuote()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
  }
}

QuoteDetails.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(QuoteDetails);
