import React, { Component } from "react";
import axios from "../../axios/index";
import { loginUser } from "../../actions/authActions";
import { createComment } from "../../actions/bidActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SendIcon from "../../images/send-icon.png";
import PaperClip from "../../images/paperclip-icon.png";
import ClearIcon from "../../images/clear-icon.png";
import isEmpty from "is-empty";

import "./Comments.css";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: "",
      userName: "",
      userImage: "",
      comments: [],
      serviceRequestId: "",
      commentsCount: "",
      replyComment: "",
      replycommentsId: "",
      replycomments: [],
      attachments: {},
      selectedFile: [],
      uploadFilesMaping: [],
      errors: {},
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get("bidrequestid");
    this.setState({ serviceRequestId: id });
    this.setState({ userName: this.props.auth.user.firstname });
    this.setState({ userImage: this.props.auth.user.profileImage });

    const serviceRequestId = {
      serviceRequestId: id.trim(),
      requestedByEmail: this.props.auth.user.email,
      requestedBy: this.props.requestedBy,
      userRole: this.props.auth.user.userRole,
      updatedBy:
        this.props.auth.user.userRole !== 2 ? "serviceManager" : "freelancer",
      bidProposalId: this.props.bidId ? this.props.bidId : null,
      requestFrom: this.props.requestFrom ? "bidProposal" : "root",
      responseTo: "root",
    };

    axios
      .post("/api/comments/allcomments", serviceRequestId)
      .then((res) => {
        this.setState(
          {
            commentsCount: res.data.length,
            comments: res.data,
          },
          () => {
            if (
              this.props.rootCommentId !== null &&
              this.props.rootCommentId !== undefined &&
              this.props.rootCommentId !== "root"
            ) {
              this.replyComments("", this.props.rootCommentId).then((el) => {
                setTimeout(() => {
                  let comment = document.getElementById(this.props.commentId);
                  comment &&
                    comment.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  comment && (comment.style.borderRadius = "10px");
                  comment && (comment.style.borderTop = "0");
                  comment &&
                    (comment.style.backgroundColor = "rgb(245,245,245)");

                  setTimeout(() => {
                    comment && (comment.style.borderRadius = "0");
                    comment && (comment.style.borderTop = "1px solid #e4e4e4");
                    comment && (comment.style.backgroundColor = "white");
                  }, 4000);
                }, 500);
              });
            } else if (
              this.props.rootCommentId !== null &&
              this.props.rootCommentId === "root"
            ) {
              let comment = document.getElementById(this.props.commentId);
              comment &&
                comment.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });
              comment && (comment.style.borderRadius = "10px");
              comment && (comment.style.borderTop = "0");
              comment && (comment.style.backgroundColor = "rgb(245,245,245)");

              setTimeout(() => {
                comment && (comment.style.borderRadius = "0");
                comment && (comment.style.borderTop = "1px solid #e4e4e4");
                comment && (comment.style.backgroundColor = "white");
              }, 4000);
            }
          }
        );
      })
      .catch((err) => console.log(err));
  }

  // checkRoot = (item) => {
  //   return item.responseTo === "root";
  // };

  onChange = (e, id) => {
    if (id) {
      this.setState({
        replyComment: {
          ...this.state.replyComment,
          [id]: e.target.value,
        },
      });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  // GET ALL ROOT COMMENTS
  getComments = () => {
    const serviceRequestId = {
      serviceRequestId: this.state.serviceRequestId,
      requestedByEmail: this.props.auth.user.email,
      requestedBy: this.props.requestedBy,
      userRole: this.props.auth.user.userRole,
      updatedBy:
        this.props.auth.user.userRole !== 2 ? "serviceManager" : "freelancer",
      bidProposalId: this.props.bidId ? this.props.bidId : null,
      requestFrom: this.props.requestFrom ? "bidProposal" : "root",
      responseTo: "root",
    };

    axios
      .post("/api/comments/allcomments", serviceRequestId)
      .then((res) => {
        this.setState({
          commentsCount: res.data.length,
          comments: res.data,
          attachments: {},
          selectedFile: [],
          uploadFilesMaping: [],
        });
        document.getElementById("commentsCount").scrollIntoView();
      })
      .catch((err) => console.log(err));
  };

  // SUBMIT ROOT COMMENTS
  postComment = async (e, id) => {
    if (this.state.selectedFile.length > 0) {
      await this.uploadAttachments(id);
    }

    const attachments =
      this.state.attachments[id] &&
      this.state.attachments[id].filter((file) => !file.startsWith("blob"));

    if (!isEmpty(this.state.newComment)) {
      this.setState({ postComment: this.state.newComment });

      if (
        this.props.auth.user.profileImage !== "undefined" ||
        this.props.auth.user.profileImage !== undefined
      ) {
        var updatedByProfileImage = this.props.auth.user.profileImage.replace(
          "public",
          ""
        );
      }

      const commentData = {
        postComment: this.state.newComment,
        serviceRequestId: this.props.id,
        updatedBy:
          this.props.auth.user.userRole !== 2 ? "serviceManager" : "freelancer",
        updatedByEmail: this.props.auth.user.email,
        updatedByFirstName: this.props.auth.user.firstname,
        updatedByProfileImage: updatedByProfileImage,
        attachments: attachments,
        bidProposalId: this.props.bidId ? this.props.bidId : null,
        responseTo: "root",
      };

      await this.props.createComment(commentData);

      this.setState({
        newComment: "",
        attachments: {},
        selectedFile: [],
        uploadFilesMaping: [],
      });

      setTimeout(() => {
        this.getComments();
      }, 500);
    }
  };

  // SUBMIT REPLY COMMENTS
  submitReplyComment = async (e, id) => {
    if (this.state.selectedFile.length > 0) {
      await this.uploadAttachments(id);
    }

    const attachments = this.state.attachments[id].filter(
      (file) => !file.startsWith("blob")
    );

    if (!isEmpty(this.state.replyComment[`${id}`])) {
      const commentData = {
        postComment: this.state.replyComment[`${id}`],
        serviceRequestId: this.props.id,
        responseTo: id,
        updatedBy:
          this.props.auth.user.userRole !== 2 ? "serviceManager" : "freelancer",
        updatedByEmail: this.props.auth.user.email,
        updatedByFirstName: this.props.auth.user.firstname,
        updatedByProfileImage: this.props.auth.user.profileImage.replace(
          "public",
          ""
        ),
        attachments: attachments,
        commentMaker: this.props.type ? "serviceManager" : "freelancer",
        bidProposalId: this.props.bidId ? this.props.bidId : null,
      };

      await this.props.createComment(commentData);

      this.setState({
        replyComment: "",
        attachments: {},
        selectedFile: [],
        uploadFilesMaping: [],
      });

      setTimeout(() => {
        this.getComments();
        // this.replyComments("", id);
        document.getElementById("reply" + id).style.display = "none";
        document.getElementById("attachments_" + id).style.display = "none";
      }, 500);
    }
  };

  // HIDE REPLIES
  hideReplies(e, id) {
    document.getElementById("replyComments_" + id).style.display = "none";
    document.getElementById("showReplies_" + id).style.display = "inline-block";
    document.getElementById("hideReplies_" + id).style.display = "none";
  }

  // SHOW REPLY FORM
  replyComment = (e, id) => {
    e.preventDefault();
    document.getElementById("reply" + id).style.display = "block";
    document.getElementById("attachments_" + id).style.display = "block";
  };

  // GET REPY COMMENTS
  replyComments = async (e, id) => {
    this.setState({ replycomments: [] });
    this.setState({ replycommentsId: id });
    // let self = this;
    const commentData = {
      responseTo: id,
    };
    axios
      .post("/api/comments/replycomments", commentData)
      .then((res) => {
        this.setState({ replycomments: res.data }, () => {
          document.getElementById(
            "replyComments_" + res.data[0].responseTo
          ).innerHTML = "";
          this.state.replycomments.forEach(function(t, i) {
            var newDiv = document.createElement("div");
            newDiv.setAttribute("class", "col s12 borderone");
            newDiv.setAttribute("id", t._id);
            var newImgDiv = document.createElement("div");
            var newCntDiv = document.createElement("div");
            // var newReplDiv = document.createElement("div");
            // var newtextDiv = document.createElement("div");
            // var newtextArea = document.createElement("textarea");
            var newSpan = document.createElement("SPAN");
            newImgDiv.setAttribute("class", "col s1 imgdiv");
            newCntDiv.setAttribute("class", "col s11");
            // newReplDiv.setAttribute("class", "col s12");
            // newReplDiv.setAttribute("id", "replyComments_" + t._id);
            // newtextDiv.setAttribute("id", "reply" + t._id);
            // newtextArea.setAttribute("id", "replyComment");
            // newtextArea.setAttribute("class", "root-comment-area");
            newSpan.style.color = "gray";
            newSpan.style.fontSize = "11px";
            newSpan.style.float = "right";
            newSpan.style.padding = "0";

            var Img = document.createElement("IMG");
            if (t.updatedByProfileImage !== undefined) {
              Img.setAttribute(
                "src",
                "https://devfreelancersapi.supportpod.com" +
                  t.updatedByProfileImage.replace("public", "")
              );
            } else {
              Img.setAttribute("src", "/images/file-upload-avatar.png");
            }
            var newh = document.createElement("h6");
            var newp = document.createElement("p");
            // var newBtn = document.createElement("button");
            // newBtn.type = "button";
            // newBtn.innerHTML = "Reply";
            // newBtn.setAttribute("class", "reply-btn");
            // newBtn.addEventListener("click", function() {
            //   document.getElementById("reply" + t._id).style.display = "block";
            //   if (
            //     document.getElementById(
            //       "attachments_" + res.data[0].responseTo
            //     ) !== null
            //   ) {
            //     document.getElementById(
            //       "attachments_" + res.data[0].responseTo
            //     ).style.display = "block";
            //   }
            // });

            // newtextArea.addEventListener("change", function(e) {
            //   self.setState({ [e.target.id]: e.target.value });
            // });

            // var newReplyBtn = document.createElement("button");
            // newReplyBtn.type = "button";
            // newReplyBtn.innerHTML = "Send";
            // newReplyBtn.setAttribute("class", "btn black right");
            // newReplyBtn.addEventListener("click", function() {
            //   self.submitReplyComment(e, t._id);
            // });

            // if (t.children.length > 0) {
            //   var viewCmtBtn = document.createElement("button");
            //   viewCmtBtn.type = "button";
            //   viewCmtBtn.innerHTML = t.children.length + " Replie(s)";
            //   viewCmtBtn.setAttribute("id", "showReplies_" + t._id);
            //   viewCmtBtn.addEventListener("click", function() {
            //     self.replyComments(e, t._id);
            //   });
            // }

            // var hideCmtBtn = document.createElement("button");
            // hideCmtBtn.type = "button";
            // hideCmtBtn.innerHTML = "Hide Replies";
            // hideCmtBtn.setAttribute("id", "hideReplies_" + t._id);
            // hideCmtBtn.style.display = "none";
            // hideCmtBtn.addEventListener("click", function() {
            //   self.hideReplies(e, t._id);
            // });

            newh.innerHTML = t.updatedByFirstName;
            newSpan.innerHTML = new Date(t.date).toLocaleString();
            newp.innerHTML = t.commenttext;
            newImgDiv.appendChild(Img);
            newDiv.appendChild(newImgDiv);
            newCntDiv.appendChild(newh);
            newh.appendChild(newSpan);
            newCntDiv.appendChild(newp);

            // if (t.children.length > 0) {
            //   newCntDiv.appendChild(viewCmtBtn);
            // }
            // newCntDiv.appendChild(hideCmtBtn);
            // newCntDiv.appendChild(newBtn);
            // newCntDiv.appendChild(newReplDiv);
            // newCntDiv.appendChild(newtextDiv);
            // newtextDiv.appendChild(newtextArea);
            // newtextDiv.appendChild(newReplyBtn);
            if (t.attachments.length > 0) {
              t.attachments.forEach(function(g, i) {
                var attachSpan = document.createElement("SPAN");
                attachSpan.setAttribute("class", "file-field input-field");
                attachSpan.style.float = "left";
                attachSpan.style.position = "relative";
                var AttachImg = document.createElement("IMG");
                var a = document.createElement("a");
                a.setAttribute(
                  "href",
                  "https://devfreelancersapi.supportpod.com" +
                    g.replace("public", "")
                );
                AttachImg.style.width = "50%";
                AttachImg.style.display = "flex";
                AttachImg.style.alignItems = "center";
                AttachImg.style.justifyContent = "center";
                AttachImg.style.width = "100%";
                AttachImg.style.height = "100%";
                AttachImg.setAttribute(
                  "src",
                  "https://devfreelancersapi.supportpod.com" +
                    g.replace("public", "")
                );
                AttachImg.setAttribute("alt", "Attachment");
                attachSpan.appendChild(a);
                a.appendChild(AttachImg);
                newCntDiv.appendChild(attachSpan);
              });
            }
            newDiv.appendChild(newCntDiv);

            document
              .getElementById("replyComments_" + t.responseTo)
              .appendChild(newDiv);
            document.getElementById(
              "replyComments_" + t.responseTo
            ).style.display = "block";
            document.getElementById(
              "showReplies_" + t.responseTo
            ).style.display = "none";
            document.getElementById(
              "hideReplies_" + t.responseTo
            ).style.display = "inline-block";
          });
        });
      })
      .catch((err) => console.log(err));
  };

  // ADD ATTACHMENTS
  onChangeHandler = (event, id) => {
    if (document.getElementsByClassName("uploaded_attachments").length) {
      var attaches = document.querySelectorAll(".uploaded_attachments");
      for (var i = 0; i < attaches.length; i++) {
        attaches[i].style.display = "none";
      }
    }

    var ext = event.target.files[0].name.split(".").pop();
    var fileSize = event.target.files[0].size;
    var file = event.target.files[0];

    // FILE SIZE VALIDATION
    if (fileSize > 5000000) {
      this.setState({
        errors: { ...this.state.errors, [`${id}`]: { fileSizeError: true } },
      });
    } else {
      this.setState({
        errors: { ...this.state.errors, [`${id}`]: { fileSizeError: false } },
      });
    }

    // FILE EXTANSION VALIDATION
    if (
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "gif" &&
      ext !== "PNG" &&
      ext !== "JPG" &&
      ext !== "JPEG" &&
      ext !== "GIF"
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          [`${id}`]: {
            extensionError: true,
            fileSizeError: false,
            fileDimensionError: false,
          },
        },
      });

      if (
        ext === "pdf" ||
        ext === "PDF" ||
        ext === "doc" ||
        ext === "docx" ||
        ext === "DOCX" ||
        ext === "xlsx" ||
        ext === "XLSX" ||
        ext === "PPTX" ||
        ext === "pptx"
      ) {
        this.setState({
          errors: {
            ...this.state.errors,
            [`${id}`]: {
              extensionError: false,
              fileSizeError: false,
              fileDimensionError: false,
            },
          },
        });
      }
      document.getElementById("attachments_" + id).style.display = "block";

      const attachmentUrl = URL.createObjectURL(file);

      this.setState({
        selectedFile: [...this.state.selectedFile, file],
        uploadFilesMaping: [
          ...this.state.uploadFilesMaping,
          [file.name, attachmentUrl],
        ],
        attachments: {
          ...this.state.attachments,
          [id]: this.state.attachments[id]
            ? [...this.state.attachments[id], attachmentUrl]
            : [attachmentUrl],
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [`${id}`]: {
            extensionError: false,
            fileSizeError: false,
            fileDimensionError: false,
          },
        },
      });

      document.getElementById("attachments_" + id).style.display = "block";

      const attachmentUrl = URL.createObjectURL(file);

      let img = new Image();
      img.src = attachmentUrl;

      img.onload = () => {
        var width = img.naturalWidth;
        var height = img.naturalHeight;

        if (width > 2000 || height > 2000) {
          window.URL.revokeObjectURL(img.src);
          this.setState({
            errors: {
              ...this.state.errors,
              [`${id}`]: {
                extensionError: false,
                fileSizeError: false,
                fileDimensionError: true,
              },
            },
          });
        } else {
          this.setState(
            {
              selectedFile: [...this.state.selectedFile, file],
              uploadFilesMaping: [
                ...this.state.uploadFilesMaping,
                [file.name, attachmentUrl],
              ],
              attachments: {
                ...this.state.attachments,
                [id]: this.state.attachments[id]
                  ? [...this.state.attachments[id], attachmentUrl]
                  : [attachmentUrl],
              },
            },
            () => console.log(this.state.attachments)
          );
        }
      };
    }
  };

  // REMOVE ATTACHMENTS
  removeAttachments = (attachmentId, fileUrl, id) => {
    const updatedUploades = [];
    const removeUploadMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] === fileUrl
    );

    const newMaping = this.state.uploadFilesMaping.filter(
      (el) => el[1] !== fileUrl
    );

    this.state.selectedFile.forEach((el) => {
      if (el.name === removeUploadMaping[0][0]) {
        window.URL.revokeObjectURL(fileUrl);
      } else {
        updatedUploades.push(el);
      }
    });

    const updatedAttachments =
      this.state.attachments[id] &&
      this.state.attachments[id].filter(
        (currentValue, index) => attachmentId * 1 !== index
      );

    this.setState({
      attachments: { ...this.state.attachments, [id]: updatedAttachments },
      selectedFile: updatedUploades,
      uploadFilesMaping: newMaping,
    });
  };

  // UPLOAD ATTACHMENTS
  uploadAttachments = async (commentid) => {
    if (
      this.state.selectedFile !== null &&
      this.state.selectedFile !== undefined &&
      this.state.selectedFile !== "undefined"
    ) {
      const data = new FormData();
      data.append("serviceRequestId", this.state.serviceRequestId);
      data.append("commentid", commentid);

      this.state.selectedFile.forEach((el, index) => {
        data.append("file", el);
      });

      await axios
        .post("/api/comments/upload", data)
        .then((res) => {
          console.log(res.data);

          const file_paths = this.state.attachments[commentid].filter(
            (file) => !file.startsWith("blob")
          );

          res.data.forEach((file_path) => {
            if (file_path !== undefined) {
              if (file_path.length > 0) {
                file_path = file_path.replace("public", "");
              }
            }
            file_paths.push(file_path);
          });
          this.setState({
            attachments: {
              ...this.state.attachments,
              [`${commentid}`]: file_paths,
            },
            selectedFile: [],
            uploadFilesMaping: [],
          });
        })
        .catch((err) => console.log(err));
    }
  };

  componentWillUnmount() {
    if (this.state.selectedFile.length > 0) {
      this.state.uploadFilesMaping.forEach((el) =>
        window.URL.revokeObjectURL(el[1])
      );
      console.log("Attachments are discarded!");
    }
  }

  render() {
    const Comments = this.state.comments;
    const Count = this.state.commentsCount;
    var profileimage = this.state.userImage;
    if (profileimage !== undefined) {
      if (profileimage.length > 0) {
        profileimage = this.state.userImage.replace("public", "");
      }
    }
    return (
      <>
        <div className="row">
          <div className="container" style={{ marginTop: "40px" }}>
            <div
              style={{
                backgroundColor: "#ffffff",
                borderRadius: this.props.type ? "20px" : "0",
                padding: "10px",
                border: this.props.type
                  ? "1px solid rgba(112,112,112,0.27)"
                  : "none",
                marginBottom: "30px",
                marginTop: "20px",
              }}
              className=" container valign-wrapper"
            >
              <div className="row">
                <div className="landing-copy ">
                  <div className="col s12">
                    <h6>Add Comment</h6>
                    <textarea
                      id="newComment"
                      className="root-comment-area"
                      placeholder="Write a comment"
                      onChange={this.onChange}
                      value={this.state.newComment}
                      style={{
                        outline: "none",
                      }}
                    ></textarea>
                    <button
                      className="btn black right"
                      onClick={(e) =>
                        this.postComment(
                          e,
                          this.props.bidId ? this.props.bidId : "root"
                        )
                      }
                      style={{
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        src={SendIcon}
                        style={{ width: "24px", marginTop: "5px" }}
                        alt="send"
                      />
                    </button>
                    <div className="col s12">
                      <p>Add Attachments</p>
                      <div
                        id={
                          this.props.bidId
                            ? `attachments_${this.props.bidId}`
                            : "attachments_root"
                        }
                        className="uploaded_attachments"
                        style={{ display: "none" }}
                      >
                        {this.state.attachments[
                          this.props.bidId ? this.props.bidId : "root"
                        ] &&
                          this.state.attachments[
                            this.props.bidId ? this.props.bidId : "root"
                          ].map(
                            function(item, idx) {
                              if (item.startsWith("blob")) {
                                return (
                                  <span
                                    className="file-field input-field "
                                    style={{
                                      float: "left",
                                      position: "relative",
                                    }}
                                    key={idx}
                                  >
                                    <a href={item} target="_blank">
                                      <img
                                        src={item}
                                        alt="Attachment"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </a>
                                    <img
                                      src={ClearIcon}
                                      alt="attachments"
                                      style={{
                                        display: "block",
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        zIndex: 1,
                                        width: "25%",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        opacity: "85%",
                                      }}
                                      onClick={() =>
                                        this.removeAttachments(
                                          idx,
                                          item,
                                          this.props.bidId
                                            ? this.props.bidId
                                            : "root"
                                        )
                                      }
                                    />
                                  </span>
                                );
                              } else {
                                item = item.replace("public", "");
                                var extension = item.split(".").pop();
                                if (
                                  extension === "jpg" ||
                                  extension === "png" ||
                                  extension === "jpeg" ||
                                  extension === "PNG" ||
                                  extension === "JPG" ||
                                  extension === "gif"
                                ) {
                                  return (
                                    <span
                                      className="file-field input-field "
                                      style={{ float: "left" }}
                                      key={idx}
                                    >
                                      <a
                                        target="_blank"
                                        href={`https://devfreelancersapi.supportpod.com${item}`}
                                      >
                                        <img
                                          src={`https://devfreelancersapi.supportpod.com${item}`}
                                          alt="item"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      </a>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <>
                                      <span
                                        className="file-field input-field attachment"
                                        style={{ float: "left" }}
                                        key={idx}
                                      >
                                        <a
                                          target="_blank"
                                          href={`https://devfreelancersapi.supportpod.com${item}`}
                                        >
                                          <img
                                            src={PaperClip}
                                            alt="attachments"
                                          />
                                          <img src={item} alt="item" />
                                        </a>
                                      </span>
                                    </>
                                  );
                                }
                              }
                            }.bind(this)
                          )}
                      </div>
                      <div
                        className="file-field input-field"
                        style={{ width: "42px", height: "42px", float: "left" }}
                      >
                        <div
                          className="btn"
                          style={{ width: "42px", height: "42px" }}
                        >
                          <span
                            style={{
                              marginTop: "4px",
                              marginLeft: "-13px",
                              backgroundSize: "24px 24px",
                            }}
                          >
                            File
                          </span>
                          <input
                            type="file"
                            title="Add Attachment"
                            name="file"
                            onChange={(event) =>
                              this.onChangeHandler(
                                event,
                                this.props.bidId ? this.props.bidId : "root"
                              )
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              zIndex: 101,
                            }}
                          />
                        </div>
                        <div
                          className="file-path-wrapper"
                          style={{
                            visibility: "hidden",
                            height: "1px",
                            width: "1px",
                          }}
                        >
                          <input
                            className="file-path validate"
                            type="text"
                            placeholder="Upload one or more files"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", paddingLeft: "10px" }}>
                    {this.state.errors["root"] &&
                      this.state.errors["root"].extensionError && (
                        <span className="red-text">
                          Please upload a valid image
                          <br />
                          (png, jpg, jpeg, gif, doc, xl, pdf, docx, text)
                        </span>
                      )}
                    {this.state.errors["root"] &&
                      this.state.errors["root"].fileDimensionError && (
                        <span className="red-text">
                          Please select a image below 2000 pixels
                        </span>
                      )}
                    {this.state.errors["root"] &&
                      this.state.errors["root"].fileSizeError && (
                        <span className="red-text">
                          Maximum file size allowed is 2MB
                        </span>
                      )}
                  </div>
                </div>
                <div id="commentsCount"></div>
              </div>
              <div className="row">
                <div className="col s12" id="commentsCount">
                  {Count > 0 && (
                    <>
                      {Count > 1 ? (
                        <h6>{Count} Comments</h6>
                      ) : (
                        <h6>{Count} Comment</h6>
                      )}
                    </>
                  )}

                  {Comments.map(
                    function(d, idx) {
                      return (
                        <div key={d._id}>
                          {d.responseTo === "root" && (
                            <div
                              className="col s12"
                              style={{
                                borderTop: "1px solid #e4e4e4",
                                marginTop: "10px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                              id={d._id}
                            >
                              <div className="col s1">
                                {d.updatedByProfileImage && (
                                  <img
                                    alt="User"
                                    src={`https://devfreelancersapi.supportpod.com${d.updatedByProfileImage}`}
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="col s11">
                                <h6>
                                  {d.updatedByFirstName}
                                  <span
                                    style={{
                                      color: "gray",
                                      fontSize: "11px",
                                      padding: "0",
                                      float: "right",
                                    }}
                                  >
                                    {new Date(d.date).toLocaleString()}
                                  </span>
                                </h6>{" "}
                                <p>{d.commenttext}</p>
                                <div className="col s12">
                                  {d.attachments.length > 0 && (
                                    <>
                                      {d.attachments.map(function(item, idx) {
                                        if (item.startsWith("blob")) {
                                          return (
                                            <span
                                              className="file-field input-field "
                                              style={{
                                                float: "left",
                                                position: "relative",
                                              }}
                                              key={idx}
                                            >
                                              <a href={item} target="_blank">
                                                <img
                                                  src={item}
                                                  alt="Document"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                />
                                              </a>
                                              <img
                                                src={ClearIcon}
                                                alt="attachments"
                                                style={{
                                                  display: "block",
                                                  position: "absolute",
                                                  top: "5px",
                                                  right: "5px",
                                                  zIndex: 1,
                                                  width: "25%",
                                                  backgroundColor: "white",
                                                  borderRadius: "5px",
                                                  padding: "5px",
                                                  opacity: "85%",
                                                }}
                                                onClick={() =>
                                                  this.removeAttachments(
                                                    idx,
                                                    item,
                                                    d._id
                                                  )
                                                }
                                              />
                                            </span>
                                          );
                                        } else {
                                          item = item.replace("public", "");
                                          var extension = item.split(".").pop();
                                          if (
                                            extension === "jpg" ||
                                            extension === "png" ||
                                            extension === "jpeg" ||
                                            extension === "PNG" ||
                                            extension === "JPG" ||
                                            extension === "gif"
                                          ) {
                                            return (
                                              <span
                                                className="file-field input-field "
                                                style={{ float: "left" }}
                                                key={item}
                                              >
                                                <a
                                                  target="_blank"
                                                  href={`https://devfreelancersapi.supportpod.com${item}`}
                                                >
                                                  <img
                                                    src={`https://devfreelancersapi.supportpod.com${item}`}
                                                    alt="Attachment"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  />
                                                </a>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span
                                                  className="file-field input-field attachment"
                                                  style={{
                                                    float: "left",
                                                    width: "42px",
                                                    height: "42px",
                                                  }}
                                                  key={item}
                                                >
                                                  <a
                                                    target="_blank"
                                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                                  >
                                                    <img
                                                      src={PaperClip}
                                                      alt="attachments"
                                                      style={{ width: "50%" }}
                                                    />
                                                  </a>
                                                </span>
                                              </>
                                            );
                                          }
                                        }
                                      })}
                                    </>
                                  )}
                                </div>
                                <div>
                                  {d.children.length > 0 && (
                                    <button
                                      id={`showReplies_${d._id}`}
                                      style={{
                                        borderRadius: "10px",
                                        border: "1px solid lightGray",
                                        width: "80px",
                                      }}
                                      onClick={(event) =>
                                        this.replyComments(event, d._id)
                                      }
                                    >
                                      {d.children.length} Replie(s)
                                    </button>
                                  )}{" "}
                                  <button
                                    id={`hideReplies_${d._id}`}
                                    style={{
                                      display: "none",
                                      borderRadius: "10px",
                                      border: "1px solid lightGray",
                                      width: "100px",
                                    }}
                                    onClick={(event) =>
                                      this.hideReplies(event, d._id)
                                    }
                                  >
                                    Hide Replies
                                  </button>{" "}
                                  <button
                                    className="reply-btn"
                                    onClick={(event) =>
                                      this.replyComment(event, d._id)
                                    }
                                  >
                                    Reply
                                  </button>
                                  <div
                                    id={`replyComments_${d._id}`}
                                    style={{ display: "none" }}
                                  ></div>
                                  <div className="col s12" id={d._id}>
                                    <div
                                      id={`reply${d._id}`}
                                      style={{ display: "none" }}
                                    >
                                      <textarea
                                        id="replyComment"
                                        className="root-comment-area"
                                        placeholder="Write a comment"
                                        onChange={(e) =>
                                          this.onChange(e, d._id)
                                        }
                                        value={
                                          this.state.replyComment[`${d._id}`]
                                            ? this.state.replyComment[
                                                `${d._id}`
                                              ]
                                            : ""
                                        }
                                        style={{
                                          outline: "none",
                                          marginTop: "10px",
                                        }}
                                      ></textarea>

                                      <button
                                        className="btn black right"
                                        style={{
                                          marginTop: "5px",
                                          marginRight: "5px",
                                        }}
                                        onClick={(event) =>
                                          this.submitReplyComment(event, d._id)
                                        }
                                      >
                                        <img
                                          alt="Send"
                                          src={SendIcon}
                                          style={{
                                            width: "24px",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </button>

                                      <div className="col s12">
                                        <div>
                                          <div
                                            id={`attachments_${d._id}`}
                                            className="uploaded_attachments"
                                            style={{ display: "none" }}
                                          >
                                            {this.state.attachments[
                                              `${d._id}`
                                            ] &&
                                              this.state.attachments[
                                                `${d._id}`
                                              ].map(
                                                function(item, idx) {
                                                  if (item.startsWith("blob")) {
                                                    return (
                                                      <span
                                                        className="file-field input-field "
                                                        style={{
                                                          float: "left",
                                                          position: "relative",
                                                        }}
                                                        key={idx}
                                                      >
                                                        <a
                                                          href={item}
                                                          target="_blank"
                                                        >
                                                          <img
                                                            src={item}
                                                            alt="Document"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              justifyContent:
                                                                "center",
                                                              width: "100%",
                                                              height: "100%",
                                                            }}
                                                          />
                                                        </a>
                                                        <img
                                                          src={ClearIcon}
                                                          alt="attachments"
                                                          style={{
                                                            display: "block",
                                                            position:
                                                              "absolute",
                                                            top: "5px",
                                                            right: "5px",
                                                            zIndex: 1,
                                                            width: "25%",
                                                            backgroundColor:
                                                              "white",
                                                            borderRadius: "5px",
                                                            padding: "5px",
                                                            opacity: "85%",
                                                          }}
                                                          onClick={() =>
                                                            this.removeAttachments(
                                                              idx,
                                                              item,
                                                              d._id
                                                            )
                                                          }
                                                        />
                                                      </span>
                                                    );
                                                  } else {
                                                    item = item.replace(
                                                      "public",
                                                      ""
                                                    );
                                                    var extension = item
                                                      .split(".")
                                                      .pop();
                                                    if (
                                                      extension === "jpg" ||
                                                      extension === "png" ||
                                                      extension === "jpeg" ||
                                                      extension === "PNG" ||
                                                      extension === "JPG" ||
                                                      extension === "gif"
                                                    ) {
                                                      return (
                                                        <span
                                                          className="file-field input-field "
                                                          style={{
                                                            float: "left",
                                                            width: "42px",
                                                            height: "42px",
                                                          }}
                                                        >
                                                          <a
                                                            target="_blank"
                                                            href={`https://devfreelancersapi.supportpod.com${item}`}
                                                          >
                                                            <img
                                                              src={`https://devfreelancersapi.supportpod.com${item}`}
                                                              alt="item"
                                                            />
                                                          </a>
                                                        </span>
                                                      );
                                                    } else {
                                                      return (
                                                        <>
                                                          <span
                                                            className="file-field input-field attachment"
                                                            style={{
                                                              float: "left",
                                                            }}
                                                          >
                                                            <a
                                                              target="_blank"
                                                              href={`https://devfreelancersapi.supportpod.com${item}`}
                                                            >
                                                              <img
                                                                src={PaperClip}
                                                                alt="attachments"
                                                                style={{
                                                                  width: "50%",
                                                                }}
                                                              />
                                                            </a>
                                                          </span>
                                                        </>
                                                      );
                                                    }
                                                  }
                                                }.bind(this)
                                              )}

                                            <div
                                              className="file-field input-field"
                                              style={{ float: "left" }}
                                            >
                                              <div
                                                className="btn"
                                                style={{
                                                  width: "42px",
                                                  height: "42px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    marginTop: "4px",
                                                    marginLeft: "-13px",
                                                    backgroundSize: "24px 24px",
                                                  }}
                                                >
                                                  File
                                                </span>
                                                <input
                                                  type="file"
                                                  title="Add Attachment"
                                                  name="file"
                                                  onChange={(event) =>
                                                    this.onChangeHandler(
                                                      event,
                                                      d._id
                                                    )
                                                  }
                                                  style={{
                                                    width: "42px",
                                                    height: "42px",
                                                    position: "absolute",
                                                    zIndex: 101,
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className="file-path-wrapper"
                                                style={{
                                                  visibility: "hidden",
                                                  height: "1px",
                                                  width: "1px",
                                                }}
                                              >
                                                <input
                                                  className="file-path validate"
                                                  type="text"
                                                  placeholder="Upload one or more files"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="col s12"> */}
                              {/* <div className="col s12" > */}
                              {/* {d.attachments.length > 0 && (
                                    <>
                                      <p>
                                        <b>Attachments</b>
                                      </p>
                                      {d.attachments.map(function(item, idx) {
                                        if (item.startsWith("blob")) {
                                          return (
                                            <span
                                              className="file-field input-field "
                                              style={{
                                                float: "left",
                                                position: "relative",
                                              }}
                                              key={idx}
                                            >
                                              <a href={item} target="_blank">
                                                <img
                                                  src={item}
                                                  alt="Document"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                />
                                              </a>
                                              <img
                                                src={ClearIcon}
                                                alt="attachments"
                                                style={{
                                                  display: "block",
                                                  position: "absolute",
                                                  top: "5px",
                                                  right: "5px",
                                                  zIndex: 1,
                                                  width: "25%",
                                                  backgroundColor: "white",
                                                  borderRadius: "5px",
                                                  padding: "5px",
                                                  opacity: "85%",
                                                }}
                                                onClick={() =>
                                                  this.removeAttachments(
                                                    idx,
                                                    item
                                                  )
                                                }
                                              />
                                            </span>
                                          );
                                        } else {
                                          item = item.replace("public", "");
                                          var extension = item.split(".").pop();
                                          if (
                                            extension === "jpg" ||
                                            extension === "png" ||
                                            extension === "jpeg" ||
                                            extension === "PNG" ||
                                            extension === "JPG" ||
                                            extension === "gif"
                                          ) {
                                            return (
                                              <span
                                                className="file-field input-field "
                                                style={{ float: "left" }}
                                                key={item}
                                              >
                                                <a
                                                  target="_blank"
                                                  href={`https://devfreelancersapi.supportpod.com${item}`}
                                                >
                                                  <img
                                                    src={`https://devfreelancersapi.supportpod.com${item}`}
                                                    alt="Attachment"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      width: "100%",
                                                      height: "100%",
                                                    }}
                                                  />
                                                </a>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span
                                                  className="file-field input-field attachment"
                                                  style={{
                                                    float: "left",
                                                    width: "42px",
                                                    height: "42px",
                                                  }}
                                                  key={item}
                                                >
                                                  <a
                                                    target="_blank"
                                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                                  >
                                                    <img
                                                      src={PaperClip}
                                                      alt="attachments"
                                                      style={{ width: "50%" }}
                                                    />
                                                  </a>
                                                </span>
                                              </>
                                            );
                                          }
                                        }
                                      })}
                                    </>
                                  )} */}
                              {/* <div
                                    id={`attachments_${d._id}`}
                                    className="uploaded_attachments"
                                    style={{ display: "none" }}
                                  >
                                    {this.state.attachments.map(
                                      function (item, idx) {
                                        if (item.startsWith("blob")) {
                                          return (
                                            <span
                                              className="file-field input-field "
                                              style={{
                                                float: "left",
                                                position: "relative",
                                              }}
                                              key={idx}
                                            >
                                              <a href={item} target="_blank">
                                                <img
                                                  src={item}
                                                  alt="Document"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                />
                                              </a>
                                              <img
                                                src={ClearIcon}
                                                alt="attachments"
                                                style={{
                                                  display: "block",
                                                  position: "absolute",
                                                  top: "5px",
                                                  right: "5px",
                                                  zIndex: 1,
                                                  width: "25%",
                                                  backgroundColor: "white",
                                                  borderRadius: "5px",
                                                  padding: "5px",
                                                  opacity: "85%",
                                                }}
                                                onClick={() =>
                                                  this.removeAttachments(
                                                    idx,
                                                    item
                                                  )
                                                }
                                              />
                                            </span>
                                          );
                                        } else {
                                          item = item.replace("public", "");
                                          var extension = item.split(".").pop();
                                          if (
                                            extension === "jpg" ||
                                            extension === "png" ||
                                            extension === "jpeg" ||
                                            extension === "PNG" ||
                                            extension === "JPG" ||
                                            extension === "gif"
                                          ) {
                                            return (
                                              <span
                                                className="file-field input-field "
                                                style={{
                                                  float: "left",
                                                  width: "42px",
                                                  height: "42px",
                                                }}
                                              >
                                                <a
                                                  target="_blank"
                                                  href={`https://devfreelancersapi.supportpod.com${item}`}
                                                >
                                                  <img
                                                    src={`https://devfreelancersapi.supportpod.com${item}`}
                                                    alt="item"
                                                  />
                                                </a>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span
                                                  className="file-field input-field attachment"
                                                  style={{ float: "left" }}
                                                >
                                                  <a
                                                    target="_blank"
                                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                                  >
                                                    <img
                                                      src={PaperClip}
                                                      alt="attachments"
                                                      style={{ width: "50%" }}
                                                    />
                                                  </a>
                                                </span>
                                              </>
                                            );
                                          }
                                        }
                                      }.bind(this)
                                    )}

                                    <div
                                      className="file-field input-field"
                                      style={{ float: "left" }}
                                    >
                                      <div
                                        className="btn"
                                        style={{
                                          width: "42px",
                                          height: "42px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginTop: "4px",
                                            marginLeft: "-13px",
                                            backgroundSize: "24px 24px",
                                          }}
                                        >
                                          File
                                        </span>
                                        <input
                                          type="file"
                                          title="Add Attachment"
                                          name="file"
                                          onChange={(event) =>
                                            this.onChangeHandler(event, d._id)
                                          }
                                          style={{
                                            width: "42px",
                                            height: "42px",
                                            position: "absolute",
                                            zIndex: 101,
                                          }}
                                        />

                                      </div>
                                      <div
                                        className="file-path-wrapper"
                                        style={{
                                          visibility: "hidden",
                                          height: "1px",
                                          width: "1px",
                                        }}
                                      >
                                        <input
                                          className="file-path validate"
                                          type="text"
                                          placeholder="Upload one or more files"
                                        />
                                      </div>

                                    </div>

                                  </div> */}

                              {/* </div> */}

                              {/* </div> */}

                              <div style={{ position: "relative" }}>
                                {this.state.errors[d._id] &&
                                  this.state.errors[d._id].extensionError && (
                                    <span className="red-text">
                                      Please upload a valid image
                                      <br />
                                      (png, jpg, jpeg, gif, doc, xl, pdf, docx,
                                      text)
                                    </span>
                                  )}
                                {this.state.errors[d._id] &&
                                  this.state.errors[d._id]
                                    .fileDimensionError && (
                                    <span className="red-text">
                                      Please select a image below 2000 pixels
                                    </span>
                                  )}
                                {this.state.errors[d._id] &&
                                  this.state.errors[d._id].fileSizeError && (
                                    <span className="red-text">
                                      Maximum file size allowed is 2MB
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }.bind(this)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Comments.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, createComment })(Comments);
