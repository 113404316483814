import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotPassword, checkEmail } from "../../actions/authActions";
import classnames from "classnames";
import "./auth.css";
import logo from "../layout/logo.png";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      errors: {},
      emailValidate:false,
      resetPasswordLink:false,
      registerLink:false
    };
  }

  componentDidMount() {


    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({errors: nextProps.errors}, () => {
        if(this.state.errors.email === "We don't have account with this email address."){
          document.getElementById('resetPassword').disabled = true;
          this.setState({registerLink:true})
        }
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

   onEmailChange = e => {
      if (e.target.value !=="") {
          var pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
          if (!pattern.test(e.target.value)) {
            this.setState({emailValidate:true})
            this.setState({errors:{}})
            e.target.focus();
            document.getElementById('resetPassword').disabled = true;
            return false;
          }
          else {
            this.setState({ [e.target.id]: e.target.value });
            this.setState({errors:{}})

            this.setState({emailValidate:false});
            const userData = {
              email:e.target.value
            }

            this.props.checkEmail(userData);
            document.getElementById('resetPassword').disabled = false;
            return true;

          }
      }else {
        this.setState({emailValidate:false})
        this.setState({errors:{}})
      }
    }

  onSubmit = e => {
    e.preventDefault();
    this.setState({resetPasswordLink:true});
    this.setState({emailValidate:false});
    const newUser = {
      email: this.state.email,
      reseturl: "https://devfreelancers.supportpod.com/reset-password"
    };
    console.log(newUser);

    localStorage.setItem("emailid",newUser.email);

    this.props.forgotPassword(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;
    return (
      <>
      <div className="navbar-fixed auth">
        <nav className="z-depth-0">
          <div className="nav-wrapper white">
            <Link
              to="/"
              style={{
                fontFamily: "monospace"
              }}
              className="col s5 brand-logo center black-text"
            >
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>
      </div>
      <div className="container auth">
      {this.state.resetPasswordLink ?
          (<div className="row">
          <div className="col s8 offset-s2">
          <p>We have sent you a reset password link to your registered email account. Please follow the instructions and reset your password.</p>
          </div>
          </div>
          ) :
        (<div className="row">
          <div className="col s8 offset-s2">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4 style={{ fontSize: "28px" }}>Forgot Your Password?</h4>
              <p>Don't Worry! Just Fill Your Email And We'll Send You Link To Reset Your Password.</p>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  onBlur={this.onEmailChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email || errors.emailnotfound
                  })}
                />
                <label htmlFor="email">Email</label>
                <span className="red-text">
                  {errors.email}
                  {errors.emailnotfound}
                </span>

                {this.state.registerLink && <span className="red-text"> Try <a href='/register'>Create an account</a> instead.</span> }
                {this.state.emailValidate && <span className="red-text">A valid email is required</span> }
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  id="resetPassword"
                  className="btn btn-large waves-effect waves-light hoverable black accent-3"
                >Reset Password
                </button>
              </div>
            </form>
          </div>
        </div> )
        }
      </div>
      
    </>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { forgotPassword, checkEmail }
)(withRouter(ForgotPassword));
