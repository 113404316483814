import React, { Component } from 'react'
import Navbar from '../layout/Navbar'
import ShieldIcon from "../../images/shield-icon.png";
import RedShieldIcon from "../../images/red-shield-icon.png";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../axios/customerIndex';
import Modal from '../customers/Modal';

class CustomerStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            statusVisible: false,
            isActive: false,
            status: [],
            statusName: [],
            modalToggle: false.target,
            setIndex: '',
            updateStatusName: '',
            selectedData: {}
        }
    }

    hideModal = () => {
        window.location.reload(false);
        this.setState({
            modalToggle: false
        })
    }

    handleImage = () => {

        if (this.state.selectedData.isActive === true) {
            const list = this.state.status
            list[this.state.setIndex].isActive = false
            this.state.selectedData.isActive = false
            this.setState({
                status: list,
                selectedData: this.state.selectedData
            })
        } else {
            const list = this.state.status
            list[this.state.setIndex].isActive = true
            this.state.selectedData.isActive = true
            this.setState({
                status: list,
                selectedData: this.state.selectedData
            })
        }

        this.setState({
            isActive: !this.state.isActive
        })
    }

    addStatusHandler() {
        this.setState({
            statusVisible: !this.state.statusVisible
        });
    }

    // get status 
    componentDidMount() {
        this.getStautsList()

    }
    getStautsList() {
        axios.get('/api/customers/getAllStatus')
            .then(res => {
                const status = res.data;
                this.setState({ status })
            })
    }

    handleChange = evt => {
        this.setState({ statusName: evt.target.value });
    }

    handleChangStautsUpdate = (e) => {
        const list = this.state.status
        list[this.state.setIndex].statusName = this.state.updateStatusName
        this.state.selectedData.statusName = e.target.value

        this.setState({
            status: list,
            updateStatusName: e.target.value,
            selectedData: this.state.selectedData
        })
    }

    // post status 
    handleSubmit = (evt) => {
        const value = {
            statusName: this.state.statusName
        };
        axios.post('/api/customers/status', value)
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
    }
    handlePopup = (data, index) => {
        this.setState({
            modalToggle: true,
            setIndex: index,
            updateStatusName: data.statusName,
            selectedData: data
        })
    }

    // update the status 
    checkSubmit = (event) => {
        event.preventDefault()
        alert('Status has been updated')
        window.location.reload(false);
        const data = {
            id: this.state.selectedData._id,
            statusName: this.state.selectedData.statusName,
            isActive: this.state.selectedData.isActive
        }

        axios.put(`/api/customers/updatestatus`, data)
        .then(res => {
            this.getStautsList()
            this.setState({
                modalToggle: false,
                status: this.state.status
            })
        })
    }

    render() {
        return (
            <>
                <Navbar />
                <div style={{ marginTop: '50px' }}>
                    <div className="row">
                        <div className="container">
                            <div className="col s10">
                                <h4>Status</h4>
                            </div>
                            <h4 class="col s2">
                                <button className="btn right black" onClick={() => this.addStatusHandler()}>Add Status</button>
                            </h4>
                        </div>
                    </div>
                    <div className="container valign-wrapper">
                        <div className="row">
                            <div className="landing-copy col s12">
                                <div className="container">
                                    {
                                        this.state.statusVisible
                                            ? <Child handleSubmit={this.handleSubmit} handleChange={this.handleChange} />
                                            : null
                                    }
                                </div>
                                <div className="servicestatus">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colspan="">Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.status.map((data, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td key={data._id}>{ data.statusName }{" "}
                                                                {(data.isActive === true) ?
                                                                    <>{(
                                                                        <img
                                                                            src={ShieldIcon}
                                                                            alt="Mandatory"
                                                                            style={{
                                                                                position: "relative",
                                                                                top: "3px",
                                                                                width: "12px",
                                                                            }}
                                                                        />
                                                                    )}</> :
                                                                    <>{(
                                                                        <img
                                                                            src={RedShieldIcon}
                                                                            alt="Mandatory"
                                                                            style={{
                                                                                position: "relative",
                                                                                top: "3px",
                                                                                width: "12px",
                                                                            }}
                                                                        />
                                                                    )}
                                                                    </>
                                                                }

                                                            </td>
                                                            <td>
                                                                <button className="btn-transparent" >
                                                                    <FontAwesomeIcon onClick={() => this.handlePopup(data, index)} icon={faEdit} />
                                                                </button>
                                                                <Modal show={this.state.modalToggle} hideModal={this.hideModal}>
                                                                    <div>
                                                                        <form>
                                                                            <h6>Please update the status details</h6> <br />
                                                                            <p>
                                                                                <input onChange={this.handleChangStautsUpdate} value={this.state.selectedData.statusName} type="text" />
                                                                            </p>
                                                                            <p>
                                                                                <label>
                                                                                    <input type="checkbox" checked={this.state.selectedData.isActive} className="filled-in" onChange={this.handleImage} />
                                                                                    <span>Click to Activate/Deactivate</span>
                                                                                </label>
                                                                            </p>
                                                                            <button onClick={this.checkSubmit} className="btn btn">Update</button>
                                                                        </form>
                                                                    </div>
                                                                </Modal>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CustomerStatus;
class Child extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="col s6">
                    <input type="text" name="statusName" onChange={this.props.handleChange} />
                </div>
                <div className="col s1">
                    <button className="btn btn-large waves-effect waves-light hoverable black accent-3" onClick={this.props.handleSubmit}>Add</button>
                </div>
            </div>
        );
    }
}
