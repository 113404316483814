import React, { Component } from "react";
import axios from "../../axios/index";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import Overlay from "../layout/Overlay";
import Photo from "../../images/file-upload-avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faUserCheck,
  faUserTimes,
  faPortrait,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import SocialIcon from "../../images/team.png";
import CallIcon from "../../images/call-icon.png";
import EmailIcon from "../../images/email-icon.png";
import SuccessIcon from "../../images/success.png";
import AdminIcon from "../../images/admin-icon.png";
import OperationsManagerIcon from "../../images/operations-manager-icon.png";
import ServiceManagerIcon from "../../images/service-manager-icon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class AdminUsers extends Component {
  constructor() {
    super();
    this.state = {
      freelancersList: [],
      userdetails: {},
      profileImage: "",
      contactdetails: {},
      skillMatrix: [],
      socialProfiles: [],
      IDProof: "",
      idproofPrevBtn: "",
      AddressProofBtn: "",
      AddressProof: "",
      IDProofVerified: false,
      AddressProofVerified: false,
      idPopup: false,
      addressPopup: false,
      showActiveGrid: true,
    };
  }

  componentDidMount() {
    const userRole = this.props.auth.user.userRole;
    if (userRole === 1) {
      axios
        .get("/api/users/adminusers")
        .then((res) => {
          this.setState({ freelancersList: res.data });
        })
        .catch((err) => console.log(err));
    } else {
      this.props.history.push("/dasboard");
    }
  }

  handleAddNewUser = (e) => {
    e.preventDefault();
    this.props.history.push("/create-user");
  };

  handleDeactivatedAdmins = (e) => {
    this.setState({ showActiveGrid: false });
  };

  handleActivatedAdmins = (e) => {
    this.setState({ showActiveGrid: true });
  };

  deactivateAccount = (email) => {
    const userData = { email: email };

    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to deactivate this account.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ AddressProofVerified: true });

            axios
              .post("/api/users/deactivate", userData)
              .then((res) => {
                console.log(res.data);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  activateAccount = (email) => {
    const userData = { email: email };

    confirmAlert({
      title: "Confirm",
      message: "Are you sure, you want to deactivate this account.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ AddressProofVerified: true });

            axios
              .post("/api/users/activate", userData)
              .then((res) => {
                console.log(res.data);
                axios
                  .get("/api/users/freelancers")
                  .then((res) => {
                    console.log(res.data);
                    this.setState({ freelancersList: res.data });
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    const freelancersList = this.state.freelancersList;
    const self = this;
    const userDetails = this.state.userdetails;
    const contactDetails = this.state.contactdetails;
    const socialProfiles = this.state.socialProfiles;
    const skillMatrix = this.state.skillMatrix;
    const ShowActiveGrid = this.state.showActiveGrid;

    var profilePic = this.state.profileImage;
    var IDProof = this.state.IDProof;
    var AddressProof = this.state.AddressProof;
    var previewId = "";
    var previewAddress = "";

    return (
      <React.Fragment>
        <Navbar current={this.props.history.location.pathname} />
        <div className="row">
          <div className="container" style={{ marginTop: "60px" }}>
            <div className="s6 col">
              <h4>Admin Users</h4>
            </div>
            <div className="col s6">
              <button
                className="btn right black"
                onClick={this.handleAddNewUser}
              >
                Add New User
              </button>
              &nbsp;
              {ShowActiveGrid ? (
                <button
                  className="btn right"
                  onClick={this.handleDeactivatedAdmins}
                  style={{ marginRight: "20px" }}
                >
                  Deactivated Admin Users
                </button>
              ) : (
                <button
                  className="btn right"
                  onClick={this.handleActivatedAdmins}
                  style={{ marginRight: "20px" }}
                >
                  Active Admin Users
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "10px",
            border: "1px solid rgba(112,112,112,0.27)",
            marginBottom: "30px",
            marginTop: "20px",
          }}
          className="container valign-wrapper"
        >
          <div className="row">
            <div className="container">
              <div className="s12 col">
                <div className="row">
                  {ShowActiveGrid ? (
                    <table style={{ margin: "10px" }}>
                      <tbody>
                        {freelancersList.map(function(d, idx) {
                          return (
                            d.userRole !== 2 &&
                            d.isActive && (
                              <tr key={idx}>
                                <td>{d.screenname}</td>
                                <td>
                                  {d.userRole === 1 && (
                                    <img
                                      src={AdminIcon}
                                      alt="Admin User"
                                      title="Admin User"
                                    />
                                  )}
                                  {d.userRole === 3 && (
                                    <img
                                      src={ServiceManagerIcon}
                                      alt="Service Manager"
                                      title="Service Manager"
                                    />
                                  )}
                                  {d.userRole === 4 && (
                                    <img
                                      src={OperationsManagerIcon}
                                      alt="Operations Manager"
                                      title="Operations Manager"
                                    />
                                  )}
                                  {d.userRole === 5 && (
                                    <img
                                      src={OperationsManagerIcon}
                                      alt="Operations Manager"
                                      title="Operations Manager"
                                    />
                                  )}
                                </td>
                                <td>
                                  {d.userRole === 1 && (
                                    <>Admin</>
                                  )}
                                  {d.userRole === 3 && (
                                    <>Service Manager</>
                                  )}
                                  {d.userRole === 4 && (
                                    <>Operational Manager</>
                                  )}
                                  {d.userRole === 5 && (
                                    <>Resource Manager</>
                                  )}
                                </td>
                                <td>{d.email}</td>
                                <td>
                                  <button
                                    className="btn red"
                                    onClick={() =>
                                      self.deactivateAccount(d.email)
                                    }
                                  >
                                    Deactivate
                                  </button>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ margin: "10px" }}>
                      <tbody>
                        {freelancersList.map(function(d, idx) {
                          return (
                            d.userRole !== 2 &&
                            d.isActive === false && (
                              <tr key={idx}>
                                <td>{d.screenname}</td>
                                <td>
                                  {d.userRole === 1 && (
                                    <img
                                      src={AdminIcon}
                                      alt="Admin User"
                                      title="Admin User"
                                    />
                                  )}
                                  {d.userRole === 3 && (
                                    <img
                                      src={ServiceManagerIcon}
                                      alt="Service Manager"
                                      title="Service Manager"
                                    />
                                  )}
                                  {d.userRole === 4 && (
                                    <img
                                      src={OperationsManagerIcon}
                                      alt="Operations Manager"
                                      title="Operations Manager"
                                    />
                                  )}
                                </td>
                                <td>{d.email}</td>
                                <td>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      self.activateAccount(d.email)
                                    }
                                  >
                                    Activate
                                  </button>
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>

          {Object.keys(userDetails).length > 0 && (
            <div>
              <div className="popup">
                <button
                  type="button"
                  className="close-btn"
                  onClick={this.closePopup}
                >
                  +
                </button>
                <div className="row">
                  <div className="container">
                    <div className="col s12">
                      <div className="col s2">
                        <div
                          className="profile-container no-margin"
                          style={{ maxWidth: "120px" }}
                        >
                          {this.state.profileImage ? (
                            <img
                              src={`https://devfreelancersapi.supportpod.com/${profilePic}`}
                              alt={userDetails.screenname}
                              width="100%"
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="col s10">
                        <div className="col s4">
                          <h6>{userDetails.screenname}</h6>
                          <div className="">{userDetails.profiletitle}</div>
                        </div>
                        <div className="col s4">
                          <h6>
                            {userDetails.firstname} {userDetails.lastname}
                          </h6>
                          {Object.keys(contactDetails).length > 0 && (
                            <div>
                              {contactDetails.phone && (
                                <p>
                                  <img
                                    src={CallIcon}
                                    alt="Call Icon"
                                    style={{ width: "18px" }}
                                  />
                                  &nbsp; {contactDetails.phone}
                                </p>
                              )}
                            </div>
                          )}
                          {userDetails.email && (
                            <p>
                              <img
                                src={EmailIcon}
                                alt="Email Icon"
                                style={{ width: "18px" }}
                              />
                              &nbsp; {userDetails.email}
                            </p>
                          )}
                        </div>
                        <div className="col s4">
                          <p>
                            {contactDetails.region} {contactDetails.country}
                          </p>
                          <p>{contactDetails.zipcode} </p>
                        </div>
                      </div>
                      <div className="col s10">
                        <p>{userDetails.profilesummary}</p>
                      </div>
                    </div>

                    {skillMatrix.length > 0 && (
                      <div className="col s12 module">
                        <h6>Skills</h6>
                        <table className="striped">
                          <thead>
                            <tr>
                              <th>Categories</th>
                              <th>Skill</th>
                              <th>Ranking</th>
                              <th>Years of Exp</th>
                            </tr>
                          </thead>

                          <tbody>
                            {skillMatrix.map(function(d, idx) {
                              return (
                                <tr key={idx}>
                                  <td>{d.selectedCategory}</td>
                                  <td>{d.selectedSkills}</td>
                                  <td>{d.selectedRanking}</td>
                                  <td>{d.selectedYearsOfExp}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {socialProfiles.length > 0 && (
                      <div className="col s12 module">
                        <h6>Social Links</h6>
                        {socialProfiles.map(function(d, idx) {
                          return (
                            <p key={idx}>
                              <img
                                src={SocialIcon}
                                alt="Social Icon"
                                style={{ width: "18px" }}
                              />
                              &nbsp;
                              <a href={d} target="_blank">
                                {d}
                              </a>
                            </p>
                          );
                        })}
                      </div>
                    )}
                    <div className="col s12 module">
                      <h6>Verification Status</h6>
                      <div className="col s4">
                        <p>
                          {userDetails.isPhoneVerified ? (
                            <div>
                              <img
                                src={SuccessIcon}
                                style={{
                                  width: "24px",
                                  position: "relative",
                                  top: "7px",
                                }}
                              />
                              <span>
                                Phone Number Verified - Verified Number :
                                {userDetails.verifiedPhoneNumber}
                              </span>
                            </div>
                          ) : (
                            " Phone Number Not Verified"
                          )}
                        </p>
                      </div>
                      <div className="col s4">
                        <div className="row">
                          <div>
                            <h6>
                              {this.state.IDProofVerified ? (
                                <span>
                                  <img
                                    src={SuccessIcon}
                                    style={{
                                      width: "24px",
                                      position: "relative",
                                      top: "7px",
                                    }}
                                  />
                                </span>
                              ) : null}
                              Photo ID
                            </h6>
                            {previewId ? <>{previewId}</> : null}

                            {this.state.IDProofVerified ? (
                              <div>
                                <img
                                  src={SuccessIcon}
                                  style={{
                                    width: "24px",
                                    position: "relative",
                                    top: "7px",
                                  }}
                                />
                                <span>Verification completed</span>
                              </div>
                            ) : (
                              <>
                                {previewId && (
                                  <button
                                    className="btn left"
                                    onClick={this.approvePhotoId}
                                  >
                                    Mark it as Verified
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col s4">
                        <div className="row">
                          <div>
                            <h6>
                              {this.state.AddressProofVerified ? (
                                <span>
                                  <img
                                    src={SuccessIcon}
                                    style={{
                                      width: "24px",
                                      position: "relative",
                                      top: "7px",
                                    }}
                                  />
                                </span>
                              ) : null}
                              Address Proof / Utility Bill
                            </h6>
                            {previewAddress ? <>{previewAddress}</> : null}

                            {this.state.AddressProofVerified ? (
                              <div>
                                <img
                                  src={SuccessIcon}
                                  style={{
                                    width: "24px",
                                    position: "relative",
                                    top: "7px",
                                  }}
                                />
                                <span>Verification completed</span>
                              </div>
                            ) : (
                              <>
                                {previewAddress && (
                                  <button
                                    className="btn left"
                                    onClick={this.approveAddressProof}
                                  >
                                    Mark it as Verified
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Overlay />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

AdminUsers.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AdminUsers);
