import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";

class StatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      statusTypes: ["Under Negotiation", "Client Review", "Under Review"],
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    this.setState({ data });
  };

  handleChange = (event) => {
    const { data } = this.state;
    const { id, value } = event.target;
    console.log({ id }, { value });
    this.setState({ data: { ...data, [id]: value } });
  };

  render() {
    const { show, handleClose, updateStatus, isStatusChange } = this.props;
    const { data, statusTypes } = this.state;
    return (
      <>
        <Modal
          className="bid_proposal"
          show={show}
          onHide={handleClose}
          centered
          style={{ position: "fixed" }}
        >
          <Modal.Header>
            {/* closeButton*/}
            <Modal.Title style={{ display: "none" }}>
              {(isStatusChange && `Toggle Status`) ||
                "Provide Rejection remarks"}
              for Proposal ID - {data.bidProposalId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "10px" }}>
            <div className="container">
              <div className="row">
                {isStatusChange && (
                  <div
                    className="input-field col s6"
                    style={{ marginLeft: "100px", marginTop: "2rem" }}
                  >
                    <label style={{ fontWeight: "bold", fontSize: "18px" }}>
                      Status
                    </label>
                    <select
                      id="status"
                      required
                      className="browser-default dropdown-sel validate"
                      style={{ borderRadius: "10px", height: "40px" }}
                      onChange={this.handleChange}
                      value={data.status}
                    >
                      {statusTypes.map((e) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                )}

                {!isStatusChange && (
                  <div
                    className="input-field col s6"
                    style={{ marginLeft: "100px" }}
                  >
                    <label>Remarks</label>
                    <textarea
                      style={{
                        border: "1px solid #000000",
                        borderRadius: "15px",
                        outline: "none",
                      }}
                      id="rejectionRemarks"
                      required
                      type="text"
                      onChange={this.handleChange}
                      value={data.rejectionRemarks}
                    />
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="custombtn" onClick={updateStatus(data)}>
              Update
            </button>
            <button className="custombtn" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

StatusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  isStatusChange: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default StatusModal;
