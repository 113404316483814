import React, { Component } from "react";
import axios from "../../axios/index";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";

// import MoreIcon from "../../images/more-icon.png";
// import BidIcon from "../../images/bid-icon.png";
// import UserIcon from "../../images/user.png";

import EditWhiteIcon from "../../images/edit-white.png";
import LeftArrow from "../../images/left-arrow.png";
import PaperClip from "../../images/paperclip-icon.png";
import exchangeIcon from "../../images/exchange-icon.png";
import Overlay from "../layout/Overlay";
import Comments from "./Comments";
import Accordion from "react-bootstrap/Accordion";
import BidProposal from "../dashboard/BidProposal";
import Card from "react-bootstrap/Card";

import { ToastContainer, toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../Common/LoadingSpinner";
import StatusModal from "./StatusModal";

class BidRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biddetails: {},
      skills: [],
      title: "",
      description: "",
      daysleft: "",
      hoursleft: "",
      attachments: [],
      freelancersList: [],
      userRole: "",
      reassign: false,
      assignedTo: "",
      serviceRequestId: "",
      bidProposalId: "",
      bidProposalData: {},
      errors: {},
      openBidModal: false,
      modalData: {},
      bidProposals: [],
      isOpen: {},
      loading: false,
      openStatusModal: false,
      isStatusChange: false,
      status: "Under Negotiation",
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    const id = params.get("bidrequestid");
    this.setState({ serviceRequestId: id, loading: true });
    const bidProposalId = `${id}-${this.props.auth.user.email}`;
    this.setState({ serviceRequestId: id, bidProposalId });
    const userRole = this.props.auth.user.userRole;
    this.setState({ userRole: this.props.auth.user.userRole });
    const isActiveUser = this.props.auth.user.isActive;

    if (
      (isActiveUser && userRole === 1) ||
      (isActiveUser && userRole === 2) ||
      (isActiveUser && userRole === 3) ||
      (isActiveUser && userRole === 4)
    ) {
      const bidId = { bidrequestid: id.trim() };
      await axios
        .post("/api/bids/biddetails", bidId)
        .then(async (res) => {
          this.setState({ biddetails: res.data });
          this.setState({ skills: res.data.skills });
          this.setState({ attachments: res.data.attachments });
          this.setState({ title: res.data.title });
          this.setState({ assignedTo: res.data.assignedTo });

          var dateFuture = new Date(res.data.endDate);
          var dateNow = new Date();

          var seconds = Math.floor((dateFuture - dateNow) / 1000);
          var minutes = Math.floor(seconds / 60);
          var hours = Math.floor(minutes / 60);
          var days = Math.floor(hours / 24);

          hours = hours - days * 24;
          minutes = minutes - days * 24 * 60 - hours * 60;
          seconds =
            seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
          this.setState({ daysleft: days });
          this.setState({ hoursleft: hours });

          await this.getBidProposal();
        })
        .catch((err) => console.log(err));
    } else {
      this.props.history.push("/dashboard");
    }
    this.setState({ loading: false });

    if (this.props.history.location.state) {
      if (
        this.props.history.location.state.bidProposalId &&
        this.props.history.location.state.rootCommentId
      ) {
        let toggleBtn = document.getElementById(
          `bidProposalToggle-${this.props.history.location.state.bidProposalId}`
        );
        toggleBtn && toggleBtn.click();
        // console.log(toggle);
      } else {
        let bidProposal = document.getElementById(
          `bidProposal-${this.props.history.location.state.bidProposalId}`
        );
        bidProposal && bidProposal.scrollIntoView({ behavior: "smooth", block: 'center',inline: 'center'});
      }
    }
  }

  notify = (type, message) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  getBidProposal = async () => {
    const { isActive: isActiveUser, userRole } = this.props.auth.user;
    const { serviceRequestId } = this.state;
    this.setState({ loading: true });
    if (isActiveUser && userRole !== 2) {
      const bidId = { serviceRequestId: serviceRequestId.trim() };

      await axios
        .post("/api/bidproposals/bidproposals", bidId)
        .then((res) => {
          this.setState({
            bidProposals:
              (res.data.length && res.data.filter((e) => !e.isDraft)) || [],
          });
        })

        .catch((err) => console.log(err));
    } else if (isActiveUser && userRole === 2) {
      const { bidProposalId } = this.state;
      await axios
        .post("/api/bidproposals/bidproposaldetails", { bidProposalId })
        .then((res) => {
          this.setState({ bidProposalData: res.data });
        })
        .catch((err) => console.log(err));
    }
    this.setState({ openBidModal: false, loading: false });
  };

  handleEditBid = (event, id) => {
    event.preventDefault();
    this.props.history.push("/edit-bid?bidrequestid=" + id);
  };

  reAssignBid = async (event, id) => {
    event.preventDefault();
    this.setState({ loading: true });
    await axios
      .get("/api/users/adminusers")
      .then((res) => {
        this.setState({ freelancersList: res.data }, () => {});
        this.setState({ reassign: true });
      })
      .catch((err) => console.log(err));
    this.setState({ loading: false });
  };

  handleBack() {
    window.history.back();
  }

  handleReAssignBid = async (event, id) => {
    event.preventDefault();
    this.setState({ loading: true });
    var newAssignment = document.getElementById("assignedTo").value;

    const newBid = {
      serviceRequestId: id,
      assignedTo: newAssignment,
    };

    const bidId = { bidrequestid: this.state.serviceRequestId.trim() };

    await axios
      .post("/api/bids/assignto", newBid)
      .then((res) => {
        axios
          .post("/api/bids/biddetails", bidId)
          .then((res) => {
            this.setState({ reassign: false });
            this.setState({ biddetails: res.data });
            this.setState({ skills: res.data.skills });
            this.setState({ attachments: res.data.attachments });
            this.setState({ title: res.data.title });
            this.setState({ assignedTo: res.data.assignedTo });

            var dateFuture = new Date(res.data.endDate);
            var dateNow = new Date();

            var seconds = Math.floor((dateFuture - dateNow) / 1000);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            var days = Math.floor(hours / 24);

            hours = hours - days * 24;
            minutes = minutes - days * 24 * 60 - hours * 60;
            seconds =
              seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
            this.setState({ daysleft: days });
            this.setState({ hoursleft: hours });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  toggleBidProposal = (bool, data) => (event) => {
    const { biddetails } = this.state;
    if (bool) {
      this.setState({
        openBidModal: true,
        modalData: { ...biddetails, ...data },
        errors: {},
      });
    } else {
      this.setState({ openBidModal: false, modalData: {}, errors: {} });
    }
  };

  toggleAccordion = (id) => (event) => {
    const { isOpen } = this.state;
    isOpen[id] = !isOpen[id];
    this.setState({ isOpen });
  };

  colorToggle = {
    "Client Review": "#c8ddc9",
    Unsuccessful: "#f4bcb8",
    rejected: "#f4bcb8",
    "Under Negotiation": "#f6d7a9",
    accepted: "#c8ddc9",
    "Under Review": "#f6d7a9",
  };

  getCreateDuration = (date) => {
    let delta = Math.abs(new Date(date) - new Date()) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    // const seconds = delta % 60;
    return `${(days && `${days} days`) || ""} ${(hours && `${hours} hrs`) ||
      ""} ${(minutes && `${minutes} min`) || ""} ago`;
  };

  updateStatusApi = async (data) => {
    if (data.status === "open") {
      data.status = "Under Negotiation";
    }
    this.setState({ loading: true });
    const response = await axios.post(
      "/api/bidproposals/createbidproposal",
      data
    );

    if (response && response.status === 200) {
      this.notify("success", "Updated Status Successfully");
    } else {
      this.notify("error", "Failed to update status");
    }
    if (data.status === "accepted") {
      const { email } = this.props.auth.user;
      const { biddetails } = this.state;
      const resource = {
        ...data,
        ...biddetails,
        bidId: biddetails.title,
        freelancerEmail: data.createdBy,
        rating: "",
        remarks: "",
        createdBy: email,
        isFavourite: false,
      };
      await axios
        .post("/api/resource/createresource", resource)
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
      await axios.put("/api/bids/updateotherbids", biddetails).catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
    }

    this.setState({
      loading: false,
      openStatusModal: false,
      statusModalData: {},
    });
    this.getBidProposal();
  };

  toggleStatus = (data, status) => async (e) => {
    e.preventDefault();
    await this.updateStatusApi({ ...data, status });
  };

  toggleRemarks = (data, status) => async (e) => {
    e.preventDefault();
    this.setState({
      statusModalData: { ...data, status },
      openStatusModal: true,
      isStatusChange: false,
    });
  };

  toggleDropdownStatus = (data) => (e) => {
    e.preventDefault();
    this.setState({
      statusModalData: data,
      openStatusModal: true,
      isStatusChange: true,
    });
  };

  updateStatus = (data) => async (e) => {
    await this.updateStatusApi(data);
  };

  closeStatusModal = () => {
    this.setState({ openStatusModal: false, statusModalData: {} });
  };

  createQuote = (data) => (event) => {
    const { _id, bidProposalId } = data;
    const {
      customerEmail,
      customerName,
      serviceRequestId,
    } = this.state.biddetails;

    const obj = {
      _id,
      bidProposalId,
      serviceRequestId,
      customerName,
      customerEmail,
    };
    this.props.history.push({
      pathname: "/quote-details",
      state: obj,
    });
  };

  render() {
    const { isActive } = this.props.auth.user;
    const biddetails = this.state.biddetails;
    const skills = this.state.skills;
    const {
      bidProposalData,
      openBidModal,
      modalData,
      bidProposals,
      isOpen,
      loading,
      openStatusModal,
      statusModalData,
      isStatusChange,
    } = this.state;
    const attachments = this.state.attachments;
    const userRole = this.state.userRole;
    const data = this.state.serviceRequestId;
    return (
      <>
        <Navbar current={this.props.history.location.pathname} />
        {(loading && <LoadingSpinner />) || (
          <>
            <div className="row">
              <div className="container" style={{ marginTop: "40px" }}>
                <div className="col s8">
                  <h4>
                    <span onClick={this.handleBack}>
                      <img
                        src={LeftArrow}
                        alt="back"
                        style={{ width: "16px", marginRight: "10px" }}
                      />
                    </span>
                    {biddetails.title}
                  </h4>
                </div>
                <div className="col s4">
                  {((!bidProposalData && isActive && userRole === 2) ||
                    (bidProposalData && bidProposalData.isDraft)) && (
                    <h4>
                      <button
                        style={{ marginLeft: "20px" }}
                        className="btn right black"
                        onClick={this.toggleBidProposal(true, biddetails)}
                      >
                        Bid now
                      </button>
                    </h4>
                  )}
                  {userRole !== 2 && (
                    <h4>
                      <button
                        style={{ marginLeft: "20px" }}
                        className="btn right black"
                        onClick={(event) =>
                          this.handleEditBid(event, biddetails.serviceRequestId)
                        }
                      >
                        <img
                          src={EditWhiteIcon}
                          alt="edit"
                          style={{ width: "14px", marginRight: "5px" }}
                        />
                        Edit Bid Request
                      </button>
                      {(this.state.userRole === 1 ||
                        this.state.userRole === 4) && (
                        <button
                          className="btn right white"
                          onClick={(event) =>
                            this.reAssignBid(event, biddetails.serviceRequestId)
                          }
                          style={{ color: "black" }}
                        >
                          <img
                            src={exchangeIcon}
                            alt="edit"
                            style={{ width: "14px", marginRight: "5px" }}
                          />
                          Reassign
                        </button>
                      )}
                    </h4>
                  )}
                </div>
              </div>
              {(this.state.userRole === 1 || this.state.userRole === 4) &&
                this.state.reassign && (
                  <>
                    <div
                      className="input-field col s3"
                      style={{
                        position: "fixed",
                        zIndex: "100",
                        top: "25%",
                        left: "40%",
                        backgroundColor: "white",
                        padding: "20px 20px 20px 40px",
                      }}
                    >
                      <select
                        id="assignedTo"
                        onChange={this.onChange}
                        value={this.state.assignedTo}
                        className="browser-default"
                        style={{
                          marginLeft: "-15px",
                          borderRadius: "20px",
                          border: "1px solid rgba(0,0,0,0.44)",
                          height: "54px",
                        }}
                      >
                        {this.state.freelancersList.map(function(d, idx) {
                          return (
                            d.userRole === 3 &&
                            d.isActive && (
                              <option key={idx} value={d.email}>
                                {d.email}
                              </option>
                            )
                          );
                        })}
                      </select>
                      <label
                        htmlFor="assignedTo"
                        className="active"
                        style={{ marginLeft: "-15px", color: "white" }}
                      >
                        Assign To
                      </label>
                      <div style={{ marginTop: "20px" }}>
                        <button
                          className="btn right"
                          style={{ marginLeft: "20px" }}
                          onClick={(event) =>
                            this.handleReAssignBid(
                              event,
                              biddetails.serviceRequestId
                            )
                          }
                        >
                          Confirm
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                          className="btn red right"
                          onClick={(event) =>
                            this.setState({ reassign: false })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <Overlay />
                  </>
                )}
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                padding: "10px",
                border: "1px solid rgba(112,112,112,0.27)",
                marginBottom: "30px",
                marginTop: "20px",
              }}
              className=" container valign-wrapper"
            >
              <div className="row">
                <div className="landing-copy col s12">
                  <div className="col s10">
                    <p style={{ fontSize: "16px" }}>
                      <b>Service Request Id - {biddetails.serviceRequestId}</b>
                    </p>
                    <p>
                      <b>{biddetails.title}</b>
                    </p>
                    <p>{biddetails.description}</p>
                    <p>
                      <b>Skills :</b>
                      {skills.map(function(d, idx) {
                        return (
                          <span
                            style={{
                              display: "inline-block",
                              padding: "5px",
                              fontWeight: "bold",
                            }}
                            key={idx}
                          >
                            {d}
                          </span>
                        );
                      })}
                    </p>
                    <p>
                      <b>Category :</b> {biddetails.category}
                    </p>
                    {attachments && (
                      <>
                        <p>
                          <b>Attachments</b>
                        </p>
                        {this.state.attachments.map(function(item, idx) {
                          item = item.replace("public", "");
                          var extension = item.split(".").pop();
                          if (
                            extension === "jpg" ||
                            extension === "png" ||
                            extension === "jpeg" ||
                            extension === "PNG" ||
                            extension === "JPG" ||
                            extension === "gif"
                          ) {
                            return (
                              <span
                                className="file-field input-field "
                                style={{ float: "left" }}
                              >
                                <a
                                  target="_blank"
                                  href={`https://devfreelancersapi.supportpod.com${item}`}
                                >
                                  <img
                                    src={`https://devfreelancersapi.supportpod.com${item}`}
                                    alt="item"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      // height: "100%",
                                    }}
                                  />
                                </a>
                              </span>
                            );
                          } else {
                            return (
                              <>
                                <span
                                  className="file-field input-field attachment"
                                  style={{ float: "left" }}
                                >
                                  <a
                                    target="_blank"
                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                  >
                                    <img src={PaperClip} alt="attachments" />
                                  </a>
                                </span>
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                  <div className="col s2 center">
                    {biddetails.minBudget !== "" &&
                      biddetails.maxBudget !== "" && (
                        <p>
                          € {biddetails.minBudget} - {biddetails.maxBudget}
                        </p>
                      )}
                    {biddetails.minBudget === "" &&
                      biddetails.maxBudget !== "" && (
                        <p>€ {biddetails.maxBudget}</p>
                      )}
                    {isActive &&
                      userRole === 2 &&
                      bidProposalData &&
                      (bidProposalData.isDraft ||
                        !Object.keys(bidProposalData).length) && (
                        <button
                          className="custombtn"
                          onClick={this.toggleBidProposal(
                            true,
                            bidProposalData
                          )}
                        >
                          Bid now
                        </button>
                      )}
                    {biddetails.status === "open" && (
                      <div className="bid-time">
                        <span style={{ display: "inline-block" }}>
                          Bidding Ends In
                        </span>
                        <span style={{ display: "inline-block" }}>
                          <b>
                            {this.state.daysleft} days {this.state.hoursleft}
                            hours
                          </b>
                        </span>
                      </div>
                    )}
                    <p>
                      Bid Status
                      <br />
                      <b>{biddetails.status === "open" ? "Open" : "Closed"}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {bidProposalData && isActive && userRole === 2 && (
              <div
                id="my-bid-proposal"
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "20px",
                  padding: "10px",
                  border: "1px solid rgba(112,112,112,0.27)",
                  marginBottom: "30px",
                  marginTop: "20px",
                }}
                className=" container valign-wrapper"
              >
                <div className="row Mybid-proposal">
                  <div className="landing-copy col s12 mb-1r">
                    <h5>My Bid Proposal</h5>
                  </div>
                  <div className="col s12 p-0  mb-1r">
                    <div className="col s2">
                      <label>
                        {(bidProposalData.bidPrice &&
                          `€${bidProposalData.bidPrice}`) ||
                          ""}
                      </label>
                      <span>Price</span>
                    </div>
                    <div className="col s2">
                      <label>
                        {(bidProposalData.duration &&
                          `${bidProposalData.duration.replace("-", " ")}(s)`) ||
                          ""}
                      </label>
                      <span>Duration</span>
                    </div>
                    <div className="col s2">
                      <label>
                        {(bidProposalData.elapsedTime &&
                          `${bidProposalData.elapsedTime.replace(
                            "-",
                            " "
                          )}(s)`) ||
                          ""}
                      </label>

                      <span>Elapsed Time</span>
                    </div>
                    <div className="col s2">
                      <label>
                        {(bidProposalData.startDate &&
                          new Intl.DateTimeFormat("en-US").format(
                            new Date(bidProposalData.startDate)
                          )) ||
                          ""}
                      </label>
                      <span>Earliest Start Date</span>
                    </div>
                    {bidProposalData.status === "Under Negotiation" &&
                      isActive &&
                      userRole === 2 && (
                        <div
                          className="col s2"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            className="custombtn"
                            style={{ width: "100%" }}
                            onClick={this.toggleBidProposal(
                              true,
                              bidProposalData
                            )}
                          >
                            Edit Proposal
                          </button>
                        </div>
                      )}
                    <div className="col s2">
                      <button
                        className="custombtn"
                        style={{
                          width: "100%",
                          cursor: "default",
                          background: this.colorToggle[bidProposalData.status],
                          color:
                            (this.colorToggle[bidProposalData.status] &&
                              "#000000") ||
                            "#fffff",
                        }}
                      >
                        {(bidProposalData.status === "rejected" &&
                          "Unsuccessful") ||
                          bidProposalData.status}
                      </button>
                    </div>
                  </div>
                  <div className="col s12  mb-1r">
                    <label>Why Me?</label>
                    <p style={{ margin: "0" }}>{bidProposalData.whyMe}</p>
                  </div>
                  {bidProposalData.rejectionRemarks && (
                    <div className="col s12  mb-1r">
                      <label>Rejection Remarks</label>
                      <p style={{ margin: "0" }}>
                        {bidProposalData.rejectionRemarks}
                      </p>
                    </div>
                  )}
                  {bidProposalData.mileStones.length ? (
                    <div className="col s12  mb-1r">
                      <label>Preferred Milestones</label>
                      {bidProposalData.mileStones.map(
                        (bidProposalData, index) => (
                          <div className="col s12" key={index}>
                            <div className="col s1">
                              <p style={{ margin: "0" }}>{`${index + 1}.`}</p>
                            </div>
                            {bidProposalData.duration ? (
                              <div className="col s2">
                                <p style={{ margin: "0" }}>
                                  {`${bidProposalData.duration} ${bidProposalData.durationType}`}
                                </p>
                              </div>
                            ) : (
                              <div className="col s6">
                                <p style={{ margin: "0" }}>
                                  {bidProposalData.description}
                                </p>
                              </div>
                            )}
                            {bidProposalData.duration ? (
                              <div className="col s9">
                                <p style={{ margin: "0" }}>
                                  {`${bidProposalData.percentage} %`}
                                </p>
                              </div>
                            ) : (
                              <div className="col s4">
                                <p style={{ margin: "0" }}>
                                  {`${bidProposalData.percentage} %`}
                                </p>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                  <div className="col s12">
                    {(bidProposalData.attachments &&
                      bidProposalData.attachments.length && (
                        <>
                          <p>
                            <b>Attachments</b>
                          </p>
                          {bidProposalData.attachments.map(function(item, idx) {
                            item = item.replace("public", "");
                            var extension = item.split(".").pop();
                            if (
                              extension === "jpg" ||
                              extension === "png" ||
                              extension === "jpeg" ||
                              extension === "PNG" ||
                              extension === "JPG" ||
                              extension === "gif"
                            ) {
                              return (
                                <span
                                  className="file-field input-field "
                                  style={{ float: "left" }}
                                >
                                  <a
                                    target="_blank"
                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                  >
                                    <img
                                      src={`https://devfreelancersapi.supportpod.com${item}`}
                                      alt="item"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </a>
                                </span>
                              );
                            } else {
                              return (
                                <>
                                  <span
                                    className="file-field input-field attachment"
                                    style={{ float: "left" }}
                                  >
                                    <a
                                      target="_blank"
                                      href={`https://devfreelancersapi.supportpod.com${item}`}
                                    >
                                      <img src={PaperClip} alt="attachments" />
                                    </a>
                                  </span>
                                </>
                              );
                            }
                          })}
                        </>
                      )) ||
                      ""}
                  </div>
                </div>
              </div>
            )}
            {isActive && userRole !== 2 && (
              <>
                <div className="container">
                  <h5>
                    <b>{bidProposals && bidProposals.length} Bid(s) Proposed</b>
                  </h5>
                </div>
                {(bidProposals &&
                  bidProposals.length &&
                  bidProposals.map((item, idx) => (
                    <div className="container" key={idx}>
                      <Accordion>
                        <Card id={`bidProposal-${item._id}`}>
                          <div className="row">
                            <div className="col s12">
                              <label className="card-label">
                                Bid Proposal Id :{" "}
                              </label>
                              <h6>{item._id}</h6>
                            </div>
                          </div>
                          <Card.Header>
                            <div className="row">
                              <div className="comm-sec col s6">
                                <i className="col s1 profIcon">
                                  <FontAwesomeIcon icon={faUserCircle} />
                                </i>
                                <p className="col s3 comm-profile">
                                  <label>{item.createdBy}</label>
                                  <span>
                                    {this.getCreateDuration(item.date)}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="col s6"
                                style={{
                                  textAlign: "right",
                                  paddingTop: "15px",
                                }}
                              >
                                <button
                                  className="custombtn"
                                  disabled={
                                    item.status === "accepted" ||
                                    item.status === "rejected"
                                  }
                                  style={{
                                    background: this.colorToggle[item.status],
                                    color:
                                      (this.colorToggle[item.status] &&
                                        "#000000") ||
                                      "#fffff",
                                  }}
                                  onClick={this.toggleDropdownStatus(item)}
                                >
                                  {!(
                                    item.status === "accepted" ||
                                    item.status === "rejected" ||
                                    item.status === "open" ||
                                    item.status === "close"
                                  ) && (
                                    <>
                                      <img
                                        src={exchangeIcon}
                                        alt="edit"
                                        style={{ width: "14px" }}
                                      />
                                      &emsp;
                                    </>
                                  )}

                                  {item.status === "open"
                                    ? "Update Status"
                                    : item.status}
                                </button>
                                {!(
                                  item.status === "rejected" ||
                                  item.status === "accepted"
                                ) && (
                                  <>
                                    <button
                                      className="custombtn"
                                      onClick={this.toggleStatus(
                                        item,
                                        "accepted"
                                      )}
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className="custombtn"
                                      style={{ background: "#f06258" }}
                                      onClick={this.toggleRemarks(
                                        item,
                                        "rejected"
                                      )}
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="row Mybid-proposal">
                                <div className="col s8 p-0  mb-1r">
                                  <div className="col s3">
                                    <label>
                                      {(item.bidPrice && `€${item.bidPrice}`) ||
                                        ""}
                                    </label>
                                    <span>Price</span>
                                  </div>
                                  <div className="col s3">
                                    <label>
                                      {(item.duration &&
                                        `${item.duration.replace(
                                          "-",
                                          " "
                                        )}(s)`) ||
                                        ""}
                                    </label>
                                    <span>Duration</span>
                                  </div>
                                  <div className="col s3">
                                    <label>
                                      {(item.elapsedTime &&
                                        `${item.elapsedTime.replace(
                                          "-",
                                          " "
                                        )}(s)`) ||
                                        ""}
                                    </label>

                                    <span>Elapsed Time</span>
                                  </div>
                                  <div className="col s3">
                                    <label>
                                      {(item.startDate &&
                                        new Intl.DateTimeFormat("en-US").format(
                                          new Date(item.startDate)
                                        )) ||
                                        ""}
                                    </label>
                                    <span>Earliest Start Date</span>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="custombtn"
                                style={{ float: "right" }}
                                onClick={this.createQuote(item)}
                              >
                                + Create Quote
                              </button>
                            </div>
                            <Accordion.Toggle
                              className="accordion_toggle"
                              onClick={this.toggleAccordion(item._id)}
                              variant="link"
                              eventKey="0"
                              id={`bidProposalToggle-${item._id}`}
                            >
                              {(isOpen[idx] && "- show less") || "+ more"}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {item && (
                                <div className="row Mybid-proposal">
                                  <div className="col s12  mb-1r">
                                    <label>Why Me?</label>
                                    <p style={{ margin: "0" }}>{item.whyMe}</p>
                                  </div>
                                  {item.mileStones.length ? (
                                    <div className="col s12  mb-1r">
                                      <label>Preferred Milestones</label>
                                      {item.mileStones.map((item, index) => (
                                        <div className="col s12">
                                          <div className="col s1">
                                            <p
                                              style={{ margin: "0" }}
                                            >{`${index + 1}.`}</p>
                                          </div>
                                          {item.duration ? (
                                            <div className="col s2">
                                              <p style={{ margin: "0" }}>
                                                {`${item.duration} ${item.durationType}`}
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="col s6">
                                              <p style={{ margin: "0" }}>
                                                {item.description}
                                              </p>
                                            </div>
                                          )}
                                          {item.duration ? (
                                            <div className="col s9">
                                              <p style={{ margin: "0" }}>
                                                {`${item.percentage} %`}
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="col s4">
                                              <p style={{ margin: "0" }}>
                                                {`${item.percentage} %`}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ) : null}

                                  <div className="col s12">
                                    {(item.attachments &&
                                      item.attachments.length && (
                                        <>
                                          <p>
                                            <b>Attachments</b>
                                          </p>
                                          {item.attachments.map(function(
                                            item,
                                            idx
                                          ) {
                                            item = item.replace("public", "");
                                            var extension = item
                                              .split(".")
                                              .pop();
                                            if (
                                              extension === "jpg" ||
                                              extension === "png" ||
                                              extension === "jpeg" ||
                                              extension === "PNG" ||
                                              extension === "JPG" ||
                                              extension === "gif"
                                            ) {
                                              return (
                                                <span
                                                  className="file-field input-field "
                                                  style={{ float: "left" }}
                                                >
                                                  <a
                                                    target="_blank"
                                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                                  >
                                                    <img
                                                      src={`https://devfreelancersapi.supportpod.com${item}`}
                                                      alt="item"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    />
                                                  </a>
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <>
                                                  <span
                                                    className="file-field input-field attachment"
                                                    style={{ float: "left" }}
                                                  >
                                                    <a
                                                      target="_blank"
                                                      href={`https://devfreelancersapi.supportpod.com${item}`}
                                                    >
                                                      <img
                                                        src={PaperClip}
                                                        alt="attachments"
                                                      />
                                                    </a>
                                                  </span>
                                                </>
                                              );
                                            }
                                          })}
                                        </>
                                      )) ||
                                      ""}
                                  </div>
                                  <div className="col s12">
                                    <Comments
                                      id={data}
                                      bidId={item._id}
                                      requestedBy={"serviceManager"}
                                      requestFrom={"bidProposal"}
                                    />
                                  </div>
                                </div>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  ))) ||
                  ""}
              </>
            )}
            {openBidModal && (
              <BidProposal
                data={modalData}
                openBidModal={openBidModal}
                toggleBidProposal={this.toggleBidProposal}
                refreshBids={this.getBidProposal}
              />
            )}
            {userRole !== 2 ? (
              <Comments
                id={data}
                requestedBy={"serviceManager"}
                type={"root"}
                rootCommentId={
                  this.props.history.location.state
                    ? this.props.history.location.state.rootCommentId
                    : null
                }
                commentId={
                  this.props.history.location.state
                    ? this.props.history.location.state.commentId
                    : null
                }
              />
            ) : (
              <Comments
                id={data}
                type={"root"}
                requestedBy={"freelancer"}
                bidId={
                  bidProposalData
                    ? bidProposalData._id
                    : biddetails.serviceRequestId
                }
                rootCommentId={
                  this.props.history.location.state
                    ? this.props.history.location.state.rootCommentId
                    : null
                }
                commentId={
                  this.props.history.location.state
                    ? this.props.history.location.state.commentId
                    : null
                }
              />
            )}

            {openStatusModal && (
              <StatusModal
                show={openStatusModal}
                data={statusModalData}
                isStatusChange={isStatusChange}
                handleClose={this.closeStatusModal}
                updateStatus={this.updateStatus}
              />
            )}
          </>
        )}
        <ToastContainer />
      </>
    );
  }
}

BidRequest.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(BidRequest);
