import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import axios from "../../axios/index";
import { loginUser } from "../../actions/authActions";
import { connect } from "react-redux";

import Modal from "react-bootstrap/Modal";
import PaperClip from "../../images/paperclip-icon.png";
import LoadingSpinner from "../Common/LoadingSpinner";
import ClearIcon from "../../images/clear-icon.png";
import isEmpty from "is-empty";

class BidProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      durationElapsedInputArray: [
        "Minute",
        "Hour",
        "Day",
        "Week",
        "Month",
        "Year",
      ],
      extensionError: false,
      fileSizeError: false,
      fileDimensionError: false,
      errors: {},
      modalData: {
        serviceRequestId: "",
        title: "",
        bidPrice: "",
        whyMe: "",
        earliestStartDate: "",
        duration: "",
        elapsedTime: "",
        mileStones: [],
        bidProposalId: "",
        rejectionRemarks: "",
        elapsedTimeType: "",
        durationType: "",
        attachments: [],
      },
      milestonesType: "Time",
      timeMileStones: {
        duration: "",
        durationType: "",
        percentage: "",
      },
      eventMileStones: {
        description: "",
        percentage: "",
      },
      selectedFile: [],
      uploadFiles: [],
      loading: false,
    };
  }

  componentDidMount = async () => {
    const { data, openBidModal } = this.props;
    const { auth: { user: { email } = {} } = {} } = this.props;
    const {
      attachments: _,
      title,
      serviceRequestId,
      status: existingStatus,
      // eslint-disable-next-line no-unused-vars
      ...popupData
    } = data;
    const bidProposalId = `${serviceRequestId}-${email}`;
    this.setState({ loading: true });
    console.log({ data }, bidProposalId);
    await axios
      .post("/api/bidproposals/bidproposaldetails", {
        bidProposalId,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          this.setState({
            openBidModal,
            modalData: {
              ...res.data,
              duration: res.data.duration && res.data.duration.split("-")[0],
              durationType:
                res.data.duration && res.data.duration.split("-")[1],
              elapsedTime:
                res.data.duration && res.data.elapsedTime.split("-")[0],
              elapsedTimeType:
                res.data.duration && res.data.elapsedTime.split("-")[1],
              earliestStartDate:
                (res.data.startDate && new Date(res.data.startDate)) || "",
              attachments: res.data.attachments || [],
              rejectionRemarks: "",
              status: existingStatus,
            },
            errors: {},
          });
        } else {
          this.setState({
            modalData: {
              bidProposalId,
              serviceRequestId,
              title,
              status: existingStatus,
              whyMe: "",
              duration: "",
              elapsedTime: "",
              mileStones: [],
              bidPrice: "",
              earliestStartDate: "",
              elapsedTimeType: "",
              durationType: "",
              attachments: [],
            },
            openBidModal: true,
          });
        }
      })
      .catch((err) => console.log(err));

    this.setState({ loading: false });
  };

  handleChange = (name) => (event) => {
    const { modalData, errors } = this.state;

    const {
      target: { value },
    } = event;
    delete errors[name];

    const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;

    if (name === "bidPrice") {
      // const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;
      const regex = /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/;

      if (!regex.test(value)) {
        errors[name] = "Please enter a valid Positive Number";
      }
    }
    if (name === "duration" || name === "elapsedTime") {
      if (!regex.test(value)) {
        errors[name] = "Please enter a valid Positive Number";
      }
    }
    // if (name === "elapsedTime") {
    //   if (+value > +modalData.duration) {
    //     errors[name] = "Elapsed time is greater than Duration";
    //   } else {
    //     delete errors.duration;
    //     modalData[name] = value;
    //   }
    // }
    // if (name === "duration") {
    //   if (+value < +modalData.elapsedTime) {
    //     errors[name] = "Duration is lesser than Elapsed Time";
    //   } else {
    //     delete errors.elapsedTime;
    //     modalData[name] = value;
    //   }
    // }
    modalData[name] = value;
    this.setState({ modalData });
  };

  handleDateChange = (value) => {
    const { modalData, errors } = this.state;
    delete errors["earliestStartDate"];
    let selectedDate = new Date(value);
    let now = new Date();
    if (selectedDate < now) {
      errors["earliestStartDate"] = "Please enter a valid date";
    } else {
      modalData["earliestStartDate"] = selectedDate;
    }
    this.setState({ errors, modalData });
  };

  // HANDLE MILESTONE CHANGES
  handleMilestoneChanges = (event, id) => {
    if (id === "root") {
      if (this.state.milestonesType === "Time") {
        const timeMileStones = this.state.timeMileStones;
        if (event.target.name === "duration") {
          timeMileStones.duration = event.target.value;
        } else if (event.target.name === "durationType") {
          timeMileStones.durationType = event.target.value;
        } else if (event.target.name === "percentage") {
          timeMileStones.percentage = event.target.value;
        }

        this.setState({
          timeMileStones,
        });
      } else {
        const eventMileStones = this.state.eventMileStones;
        if (event.target.name === "description") {
          eventMileStones.description = event.target.value;
        } else if (event.target.name === "percentage") {
          eventMileStones.percentage = event.target.value;
        }
        this.setState({
          eventMileStones,
        });
      }
    } else {
      if (this.state.milestonesType === "Time") {
        const timeMileStones = this.state.modalData.mileStones[id];
        if (event.target.name === "duration") {
          timeMileStones.duration = event.target.value;
        } else if (event.target.name === "durationType") {
          timeMileStones.durationType = event.target.value;
        } else if (event.target.name === "percentage") {
          timeMileStones.percentage = event.target.value;
        }

        const updatedMS = [];

        this.state.modalData.mileStones.forEach((el, index) => {
          if (index === id) {
            updatedMS.push(timeMileStones);
          } else {
            updatedMS.push(el);
          }
        });

        this.setState({
          modalData: {
            ...this.state.modalData,
            mileStones: updatedMS,
          },
        });
      } else {
        const eventMileStones = this.state.modalData.mileStones[id];
        if (event.target.name === "description") {
          eventMileStones.description = event.target.value;
        } else if (event.target.name === "percentage") {
          eventMileStones.percentage = event.target.value;
        }

        const updatedMS = [];

        this.state.modalData.mileStones.forEach((el, index) => {
          if (index === id) {
            updatedMS.push(eventMileStones);
          } else {
            updatedMS.push(el);
          }
        });

        this.setState({
          modalData: {
            ...this.state.modalData,
            mileStones: updatedMS,
          },
        });
      }
    }
  };

  // ADD MILESTOMES
  onChangeMilestones = (event) => {
    event.preventDefault();
    if (this.state.milestonesType === "Time") {
      const timeMileStones = this.state.timeMileStones;
      timeMileStones.percentage = timeMileStones.percentage.replace("%", "");

      if (isEmpty(timeMileStones.duration) || isNaN(timeMileStones.duration)) {
        this.setState({
          errors: {
            ...this.state.errors,
            mileStone: "Please enter a valid duration numbers!",
          },
        });
      } else if (isEmpty(timeMileStones.durationType)) {
        this.setState({
          errors: {
            ...this.state.errors,
            mileStone: "Please enter mileStone durationType!",
          },
        });
      } else if (
        isEmpty(timeMileStones.percentage) ||
        isNaN(timeMileStones.percentage)
      ) {
        this.setState({
          errors: {
            ...this.state.errors,
            mileStone: "Please enter a valid percentage numbers!",
          },
        });
      } else {
        const mileStones = [...this.state.modalData.mileStones, timeMileStones];

        this.setState({
          modalData: {
            ...this.state.modalData,
            mileStones,
          },
          timeMileStones: {
            duration: "",
            durationType: "",
            percentage: "",
          },
          errors: {
            ...this.state.errors,
            mileStone: null,
          },
        });
      }
    } else {
      const eventMileStones = this.state.eventMileStones;
      eventMileStones.percentage = eventMileStones.percentage.replace("%", "");

      if (isEmpty(eventMileStones.description)) {
        this.setState({
          errors: {
            ...this.state.errors,
            mileStone: "Please enter a valid description!",
          },
        });
      } else if (
        isEmpty(eventMileStones.percentage) ||
        isNaN(eventMileStones.percentage)
      ) {
        this.setState({
          errors: {
            ...this.state.errors,
            mileStone: "Please enter a valid percentage numbers!",
          },
        });
      } else {
        const mileStones = [
          ...this.state.modalData.mileStones,
          eventMileStones,
        ];

        this.setState({
          modalData: {
            ...this.state.modalData,
            mileStones,
          },
          eventMileStones: {
            description: "",
            percentage: "",
          },
          errors: {
            ...this.state.errors,
            mileStone: null,
          },
        });
      }
    }
  };

  // REMOVE MILESTOMES
  removeMilestone = (idx) => {
    const newMilestones = this.state.modalData.mileStones.filter(
      (el, index) => index !== idx
    );

    this.setState({
      modalData: {
        ...this.state.modalData,
        mileStones: newMilestones,
      },
    });
  };

  // HANDLE MILESTONE TYPE CHANGES
  onChangeMilestoneType = (name) => {
    this.setState({
      milestonesType: name,
      modalData: {
        ...this.state.modalData,
        mileStones: [],
      },
      errors: {
        ...this.state.errors,
        mileStone: null,
      },
      timeMileStones: {
        duration: "",
        durationType: "",
        percentage: "",
      },
      eventMileStones: {
        description: "",
        percentage: "",
      },
    });
  };

  // ADD ATTACHMENTS
  onChangeHandler = (event) => {
    if (
      document.getElementsByClassName("uploaded_attachments_bid_proposal")
        .length
    ) {
      var attaches = document.querySelectorAll(
        ".uploaded_attachments_bid_proposal"
      );
      for (var i = 0; i < attaches.length; i++) {
        attaches[i].style.display = "none";
      }
    }

    var ext = event.target.files[0].name.split(".").pop();
    var fileSize = event.target.files[0].size;
    var file = event.target.files[0];

    // FILE SIZE VALIDATION
    if (fileSize > 5000000) {
      this.setState({
        fileSizeError: true,
      });
    } else {
      this.setState({
        fileSizeError: false,
      });
    }

    // FILE EXTANSION VALIDATION
    if (
      ext !== "png" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "gif" &&
      ext !== "PNG" &&
      ext !== "JPG" &&
      ext !== "JPEG" &&
      ext !== "GIF"
    ) {
      this.setState({
        extensionError: true,
        fileSizeError: false,
        fileDimensionError: false,
      });

      if (
        ext === "pdf" ||
        ext === "PDF" ||
        ext === "doc" ||
        ext === "docx" ||
        ext === "DOCX" ||
        ext === "xlsx" ||
        ext === "XLSX" ||
        ext === "PPTX" ||
        ext === "pptx"
      ) {
        this.setState({
          extensionError: false,
          fileSizeError: false,
          fileDimensionError: false,
        });
      }
      document.getElementById("attachments_root_bid_proposal").style.display =
        "block";

      const attachmentUrl = URL.createObjectURL(file);

      this.setState({
        selectedFile: [...this.state.selectedFile, file],
        uploadFiles: [...this.state.uploadFiles, [file.name, attachmentUrl]],
        modalData: {
          ...this.state.modalData,
          attachments: [...this.state.modalData.attachments, attachmentUrl],
        },
      });
    } else {
      this.setState({
        extensionError: false,
        fileSizeError: false,
        fileDimensionError: false,
      });

      document.getElementById("attachments_root_bid_proposal").style.display =
        "block";
      const attachmentUrl = URL.createObjectURL(file);

      let img = new Image();
      img.src = attachmentUrl;

      img.onload = () => {
        var width = img.naturalWidth;
        var height = img.naturalHeight;

        if (width > 2000 || height > 2000) {
          window.URL.revokeObjectURL(img.src);
          this.setState({
            extensionError: false,
            fileSizeError: false,
            fileDimensionError: true,
          });
        } else {
          this.setState({
            selectedFile: [...this.state.selectedFile, file],
            uploadFiles: [
              ...this.state.uploadFiles,
              [file.name, attachmentUrl],
            ],
            modalData: {
              ...this.state.modalData,
              attachments: [...this.state.modalData.attachments, attachmentUrl],
            },
          });
        }
      };
    }
  };

  // REMOVE ATTACHMENTS
  removeAttachments = (attachmentId, fileUrl) => {
    const updatedUploades = [];
    const removeUpload = this.state.uploadFiles.filter(
      (el) => el[1] === fileUrl
    );

    const newMaping = this.state.uploadFiles.filter((el) => el[1] !== fileUrl);

    this.state.selectedFile.forEach((el) => {
      if (el.name === removeUpload[0][0]) {
        window.URL.revokeObjectURL(fileUrl);
      } else {
        updatedUploades.push(el);
      }
    });

    const updatedAttachments = this.state.modalData.attachments.filter(
      (currentValue, index) => attachmentId * 1 !== index
    );

    this.setState({
      modalData: {
        ...this.state.modalData,
        attachments: updatedAttachments,
      },
      selectedFile: updatedUploades,
      uploadFiles: newMaping,
    });
  };

  // UPLOAD ATTACHMENTS
  uploadAttachments = async () => {
    if (
      this.state.selectedFile !== null &&
      this.state.selectedFile !== undefined &&
      this.state.selectedFile !== "undefined"
    ) {
      const { bidProposalId, serviceRequestId } = this.state.modalData;
      const { modalData: prevModalData } = this.state;

      this.state.uploadFiles.forEach((el) => {
        let img = new Image();
        img.src = el[1];

        img.onload = () => {
          var width = img.naturalWidth;
          var height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);

          if (width > 2000 || height > 2000) {
            this.setState({ fileDimensionError: true });
          } else {
            this.setState({ fileDimensionError: false });
          }
        };
      });

      const data = new FormData();
      data.append("bidProposalId", bidProposalId);
      data.append("serviceRequestId", serviceRequestId);

      this.state.selectedFile.forEach((el, index) => {
        var ext = el.name.split(".").pop();
        var fileSize = el.size;

        if (fileSize > 50000000) {
          this.setState({ fileSizeError: true });
        } else {
          this.setState({ fileSizeError: false });
        }

        if (
          ext !== "png" &&
          ext !== "jpg" &&
          ext !== "jpeg" &&
          ext !== "gif" &&
          ext !== "PNG" &&
          ext !== "JPG" &&
          ext !== "JPEG" &&
          ext !== "GIF"
        ) {
          this.setState({
            extensionError: true,
            fileSizeError: false,
            fileDimensionError: false,
          });

          if (
            ext === "pdf" ||
            ext === "PDF" ||
            ext === "doc" ||
            ext === "docx" ||
            ext === "DOCX" ||
            ext === "xlsx" ||
            ext === "XLSX" ||
            ext === "PPTX" ||
            ext === "pptx"
          ) {
            this.setState({
              extensionError: false,
              fileSizeError: false,
              fileDimensionError: false,
            });
          }

          data.append("file", el);
        } else {
          this.setState({
            extensionError: false,
            fileSizeError: false,
            fileDimensionError: false,
          });
          this.setState({ extensionError: false });
          data.append("file", el);
        }
      });

      try {
        const res = await axios.post("/api/bidproposals/upload", data);
        console.log(res.data);

        var file_paths = this.state.modalData.attachments.filter(
          (file) => !file.startsWith("blob")
        );

        console.log(file_paths);
        console.log(this.state.modalData.attachments);

        res.data.forEach((file_path) => {
          if (file_path !== undefined) {
            if (file_path.length > 0) {
              file_path = file_path.replace("public", "");
            }
          }
          file_paths.push(file_path);
        });
        this.setState({
          modalData: {
            ...prevModalData,
            attachments: file_paths,
          },
          selectedFile: [],
          uploadFiles: [],
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  validator = (data, prevErr) =>
    Object.keys(data).reduce(
      (errors, item) => ({
        ...errors,
        [item]:
          prevErr[item] ||
          (item === "bidPrice" && !data.bidPrice && "Required Field") ||
          (typeof data[item] === "string" &&
            !data[item].trim() &&
            "Required Field"),
      }),
      {}
    );

  save = async ({ isDraft }) => {
    const { auth: { user: { email } = {} } = {}, refreshBids } = this.props;
    const { modalData, errors: previousErrors } = this.state;

    let unsavedMileStone;
    if (this.state.milestonesType === "Time") {
      const timeMileStones = this.state.timeMileStones;
      if (
        timeMileStones.duration !== "" &&
        timeMileStones.durationType !== "" &&
        timeMileStones.percentage !== ""
      ) {
        unsavedMileStone = timeMileStones;
      }
    } else {
      const eventMileStones = this.state.eventMileStones;
      if (
        eventMileStones.description !== "" &&
        eventMileStones.percentage !== ""
      ) {
        unsavedMileStone = eventMileStones;
      }
    }

    let mileStones = [...this.state.modalData.mileStones];

    if (unsavedMileStone) {
      mileStones = [...mileStones, unsavedMileStone];
    }

    const {
      serviceRequestId,
      title,
      whyMe,
      earliestStartDate,
      duration,
      durationType,
      elapsedTime,
      elapsedTimeType,
      bidProposalId,
      bidPrice,
      attachments,
      status,
      // eslint-disable-next-line no-unused-vars
      ..._
    } = modalData;
    const reqBody = {
      serviceRequestId,
      title,
      bidPrice,
      whyMe: whyMe.trim(),
      earliestStartDate,
      duration: (duration && `${duration}-${durationType}`) || "",
      elapsedTime: (elapsedTime && `${elapsedTime}-${elapsedTimeType}`) || "",
      mileStones: mileStones,
      createdBy: email,
      bidProposalId,
      firstname: this.props.auth.user.firstname,
      profileImage: this.props.auth.user.profileImage.replace("public", ""),
    };

    const errors = this.validator(
      { ...reqBody, durationType, elapsedTimeType },
      previousErrors
    );
    console.log(reqBody);
    if (!isDraft && Object.values(errors).filter(Boolean).length) {
      this.setState({ errors });
      return;
    }
    if (
      Object.values(errors).filter((e) => e && e !== "Required Field").length
    ) {
      return;
    }
    this.setState({ loading: true });
    const filteredAttachments = this.state.modalData.attachments.filter(
      (file) => !file.startsWith("blob")
    );

    const response = await axios.post("/api/bidproposals/createbidproposal", {
      ...reqBody,
      startDate: earliestStartDate || "",
      attachments: filteredAttachments || [],
      isDraft,
      status: status || "Under Review",
      rejectionRemarks: "",
    });

    if (response && response.data && response.status !== 200) {
      this.setState({ loading: false });
    }

    await axios.post("/api/bids/updatebidparticipants", {
      ...reqBody,
      isDraft,
    });

    if (this.state.selectedFile.length > 0) {
      await this.uploadAttachments();
    }

    await refreshBids();
    this.setState({
      attachments: [],
      modalData: {},
      loading: false,
      openBidModal: false,
      currentMileStones: {
        duration: "",
        durationType: "",
        mileStone: "",
      },
    });
  };

  render() {
    const { openBidModal, toggleBidProposal } = this.props;
    const {
      modalData,
      durationElapsedInputArray,
      errors,
      loading,
    } = this.state;
    return (
      <>
        <Modal
          className="bid_proposal"
          show={openBidModal}
          onHide={toggleBidProposal(false, {})}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Bid Proposal</Modal.Title>
          </Modal.Header>
          {(loading && <LoadingSpinner />) || (
            <>
              <Modal.Body>
                <span className="projID">
                  Service Request ID:#{modalData.serviceRequestId}
                </span>
                <p className="title">{modalData.title}</p>
                <div className="row">
                  <div className="input-field col s6">
                    <label>
                      Price<span className="red-text">*</span>
                    </label>
                    <span className="prictag">€</span>
                    <input
                      required
                      style={{
                        paddingLeft: "60px",
                        borderRadius: "10px",
                        height: "40px",
                        margin: 0,
                      }}
                      onChange={this.handleChange("bidPrice")}
                      value={modalData.bidPrice}
                      className="validate"
                    />
                    <div className="red-text">{errors.bidPrice}</div>
                  </div>

                  <div className="input-field col s6">
                    <label>
                      Estimated Time<span className="red-text">*</span>
                    </label>
                    <div className="input-field col s6 m-0 p-0">
                      <input
                        required
                        onChange={this.handleChange("duration")}
                        value={modalData.duration}
                        className="validate"
                      />
                      <div className="red-text">{errors.duration}</div>
                    </div>
                    <div
                      className="col s6"
                      style={{ padding: "0", paddingLeft: "7px" }}
                    >
                      {/* <label>
                        <span className="red-text">*</span>
                      </label> */}
                      <select
                        required
                        className="browser-default dropdown-sel validate"
                        style={{ borderRadius: "10px", height: "40px" }}
                        onChange={this.handleChange("durationType")}
                        value={modalData.durationType}
                      >
                        <option value=""></option>
                        {durationElapsedInputArray.map((e) => (
                          <option value={e}>{e}(s)</option>
                        ))}
                      </select>
                      <div className="red-text">{errors.durationType}</div>
                    </div>
                  </div>
                  <div className="col s12 p-0">
                    <div className="input-field col s6 pl-0">
                      <label>
                        Elapsed Time<span className="red-text">*</span>
                      </label>
                      <div className="input-field col s7 m-0">
                        <input
                          required
                          onChange={this.handleChange("elapsedTime")}
                          value={modalData.elapsedTime}
                          className="validate"
                        />
                        <div className="red-text">{errors.elapsedTime}</div>
                      </div>
                      <div className="col s5 p-0">
                        {/* <label>
                        <span className="red-text">*</span>
                      </label> */}
                        <select
                          required
                          className="browser-default dropdown-sel validate"
                          style={{ borderRadius: "10px", height: "40px" }}
                          onChange={this.handleChange("elapsedTimeType")}
                          value={modalData.elapsedTimeType}
                        >
                          <option value=""></option>
                          {durationElapsedInputArray.map((e) => (
                            <option value={e}>{e}(s)</option>
                          ))}
                        </select>
                        <div className="red-text">{errors.elapsedTimeType}</div>
                      </div>
                    </div>
                    <div className="input-field col s6">
                      <label>
                        Earliest Start Date<span className="red-text">*</span>
                      </label>
                      <DatePicker
                        calendarClassName="input-field"
                        id="earliestStartDate"
                        style
                        selected={modalData.earliestStartDate || ""}
                        onChange={this.handleDateChange}
                        className="datepicker"
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <div className="red-text">{errors.earliestStartDate}</div>
                    </div>
                  </div>

                  <div className="input-field col s12">
                    <label>
                      Why Me?<span className="red-text">*</span>
                    </label>
                    <textarea
                      required
                      placeholder="Describe Your Skillset"
                      className="validate materialize-textarea m-0 "
                      onChange={this.handleChange("whyMe")}
                      value={modalData.whyMe}
                      style={{
                        overflowY: "scroll",
                        height: "120px",
                        maxHeight: "120px",
                      }}
                    ></textarea>
                    <div className="red-text">{errors.whyMe}</div>
                  </div>
                  <div
                    className="input-field col s12"
                    style={{ marginLeft: "0", paddingLeft: "0" }}
                  >
                    <div className="input-field col s6">
                      <label>
                        Preferred Milestones
                        <span className="red-text">*</span>
                      </label>
                    </div>
                    <div className="col s6">
                      <button
                        className="custombtn"
                        onClick={() => this.onChangeMilestoneType("Event")}
                        style={{
                          width: "70px",
                          height: "25px",
                          float: "right",
                          margin: "0 2px",
                          color:
                            this.state.milestonesType === "Event"
                              ? "white"
                              : "black",
                          backgroundColor:
                            this.state.milestonesType === "Event"
                              ? "black"
                              : "white",
                          border: "1px solid gray",
                          borderRadius: "5px",
                        }}
                      >
                        Event
                      </button>
                      <button
                        className="custombtn"
                        onClick={() => this.onChangeMilestoneType("Time")}
                        style={{
                          width: "70px",
                          height: "25px",
                          float: "right",
                          margin: "0 2px",
                          color:
                            this.state.milestonesType === "Time"
                              ? "white"
                              : "black",
                          backgroundColor:
                            this.state.milestonesType === "Time"
                              ? "black"
                              : "white",
                          border: "1px solid gray",
                          borderRadius: "5px",
                        }}
                      >
                        Time
                      </button>
                    </div>
                    {this.state.milestonesType === "Time" ? (
                      <div
                        id="preferred_milestones_root"
                        className="preferred_milestones"
                        style={{ margin: "0", padding: "0" }}
                      >
                        {this.state.modalData.mileStones.map((item, idx) => (
                          <div
                            id={`preferred_milestones_${idx}`}
                            className="col s12"
                            style={{ margin: "0", padding: "0" }}
                          >
                            <div
                              className="input-field col s12 m-0"
                              style={{ margin: "0", padding: "0" }}
                            >
                              <div
                                className="col s1"
                                style={{ margin: "0", padding: "0" }}
                              >
                                {" "}
                                <p>
                                  <b>{`${idx + 1}.`}</b>
                                </p>
                              </div>
                              <div className="col s10"></div>
                              <div
                                className="col s1"
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <p
                                  style={{
                                    float: "right",
                                    paddingRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.removeMilestone(idx)}
                                >
                                  {`X`}
                                </p>
                              </div>
                            </div>
                            <div
                              className="input-field col s4 m-0"
                              style={{ marginLeft: "0" }}
                            >
                              <input
                                required
                                name="duration"
                                onChange={(event) =>
                                  this.handleMilestoneChanges(event, idx)
                                }
                                value={item.duration}
                                className="validate"
                              />
                            </div>
                            <div className="input-field col s4 m-0">
                              <select
                                required
                                name="durationType"
                                placeholder="Hours/Days"
                                className="browser-default dropdown-sel validate"
                                style={{ borderRadius: "10px", height: "40px" }}
                                onChange={(event) =>
                                  this.handleMilestoneChanges(event, idx)
                                }
                                value={item.durationType}
                              >
                                <option value=""></option>
                                {durationElapsedInputArray.map((e) => (
                                  <option value={e}>{e}(s)</option>
                                ))}
                              </select>
                            </div>

                            <div
                              className="input-field col s4 m-0"
                              style={{ marginRight: "0" }}
                            >
                              <input
                                required
                                name="percentage"
                                placeholder="% of total Payment"
                                onChange={(event) =>
                                  this.handleMilestoneChanges(event, idx)
                                }
                                value={item.percentage}
                                className="validate"
                              />
                            </div>

                            <div className="red-text">{errors.mileStones}</div>
                          </div>
                        ))}
                        {/*  Default MileStone Form*/}
                        <div
                          className="col s12"
                          style={{
                            margin: "0",
                            marginTop: "10px",
                            padding: "0",
                          }}
                        >
                          <p>
                            <b>{``}</b>
                          </p>
                          <div
                            className="input-field col s4 m-0"
                            style={{ marginLeft: "0" }}
                          >
                            <input
                              required
                              name="duration"
                              placeholder="Number"
                              onChange={(event) =>
                                this.handleMilestoneChanges(event, "root")
                              }
                              value={this.state.timeMileStones.duration}
                              className="validate"
                            />
                          </div>
                          <div className="input-field col s4 m-0">
                            <select
                              required
                              name="durationType"
                              placeholder="Hours/Days"
                              className="browser-default dropdown-sel validate"
                              style={{ borderRadius: "10px", height: "40px" }}
                              onChange={(event) =>
                                this.handleMilestoneChanges(event, "root")
                              }
                              value={this.state.timeMileStones.durationType}
                            >
                              <option value=""></option>
                              {durationElapsedInputArray.map((e) => (
                                <option value={e}>{e}(s)</option>
                              ))}
                            </select>
                          </div>
                          <div
                            className="input-field col s4 m-0"
                            style={{ marginRight: "0" }}
                          >
                            <input
                              required
                              name="percentage"
                              placeholder="% of total Payment"
                              onChange={(event) =>
                                this.handleMilestoneChanges(event, "root")
                              }
                              value={this.state.timeMileStones.percentage}
                              className="validate"
                            />
                          </div>
                          <div className="red-text">{errors.mileStone}</div>
                        </div>
                      </div>
                    ) : (
                      <div
                        id="preferred_milestones_root"
                        className="preferred_milestones"
                        style={{ margin: "0", padding: "0" }}
                      >
                        {this.state.modalData.mileStones.map((item, idx) => (
                          <div
                            id={`preferred_milestones_${idx}`}
                            className="col s12"
                            style={{ margin: "0", padding: "0" }}
                          >
                            <div
                              className="input-field col s12 m-0"
                              style={{ margin: "0", padding: "0" }}
                            >
                              <div
                                className="col s1"
                                style={{ margin: "0", padding: "0" }}
                              >
                                {" "}
                                <p>
                                  <b>{`${idx + 1}.`}</b>
                                </p>
                              </div>
                              <div className="col s10"></div>
                              <div
                                className="col s1"
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <p
                                  style={{
                                    float: "right",
                                    paddingRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.removeMilestone(idx)}
                                >
                                  {`X`}
                                </p>
                              </div>
                            </div>
                            <div
                              className="input-field col s8 m-0"
                              style={{ marginLeft: "0" }}
                            >
                              <textarea
                                required
                                name="description"
                                placeholder="Preferred Milestones Description"
                                className="validate materialize-textarea m-0"
                                onChange={(event) =>
                                  this.handleMilestoneChanges(event, idx)
                                }
                                value={item.description}
                              ></textarea>
                            </div>
                            <div
                              className="input-field col s4 m-0"
                              style={{ marginRight: "0" }}
                            >
                              <input
                                required
                                name="percentage"
                                placeholder="% of total Payment"
                                onChange={(event) =>
                                  this.handleMilestoneChanges(event, idx)
                                }
                                value={item.percentage}
                                className="validate"
                              />
                            </div>
                            <div className="red-text">{errors.mileStones}</div>
                          </div>
                        ))}
                        {/*  Default MileStone Form*/}
                        <div
                          className="col s12"
                          style={{
                            margin: "0",
                            marginTop: "10px",
                            padding: "0",
                          }}
                        >
                          <p>
                            <b>{``}</b>
                          </p>
                          <div
                            className="input-field col s8 m-0"
                            style={{ marginLeft: "0" }}
                          >
                            <textarea
                              required
                              name="description"
                              placeholder="Preferred Milestones Description"
                              className="validate materialize-textarea m-0"
                              onChange={(event) =>
                                this.handleMilestoneChanges(event, "root")
                              }
                              value={this.state.eventMileStones.description}
                            ></textarea>
                            <div className="red-text">{errors.mileStone}</div>
                          </div>

                          <div
                            className="input-field col s4 m-0"
                            style={{ marginRight: "0" }}
                          >
                            <input
                              required
                              name="percentage"
                              placeholder="% of total Payment"
                              onChange={(event) =>
                                this.handleMilestoneChanges(event, "root")
                              }
                              value={this.state.eventMileStones.percentage}
                              className="validate"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {/*  Add Another Milestone Button*/}
                    <div
                      className="col s12 m-0"
                      style={{ margin: "0", padding: "0" }}
                    >
                      <div
                        className="col s8 m-0"
                        style={{ margin: "0", padding: "0" }}
                      ></div>
                      <div
                        className="col s4 m-0"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <button
                          className="custombtn"
                          onClick={(event) => this.onChangeMilestones(event)}
                          style={{
                            marginTop: "10px",
                            width: "180px",
                            height: "40px",
                            float: "right",
                            marginRight: "10px",
                            color: "black",
                            backgroundColor: "white",
                            border: "1px solid gray",
                            borderRadius: "10px",
                          }}
                        >
                          Add Another Milestone
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col s12">
                    <p>Add Attachments</p>
                    <div
                      id="attachments_root_bid_proposal"
                      className="uploaded_attachments_bid_proposal"
                      style={{ display: "none" }}
                    >
                      {this.state.modalData.attachments.map(
                        function(item, idx) {
                          if (item.startsWith("blob")) {
                            return (
                              <span
                                className="file-field input-field "
                                style={{
                                  float: "left",
                                  position: "relative",
                                }}
                                key={idx}
                              >
                                <a href={item} target="_blank">
                                  <img
                                    src={item}
                                    alt="Attachment"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </a>
                                <img
                                  src={ClearIcon}
                                  alt="attachments"
                                  style={{
                                    display: "block",
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: 1,
                                    width: "25%",
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    opacity: "85%",
                                  }}
                                  onClick={() =>
                                    this.removeAttachments(idx, item)
                                  }
                                />
                              </span>
                            );
                          } else {
                            item = item.replace("public", "");
                            var extension = item.split(".").pop();
                            if (
                              extension === "jpg" ||
                              extension === "png" ||
                              extension === "jpeg" ||
                              extension === "PNG" ||
                              extension === "JPG" ||
                              extension === "gif"
                            ) {
                              return (
                                <span
                                  className="file-field input-field "
                                  style={{ float: "left" }}
                                  key={idx}
                                >
                                  <a
                                    target="_blank"
                                    href={`https://devfreelancersapi.supportpod.com${item}`}
                                  >
                                    <img
                                      src={`https://devfreelancersapi.supportpod.com${item}`}
                                      alt="item"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </a>
                                </span>
                              );
                            } else {
                              return (
                                <>
                                  <span
                                    className="file-field input-field attachment"
                                    style={{ float: "left" }}
                                    key={idx}
                                  >
                                    <a
                                      target="_blank"
                                      href={`https://devfreelancersapi.supportpod.com${item}`}
                                    >
                                      <img src={PaperClip} alt="attachments" />
                                      <img src={item} alt="item" />
                                    </a>
                                  </span>
                                </>
                              );
                            }
                          }
                        }.bind(this)
                      )}
                    </div>
                    <div
                      className="file-field input-field"
                      style={{ width: "42px", height: "42px", float: "left" }}
                    >
                      <div
                        className="btn"
                        style={{ width: "42px", height: "42px" }}
                      >
                        <span
                          style={{
                            marginTop: "4px",
                            marginLeft: "-13px",
                            backgroundSize: "24px 24px",
                          }}
                        >
                          File
                        </span>
                        <input
                          type="file"
                          title="Add Attachment"
                          name="file"
                          onChange={(event) => this.onChangeHandler(event)}
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 101,
                          }}
                        />
                      </div>
                      <div
                        className="file-path-wrapper"
                        style={{
                          visibility: "hidden",
                          height: "1px",
                          width: "1px",
                        }}
                      >
                        <input
                          className="file-path validate"
                          type="text"
                          placeholder="Upload one or more files"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", zIndex: "-9" }}>
                    {this.state.extensionError && (
                      <span className="red-text">
                        Please upload a valid file
                        <br />
                        (png, jpg, jpeg, gif, xl, pdf, docx)
                      </span>
                    )}
                    {this.state.fileDimensionError && (
                      <span className="red-text">
                        Please select a image below 2000 pixels
                      </span>
                    )}
                    {this.state.fileSizeError && (
                      <span className="red-text">
                        Maximum file size allowed is 2MB
                      </span>
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="custombtn"
                  onClick={() => this.save({ isDraft: true })}
                >
                  Save As Draft
                </button>

                <button
                  className="custombtn"
                  onClick={() => this.save({ isDraft: false })}
                >
                  Save & Submit
                </button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </>
    );
  }
}

BidProposal.propTypes = {
  errors: PropTypes.object.isRequired,
  openBidModal: PropTypes.bool.isRequired,
  toggleBidProposal: PropTypes.func.isRequired,
  refreshBids: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(BidProposal);
