import React, { Component } from "react";
import { Link } from "react-router-dom";
class AdminNav extends Component {
  render() {
    return (
      <div class="nav-content">
		  <ul class="tabs tabs-transparent">
		    <li class="tab"><a href="#test1">Test 1</a></li>
		    <li class="tab"><a class="active" href="#test2">Test 2</a></li>
		    <li class="tab"><Link to="/">Test 3</Link></li>
		    <li class="tab"><a href="#test4">Test 4</a></li>
		  </ul>
		</div>
    );
  }
}

export default AdminNav;

